const QuoteToken = {
  BNB: 'BNB',
  CAKE: 'CAKE',
  SYRUP: 'SYRUP',
  BUSD: 'BUSD',
  TWT: 'TWT',
  UST: 'UST',
  ETH: 'ETH',
  COMP: 'COMP',
  SUSHI: 'SUSHI',
  TPT: 'TPT',
  MINT: 'MINT',
  JIG: 'JIG',
  ZAP: 'ZAP'
};

export default QuoteToken;

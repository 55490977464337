import Farming from './Farming';
import { ApolloProvider, ApolloLink, HttpLink, ApolloClient, InMemoryCache } from '@apollo/client';

const BASE_URL = 'https://api.thegraph.com/subgraphs/name';

const masterChefLink = new HttpLink({
  uri: `${BASE_URL}/sushiswap/master-chef`
});

const exchangeLink = new HttpLink({ uri: `${BASE_URL}/matthewlilley/exchange` });

const client = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/sushiswap/master-chef',
  link: ApolloLink.split(
    operation => operation.getContext().clientName === 'masterchef',
    // the string "third-party" can be anything you want,
    // we will use it in a bit
    masterChefLink, // <= apollo will send to this if clientName is "third-party"
    exchangeLink // <= otherwise will send to this
  ),
  cache: new InMemoryCache()
});

const FarmingWithProvider = () => {
  return (
    <ApolloProvider client={client}>
      <Farming />
    </ApolloProvider>
  );
};

export default FarmingWithProvider;

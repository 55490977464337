import { useState, useEffect, useCallback } from 'react';
import axios from '../../../../../api/axios';

const useFetch = (uri, suspendRender = false, config = {}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState('');

  const fetchDataFromUri = useCallback(async () => {
    setLoading(true);

    try {
      const response = await axios.get(uri, config);
      response.data && setData(response?.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uri]);

  useEffect(() => {
    uri && !suspendRender && fetchDataFromUri();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uri, suspendRender]);

  return {
    loading,
    error,
    data
  };
};

export default useFetch;

import { ApolloError } from "@apollo/client";
import { createContext, useContext } from "react";
import { AssetSearchQueryVariableType, AssetSearchQueryResponseType } from "../../../static/type";

export type ListingGlobalContent = {
  variables: AssetSearchQueryVariableType;
  setNewVariables: (variables: AssetSearchQueryVariableType) => void;
  currentData: AssetSearchQueryResponseType;
  mappingDataStorage: AssetSearchQueryResponseType;
  loading: boolean;
  error: ApolloError | undefined;
  currentPage: number;
  isLoadingMore: boolean;
  handleChangedPage: (newPage: number) => void;
};
export const ListingGlobalContext = createContext<ListingGlobalContent>({} as ListingGlobalContent);
export const useListingGlobalContext = () => useContext(ListingGlobalContext);

import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegramPlane, faTwitter, faDiscord } from '@fortawesome/free-brands-svg-icons';

import './AppFooter.scss';

const SocialLinks = [
  { href: 'https://t.me/JoinDePo', icon: faTelegramPlane },
  { href: 'https://twitter.com/DePo_io', icon: faTwitter },
  { href: 'https://discord.gg/mcZnfbN9ua', icon: faDiscord }
];

export const SocialMediaLinks = () => {
  return (
    <div className="AppFooter__SocialMediaLinks">
      {SocialLinks.map(link => (
        <a key={link.href} href={link.href} className="AppFooter__Link" onClick={openExternalLink}>
          <FontAwesomeIcon icon={link.icon} size="1x" />
        </a>
      ))}
    </div>
  );
};

function openExternalLink(event) {
  event.preventDefault();
  window.open(event.currentTarget['href']);
}

export const AppFooter = ({ extended = true }) => (
  <>
    <div className={`AppFooter ${extended ? 'AppFooter--extended' : ''}`}>
      <div className={`AppFooter__SocialMedia`}>
        <SocialMediaLinks />
      </div>

      <div className="AppFooter__Info">
        {extended ? 'Copyright © All rights reserved by Depo 2021' : 'Copyright © Depo 2021'}
        {extended && '.In crypto we trust'}
      </div>
    </div>
  </>
);

export default AppFooter;

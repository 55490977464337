import {useState, useCallback} from 'react';
import {ApolloClient, InMemoryCache} from '@apollo/client';
import {useSelector} from 'react-redux';
import BigNumber from 'bignumber.js';

import {GET_WALLET_BALANCE} from '../../../../../graphql/opensea/WalletBalanceQuery';

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_PROXY_URL}${process.env.REACT_APP_OPENSEA_GRAPHQL_API}`,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
});

const NETWORK_NAME = process.env.REACT_APP_NETWORK_NAME;
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
const assetId = CHAIN_ID === 1 ? process.env.REACT_APP_OPENSEA_WETH_ASSET_ID_MAINNET : process.env.REACT_APP_OPENSEA_WETH_ASSET_ID;

const useWalletBalance = () => {
  const [walletBalance, setWalletBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const user = useSelector(state => state.user);

  const fetchUserWalletBalance = useCallback(async () => {
    try {
      if (user.address) {
        setLoading(true);

        const result = await client.query({
          query: GET_WALLET_BALANCE,
          variables: {
            assetId,
            identity: {
              address: user.address,
              chain: NETWORK_NAME
            }
          }
        });

        if (result.data) {
          const {blockchain} = result.data;
          setWalletBalance(new BigNumber(blockchain.balance).div(1e18).toFixed());
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  }, [user.address]);

  return {fetchUserWalletBalance, walletBalance, loading};
};

export default useWalletBalance;

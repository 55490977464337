import {useState, useCallback} from 'react';
import {INFURA} from '../../../Pages/Swap/uni/const';
import Web3 from 'web3';
import {useSelector} from 'react-redux';

let web3 = new Web3(new Web3.providers.HttpProvider(INFURA));

const useETHBalance = () => {
  const user = useSelector(state => state.user);
  const [balance, setBalance] = useState(0);
  const [loadingBalance, setLoadingBalance] = useState(false);

  const fetchETHBalance = useCallback(async () => {
    try {
      setLoadingBalance(true);
      let balanceCoin = await new web3.eth.getBalance(user.address);
      let balanceCoinConvert = web3.utils.fromWei(balanceCoin);

      console.log(balanceCoin);

      setBalance(Number(balanceCoinConvert).toFixed(4));
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingBalance(false);
    }
  }, [user]);

  return [fetchETHBalance, balance, loadingBalance];
};

export default useETHBalance;

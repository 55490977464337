import { useCallback } from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { useState } from 'react';
import BigNumber from 'bignumber.js';
import moment from 'moment';

import { GET_ASSET_BUNDLE_QUERY } from '../../../../../graphql/opensea/AssetBundleQuery';

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_PROXY_URL}${process.env.REACT_APP_OPENSEA_GRAPHQL_API}`,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
});

const useNFTTokenDetails = bundleId => {
  const [tokenDetails, setTokenDetails] = useState(undefined);
  const [canBeBidding, setCanBeBidding] = useState(false);

  const fetchNFTTokenDetails = useCallback(async () => {
    if (bundleId) {
      await client
        .query({
          query: GET_ASSET_BUNDLE_QUERY,
          variables: {
            chain: null,
            slug: bundleId
          }
        })
        .then(result => {
          if (result.data) {
            const { bundle, tradeSummary } = result.data;
            const { name, maker, description, assetQuantities, slug } = bundle;

            const { user } = maker;

            const { bestAsk, bestBid } = tradeSummary;
            const tokenDetailsBuyInfo = {};
            let paymentToken;

            if (bestAsk && bestAsk.oldOrder) {
              const { quantity_remaining_takerAssetBundle } = bestAsk;
              const oldOrder = JSON.parse(bestAsk.oldOrder);
              const { decimals, usd_price } = oldOrder ? oldOrder.payment_token_contract : {};
              paymentToken = oldOrder && oldOrder.payment_token_contract;
              const currentPrice = oldOrder
                ? new BigNumber(oldOrder.current_price).div(new BigNumber(10).pow(decimals))
                : new BigNumber(0);

              tokenDetailsBuyInfo.currentPrice = currentPrice.toFixed();
              tokenDetailsBuyInfo.currentPriceToDollar = usd_price
                ? currentPrice.multipliedBy(usd_price).toFixed(2)
                : undefined;
              tokenDetailsBuyInfo.saleEndsIn = bestAsk.closedAt;
              tokenDetailsBuyInfo.assetId =
                quantity_remaining_takerAssetBundle.assetQuantities.edges[0].node.asset.id;
              tokenDetailsBuyInfo.assetContractAddress =
                oldOrder.asset_bundle.assets[0].asset_contract.address;
            }

            if (bestBid && bestBid.makerAssetBundle) {
              const { assetQuantities } = bestBid.makerAssetBundle;
              const { decimals, usdSpotPrice } = assetQuantities.edges[0].node.asset;
              paymentToken = assetQuantities.edges[0].node.asset;
              const currentPrice = new BigNumber(assetQuantities.edges[0].node.quantity).div(
                new BigNumber(10).pow(decimals)
              );
              tokenDetailsBuyInfo.bestBidPrice = currentPrice.toFixed();
              tokenDetailsBuyInfo.bestBidPriceToDollar = usdSpotPrice
                ? currentPrice.multipliedBy(usdSpotPrice).toFixed(2)
                : undefined;
              // tokenDetailsBuyInfo.saleEndsIn = bestBid.closedAt;
              tokenDetailsBuyInfo.assetId = assetQuantities.edges[0].node.asset.id;
            }

            const tokenDetails = {
              assets: assetQuantities.edges.map(assetQuantity => {
                const { quantity, asset, id } = assetQuantity.node;
                const {
                  description,
                  imageUrl,
                  animationUrl,
                  assetContract,
                  tokenId,
                  name,
                  collection
                } = asset;

                return {
                  quantity,
                  description,
                  assetContract,
                  tokenId,
                  name,
                  collectionName: collection.name || '',
                  imageUrl,
                  animationUrl,
                  id
                };
              }),
              name,
              slug,
              ...tokenDetailsBuyInfo,
              description: description
                ? {
                    isHTML: description.includes('↵'),
                    text: description.replaceAll('↵', '')
                  }
                : undefined,
              maker,
              availableToOffer: bestAsk || bestBid,
              paymentToken,
              id: bestAsk ? bestAsk.id : bestBid ? bestBid.id : undefined,
              bestAsk,
              publicUsername: user ? user.publicUsername : ''
            };

            bestAsk &&
              bestAsk.closedAt &&
              moment
                .utc(bestAsk.closedAt)
                .local()
                .isAfter(moment.utc(new Date()).local()) &&
              setCanBeBidding(true);
            setTokenDetails(tokenDetails);
          }
        })
        .catch(err => console.log(err.message));
    }
  }, [bundleId]);

  return {
    fetchNFTTokenDetails,
    canBeBidding,
    tokenDetails
  };
};

export default useNFTTokenDetails;

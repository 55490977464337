import { ethers } from 'ethers';
import WalletConnect from '@walletconnect/web3-provider';
import { config } from '../config';
var Tx = require('ethereumjs-tx').Transaction;

const CHAIN_NAME = config.chain_name || '';

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY;

export const signTransaction = async (params, privateKey, callbackHash = hash => {}) => {
  const tx = new Tx(params, { chain: CHAIN_NAME.toLowerCase() });
  tx.sign(Buffer.from(privateKey, 'hex'));
  const serializeTx = tx.serialize();
  await window.web3.eth.sendSignedTransaction('0x' + serializeTx.toString('hex'), function(
    err,
    hash
  ) {
    if (!err) {
      console.log(hash);
    } else {
      console.log(err);
    }
    callbackHash(hash);
  });
};

export const getTransactionSigner = async (chainId = 1, usingInfura = false, isWc = false) => {
  if (isWc) {
    const wcProvider = new WalletConnect({
      infuraId: process.env.REACT_APP_INFURA_KEY
    });

    await wcProvider.enable();

    const provider = new ethers.providers.Web3Provider(wcProvider);
    return provider.getSigner();
  }

  if (chainId !== 1 && !usingInfura) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    return provider.getSigner();
  }

  // If !usingInfura => chainId !== 1 || chainId === 1
  // If usingInfura => chainId === 1 || chainId !== 1
  const networkName = usingInfura && chainId === 1 ? 'mainnet' : config.chain_name.toLowerCase();
  return new ethers.providers.InfuraProvider(networkName, INFURA_KEY);
};
export const getTransactionSignerV2 = async (chainId = 1, usingInfura = false, isWc = false) => {
  if (isWc) {
    const wcProvider = new WalletConnect({
      infuraId: process.env.REACT_APP_INFURA_KEY
    });

    await wcProvider.enable();

    return wcProvider;
  }

  if (chainId !== 1 && !usingInfura) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    return provider.getSigner();
  }

  // If !usingInfura => chainId !== 1 || chainId === 1
  // If usingInfura => chainId === 1 || chainId !== 1
  const networkName = usingInfura && chainId === 1 ? 'mainnet' : config.chain_name.toLowerCase();
  return new ethers.providers.InfuraProvider(networkName, INFURA_KEY);
};

import React, { useState, useEffect } from 'react';
import './ManageToken.scss';
import { uriToHttp, contenthashToUri, ensResolver } from '../function';
import classNames from 'classnames';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import {
  add_token_in_list,
  remove_token_in_list,
  remove_one_token_added_by_address
} from '../../../_actions/swap.action';
import { useDispatch, useSelector } from 'react-redux';
import delete_icon from '../../../assets/swap/delete-24.png';
import view_link from '../../../assets/swap/external-link-symbol.png';
import { config } from '../../../../src/config';

import {
  COMPOUND_LIST,
  UMA_LIST,
  SET_LIST,
  OPYN_LIST,
  ROLL_LIST,
  GEMINI_LIST,
  CMC_ALL_LIST,
  CMC_STABLECOIN,
  KLEROS_LIST,
  WRAPPED_LIST,
  SYNTHETIX_LIST,
  AAVE_LIST,
  CHAIN_ID
} from '../uni/const';

const DEFAULT_LIST_OF_LISTS = [
  COMPOUND_LIST,
  GEMINI_LIST,
  ROLL_LIST,
  SET_LIST,
  UMA_LIST,
  OPYN_LIST
];

const LIST_INS = [
  CMC_ALL_LIST,
  CMC_STABLECOIN,
  KLEROS_LIST,
  WRAPPED_LIST,
  SYNTHETIX_LIST,
  AAVE_LIST
];

// ins =>uri =>https

const ItemToken = props => {
  const SwitchBtn = withStyles(theme => ({
    root: {
      width: 41,
      height: 27,
      padding: 0,
      margin: 0, //theme.spacing(1),

      display: 'flex'
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#7ac231',
          borderColor: theme.palette.primary.main
        }
      }
    },
    thumb: {
      width: 23,
      height: 23,
      boxShadow: 'none'
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 26 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white
    },
    checked: {}
  }))(Switch);

  const dispatch = useDispatch();

  const { item, chainId } = props;

  const [swithBtn, setSwithBtn] = useState(false);

  const dataTokenInfor = JSON.parse(localStorage.getItem('dataTokenInfor'));
  // const listData = useSelector(state => state.swapPage).listToken;

  const handleChangeSwith = event => {
    setSwithBtn(event.target.checked);
    if (event.target.checked) {
      let itemOfChainId = item.tokens.filter(ele => {
        return ele.chainId === chainId;
      });
      const itemUpdate = {
        ...item,
        tokens: itemOfChainId
      };
      dispatch(add_token_in_list(itemUpdate));
    } else {
      dispatch(remove_token_in_list(item));
    }
  };
  useEffect(() => {
    if (dataTokenInfor && !isEmpty(dataTokenInfor)) {
      dataTokenInfor.forEach(element => {
        if (element.name === item.name) {
          setSwithBtn(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="item-token">
      <div className="container">
        <div className="item-token__logo">
          <img src={item?.logoURI} alt="" />
        </div>
        <div className="wraper">
          <div className="item-token__symbol">{item?.name}</div>
          <span className="item-token__name">{item?.tokens.length} tokens</span>
        </div>
        <div className="item-token__swith-btn">
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Off</Grid>
            <Grid item>
              <SwitchBtn checked={swithBtn} onChange={handleChangeSwith} name={item?.name} />
            </Grid>
            <Grid item>On</Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

const ItemTokenAdd = props => {
  const dispatch = useDispatch();

  const { item } = props;

  const deleteTokenAdd = address => {
    let alert_confirm = window.confirm('Are you sure detele token');
    if (alert_confirm === true) {
      return dispatch(remove_one_token_added_by_address(address));
    }
    return;
  };

  return (
    <div className="item-token-add">
      <div className="container">
        <div className="item-token__logo">
          <img src={item?.logoURI} alt="" />
        </div>
        <div className="wraper">
          <div className="item-token__symbol">{item?.name}</div>
        </div>
        <div className="item-token__group-btn">
          <div className="delete">
            <img src={delete_icon} onClick={() => deleteTokenAdd(item.address)} alt="" />
          </div>
          <div className="view-info">
            <a
              target="_blank"
              href={`${
                CHAIN_ID === 4
                  ? `https://rinkeby.etherscan.io/address/${item.address}`
                  : `https://etherscan.io/address/${item.address}`
              }`}
              rel="noreferrer"
            >
              <img src={view_link} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function ManageToken() {
  const tabList = [
    {
      name: 'List'
    },
    {
      name: 'Token'
    }
  ];

  const [itemActive, setItemActive] = useState('List');
  const [dataListToken, setDataListToken] = useState([]);
  const [chainId, setChainId] = useState(Number);

  const listDataHttps = JSON.parse(localStorage.getItem('list_token_http'));

  const listData = useSelector(state => state.swapPage).listToken;

  useEffect(() => {
    setChainId(config.chain_id);
    if (listDataHttps) {
      setDataListToken(listDataHttps);
    } else {
      const fetchData = async () => {
        let listTokenPromise = LIST_INS.map(async item => {
          const contentHash = await ensResolver(item);
          return uriToHttp(contenthashToUri(contentHash))[0];
        });

        let tokenInsConvertHttp = await Promise.all(listTokenPromise);

        // console.log(tokenInsConvertHttp);

        try {
          const tokenListsPromise = tokenInsConvertHttp
            .concat(DEFAULT_LIST_OF_LISTS)
            .map(url => fetch(url).then(response => response.json()));
          const tokenLists = await Promise.all(tokenListsPromise);
          const listTokenUpdate = tokenLists.map(item => {
            return {
              name: item.name,
              tokens: item.tokens,
              logoURI: uriToHttp(item.logoURI)[0]
            };
          });

          setDataListToken(listTokenUpdate);
          localStorage.setItem('list_token_http', JSON.stringify(listTokenUpdate));
          // let chain_id = await new web3.eth.net.getId();
          // setChainId(chain_id);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="manage-token">
      <div className="sub-tab">
        {tabList.map(item => {
          return (
            <span
              onClick={() => setItemActive(item.name)}
              key={item.name}
              className={classNames({
                active: item.name === itemActive
              })}
            >
              {item.name}
            </span>
          );
        })}
      </div>
      <div className="body-manage">
        {itemActive === 'List' ? (
          <>
            {dataListToken &&
              !isEmpty(dataListToken) &&
              dataListToken.map(item => {
                return <ItemToken key={item.name} item={item} chainId={chainId} />;
              })}
          </>
        ) : (
          listData &&
          !isEmpty(listData) &&
          listData
            .filter(item => item.isDefault === false)
            .map(item => {
              return <ItemTokenAdd key={item.name} item={item} chainId={chainId} />;
            })
        )}
      </div>
    </div>
  );
}

import { useState } from 'react';
import { Link } from 'react-router-dom';

import Offer from 'assets/icons/offer.svg';
import PlaceHolder from '../../../../../assets/placeholder.png';

const AssetList = ({ tokenDetails }) => {
  const [showListMap, setShowListMap] = useState(false);

  return (
    <div className="detail-item__offer">
      <div className="detail-item__offer-tit" onClick={() => setShowListMap(!showListMap)}>
        <div className="detail-item__style-titOffer">
          <img src={Offer} alt="" />
          <span className="detail-item__text-offer">{tokenDetails.assets.length} Items</span>
        </div>
        <div className="detail-item__btn-ip">
          {(function() {
            return showListMap ? (
              <svg
                width="10"
                height="7"
                viewBox="0 0 10 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.175 6.1748L5 2.35814L8.825 6.1748L10 4.9998L5 -0.000195503L0 4.9998L1.175 6.1748Z"
                  fill="#7AC231"
                />
              </svg>
            ) : (
              <svg
                width="10"
                height="7"
                viewBox="0 0 10 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.175 0L5 3.81667L8.825 0L10 1.175L5 6.175L0 1.175L1.175 0Z"
                  fill="#7AC231"
                />
              </svg>
            );
          })()}
        </div>
      </div>
      <div
        className={`detail-item__offer-table ${showListMap && 'hidden_map'}`}
        style={{
          maxHeight: 300,
          overflow: 'auto'
        }}
      >
        {tokenDetails.assets.map(asset => (
          <Link
            style={{
              padding: '20px 10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
              color: 'white',
              borderBottom: '1px solid #43444b',
              height: 138
            }}
            to={`/opensea/assets/${asset.assetContract.account.address}/${asset.tokenId}`}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {asset.imageUrl ? (
                asset.imageUrl.endsWith('.mp4') ? (
                  <video
                    src={asset.imageUrl}
                    autoPlay="autoPlay"
                    muted={true}
                    style={{ width: 76 }}
                  />
                ) : (
                  <img src={asset.imageUrl} style={{ width: 76 }} alt="" />
                )
              ) : (
                <img src={PlaceHolder} style={{ width: 76 }} alt="" />
              )}
              <div style={{ marginLeft: 20 }}>
                <p style={{ fontSize: 14 }}>{asset.collectionName}</p>
                <h3 style={{ fontWeight: 'normal', fontSize: 16 }}>{asset.name}</h3>
              </div>
            </div>
            <div style={{ fontSize: 14, color: 'rgb(138, 147, 155)' }}>x{asset.quantity}</div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default AssetList;

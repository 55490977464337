import { useState, useEffect } from 'react';

const useSushiPrice = () => {
  const [sushiPrice, setSushiPrice] = useState(0);

  useEffect(() => {
    const fetchSushiPrice = () => {
      fetch(
        'https://min-api.cryptocompare.com/data/pricemultifull?fsyms=SUSHI&tsyms=USD&api_key=dd1be722aea6f7facf951c0be42a8b23bdb224fa42cba9ecb6ac0965312e533a'
      )
        .then(res => res.json())
        .then(data => setSushiPrice(data.RAW.SUSHI.USD.HIGHHOUR));
    };

    fetchSushiPrice();
  }, []);

  return [sushiPrice, setSushiPrice];
};

export default useSushiPrice;

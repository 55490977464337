import useStyles from "./styles";

export enum CHOOSE_ITEM {
  CHECK,
  UNCHECK
}

type FeaturedItemProps = {
  id: string;
  name: string;
  onChooseItem: (choosedId: string, action: CHOOSE_ITEM) => void;
  isActived: Boolean;
};

const FeaturedItem: React.FC<FeaturedItemProps> = (props): JSX.Element => {
  const { name, onChooseItem, isActived, id } = props;
  const styles = useStyles();
  return (
    <>
      <div
        onClick={() => {
          onChooseItem(id, isActived ? CHOOSE_ITEM.UNCHECK : CHOOSE_ITEM.CHECK);
        }}
        className={`${styles.featuredFilterItem} ${isActived && " active"}`}
      >
        {name}
      </div>
    </>
  );
};

export default FeaturedItem;

import {useState, useMemo, useEffect} from 'react';
import {useSelector} from 'react-redux';
import flatMap from 'lodash.flatmap';
import {
  DEFAULT_ACTIVE_LIST_URLS,
  PINNED_PAIRS,
  BASES_TO_TRACK_LIQUIDITY_FOR
} from '../constants/liquidityPair';

import {fetchDefaultActiveList, deserializeToken} from '../../../util/liquidity-utils';
import {
  BASES_TO_TRACK_LIQUIDITY_FOR_SUSHI,
  PINNED_PAIRS_SUSHI
} from '../constants/liquidityPairSushi';

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

const useTrackedPairs = selectedPool => {
  const {listToken} = useSelector(state => state.swapPage);
  const [tokenImported, setTokenImported] = useState([]);
  const [tokensMappingAddress, setTokensMapping] = useState([]);

  const pairsSavedByUser = useMemo(() => JSON.parse(localStorage.getItem('liquidity_pairs')) || {}, [localStorage.getItem('liquidity_pairs')]);

  useEffect(() => {
    setTokenImported(listToken);
  }, [listToken]);

  const pinnedPairs = useMemo(
    () =>
      CHAIN_ID
        ? (selectedPool === 'uni' ? PINNED_PAIRS[CHAIN_ID] : PINNED_PAIRS_SUSHI[CHAIN_ID]) ?? []
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [CHAIN_ID, selectedPool]
  );

  const generatedPairs = useMemo(() => {
    const tokenImportedDeepClone = [...tokenImported];
    // Splice from 0 index 3 elements. Because we have already loaded 3 default tokens from beginning
    // So just need tokens from index 3 for generating pairs
    tokenImportedDeepClone.splice(0, 3);
    const deserializeTokenImported = tokenImported.length > 0 ? [...tokenImportedDeepClone].map(tokenInfo => deserializeToken(tokenInfo)) : [];

    const generatedPairs = CHAIN_ID
      ? flatMap(Object.keys(tokensMappingAddress), tokenAddress => {
        const {address} = tokensMappingAddress[tokenAddress];

        const BASES_TO_TRACK = selectedPool === 'uni'
          ? [...BASES_TO_TRACK_LIQUIDITY_FOR[CHAIN_ID], ...deserializeTokenImported]
          : [...BASES_TO_TRACK_LIQUIDITY_FOR_SUSHI[CHAIN_ID], ...deserializeTokenImported] ?? []

        return BASES_TO_TRACK
          .map(base => base.address === address ? null : [base, tokensMappingAddress[tokenAddress]])
          .filter(pair => pair !== null && pair[0].address !== pair[1].address);
      })
      : [];


    return generatedPairs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CHAIN_ID, tokensMappingAddress, selectedPool, tokenImported]);

  const userPairs = useMemo(() => {
    if (!CHAIN_ID || !pairsSavedByUser) return [];

    return Object.keys(pairsSavedByUser).map(key => {
      const {token0, token1} = pairsSavedByUser[key];
      return [
        // {...deserializeToken(token0), tokenInfo: token0.tokenInfo},
        // {...deserializeToken(token1), tokenInfo: token1.tokenInfo}
        token0,
        token1
      ];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CHAIN_ID, pairsSavedByUser]);

  useEffect(() => {
    const fetchActiveTokensByChainId = async chainId => {
      const tokensMappingAddress = await fetchDefaultActiveList(
        chainId,
        DEFAULT_ACTIVE_LIST_URLS,
        selectedPool
      );
      setTokensMapping(tokensMappingAddress);
    };

    fetchActiveTokensByChainId(CHAIN_ID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CHAIN_ID, selectedPool]);

  return useMemo(() => {
    const chunkList = generatedPairs.concat(userPairs).concat(pinnedPairs);

    const keyed = chunkList.reduce((memo, [tokenA, tokenB]) => {
      const sorted = deserializeToken(tokenA).sortsBefore(deserializeToken(tokenB));
      const key = sorted
        ? `${tokenA.address},${tokenB.address}`
        : `${tokenB.address},${tokenA.address}`;

      if (memo[key]) return memo;
      memo[key] = sorted ? [tokenA, tokenB] : [tokenB, tokenA];
      return memo;
    }, {});

    return Object.keys(keyed).map(pair => keyed[pair]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedPairs, userPairs, pinnedPairs]);
};

export default useTrackedPairs;

import {useEffect, useState} from 'react';
import usePoolLibary from './usePoolLibary';
import BigNumber from 'bignumber.js';
import {deserializeToken} from '../../../util/liquidity-utils';

const usePoolPair = (pairDetail, selectedLiquidity, pool) => {
  const [totalPoolSupply, setTotalPoolSupply] = useState();
  const [v2Pair, setV2Pair] = useState();
  const [token0Deposited, setToken0Deposited] = useState();
  const [token1Deposited, setToken1Deposited] = useState();
  const [TokenAmount, Pair] = usePoolLibary(pool);

  useEffect(() => {
    const getPairDetail = () => {
      if (new BigNumber(pairDetail.totalSupply.toString()).isZero()) {
        return;
      }
      const totalSupply = selectedLiquidity
        ? new TokenAmount(selectedLiquidity.liquidityToken, pairDetail.totalSupply.toString())
        : null;
      setTotalPoolSupply(totalSupply);
      const pair = selectedLiquidity
        ? new Pair(
          new TokenAmount(
            deserializeToken(selectedLiquidity.token0),
            pairDetail.token0 === selectedLiquidity.token0.address
              ? pairDetail.reserves._reserve0.toString()
              : pairDetail.reserves._reserve1.toString()
          ),
          new TokenAmount(
            deserializeToken(selectedLiquidity.token1),
            pairDetail.token0 === selectedLiquidity.token1.address
              ? pairDetail.reserves._reserve0.toString()
              : pairDetail.reserves._reserve1.toString()
          )
        )
        : null;
      setV2Pair(pair);
      try {
        const token0Deposited =
          pair && totalSupply
            ? pair.getLiquidityValue(
              deserializeToken(selectedLiquidity.token0),
              totalSupply,
              new TokenAmount(selectedLiquidity.liquidityToken, pairDetail.balance.toString()),
              false
            )
            : undefined;
        const token1Deposited =
          pairDetail && pair
            ? pair.getLiquidityValue(
              deserializeToken(selectedLiquidity.token1),
              totalSupply,
              new TokenAmount(selectedLiquidity.liquidityToken, pairDetail.balance.toString()),
              false
            )
            : undefined;
        setToken0Deposited(token0Deposited);
        setToken1Deposited(token1Deposited);
      } catch (e) {
        setToken0Deposited(undefined);
        setToken1Deposited(undefined);
      }
    };

    if (pairDetail && selectedLiquidity) {
      getPairDetail();
    } else {
      setV2Pair(null);
      setTotalPoolSupply(null);
      setToken0Deposited(undefined);
      setToken1Deposited(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pairDetail, selectedLiquidity]);

  return [totalPoolSupply, v2Pair, token0Deposited, token1Deposited];
};

export default usePoolPair;

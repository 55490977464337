import { Component } from 'react';
import { connect } from 'react-redux';
import Page from 'components/Page/Page';
import { Row, Col, Container } from 'components/GridSystem/GridSystem';
import BalanceHeader from 'components/BalanceHeader';
import OpenOrders from '../Market/OpenOrders/OpenOrders';

import DashboardHoldings from './DashboardHoldings/DashboardHoldings';
import DashboardHoldingsChart from './DashboardHoldings/DashboardHoldingsChart';
import NetworthBalances from './NetworthBalances/NetworthBalances';
import { changeTitlePage } from '_actions/titlePage.actions';
import { ToastContainer } from 'react-toastify';
import HeaderWarning from 'components/HeaderCommon/HeaderWarning';

import './Dashboard.scss';

class Dashboard extends Component {
  recheckLoadingState(balance, exchanges, selectedExchange) {
    let isLoading = true;
    if (balance && selectedExchange) {
      let allExchanges = [];
      if (selectedExchange.exchangeName !== 'ALL') {
        allExchanges.push(selectedExchange.exchangeName);
      } else {
        allExchanges = exchanges.map(e => e.exchangeName);
      }
      let allBalances = [];
      for (let i = 0; i < balance.length; i++) {
        if (allBalances.indexOf(balance[i].exchange) === -1) {
          allBalances.push(balance[i].exchange);
        }
      }
      //TODO: binance đang lỗi
      // if (allBalances.length === allExchanges.length) {
      //   isLoading = false;
      // }
      isLoading = false;
    }
    return isLoading;
  }

  componentDidMount() {
    this.props.changeTitlePage('Dashboard');
  }

  render() {
    const {
      user,
      selectedExchange,
      orders,
      openOrders,
      cancelOrder,
      balance,
      exchanges,
      networth,
      markets,
      maxOrderPages,
      consolidated
    } = this.props;

    const loading = this.recheckLoadingState(balance, exchanges, selectedExchange);

    return (
      <div>
        <div className="Container">
          <div style={{ width: '175%' }}>{user.isWC && <HeaderWarning user={user} />}</div>
          <Page title="Dashboard" className="Dashboard">
            <Container>
              <NetworthBalances markets={markets} networth={networth} consolidated={consolidated} />
              {/* <NetworthChart networth={networth} /> */}

              <Row>
                <Col size="3" className="Dashboard--Side-column">
                  <div className="Dashboard__Chart">
                    <div className="Dashboard__Chart--wrapper">
                      <DashboardHoldingsChart balance={balance} loading={loading} />
                    </div>
                  </div>
                </Col>
                <Col size="10">
                  <div className="Dashboard__Holdings--stacked">
                    <BalanceHeader isCompact consolidated={consolidated} />
                    <DashboardHoldings loading={loading} balance={balance} />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col size="12" className="Dashboard__OpenOrders-column">
                  <OpenOrders
                    maxPages={maxOrderPages.open}
                    meta={orders.meta}
                    orders={openOrders}
                    selectedExchange={selectedExchange}
                    cancelOrder={cancelOrder}
                    contextColumns={true}
                  />
                </Col>
              </Row>
            </Container>
            <ToastContainer />
          </Page>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = {
  changeTitlePage
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { cache } from "./cache";

export const getClient = (address: string) => {
  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache,
    uri: `${process.env.REACT_APP_PROXY_URL}${process.env.REACT_APP_OPENSEA_GRAPHQL_API}`,
    headers: {
      "x-viewer-address": address || "",
      // TODO: mainnet not passing "x-viewer-chain"
      // Currently, testnet just "RINKEBY"
      "x-viewer-chain": `${process.env.REACT_APP_NETWORK_NAME === "RINKEBY" ? "RINKEBY" : ""}`
    }
  });
  return client;
};

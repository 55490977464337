import React from 'react';
import PropTypes from 'prop-types';

const CryptocurrencyIcon = ({ icon, size = 16 }) => {
  try {
    return (
      <img
        width={size}
        height={size}
        src={require(`cryptocurrency-icons/svg/color/${icon.toLowerCase()}.svg`).default}
        alt={icon}
      />
    );
  } catch (e) {
    return (
      <img
        width={size}
        height={size}
        src="https://www.gravatar.com/avatar/09cf3818f52d23cd11a16c40d0614edd?s=32&d=identicon&r=PG"
        alt={icon}
      />
    );
  }
};

CryptocurrencyIcon.propTypes = {
  icon: PropTypes.string
};

export default CryptocurrencyIcon;

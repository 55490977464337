import {WETH} from '@uniswap/sdk';

export function unwrappedToken(token) {
  if (token.equals(WETH[token.chainId])) return {
    ...token,
    name: 'Ether',
    symbol: 'ETH'
  };

  return token;
}

export function unWrappedNameToken(token) {
  return token;
}

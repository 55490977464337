import { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';

import CustomDialog from '../../../components/CustomDialog';
import useTokenApprove from '../hooks/useTokenApprove';
import useHarvestAction from '../hooks/useHarvestAction';
import useStakeAction from '../hooks/useStakeAction';
import SushiIcon from '../../../assets/sushi.png';
import LinkIcon from '../../../assets/link.svg';
import { getMasterchefAddress, getAddress } from '../../../util/getAddresses';
import {
  isNotValidASCIINumber,
  trimLeadingZerosWithDecimal,
  isPreventASCIICharacters
} from '../../../util/input';
import { getTransactionSigner } from '../../../util/transaction';
import { getTokenAllowance, getCurrentStakedLPTokens } from '../../../util/masterchef';
import './FarmingTableDropdown.scss';
import FarmingContext from '../context/FarmingContext';
import { withRouter } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { Button } from '../../../components/Button/Button';
import IconSubmitted from '../../../assets/icons/icon-submitted.svg';

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID || 1;
const CHAIN_ID_MAINNET = 1;

const FarmingTableDropdown = ({ pool, testPool = false, history }) => {
  const [tokenLPAllowance, setTokenLPAllowance] = useState(0);
  const [loadingTokenAllowance, setLoadingTokenAllowance] = useState(false);
  const [openStakeTokens, setOpenStakeTokens] = useState(false);
  const [openWithdrawTokens, setOpenWithdrawTokens] = useState(false);
  const [openHarvestTokens, setOpenHarvestTokens] = useState(false);
  const [input, setInput] = useState('');
  const [txHash, setTxHash] = useState('');
  const [isOpenTxHash, setIsOpenTxHash] = useState(false);
  const { address } = useSelector(state => state.user);
  const { sushiPrice } = useContext(FarmingContext);

  const masterchefAddress = getMasterchefAddress(testPool ? CHAIN_ID : CHAIN_ID_MAINNET);
  const poolBalance = pool.balance ? new BigNumber(pool.balance) : new BigNumber(0);
  const pendingSushi = pool.pendingSushi ? new BigNumber(pool.pendingSushi) : new BigNumber(0);
  const stakedLPToken = pool.stakedLPToken ? new BigNumber(pool.stakedLPToken) : new BigNumber(0);
  const pairSymbol = testPool
    ? `${pool.lpSymbol} (Rinkeby)`
    : `${pool.token0 ? `${pool.token0.symbol}-${pool.token1.symbol}` : '_'}`;

  const [loadingHarvest, handleSushiHarvest] = useHarvestAction(setTxHash);
  const {
    loadingDeposit,
    loadingWithdraw,
    handleLPTokenDeposit,
    handleLPTokenWithdraw
  } = useStakeAction(setTxHash);
  const [approveLoading, handleTokenApprove] = useTokenApprove();

  const handleStakeDialogClose = () => {
    setInput('');
    setOpenStakeTokens(false);
  };

  const handleStakeDialogConfirm = async () => {
    await handleLPTokenDeposit(testPool, pool, input);
    setInput('');
    setOpenStakeTokens(false);
  };

  const handleWithdrawDialogClose = () => {
    setInput('');
    setOpenWithdrawTokens(false);
  };

  const handleWithdrawDialogConfirm = async () => {
    await handleLPTokenWithdraw(testPool, pool, input);
    setInput('');
    setOpenWithdrawTokens(false);
  };

  const handleHarvestingClose = () => {
    setInput('');
    setOpenHarvestTokens(false);
  };

  const handleHarvestingConfirm = async () => {
    await handleSushiHarvest(testPool, pool);
    setInput('');
    setOpenHarvestTokens(false);
  };

  const handleInputDialogChange = e => {
    const inputVal = e.target.value;

    if (Number(inputVal) < 0) {
      e.preventDefault();
      return;
    }

    setInput(inputVal.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
  };

  const renderConfirmBtnText = (input, max) => {
    const val = new BigNumber(input);

    if (!input || val.lte(0)) {
      return 'Enter an amount';
    }

    if (val.gt(max)) {
      return 'Insufficient balance';
    }

    return 'Confirm';
  };

  useEffect(() => {
    const fetchTokenAllowance = async () => {
      const lpTokenAddress = testPool ? getAddress(pool.lpAddresses) : pool.pair;
      const signer = await getTransactionSigner(!testPool ? CHAIN_ID_MAINNET : CHAIN_ID, true);
      try {
        setLoadingTokenAllowance(true);
        const allowance = await getTokenAllowance(
          lpTokenAddress,
          address,
          masterchefAddress,
          signer
        );

        setTokenLPAllowance(allowance.toString());

        setLoadingTokenAllowance(false);
      } catch (err) {
        console.error(err.message);
        setLoadingTokenAllowance(false);
      }
    };

    pool && fetchTokenAllowance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pool]);

  useEffect(() => {
    if (txHash) {
      setIsOpenTxHash(true);
    }
  }, [txHash]);

  return (
    <div className="Farming-dropdown">
      <h2 className="Farming-dropdown__title">Farming</h2>
      <div className="Farming-dropdown__content">
        <div className="Farming-dropdown__info">
          <div className="Farming-dropdown__pair">
            <img src={SushiIcon} className="Farming__icon" alt="" />
            <span className="Farming__text">{pairSymbol}</span>
            <ul className="Farming-info__list">
              <li className="Farming-info__item">
                <div className="Farming-info__link" onClick={() => history.push('/liquidity')}>
                  <span>Get {pairSymbol} LP</span>
                  <img src={LinkIcon} className="Farming-info__icon" alt="" />
                </div>
              </li>
              <li className="Farming-info__item">
                <a
                  className="Farming-info__link"
                  target="_blank"
                  href={`${
                    testPool
                      ? `https://rinkeby.etherscan.io/address/${getAddress(pool.lpAddresses)}`
                      : `https://etherscan.io/address/${pool.pair}`
                  }`}
                  rel="noreferrer"
                >
                  <span>View Contract</span>
                  <img src={LinkIcon} className="Farming-info__icon" alt="" />
                </a>
              </li>
              <li className="Farming-info__item">
                <a
                  className="Farming-info__link"
                  target="_blank"
                  href={`${testPool ? '' : `https://sushiswap.vision/pair/${pool.pair}`}`}
                  rel="noreferrer"
                >
                  <span>See Pair Info</span>
                  <img src={LinkIcon} className="Farming-info__icon" alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="Farming-dropdown__box arming-dropdown__box--harvest">
          <h4 className="Farming-box__title">SUSHI EARNED</h4>
          <div className="Farming-box__wrapper">
            <div className="Farming-box__left">
              <p className="Farming-box__sushi">{pendingSushi.toFormat(3)}</p>
              <p className="Farming-box__dollar">
                {new BigNumber(pendingSushi).times(new BigNumber(sushiPrice)).toFormat(3)} USD
              </p>
            </div>
            <div className="Farming-box__right">
              <button
                className="Farming-box__button"
                disabled={!new BigNumber(pendingSushi).gt(0) || loadingHarvest}
                onClick={() => setOpenHarvestTokens(true)}
              >
                HARVEST
              </button>
            </div>
          </div>
        </div>
        <div className="Farming-dropdown__box arming-dropdown__box--stake">
          <h4 className="Farming-box__title">{pairSymbol} LP STAKED</h4>
          {!loadingTokenAllowance ? (
            <div className="Farming-box__wrapper">
              <div className="Farming-box__left">
                {tokenLPAllowance &&
                  (new BigNumber(tokenLPAllowance).gt(new BigNumber(0)) ? (
                    <p className="Farming-box__sushi">
                      {trimLeadingZerosWithDecimal(stakedLPToken.toFormat(4))}
                    </p>
                  ) : (
                    <button
                      className="Farming-box__button"
                      onClick={() => handleTokenApprove(testPool, pool)}
                      disabled={approveLoading}
                    >
                      {approveLoading && (
                        <CircularProgress size={20} style={{ marginRight: 10, color: 'white' }} />
                      )}
                      APPROVE STAKING
                    </button>
                  ))}
              </div>
              {tokenLPAllowance && new BigNumber(tokenLPAllowance).gt(new BigNumber(0)) && (
                <div className="Farming-box__right Farming-box__flex">
                  {
                    <>
                      <button
                        className="Farming-stake__button"
                        onClick={() => setOpenStakeTokens(true)}
                      >
                        +
                      </button>
                      <button
                        className="Farming-stake__button"
                        onClick={() => setOpenWithdrawTokens(true)}
                      >
                        -
                      </button>
                    </>
                  }
                </div>
              )}
            </div>
          ) : (
            <CircularProgress size={30} style={{ color: 'white', marginTop: 30 }} />
          )}
        </div>
      </div>

      <CustomDialog
        title="Stake LP Tokens"
        handleDialogConfirm={handleStakeDialogConfirm}
        handleDialogClose={handleStakeDialogClose}
        isOpened={openStakeTokens}
        footer={
          <div
            onClick={() => history.push('liquidity')}
            className="Farming-info__link Farming-stake__link"
          >
            <span>Get {pairSymbol} LP</span>
            <img src={LinkIcon} className="Farming-info__icon" alt="" />
          </div>
        }
        loading={loadingDeposit}
        input={input}
        usePreConfirm
        preConfirmTitle="Confirm stake"
        preConfirmContent={
          <div>
            <p className="stake-form__pair">
              Stake: {trimLeadingZerosWithDecimal(new BigNumber(input).toFormat(4))} {pairSymbol}
            </p>
            <p className="stake-form__pair">
              Current Staked:{' '}
              {trimLeadingZerosWithDecimal(getCurrentStakedLPTokens(pool, testPool))} {pairSymbol}
            </p>
          </div>
        }
        disableCondition={Number(input) <= 0 || Number(input) > poolBalance}
        renderButtonTextCondition={() => renderConfirmBtnText(input, poolBalance)}
      >
        <div className="Farming-stake__form">
          <header className="stake-form__header">
            <p className="Farming__text stake-form__header-typo">Stake</p>
            <p className="Farming__text stake-form__header-typo">
              Balance: {trimLeadingZerosWithDecimal(poolBalance.toFormat(4))}
            </p>
          </header>
          <div className="stake-form__content">
            <input
              autoFocus
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              fullWidth
              className="stake-form__input"
              placeholder="0"
              value={input}
              onChange={handleInputDialogChange}
              onKeyDown={e => isNotValidASCIINumber(e.keyCode, true) && e.preventDefault()}
              onKeyPress={e => isPreventASCIICharacters(e.key) && e.preventDefault()}
              onBlur={e => setInput(trimLeadingZerosWithDecimal(e.target.value))}
              onPaste={e => {
                const pastedText = e.clipboardData.getData('text');

                if (isNaN(Number(pastedText))) {
                  e.preventDefault();
                }
              }}
              max={Number(pool.balance)}
              min={0}
              maxLength={255}
            />
            <button className="stake-form__max" onClick={() => setInput(pool.balance)}>
              MAX
            </button>
            <p className="stake-form__pair">{pairSymbol} LP</p>
          </div>
        </div>
      </CustomDialog>
      <CustomDialog
        title="Unstake LP Tokens"
        handleDialogConfirm={handleWithdrawDialogConfirm}
        handleDialogClose={handleWithdrawDialogClose}
        isOpened={openWithdrawTokens}
        footer={
          <div
            onClick={() => history.push('/liquidity')}
            className="Farming-info__link Farming-stake__link"
          >
            <span>Get {pairSymbol} LP</span>
            <img src={LinkIcon} className="Farming-info__icon" alt="" />
          </div>
        }
        input={input}
        loading={loadingWithdraw}
        usePreConfirm
        preConfirmTitle="Confirm Unstake"
        preConfirmContent={
          <div>
            <p className="stake-form__pair">
              Unstake: {trimLeadingZerosWithDecimal(new BigNumber(input).toFormat(4))} {pairSymbol}
            </p>
            <p className="stake-form__pair">
              Current Staked:{' '}
              {trimLeadingZerosWithDecimal(getCurrentStakedLPTokens(pool, testPool))} {pairSymbol}
            </p>
          </div>
        }
        disableCondition={Number(input) <= 0 || Number(input) > stakedLPToken}
        renderButtonTextCondition={() => renderConfirmBtnText(input, stakedLPToken)}
      >
        <div className="Farming-stake__form">
          <header className="stake-form__header">
            <p className="Farming__text stake-form__header-typo">Unstake</p>
            <p className="Farming__text stake-form__header-typo">
              Balance: {trimLeadingZerosWithDecimal(stakedLPToken.toFormat(4))}
            </p>
          </header>
          <div className="stake-form__content">
            <input
              autoFocus
              margin="dense"
              id="unstake"
              fullWidth
              className="stake-form__input"
              placeholder="0"
              value={input}
              onChange={handleInputDialogChange}
              onKeyDown={e => isNotValidASCIINumber(e.keyCode, true) && e.preventDefault()}
              onKeyPress={e => isPreventASCIICharacters(e.key) && e.preventDefault()}
              onBlur={e => setInput(trimLeadingZerosWithDecimal(e.target.value))}
              onPaste={e => {
                const pastedText = e.clipboardData.getData('text');

                if (isNaN(Number(pastedText))) {
                  e.preventDefault();
                }
              }}
              max={Number(pool.stakedLPToken)}
              min={0}
              maxLength={255}
            />
            <button className="stake-form__max" onClick={() => setInput(pool.stakedLPToken)}>
              MAX
            </button>
            <p className="stake-form__pair">{pairSymbol} LP</p>
          </div>
        </div>
      </CustomDialog>
      <CustomDialog
        title="Harvesting Sushi"
        handleDialogConfirm={handleHarvestingConfirm}
        handleDialogClose={handleHarvestingClose}
        isOpened={openHarvestTokens}
        loading={loadingHarvest}
      >
        <div
          className="Farming-dropdown__box arming-dropdown__box--harvest"
          style={{ marginTop: 20 }}
        >
          <h4 className="Farming-box__title">SUSHI EARNED</h4>
          <div className="Farming-box__wrapper">
            <div className="Farming-box__left">
              <p className="Farming-box__sushi" style={{ color: 'white' }}>
                <span style={{ fontWeight: 'bold' }}>Estimated: </span>
                {pendingSushi.toFormat(3)} SUSHI
              </p>
              <p className="Farming-box__dollar">
                {new BigNumber(pendingSushi).times(new BigNumber(sushiPrice)).toFormat(3)} USD
              </p>
            </div>
          </div>
        </div>
      </CustomDialog>
      <CustomDialog
        title="Transaction Submitted"
        isOpened={isOpenTxHash}
        handleDialogClose={() => {
          setIsOpenTxHash(false);
          setTxHash('');
        }}
      >
        <div className="Farming-box__submitted_dialog">
          <img alt="" src={IconSubmitted} />
          <a
            className="view-on-ether"
            target="_blank"
            href={`${
              CHAIN_ID === '4'
                ? `https://rinkeby.etherscan.io/tx/${txHash}`
                : `https://etherscan.io/tx/${txHash}`
            }`}
            rel="noreferrer"
          >
            View on Etherscan
          </a>
          <div className="close-wrapper">
            <Button
              onClick={() => {
                setIsOpenTxHash(false);
                setTxHash('');
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </CustomDialog>
    </div>
  );
};

export default withRouter(FarmingTableDropdown);

import { useState, useEffect } from 'react';

const useETHPrice = () => {
  const [ethPrice, setEthPrice] = useState(0);

  useEffect(() => {
    fetch(
      'https://proxy-depo.sotatek.works/https://min-api.cryptocompare.com/data/pricemultifull?fsyms=ETH&tsyms=USD&api_key=26b9347b7888b560e8e5ed82bba79286c0964c61bf16b2447e20c7918ebb61d3'
    )
      .then(res => res.json())
      .then(data => {
        setEthPrice(data.RAW.ETH.USD.PRICE);
      });
  }, []);

  return { ethPrice };
};

export default useETHPrice;

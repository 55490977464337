import { useState, useCallback } from 'react';
import Web3 from 'web3';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { splitSignature } from '@ethersproject/bytes';
import { OpenSeaPort, Network } from 'opensea-js';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getTransactionSignerV2 } from '../../../../../util/transaction';

const MAINNET_CHAIN_ID = 1;
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
const API_URI = `${process.env.REACT_APP_PROXY_URL}${process.env.REACT_APP_OPENSEA_API_URI}`;
const TOKEN_USE_FOR_PURCHASE = process.env.REACT_APP_TOKEN_USE_FOR_PURCHASE;

const useOfferOrderAction = (bundleId, assetBundle, offerAmount, date, canBeBidding) => {
  const { isMetaMask, privateKey, address, isWC } = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [txHash, setTxHash] = useState('');

  let seaport = new OpenSeaPort(window.ethereum, {
    networkName: CHAIN_ID === MAINNET_CHAIN_ID ? Network.Main : Network.Rinkeby
  });

  const handleOfferMaking = useCallback(async () => {
    try {
      setLoading(true);

      if (offerAmount && new BigNumber(offerAmount).gt(0) && assetBundle && date) {
        if (isMetaMask || isWC) {
          if (isWC) {
            const signer = await getTransactionSignerV2(CHAIN_ID, false, isWC);

            // eslint-disable-next-line react-hooks/exhaustive-deps
            seaport = new OpenSeaPort(signer, {
              networkName: CHAIN_ID === MAINNET_CHAIN_ID ? Network.Main : Network.Rinkeby
            });
          }

          const offer = await seaport.createBundleBuyOrder({
            assets: assetBundle.assets.map(asset => ({
              tokenId: asset.token_id,
              tokenAddress: asset.asset_contract.address,
              schemaName: asset.asset_contract.schema_name
            })),
            accountAddress: address,
            expirationTime: !canBeBidding
              ? moment(date).unix()
              : moment(date)
                  .add(1, 'months')
                  .unix(),
            // Value of the offer, in units of the payment token (or wrapped ETH if none is specified):
            startAmount: offerAmount
          });

          setTxHash(offer.hash);
          toast.success(
            !canBeBidding ? 'Make an offer successfully!' : 'Place a bid successfully!'
          );
        } else {
          var order = await seaport._makeBundleBuyOrder({
            assets: assetBundle.assets.map(asset => ({
              tokenId: asset.token_id,
              tokenAddress: asset.asset_contract.address,
              schemaName: asset.asset_contract.schema_name
            })),
            collection: {
              slug: bundleId
            },
            quantities: assetBundle.orders.map(order => order.quantity),
            accountAddress: address,
            startAmount: offerAmount,
            expirationTime: !canBeBidding
              ? moment(date).unix()
              : moment(date)
                  .add(1, 'months')
                  .unix(),
            paymentTokenAddress: TOKEN_USE_FOR_PURCHASE,
            // extraBountyBasisPoints: 0,
            sellOrder: undefined,
            referrerAddress: undefined
          });
          var utils = require('opensea-js/lib/utils/utils');
          var message = utils.getOrderHash(order);

          const web3 = new Web3(window.ethereum);
          const account = web3.eth.accounts.privateKeyToAccount(privateKey);
          const accountAddress = account.address;

          web3.eth.accounts.wallet.add(account);
          // 2. Set default account
          web3.eth.defaultAccount = account.address;
          const signature = await web3.eth.sign(message, accountAddress);
          const { r, s, v } = splitSignature(signature);

          // delete order['waitingForBestCounterOrder'];

          const response = await fetch(API_URI, {
            method: 'POST',
            headers: {
              'x-api-key': process.env.OPENSEA_GRAPHQL_API_KEY,
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              ...order,
              basePrice: order.basePrice.toFixed(),
              expirationTime: !canBeBidding
                ? moment(date).unix()
                : moment(date)
                    .add(1, 'months')
                    .unix(),
              extra: order.extra.toFixed(),
              listingTime: order.listingTime.toFixed(),
              makerProtocolFee: order.makerProtocolFee.toFixed(),
              makerReferrerFee: order.makerReferrerFee.toFixed(),
              makerRelayerFee: order.makerRelayerFee.toFixed(),
              quantity: order.quantity.toFixed(),
              salt: order.salt.toFixed(),
              takerProtocolFee: order.takerProtocolFee.toFixed(),
              takerRelayerFee: order.takerRelayerFee.toFixed(),
              r,
              s,
              v,
              hash: message
            })
          });
          const resToJSON = await response.json();

          if (resToJSON.errors) {
            throw new Error(resToJSON.errors[0]);
          }

          toast.success(
            !canBeBidding ? 'Make an offer successfully!' : 'Place a bid successfully!'
          );
        }

        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  }, [offerAmount, assetBundle, bundleId, date, canBeBidding]);

  return { handleOfferMaking, loading, txHash, setTxHash };
};

export default useOfferOrderAction;

import { useMemo } from 'react';
import { Pair as PairUni, TokenAmount as TokenAmountUni, Token as TokenUni } from '@uniswap/sdk';
import {
  Pair as PairSushi,
  TokenAmount as TokenAmountSushi,
  Token as TokenSushi
} from '@sushiswap/sdk';

const usePoolLibary = pool => {
  return useMemo(() => {
    const tokenAmount = pool === 'uni' ? TokenAmountUni : TokenAmountSushi;
    const pair = pool === 'uni' ? PairUni : PairSushi;
    const token = pool === 'uni' ? TokenUni : TokenSushi;
    return [tokenAmount, pair, token];
  }, [pool]);
};

export default usePoolLibary;

function fetch(...data) {
  return new Promise(function(resolve, reject) {
    resolve(); //or reject();
  });
}

function create(userId) {
  const data = {
    userId,
    type: 'time_based'
  };

  return fetch('2fa', data, { method: 'PUT' });
}

function confirm(number, userId) {
  const data = {
    number,
    userId
  };

  return fetch('2fa/confirm', data);
}

function disable(number, userId) {
  const data = {
    number,
    userId
  };

  return fetch('2fa', data, { method: 'DELETE' });
}

const TwoFactorAuthService = {
  create,
  confirm,
  disable
};

export default TwoFactorAuthService;

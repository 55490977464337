import { FC } from "react";
import { CHOOSE_ITEM } from "../Featured/FeaturedItem";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import useStyles from "./styles";

type CollectionItemProps = {
  isChecked?: boolean;
  id: string;
  name: string;
  imgUrl: string;
  onChooseItem: (choosedId: string, action: CHOOSE_ITEM) => void;
};

const CollectionItem: FC<CollectionItemProps> = (props): JSX.Element => {
  const styles = useStyles();
  const { isChecked, name, id, onChooseItem } = props;

  return (
    <>
      <div
        onClick={() => {
          onChooseItem(id, isChecked ? CHOOSE_ITEM.UNCHECK : CHOOSE_ITEM.CHECK);
        }}
        className={`${styles.collectionFilterItem} ${isChecked && "active"}`}
      >
        {isChecked ? (
          <CheckCircleOutlineIcon
            className={`${styles.iconCollectionFilterItem} ${isChecked && "active"}`}
          />
        ) : (
          <img
            className={styles.iconCollectionFilterItem}
            src={`/images/icons/icon_close_collection.svg`}
            alt=""
          />
        )}
        {name}
      </div>
    </>
  );
};

CollectionItem.defaultProps = {
  isChecked: false
};

export default CollectionItem;

import { OperationVariables, useQuery } from "@apollo/client";
import _ from "lodash";
import {
  AssetSearchQuery,
  WALLET_ASSET_SEARCH_INIT_VARIABLES
} from "Pages/OpenSea/graphql/queries";
import { FC, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import WalletSearchView from "./WalletContentMain/WalletSearchView";
import WalletContentTop from "./WalletContentTop";
import { WalletGlobalContext } from "./context/WalletGlobalContext";
import {
  ASSETS_BUNDLE_START_PAGE,
  OPENSEA_LOCAL_STORAGE_KEY,
  SCREEN
} from "../commons/constants/constants";
import WalletNavigation from "./WalletNavigation/WalletNavigation";
import useStyles from "./styles";
import OpenSeaLoading from "../commons/loading_component/OpenSeaLoading";
import {
  convertQueryStringToVariables,
  convertAssetsVariablesToQueryString
} from "../commons/helper";
import { useLocalStorage } from "../commons/hooks";
import AccountTabHeader from "../commons/AccountTabHeader";
import {
  AssetSearchQueryResponseType,
  AssetSearchQueryVariableType
} from "Pages/OpenSea/static/type";
import PagingController from "../commons/PagingController";
import {
  getCanFetchMoreDataFromDataResponse,
  getTotalCurrentPageFromDataResponse
} from "../commons/PagingController/helper";

const AccountInWalletPage: FC = (): JSX.Element => {
  const history = useHistory();
  const styles = useStyles();

  const [currentPage, setCurrentPage] = useState(ASSETS_BUNDLE_START_PAGE);

  // Get data form path
  const useLocationResult = useLocation();
  const queryString = useLocationResult.search;

  // Parse url to variables
  const variables = useMemo(() => {
    const dataFromQueryString = convertQueryStringToVariables(queryString, SCREEN.INWALLET);
    const variables = { ...WALLET_ASSET_SEARCH_INIT_VARIABLES, ...dataFromQueryString };
    return variables;
  }, [queryString]);

  // variables and newVariables will be different when click filter
  const [newVariables, setNewVariables] = useState(variables);
  useEffect(() => {
    if (!_.isEqual(newVariables, variables)) {
      const queryString = convertAssetsVariablesToQueryString(newVariables);
      history.push(`${history?.location?.pathname}${queryString}`);
      setCurrentPage(ASSETS_BUNDLE_START_PAGE);
      window.scrollTo(0, 0);
    }
  }, [history, newVariables, variables]);

  // currentData and mappingDataStorage will be different when useQuery loading
  // (in loading, currentData = undefined)
  const { data: currentData, loading, error, fetchMore } = useQuery(AssetSearchQuery, {
    variables,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });
  const [mappingDataStorage, setMappingDataStorage] = useLocalStorage(
    OPENSEA_LOCAL_STORAGE_KEY.ACCOUNT_WALLET_DATA,
    currentData
  );
  useEffect(() => {
    if (currentData) {
      setMappingDataStorage(currentData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData]);

  /* Paging loading begin */
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const updateQuery = <
    TData extends object & AssetSearchQueryResponseType,
    TVariables = OperationVariables & AssetSearchQueryVariableType
  >(
    previousQueryResult: TData,
    options: {
      fetchMoreResult?: TData;
      variables?: TVariables;
    }
  ): TData => {
    if (!options.fetchMoreResult) {
      return previousQueryResult;
    }
    const newQueryResult = _.cloneDeep(options.fetchMoreResult);
    const previousEdges = previousQueryResult.query.search.edges;
    const newEdges = newQueryResult.query.search.edges;
    const allEdges = _.concat(previousEdges, newEdges);

    newQueryResult.query.search.edges = allEdges;

    return newQueryResult;
  };

  const fetchMoreAssets = async () => {
    setIsLoadingMore(true);
    await fetchMore({
      variables: {
        ...variables,
        cursor: _.get(currentData, "query.search.pageInfo.endCursor", null)
      },
      updateQuery
    });
    setIsLoadingMore(false);
  };

  const handleChangedPage = (newPage: number) => {
    const totalCurrentPage = getTotalCurrentPageFromDataResponse(currentData);
    if (totalCurrentPage - 1 < newPage - 1) {
      fetchMoreAssets();
    }
    setCurrentPage(newPage);
  };
  /* Paging loading end */

  return (
    <>
      <WalletGlobalContext.Provider
        value={{
          variables,
          setNewVariables,
          currentData: currentData as AssetSearchQueryResponseType,
          mappingDataStorage: mappingDataStorage as AssetSearchQueryResponseType,
          loading,
          error,
          currentPage,
          isLoadingMore,
          handleChangedPage
        }}
      >
        <WalletNavigation />
        <div className={styles.mainContentSearch}>
          <div>
            <AccountTabHeader />
            <WalletContentTop />
          </div>
          <WalletSearchView />
          {(loading || isLoadingMore) && <OpenSeaLoading />}
          <div className={styles.pagingBottom}>
            <PagingController
              currentPage={currentPage}
              isLoadingData={isLoadingMore || loading}
              totalcurrentPage={getTotalCurrentPageFromDataResponse(mappingDataStorage)}
              canFetchMoreData={getCanFetchMoreDataFromDataResponse(mappingDataStorage)}
              handleChangedPage={handleChangedPage}
            />
          </div>
        </div>
      </WalletGlobalContext.Provider>
    </>
  );
};

export default AccountInWalletPage;

import BaseService from './BaseService';

const exchangeLookup = require('./../exchange/exchange-lookup');

class TradeService extends BaseService {
  validate(exchange, symbol) {
    if (!exchange) {
      throw new Error('No exchange specified');
    }

    if (!symbol) {
      throw new Error('No Symbol specified');
    }
  }

  async fetch(exchange, symbol) {
    this.validate(exchange, symbol);

    const result = await exchangeLookup[exchange].fetchTrades(symbol);

    const data = result.sort(function(a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.timestamp) - new Date(a.timestamp);
    });
    return {
      exchange: exchange,
      trades: data
    };
  }

  async cancelOrder(order, exchangeName, symbol) {
    const exchangeApi = this.getExchangeApi(exchangeName.toLowerCase(), '', '');
    const res = await exchangeApi.cancelOrder(order.id, symbol);
    return res;
  }

  async createOrder(orderInfo) {
    const exchangeName = orderInfo.exchangeName.toLowerCase();
    const symbolPair = orderInfo.symbolPair;
    const price = orderInfo.price;
    const orderType = orderInfo.orderType;
    const offerType = orderInfo.offerType.toLowerCase() !== 'sell' ? 'buy' : 'sell';
    const amount = orderInfo.amount;
    try {
      if (orderType === 'LIMIT') {
        const exchangeApi = this.getExchangeApi(exchangeName, '', '');
        const res = await exchangeApi.createLimitOrder(symbolPair, offerType, amount, price);
        switch (exchangeName) {
          case 'binance':
            return {
              data: {
                id: 'b2f5d2c0-15a3-441d-a206-c9cdc77900d4',
                amount: res.origQty,
                exchangeName: exchangeName,
                exchangeOrderId: res.clientOrderId,
                offerType: offerType,
                orderType: orderType,
                price: res.price,
                status: res.status,
                symbolPair: res.symbol,
                timestamp: res.transactTime,
                total: res.executedQty
              }
            };
          case 'bittrex':
            return {
              data: {
                id: res.id,
                amount: res.quantity,
                exchangeName: exchangeName,
                exchangeOrderId: '',
                offerType: offerType,
                orderType: orderType,
                price: res.limit,
                status: res.status,
                symbolPair: res.marketSymbol,
                timestamp: '',
                total: res.quantity
              }
            };
          default:
            return { data: {} };
        }
      } else if (orderType === 'MARKET') {
        const exchangeApi = this.getExchangeApi(exchangeName, '', '');
        const res = await exchangeApi.createMarketOrder(symbolPair, offerType, amount);
        switch (exchangeName) {
          case 'binance':
            return {
              data: {
                id: 'b2f5d2c0-15a3-441d-a206-c9cdc77900d4',
                amount: res.origQty,
                exchangeName: exchangeName,
                exchangeOrderId: res.clientOrderId,
                offerType: offerType,
                orderType: orderType,
                price: res.price,
                status: res.status,
                symbolPair: res.symbol,
                timestamp: res.transactTime,
                total: res.executedQty
              }
            };
          case 'bittrex':
            return {
              data: {
                id: res.id,
                amount: res.quantity,
                exchangeName: exchangeName,
                exchangeOrderId: '',
                offerType: offerType,
                orderType: orderType,
                price: res.limit,
                status: res.status,
                symbolPair: res.marketSymbol,
                timestamp: '',
                total: res.quantity
              }
            };
          default:
            return { data: {} };
        }
      }
    } catch (error) {
      throw error;
    }
  }
}

export default TradeService.getInstance();

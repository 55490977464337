import {
  AccountAssetEventType,
  AssetNodeEventType,
  AssetQuantityAssetEventType
} from "./../../../static/type/ActivityResponseType";
import _ from "lodash";
import {
  VECTOR_IMG_PATH,
  STROLLER_ICON_IMG_PATH,
  ARROW_LEFT_RIGHT_IMG_PATH,
  TAG_2_IMG_PATH,
  BID_ICON_IMG_PATH,
  SMILE_ICON_IMG_PATH
} from "Pages/OpenSea/static/images";
import { EventHistoryQueryResponseType, ImageAndTextType } from "Pages/OpenSea/static/type";
import BigNumber from "bignumber.js";
import { formatPrefixesUpCaseString } from "../../commons/helper";
import moment from "moment";

export type ActivitySearchHelperType = {
  // key
  key: string;

  // event
  eventType: ImageAndTextType;
  isExpied: boolean;

  // item
  assetsLink: string;
  pathImage: string;
  listImages: string[];
  assetName: string;

  // Unit Price
  price: string;

  // Quantity
  assetQuantity: string;

  // From
  from: ImageAndTextType;

  // To
  to: ImageAndTextType;

  // Date
  blockExplorerLink: string;
  timeStamp: string;
};

const checkExpired = (closeAt: string | null) => {
  if (closeAt) {
    // "2021-05-26T07:03:00" Timezone +0
    const now = moment.utc();
    const dateClosed = moment.utc(closeAt, "YYYY-MM-DDThh:mm:ss");
    if (dateClosed.isBefore(now)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const getEventTypeFromGraph = (eventType: any, address: string): ImageAndTextType => {
  switch (eventType) {
    case "OFFER_ENTERED": {
      return { text: "Offer", imageUrl: VECTOR_IMG_PATH };
    }
    case "TRANSFER": {
      const type = Number(address);
      if (type === 0) {
        return {
          text: "Created",
          imageUrl: STROLLER_ICON_IMG_PATH
        };
      } else {
        return {
          text: "Transfer",
          imageUrl: ARROW_LEFT_RIGHT_IMG_PATH
        };
      }
    }
    case "CREATED": {
      return {
        text: "List",
        imageUrl: TAG_2_IMG_PATH
      };
    }
    case "BID_ENTERED": {
      return { text: "Bid", imageUrl: BID_ICON_IMG_PATH };
    }
    case "BID_WITHDRAWN": {
      return { text: "Bid Cancel", imageUrl: SMILE_ICON_IMG_PATH };
    }
    case "SUCCESSFUL": {
      return { text: "Sale", imageUrl: STROLLER_ICON_IMG_PATH };
    }
    case "CANCELLED": {
      return { text: "Cancel", imageUrl: BID_ICON_IMG_PATH };
    }
    default: {
      return { text: "", imageUrl: "" };
    }
  }
};

const getKeyFromEventHistoryResult = (cursor: string) => {
  return { key: cursor };
};

const getEventColumnFromEventHistoryResult = (nodeData: AssetNodeEventType) => {
  const isExpied = checkExpired(nodeData.offerEnteredClosedAt);
  const eventType: ImageAndTextType = getEventTypeFromGraph(
    nodeData.eventType ?? {},
    nodeData.fromAccount?.address ?? ""
  );
  return { isExpied, eventType };
};

const getAssetsLinkFromNodeData = (nodeData: AssetNodeEventType) => {
  let assetsLink = "#";
  if (nodeData.assetQuantity) {
    const address = nodeData.assetQuantity.asset?.assetContract?.account?.address;
    const tokenId = nodeData.assetQuantity.asset?.tokenId;
    if (address && tokenId) {
      assetsLink = `/opensea/assets/${address}/${tokenId}`;
    }
  } else if (nodeData.assetBundle) {
    const address = nodeData.assetBundle.slug;
    if (address) {
      assetsLink = `/opensea/assets/bundles/${address}`;
    }
  }
  return assetsLink;
};

const getPathImageFromAssetQuantity = (assetQuantity: AssetQuantityAssetEventType | null) => {
  let pathImage = "";
  const asset = _.get(assetQuantity, ["asset"], null);
  if (asset && asset.imageUrl) {
    pathImage = asset.imageUrl;
  } else if (asset && asset.collection.imageUrl) {
    pathImage = asset.collection.imageUrl;
  }
  return pathImage;
};

const getItemColumnFromEventHistoryResult = (nodeData: AssetNodeEventType) => {
  const assetsLink = getAssetsLinkFromNodeData(nodeData);
  const pathImage = getPathImageFromAssetQuantity(nodeData.assetQuantity);
  const listImages = _.map(nodeData.assetBundle?.assetQuantities?.edges, item => {
    return item.node.asset?.imageUrl ?? "";
  });
  const assetName =
    nodeData.assetQuantity?.asset?.name ??
    nodeData.assetQuantity?.asset?.collection?.name ??
    nodeData.assetBundle?.name ??
    "no name";
  return {
    assetsLink,
    pathImage,
    listImages,
    assetName
  };
};

const getUnitPriceColumnFromEventHistoryResult = (nodeData: AssetNodeEventType) => {
  const price = nodeData.price
    ? new BigNumber(nodeData.price.quantity)
        .div(Math.pow(10, nodeData.price.asset.decimals))
        .toFixed()
    : "";
  return { price };
};

const getQuantityColumnFromEventHistoryResult = (nodeData: AssetNodeEventType) => {
  const assetQuantity = nodeData.assetQuantity?.quantity ?? "";
  return { assetQuantity };
};

const getNameFromAccountAssetEvent = (
  accountAssetEvent: AccountAssetEventType,
  addressUser: string
) => {
  let name = "";
  if (addressUser.toLowerCase() === accountAssetEvent.address.toLowerCase()) {
    name = "You";
  } else if (
    accountAssetEvent.user &&
    accountAssetEvent.user.publicUsername &&
    !_.isEmpty(accountAssetEvent.user.publicUsername)
  ) {
    name = accountAssetEvent.user.publicUsername;
  } else {
    name = formatPrefixesUpCaseString(accountAssetEvent.address);
  }
  return name;
};

const getNameAndImageFromAccountAssetEvent = (
  accountAssetEvent: AccountAssetEventType,
  addressUser: string
) => {
  const name = getNameFromAccountAssetEvent(accountAssetEvent, addressUser);
  const from = {
    text: name,
    imageUrl: accountAssetEvent.imageUrl
  };
  return from;
};

const getFromColumnFromEventHistoryResult = (
  nodeData: AssetNodeEventType,
  addressUser: string
): { from: ImageAndTextType } => {
  let from = {
    text: "",
    imageUrl: ""
  };
  if (nodeData.fromAccount) {
    from = getNameAndImageFromAccountAssetEvent(nodeData.fromAccount, addressUser);
  } else if (nodeData.seller) {
    from = getNameAndImageFromAccountAssetEvent(nodeData.seller, addressUser);
  }
  return { from };
};

const getToColumnFromEventHistoryResult = (
  nodeData: AssetNodeEventType,
  addressUser: string
): { to: ImageAndTextType } => {
  let to = {
    text: "",
    imageUrl: ""
  };
  if (nodeData.toAccount) {
    to = getNameAndImageFromAccountAssetEvent(nodeData.toAccount, addressUser);
  } else if (nodeData.winnerAccount) {
    to = getNameAndImageFromAccountAssetEvent(nodeData.winnerAccount, addressUser);
  }
  return { to };
};

const getDateColumnFromEventHistoryResult = (nodeData: AssetNodeEventType) => {
  const blockExplorerLink = nodeData.transaction?.blockExplorerLink ?? "";
  const timeStamp = moment.utc(nodeData.eventTimestamp).fromNow();
  return { blockExplorerLink, timeStamp };
};

export const convertActivityItemsFromEventHistoryResponse = (
  data: EventHistoryQueryResponseType,
  addressUser: string
): ActivitySearchHelperType[] => {
  const listEdges = data.assetEvents.edges;

  if (listEdges) {
    return _.map(
      listEdges,
      (item): ActivitySearchHelperType => {
        return {
          ...getKeyFromEventHistoryResult(item.cursor),
          ...getEventColumnFromEventHistoryResult(item.node),
          ...getItemColumnFromEventHistoryResult(item.node),
          ...getUnitPriceColumnFromEventHistoryResult(item.node),
          ...getQuantityColumnFromEventHistoryResult(item.node),
          ...getFromColumnFromEventHistoryResult(item.node, addressUser),
          ...getToColumnFromEventHistoryResult(item.node, addressUser),
          ...getDateColumnFromEventHistoryResult(item.node)
        };
      }
    );
  }

  return [];
};

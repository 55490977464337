import { find } from 'lodash';
import { BigNumber } from 'bignumber.js';
import CryptocurrencyIcon from 'components/CryptocurrencyIcon/CryptocurrencyIcon';

export default function Item(props) {
  const { item, listTokenBalance } = props;
  const tokenBalance = find(listTokenBalance, e => {
    return e.address === item.contractAddress;
  });
  return (
    <div className="item-token" key={item.contractAddress}>
      <div className="container">
        <div className="item-token__logo">
          <CryptocurrencyIcon icon={item.symbol} size={18} />
        </div>
        <div className="wraper">
          <div className="item-token__symbol">{item.symbol}</div>
          <span className="item-token__name">{item.name}</span>
        </div>
        <div className="item-token__amount-token">
          {(tokenBalance && new BigNumber(tokenBalance.balance).toFixed()) || 0}
        </div>
      </div>
    </div>
  );
}

import { useState, useEffect, useCallback } from 'react';
import icon_pr from '../../assets/swap/icon_pr.svg';
import Switch from 'react-switch';
import Tooltip from '@material-ui/core/Tooltip';
import { debounce } from 'debounce';
import {
  text_slippage,
  text_expert_mode,
  text_deadline,
  text_dispaly_multihop
} from '../../Pages/Swap/uni/const';

import classNames from 'classnames';
import './SettingTransection.scss';
import { isNotValidASCIINumber, isPreventASCIICharacters } from '../../util/input';

export default function SettingTransection(props) {
  const {
    activePercent,
    dealine,
    checkedExpert,
    checkedMultihop,
    setActivePercent,
    setDealine,
    setCheckedExpert,
    setCheckedMultihop
  } = props;

  const [inputSlip, setInputSlip] = useState(0);
  const [activeInputSlip, setActiveInputSlip] = useState(false);
  const [safeSlip, setSafeSlip] = useState(false);

  const list_percent = [
    {
      value: 0.1,
      name: '0.1%'
    },
    {
      value: 0.5,
      name: '0.5%'
    },
    {
      value: 1,
      name: '1%'
    }
  ];

  useEffect(() => {
    // bo lan dau
    const dataSettingTransaction = {
      activePercent,
      dealine,
      checkedExpert,
      checkedMultihop
    };
    localStorage.setItem('setting_transaction', JSON.stringify(dataSettingTransaction));
  }, [activePercent, dealine, checkedExpert, checkedMultihop]);

  useEffect(() => {
    if (activePercent !== 0.1 || activePercent !== 0.5 || activePercent !== 1) {
      setActiveInputSlip(true);
      setInputSlip(activePercent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeExpert = nextChecked => {
    if (checkedExpert === false) {
      var alert_confirm = window.confirm(
        'Expert mode turns off the confirm transaction prompt and allows high slippage trades that often result in bad rates and lost funds . ONLY USE THIS MODE IF YOU KNOW WHAT YOU ARE DOING.'
      );
      if (alert_confirm === true) {
        setCheckedExpert(true);
        return;
      }
      setCheckedExpert(false);
      return;
    }
    setCheckedExpert(false);
    return;
  };
  const handleChangeMultihop = nextChecked => {
    setCheckedMultihop(nextChecked);
  };

  const onChangeChange = useCallback(e => {
    if (e.target.value === '') {
      setDealine(e.target.value);
    }
    if (!Number(e.target.value)) {
      return;
    }
    setDealine(e.target.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setActiveInput = useCallback(
    debounce(() => {
      setActivePercent(inputSlip);
      if (inputSlip > 5) {
        setSafeSlip(false);
        return;
      }
      setSafeSlip(true);
    }, 700),
    [inputSlip]
  );

  useEffect(() => {
    if (activeInputSlip) {
      setActiveInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSlip, activeInputSlip]);

  return (
    <div className="setting-transection">
      <div className="title">Transaction Settings</div>
      <div className="transection-title">
        Slippage tolerance{' '}
        <Tooltip title={text_slippage} placement="right" arrow>
          <img src={icon_pr} alt="" />
        </Tooltip>
      </div>
      <div className="list-percent">
        {list_percent.map(item => {
          return (
            <div
              className={classNames(
                {
                  active: item.value === activePercent
                },
                'item'
              )}
              key={item.name}
              onClick={() => {
                setActivePercent(item.value);
                setInputSlip(item.value);
                setActiveInputSlip(false);
                setSafeSlip(true);
              }}
            >
              {item.name}
            </div>
          );
        })}
        <div
          className={classNames(
            {
              'input-slippage-active': activeInputSlip
            },
            'input-slippage'
          )}
          onClick={() => setActiveInputSlip(true)}
        >
          <input
            type="number"
            value={inputSlip}
            onKeyDown={e => isNotValidASCIINumber(e.keyCode, true) && e.preventDefault()}
            onKeyPress={e => isPreventASCIICharacters(e.key) && e.preventDefault()}
            onChange={e => {
              if (e.target.value.length === 5) {
                return;
              }
              setInputSlip(e.target.value);
            }}
          />{' '}
          <span>%</span>
        </div>
      </div>
      {!safeSlip && inputSlip > 5 && inputSlip < 50 ? (
        <div className="slip-text--warning slip-text">Your transaction may be frontrun</div>
      ) : !safeSlip && inputSlip >= 50 ? (
        <div className="slip-text--error slip-text">Your transaction may be frontrun</div>
      ) : null}

      <div className="transection-title">
        Transaction deadline{' '}
        <Tooltip title={text_deadline} placement="right" arrow>
          <img src={icon_pr} alt="" />
        </Tooltip>
      </div>
      <div className="dealine-input">
        <input value={dealine} onChange={onChangeChange} />
        <span>Minutes</span>
      </div>
      <div className="title">Interface Settings</div>
      <div className="on_off_setting">
        <div className="item">
          <span>
            Toggle Expert Mode{' '}
            <Tooltip title={text_expert_mode} placement="right" arrow>
              <img src={icon_pr} alt="" />
            </Tooltip>
          </span>
          <Switch
            onChange={handleChangeExpert}
            checked={checkedExpert}
            uncheckedIcon={<span className="text-switch">Off</span>}
            checkedIcon={<span className="text-switch">On</span>}
            offColor="#43444B"
            onColor="#7ac231"
            offHandleColor="#FF662B"
            onHandleColor="#FF662B"
          />
        </div>
        <div className="item">
          <span>
            Disable Multihops{' '}
            <Tooltip title={text_dispaly_multihop} placement="right" arrow>
              <img src={icon_pr} alt="" />
            </Tooltip>
          </span>
          <Switch
            onChange={handleChangeMultihop}
            checked={checkedMultihop}
            uncheckedIcon={<span className="text-switch">Off</span>}
            checkedIcon={<span className="text-switch">On</span>}
            offColor="#43444B"
            onColor="#7ac231"
            offHandleColor="#FF662B"
            onHandleColor="#FF662B"
          />
        </div>
      </div>
    </div>
  );
}

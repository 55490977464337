import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Row, Col } from 'components/GridSystem/GridSystem';
import './BodySwap.scss';
import arrow_down from '../../../assets/swap/arrow-down.svg';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import icon_uni from '../../../assets/swap/uni-icon.svg';
import icon_pr from '../../../assets/swap/icon_pr.svg';
import switch_icon_large from '../../../assets/swap/switch_icon_large.svg';
import setting_icon from '../../../assets/swap/setting_icon.svg';
import down_icon from '../../../assets/swap/down_icon.svg';
import chevron_right_white_24x24 from '../../../assets/swap/chevron_right_white_24x24.png';
import ModalCommon from '../../../components/ModalCommon/ModalCommon';
import ListToken from '../ListToken/ListToken';
import ModalConfirmTransection from '../../../components/ModalConfirmTransection/ModalConfirmTransection';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import BigNumber from 'bignumber.js';
import * as UNI_SDK from '@uniswap/sdk';
import * as SUSHI_SDK from '@sushiswap/sdk';
import Tooltip from '@material-ui/core/Tooltip';
import Spinner from '../../../components/Spinner/index';
import ModalWating from '../ModalWating/ModalWating';
import SushiIcon from '../../../assets/sushi.png';
import { useDispatch, useSelector } from 'react-redux';
import { set_check_pool } from '../../../_actions/swap.action';
import _ from 'lodash';

import * as uni_func from '../function';

import * as sushi_func from '../function_sushi';

import Web3 from 'web3';
import ERC20 from '../uni/ERC20.json';
import {
  WETH_TOKEN,
  text_received,
  text_price_impact,
  text_liquidity_provider_free,
  text_route
} from '../uni/const';
import SettingTransection from 'components/SettingTransection/SettingTransection';
import { isNotValidASCIINumber, isPreventASCIICharacters } from 'util/input';

let web3 = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_URL));
export default function BodySwap(props) {
  const settingTransaction = localStorage.getItem('setting_transaction')
    ? JSON.parse(localStorage.getItem('setting_transaction'))
    : null;

  const { user, dataTokenETH } = props;

  const { checkedPool, balanceETH } = useSelector(state => state.swapPage);
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState('');
  const [outputValue, setOutputvalue] = useState('');
  const [openModalListToken, setOpenModalListToken] = useState(false);
  const [openModalConfirmSwap, setOpenModalConfirmSwap] = useState(false);
  const [changePrice, setChangePrice] = useState(false);

  const [independency, setIndependency] = useState('');

  const [inputToken, setInputToken] = useState(null);
  const [outputToken, setOutputToken] = useState(null);
  const [bestTrade, setBestTrade] = useState({});
  const [currentTarget, setCurrentTarget] = useState('');

  const [balanceInput, setBalanceInput] = useState(Number);
  const [balanceOutput, setBalanceOutput] = useState(Number);

  const [disableBtnMaxInput, setDisableBtnMaxInput] = useState(false);
  const [statusText, setStatusText] = useState('Enter an amount');
  const [displayApprove, setDisplayApprove] = useState(false);
  const [statusBtn, setStatusBtn] = useState('disable');
  const [openSetting, setOpenSetting] = useState(false);
  const [openModalWating, setOpenModalWating] = useState(false);
  const [statusConfirm, setStatusConfirm] = useState('');
  const [txHash, setTxHash] = useState('');
  const [bestTradeRealTime, setbestTradeRealTime] = useState(null);

  const [activePercent, setActivePercent] = useState(0.5);
  const [dealine, setDealine] = useState(20);
  const [checkedExpert, setCheckedExpert] = useState(false);
  const [checkedMultihop, setCheckedMultihop] = useState(false);

  const [loading, setLoading] = useState(false);

  let maxAmountInput = useMemo(() => {
    if (inputToken && balanceInput) {
      const balanceETHBigNumber = !_.isNaN(balanceETH)
        ? new BigNumber(balanceETH).multipliedBy(Math.pow(10, inputToken.decimals))
        : new BigNumber(0);
      const balanceInputBigNumber = !_.isNaN(balanceInput)
        ? new BigNumber(balanceInput).multipliedBy(Math.pow(10, inputToken.decimals))
        : new BigNumber(0);

      return checkedPool === 'uni_swap'
        ? uni_func.maxAmountSpend(
            //JIG-447: Hot-Fix Wrap and Unwrap WETH
            // Begin Hot-Fix
            inputToken.address === WETH_TOKEN && inputToken.symbol.toLowerCase() === 'eth'
              ? // End Hot-Fix
                new UNI_SDK.TokenAmount(UNI_SDK.ETHER, balanceETHBigNumber.toFixed())
              : new UNI_SDK.TokenAmount(
                  uni_func.getCurrencyToken(inputToken),
                  balanceInputBigNumber.toFixed()
                )
          )
        : sushi_func.maxAmountSpend(
            //JIG-447: Hot-Fix Wrap and Unwrap WETH
            // Begin Hot-Fix
            inputToken.address === WETH_TOKEN && inputToken.symbol.toLowerCase() === 'eth'
              ? // End Hot-Fix
                new SUSHI_SDK.TokenAmount(UNI_SDK.ETHER, balanceETHBigNumber.toFixed())
              : new SUSHI_SDK.TokenAmount(
                  sushi_func.getCurrencyToken(inputToken),
                  balanceInputBigNumber.toFixed()
                )
          );
    }
  }, [balanceInput, checkedPool, balanceETH]);

  let atMaxAmountInput = Boolean(
    maxAmountInput && inputValue && maxAmountInput.toExact() === inputValue
  );

  //JIG-447: Hot-Fix Wrap and Unwrap WETH
  // Begin Hot-Fix
  const [isWrapOrUnwrapWETH, setIsWrapOrUnwrapWETH] = useState(false);
  // End Hot-fix

  const onClearForm = () => {
    setInputValue('');
    setOutputvalue('');
    setChangePrice(false);
    setDisplayApprove(false);
    setIndependency('INPUT');
    setInputToken(dataTokenETH);
    setOutputToken(null);
    setCurrentTarget('');
    setBalanceInput(balanceETH);
    setBalanceOutput(0);
    setBestTrade({});
    setDisableBtnMaxInput(false);
    setStatusText('Enter an amount');
    setStatusBtn('disable');
    setStatusConfirm('');
    props.setTransactionSuccess(false);
    setLoading(false);
  };

  const onSwithIndependency = () => {
    setIndependency(independency === 'INPUT' ? 'OUTPUT' : 'INPUT');
    setInputToken(outputToken);
    setOutputToken(inputToken);
    if (independency === 'INPUT') {
      setOutputvalue(inputValue);
      setInputValue('');
    } else {
      setInputValue(outputValue);
      setOutputvalue('');
    }
  };

  const getToken = useCallback(
    (currencyIn, currencyOut) => {
      if (checkedPool === 'uni_swap') {
        const paramsIn = uni_func.getParams(currencyIn);
        const tokenIn = new UNI_SDK.Token(...paramsIn);
        const paramsOut = uni_func.getParams(currencyOut);
        const tokenOut = new UNI_SDK.Token(...paramsOut);
        return { tokenIn, tokenOut };
      } else {
        const paramsIn = sushi_func.getParams(currencyIn);
        const tokenIn = new SUSHI_SDK.Token(...paramsIn);
        const paramsOut = sushi_func.getParams(currencyOut);
        const tokenOut = new SUSHI_SDK.Token(...paramsOut);
        return { tokenIn, tokenOut };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputToken, outputToken]
  );

  useEffect(() => {
    if (settingTransaction) {
      setActivePercent(settingTransaction.activePercent);
      setDealine(settingTransaction.dealine);
      setCheckedExpert(settingTransaction.checkedExpert);
      setCheckedMultihop(settingTransaction.checkedMultihop);
    } else {
      const dataSettingTransaction = {
        activePercent,
        dealine,
        checkedExpert,
        checkedMultihop
      };
      localStorage.setItem('setting_transaction', JSON.stringify(dataSettingTransaction));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onClearForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedPool]);

  useEffect(() => {
    if (dataTokenETH) {
      setInputToken(dataTokenETH);
    }
  }, [dataTokenETH]);

  useEffect(() => {
    let fetchData = async () => {
      if (inputToken) {
        try {
          setLoading(true);
          const dataBalance = await new web3.eth.Contract(ERC20.abi, inputToken.address).methods
            .balanceOf(user.address)
            .call();
          const dataDecimal = await new web3.eth.Contract(ERC20.abi, inputToken.address).methods
            .decimals()
            .call();
          setBalanceInput(
            //JIG-447: Hot-Fix Wrap and Unwrap WETH
            // Begin Hot-Fix
            inputToken.address === WETH_TOKEN && inputToken.symbol.toLowerCase() === 'eth'
              ? // End Hot-Fix
                new BigNumber(balanceETH || 0).toFixed()
              : new BigNumber(dataBalance).dividedBy(Math.pow(10, dataDecimal)).toFixed()
          );
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
        setDisableBtnMaxInput(false);
      } else {
        setBalanceInput(0);
      }

      if (outputToken) {
        try {
          setLoading(true);
          const dataBalance = await new web3.eth.Contract(ERC20.abi, outputToken.address).methods
            .balanceOf(user.address)
            .call();
          const dataDecimal = await new web3.eth.Contract(ERC20.abi, outputToken.address).methods
            .decimals()
            .call();
          setBalanceOutput(
            //JIG-447: Hot-Fix Wrap and Unwrap WETH
            // Begin Hot-Fix
            outputToken.address === WETH_TOKEN && outputToken.symbol.toLowerCase() === 'eth'
              ? // End Hot-Fix
                new BigNumber(balanceETH || 0).toFixed()
              : new BigNumber(dataBalance).dividedBy(Math.pow(10, dataDecimal)).toFixed()
          );
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      } else {
        setBalanceOutput(0);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceETH, inputToken, outputToken, balanceInput, balanceOutput]);

  useEffect(() => {
    if (independency === 'INPUT') {
      if (Number(inputValue) > 0 && !outputToken) {
        setStatusText(`Select a token`);
        setStatusBtn('disable');
        setOutputvalue('');
      } else if (Number(inputValue) < 0 || !outputToken) {
        setStatusText(`Enter an amount`);
        setStatusBtn('disable');
        setBestTrade({});
        setOutputvalue('');
      }
      const findBestRoute = async () => {
        if (!isEmpty(inputToken) && !isEmpty(outputToken)) {
          const { tokenIn, tokenOut } = getToken(inputToken, outputToken);
          if (Number(inputValue) > 0) {
            const amountIn = Math.floor(inputValue * 10 ** tokenIn.decimals);
            // JIG-447: Hot-fix to wrap and unwrap WETH
            // Begin
            let trade;
            if (
              ((tokenIn.symbol.toLowerCase() === 'eth' &&
                outputToken.symbol.toLowerCase() === 'weth') ||
                (tokenIn.symbol.toLowerCase() === 'weth' &&
                  outputToken.symbol.toLowerCase() === 'eth')) &&
              tokenIn.address.toLowerCase() === WETH_TOKEN.toLowerCase() &&
              outputToken.address.toLowerCase() === WETH_TOKEN.toLowerCase()
            ) {
              setIsWrapOrUnwrapWETH(true);
              setOutputvalue(inputValue);
              setBestTrade({});
              if (+inputValue > +balanceInput) {
                setStatusText(`Insufficient ${inputToken.symbol} balance`);
                setStatusBtn('disable');
              } else {
                if (tokenIn.symbol.toLowerCase() === 'eth') {
                  setStatusText('Wrap');
                } else {
                  setStatusText('Unwrap');
                }
                setStatusBtn('success');
              }
            } else {
              setIsWrapOrUnwrapWETH(false);
              //END
              trade =
                checkedPool === 'uni_swap'
                  ? await uni_func.fetchToShowBestTradeExactIn(
                      new UNI_SDK.TokenAmount(tokenIn, amountIn),
                      tokenOut,
                      activePercent,
                      checkedMultihop
                    )
                  : await sushi_func.fetchToShowBestTradeExactIn(
                      new SUSHI_SDK.TokenAmount(tokenIn, amountIn),
                      tokenOut,
                      activePercent,
                      checkedMultihop
                    );

              if (trade) {
                if (+inputValue > +balanceInput && +trade.priceImpact > 15) {
                  setStatusText(`Insufficient ${inputToken.symbol} balance`);
                  setStatusBtn('disable');
                } else if (+trade.priceImpact > 15 && !checkedExpert) {
                  setStatusText('Price Impact Too High');
                  setStatusBtn('dangerus');
                } else if (+inputValue > +balanceInput && +trade.priceImpact > 5) {
                  setStatusText(`Insufficient ${inputToken.symbol} balance`);
                  setStatusBtn('disable_warning');
                } else if (+trade.priceImpact > 5) {
                  setStatusText('Swap Anyway');
                  setStatusBtn('warning');
                } else if (+inputValue > +balanceInput) {
                  setStatusText(`Insufficient ${inputToken.symbol} balance`);
                  setStatusBtn('disable');
                } else {
                  setStatusText('Swap');
                  setStatusBtn('success');
                }

                setBestTrade(trade);
                setOutputvalue(trade.outputAmount.toFixed());
                setbestTradeRealTime(null);
              } else {
                setStatusText(`Not Pair`);
                setStatusBtn('disable');
                setOutputvalue('');
                setBestTrade({});
              }
            }
          } else {
            setBestTrade({});
            setOutputvalue('');
            setStatusBtn('disable');
            setStatusText('Enter an amount');
          }
        }
      };
      findBestRoute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inputValue,
    balanceInput,
    independency,
    inputToken,
    outputToken,
    activePercent,
    checkedExpert,
    checkedMultihop
  ]);

  useEffect(() => {
    if (independency === 'OUTPUT') {
      if (inputValue > 0 && outputValue > 0 && !outputToken) {
        setInputValue('');
      }
      const findBestRoute = async () => {
        if (!isEmpty(inputToken) && !isEmpty(outputToken)) {
          const { tokenOut, tokenIn } = getToken(inputToken, outputToken);

          if (Number(outputValue) > 0) {
            const amountOut = Math.floor(outputValue * 10 ** tokenOut.decimals);
            // JIG-447: Hot-fix to wrap and unwrap WETH
            // Begin
            if (
              ((tokenIn.symbol.toLowerCase() === 'eth' &&
                outputToken.symbol.toLowerCase() === 'weth') ||
                (tokenIn.symbol.toLowerCase() === 'weth' &&
                  outputToken.symbol.toLowerCase() === 'eth')) &&
              tokenIn.address.toLowerCase() === WETH_TOKEN.toLowerCase() &&
              outputToken.address.toLowerCase() === WETH_TOKEN.toLowerCase()
            ) {
              setIsWrapOrUnwrapWETH(true);
              setInputValue(outputValue);
              setBestTrade({});
              if (+inputValue > +balanceInput) {
                setStatusText(`Insufficient ${inputToken.symbol} balance`);
                setStatusBtn('disable');
              } else {
                if (tokenIn.symbol.toLowerCase() === 'eth') {
                  setStatusText('Wrap');
                } else {
                  setStatusText('Unwrap');
                }
                setStatusBtn('success');
              }
            } else {
              setIsWrapOrUnwrapWETH(false);
              //END
              let trade =
                checkedPool === 'uni_swap'
                  ? await uni_func.fetchToShowBestTradeExactOut(
                      tokenIn,
                      new UNI_SDK.TokenAmount(tokenOut, amountOut),
                      activePercent,
                      checkedMultihop
                    )
                  : await sushi_func.fetchToShowBestTradeExactOut(
                      tokenIn,
                      new SUSHI_SDK.TokenAmount(tokenOut, amountOut),
                      activePercent,
                      checkedMultihop
                    );
              if (trade) {
                if (+trade.inputAmount.toFixed() > +balanceInput && +trade.priceImpact > 15) {
                  setStatusText(`Insufficient ${inputToken.symbol} balance`);
                  setStatusBtn('disable');
                } else if (+trade.priceImpact > 15 && !checkedExpert) {
                  setStatusText('Price Impact Too High');
                  setStatusBtn('dangerus');
                } else if (+trade.inputAmount.toFixed() > +balanceInput && +trade.priceImpact > 5) {
                  setStatusText(`Insufficient ${inputToken.symbol} balance`);
                  setStatusBtn('disable_warning');
                } else if (+trade.priceImpact > 5) {
                  setStatusText('Swap Anyway');
                  setStatusBtn('warning');
                } else if (+trade.inputAmount.toFixed() > +balanceInput) {
                  setStatusText(`Insufficient ${inputToken.symbol} balance`);
                  setStatusBtn('disable');
                } else {
                  setStatusText('Swap');
                  setStatusBtn('success');
                }

                setBestTrade(trade);
                setInputValue(trade.inputAmount.toFixed());
                setbestTradeRealTime(null);
              } else {
                setStatusText(`Not Pair`);
                setStatusBtn('disable');
                setInputValue('');
                setBestTrade({});
              }
            }
          } else {
            setBestTrade({});
            setInputValue('');
            setStatusBtn('disable');
            setStatusText('Enter an amount');
          }
        }
      };
      findBestRoute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    outputValue,
    inputValue,
    balanceInput,
    independency,
    inputToken,
    outputToken,
    activePercent,
    checkedExpert,
    // checkedPool,
    checkedMultihop
  ]);

  useEffect(() => {
    if (!isEmpty(bestTrade)) {
      const path = bestTrade.routes.map(i => {
        return i.address.toLowerCase();
      });
      const fetchData = async () => {
        try {
          let value =
            checkedPool === 'uni_swap'
              ? await uni_func.allowance(path, user.address)
              : await sushi_func.allowance(path, user.address);
          if (inputToken.address !== WETH_TOKEN) {
            if (+value === 0) {
              setDisplayApprove(true);
            } else {
              setDisplayApprove(false);
            }
          } else {
            setDisplayApprove(false);
          }
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bestTrade]);

  const renderModalListToken = useMemo(() => {
    let modalProps = {
      open: openModalListToken,
      handleClose: () => setOpenModalListToken(false)
    };
    return (
      <ModalCommon {...modalProps}>
        <ListToken
          handleClose={modalProps.handleClose}
          user={user}
          setOutputToken={setOutputToken}
          setInputToken={setInputToken}
          currentTarget={currentTarget}
          inputToken={inputToken}
          outputToken={outputToken}
        />
      </ModalCommon>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModalListToken]);

  const callbackError = () => {
    setStatusConfirm('error');
  };

  const callbackReject = () => {
    setStatusConfirm('rejected');
  };

  const callbackHash = transactionHash => {
    setTxHash(transactionHash);
    setStatusConfirm('confirmed');
  };

  const renderModalWating = useMemo(() => {
    let token_in_out = [
      {
        amount: independency === 'INPUT' ? inputValue : bestTrade?.inputAmount?.toSignificant(6),
        token: inputToken
      },
      {
        amount: independency === 'INPUT' ? bestTrade?.outputAmount?.toSignificant(6) : outputValue,
        token: outputToken
      }
    ];
    return (
      <>
        {statusConfirm === 'waiting' && (user.privateKey || user.isMnemomic === true) ? null : (
          <ModalWating
            handleClose={() => setOpenModalWating(false)}
            isOpen={openModalWating}
            tokenInOut={token_in_out}
            status={statusConfirm}
            txHash={txHash}
            setStatusConfirm={setStatusConfirm}
            onClearForm={onClearForm}
            setOpenModalConfirmSwap={() => setOpenModalConfirmSwap(false)}
            user={user}
            //JIG-447: Hot-Fix Wrap and Unwrap WETH
            // Begin Hot-Fix
            isWrapOrUnwrapWETH={isWrapOrUnwrapWETH}
            // End Hot-Fix
          />
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModalWating, statusConfirm, txHash, bestTrade]);

  const swapAction = () => {
    const callbackSuccess = receipt => {
      console.log(receipt);
      props.setTransactionSuccess(true);
      // toast.success("Transaction success");
      // setStatusConfirm('swap-success');
    };
    if (
      Number(inputValue) > 0 &&
      independency === 'INPUT' &&
      !isEmpty(inputToken) &&
      !isEmpty(outputToken)
    ) {
      //JIG-447: Hot-Fix Wrap and Unwrap WETH
      // Begin Hot-Fix
      let path = '';
      if (isWrapOrUnwrapWETH) {
        path = '';
      } else {
        path = bestTrade.routes.map(i => {
          return i.address.toLowerCase();
        });
      }
      // End Hot-fix

      const data = {
        valueIndepen: new BigNumber(inputValue).toFixed(inputToken.decimals),
        path,
        user,
        deadline: parseInt((Date.now() + (dealine !== '' ? +dealine : 1) * 60000) / 1000),
        isDepen: independency,
        amountCalculationForSlip:
          bestTrade &&
          new BigNumber(bestTrade?.outputAmount?.toFixed())
            .multipliedBy((100 - Number(activePercent)) / 100)
            .toFixed(outputToken.decimals),
        callbackError,
        callbackReject,
        callbackHash,
        callbackSuccess,
        decimalsA: inputToken.decimals,
        decimalsB: outputToken.decimals,
        inputToken,
        outputToken
      };

      //JIG-447: Hot-Fix Wrap and Unwrap WETH
      // Begin Hot-Fix
      checkedPool === 'uni_swap' || isWrapOrUnwrapWETH
        ? uni_func.swap(data)
        : sushi_func.swap(data);
      // End Hot-Fix

      setOpenModalWating(true);
      setStatusConfirm('waiting');
      props.setTransactionSuccess(false);
      // setOpenModalConfirmSwap(false);
      // onClearForm();
    } else if (
      Number(outputValue) > 0 &&
      independency === 'OUTPUT' &&
      !isEmpty(inputToken) &&
      !isEmpty(outputToken)
    ) {
      //JIG-447: Hot-Fix Wrap and Unwrap WETH
      // Begin Hot-Fix
      let path = '';
      if (isWrapOrUnwrapWETH) {
        path = '';
      } else {
        path = bestTrade.routes.map(i => {
          return i.address.toLowerCase();
        });
      }
      // End Hot-fix

      const data = {
        valueIndepen: new BigNumber(outputValue).toFixed(outputToken.decimals),
        valueDependen: new BigNumber(inputValue).toFixed(inputToken.decimals),
        path,
        user,
        deadline: parseInt((Date.now() + (dealine !== '' ? +dealine : 1) * 60000) / 1000),
        isDepen: independency,
        callbackError,
        callbackReject,
        callbackHash,
        callbackSuccess,
        decimalsA: inputToken.decimals,
        decimalsB: outputToken.decimals,
        inputToken,
        outputToken
      };

      //JIG-447: Hot-Fix Wrap and Unwrap WETH
      // Begin Hot-Fix
      checkedPool === 'uni_swap' || isWrapOrUnwrapWETH
        ? uni_func.swap(data)
        : sushi_func.swap(data);
      // End Hot-Fix

      setOpenModalWating(true);
      setStatusConfirm('waiting');
      props.setTransactionSuccess(false);
      // setOpenModalConfirmSwap(false);
      // onClearForm();
    }
  };

  const onConfirmSwap = () => {
    if (statusBtn === 'warning') {
      let alert_confirm = window.confirm(
        'This swap has a price impact of at least 5%. Please confirm that you would like to continue with this swap.'
      );
      if (alert_confirm === true) {
        swapAction();

        return;
      }
      return;
    }
    swapAction();
  };

  const renderModalConfirmSwap = useMemo(() => {
    console.log(bestTradeRealTime);
    if (openModalConfirmSwap) {
      let modalProps = {
        open: openModalConfirmSwap,
        handleClose: () => setOpenModalConfirmSwap(false)
      };

      // let handleAcceptChanges = () => {
      //   bestTradeRealTime && setBestTrade(bestTradeRealTime);
      //   setStopRealTime(true);
      //   // clearInterval(intervalBestTrade);
      // };

      let token_in_out = [
        {
          amount: inputValue,
          token: inputToken
        },
        {
          amount: outputValue,
          token: outputToken
        }
      ];

      let icon_mid = arrow_down;
      let note = '';
      if (statusBtn === 'success') {
        note =
          'Output is estimated. If the price changes by more than 1% your transaction will revert';
      } else {
        note = `Output is estimated. You will receive at least ${Number(
          bestTrade[independency === 'INPUT' ? 'minimumAmountOut' : 'maximumAmountIn']
        )} ${
          independency === 'INPUT' ? outputToken?.symbol : inputToken?.symbol
        } or the transaction will revert.`;
      }

      let renderDetail = () => {
        let renderPrice = () => {
          if (!changePrice) {
            return `${bestTrade['midPrice']} ${outputToken?.symbol} / ${inputToken?.symbol}`;
          } else if (changePrice) {
            return `${bestTrade['midPriceInvert']} ${inputToken?.symbol} / ${outputToken?.symbol}`;
          } else {
            return null;
          }
        };

        //JIG-447: Hot-Fix Wrap and Unwrap WETH
        // Begin Hot-Fix
        if (isWrapOrUnwrapWETH) {
          // End Hot-fix
        } else {
          return (
            <div className="wrap-content-detail">
              <div className="item-detail">
                <div className="left">Price</div>
                <div className="right" style={{ display: 'flex' }}>
                  {renderPrice()}
                  {/* {bestTrade[independency === "INPUT" ? "midPrice" : "midPriceInvert"]}
                {`  `}
                {independency === "INPUT"
                  ? `${outputToken?.symbol} / ${inputToken?.symbol}`
                  : `${inputToken?.symbol} / ${outputToken?.symbol}`} */}
                  <div
                    className="change-icon"
                    onClick={() => {
                      setChangePrice(!changePrice);
                    }}
                  >
                    <img src={switch_icon_large} alt="" />
                  </div>
                </div>
              </div>
              <div className="item-detail">
                <div className="left">
                  {' '}
                  {independency === 'INPUT' ? 'Minimum Received' : 'Maximum sold'}
                </div>
                <div className="right">
                  {bestTrade[
                    independency === 'INPUT' ? 'minimumAmountOut' : 'maximumAmountIn'
                  ]?.toString()}{' '}
                  {independency === 'INPUT' ? outputToken?.symbol : inputToken?.symbol}
                </div>
              </div>
              <div className="item-detail">
                <div className="left">Price Impact</div>
                <div
                  className={classNames(
                    {
                      'right-warning': statusBtn === 'warning'
                    },
                    'right'
                  )}
                >
                  {bestTrade?.priceImpact < 0.01 ? '< 0.01 ' : bestTrade?.priceImpact?.toString()} %
                </div>
              </div>
              <div className="item-detail">
                <div className="left"> Liquidity Provider Fee</div>
                <div className="right">
                  {bestTrade.realizedLPFee} {inputToken?.symbol}{' '}
                </div>
              </div>
            </div>
          );
        }
      };
      return (
        <ModalCommon {...modalProps}>
          <ModalConfirmTransection
            handleClose={modalProps.handleClose}
            note={note}
            title="Confirm Swap"
            iconMid={icon_mid}
            tokenInOut={token_in_out}
            renderDetail={renderDetail}
            onConfirm={onConfirmSwap}
            statusBtn={statusBtn}
            originalTrade={bestTrade}
            trade={bestTradeRealTime}
            // onAcceptChanges={handleAcceptChanges}
            independency={independency}
          />
        </ModalCommon>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModalConfirmSwap, changePrice, bestTrade, bestTradeRealTime]);

  const renderRoute = useCallback(() => {
    if (!isEmpty(bestTrade.routes)) {
      let routesUpdate = bestTrade.routes.map(item => {
        if (item.symbol === 'WETH') {
          item.symbol = 'ETH';
          return item;
        }
        return item;
      });
      return (
        <div className="route-list">
          {routesUpdate.map((token, i, path) => {
            const isLastItem = i === path.length - 1;
            return (
              <React.Fragment key={i}>
                <span className="token-name">{token.symbol}</span>
                {isLastItem ? null : <img src={chevron_right_white_24x24} alt="" />}
              </React.Fragment>
            );
          })}
        </div>
      );
    }
  }, [bestTrade]);

  const onApprove = async () => {
    const callbackSuccess = () => {
      // toast.success("Transaction success");
    };
    if (!isEmpty(bestTrade)) {
      // let valueIndepen = inputValue;
      let valueIndepen = 99999999999999;

      const path = bestTrade.routes.map(i => {
        return i.address.toLowerCase();
      });

      checkedPool === 'uni_swap'
        ? await uni_func.approve(
            path[0],
            valueIndepen,
            user,
            callbackError,
            callbackReject,
            callbackHash,
            callbackSuccess,
            setDisplayApprove,
            setStatusConfirm
          )
        : await sushi_func.approve(
            path[0],
            valueIndepen,
            user,
            callbackError,
            callbackReject,
            callbackHash,
            callbackSuccess,
            setDisplayApprove,
            setStatusConfirm
          );
    }
  };

  const onSetMaxInputValue = () => {
    setInputValue(maxAmountInput.toExact());

    setIndependency('INPUT');
    setDisableBtnMaxInput(true);
  };

  const handleClickAway = () => {
    setOpenSetting(false);
  };

  const hanldClickSettig = () => {
    setOpenSetting(prev => !prev);
  };

  console.log('statusConfirm', statusConfirm);
  console.log('loading', loading);
  return (
    <Row className="body">
      <div className="wrap-top">
        <div className="select-pool">
          <div className="select-label">Select Pool :</div>
          <RadioGroup
            defaultValue="uni_swap"
            value={checkedPool}
            onChange={e => dispatch(set_check_pool(e.target.value))}
          >
            <FormControlLabel value="uni_swap" control={<Radio />} label="Uni Swap" />
            <FormControlLabel value="sushi_swap" control={<Radio />} label="Sushi Swap" />
          </RadioGroup>
        </div>
        <ClickAwayListener onClickAway={handleClickAway}>
          <div
            style={{
              position: 'relative'
            }}
          >
            <img src={setting_icon} onClick={hanldClickSettig} alt="" />
            {openSetting ? (
              <SettingTransection
                dealine={dealine}
                activePercent={activePercent}
                checkedExpert={checkedExpert}
                checkedMultihop={checkedMultihop}
                setDealine={setDealine}
                setActivePercent={setActivePercent}
                setCheckedExpert={setCheckedExpert}
                setCheckedMultihop={setCheckedMultihop}
              />
            ) : null}
          </div>
        </ClickAwayListener>
      </div>

      <div className="main-swap-form">
        <Row>
          <Col size="11" className="from">
            <div className="flex-column token-input-place">
              <span>
                {independency === 'OUTPUT' && !isEmpty(bestTrade) ? 'From (estimated)' : 'From'}
              </span>
              <div className="flex-row">
                <TextField
                  // onKeyDown={e => {
                  //   // setTargetInput(true);
                  //   setIndependency("INPUT");
                  // }}
                  onKeyDown={e => isNotValidASCIINumber(e.keyCode, true) && e.preventDefault()}
                  onKeyPress={e => isPreventASCIICharacters(e.key) && e.preventDefault()}
                  //JIG-544: Remove tooltip message
                  //type="number"
                  //End
                  placeholder="0.0"
                  autoComplete="off"
                  id="input_form"
                  value={inputValue}
                  onChange={e => {
                    setInputValue(e.target.value);
                    setIndependency('INPUT');
                    // if (Number(e.target.value) > 0) {
                    //   setIndependency("INPUT");
                    // }
                  }}
                />
                <div
                  className={classNames(
                    {
                      'btn-max-disable': disableBtnMaxInput && atMaxAmountInput
                    },
                    'btn-max-input'
                  )}
                  onClick={onSetMaxInputValue}
                >
                  MAX
                </div>
              </div>
            </div>
            <div className="flex-column align-item-end">
              <span style={{ textAlign: 'end' }}>Balance : {balanceInput}</span>
              <div
                className="display-token-name"
                id="input-token-name"
                onClick={e => {
                  setOpenModalListToken(true);
                  setCurrentTarget(e.currentTarget.id);
                }}
              >
                <img src={inputToken?.logoURI} alt="" />
                <div>{inputToken ? inputToken?.symbol : 'Select a token'}</div>
                <div className="icon">
                  <img src={down_icon} alt="" />
                </div>
              </div>
            </div>
          </Col>
          <Col size="2" className="swith-swap">
            {!loading ? (
              <div className="bg-img-wrap" onClick={onSwithIndependency}>
                <img src={switch_icon_large} alt="" />
              </div>
            ) : (
              <Spinner size="big" />
            )}
          </Col>
          <Col size="11" className="to">
            <div className="flex-column token-output-place">
              <span>
                {independency === 'INPUT' && !isEmpty(bestTrade) ? 'To (estimated)' : 'To'}
              </span>
              <div className="flex-row">
                <TextField
                  // onKeyDown={e => {
                  //   // setTargetOutput(true);
                  //   setIndependency("OUTPUT");
                  // }}
                  onKeyDown={e => isNotValidASCIINumber(e.keyCode, true) && e.preventDefault()}
                  onKeyPress={e => isPreventASCIICharacters(e.key) && e.preventDefault()}
                  type="number"
                  placeholder="0.0"
                  autoComplete="off"
                  id="output_forn"
                  value={outputValue}
                  onChange={e => {
                    setOutputvalue(e.target.value);
                    setIndependency('OUTPUT');
                  }}
                />
              </div>
            </div>
            <div className="flex-column align-item-end">
              <span style={{ textAlign: 'end' }}>Balance : {balanceOutput}</span>
              <div
                className="display-token-name"
                id="output-token-name"
                onClick={e => {
                  setOpenModalListToken(true);
                  setCurrentTarget(e.currentTarget.id);
                }}
              >
                <img src={outputToken?.logoURI} alt="" />
                <div>{outputToken ? outputToken?.symbol : 'Select a token'}</div>
                <div className="icon">
                  <img src={down_icon} alt="" />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {!isEmpty(bestTrade) && (
        <div className="more-info">
          <div className="title-header">Provider</div>
          <Row>
            <Col size="6" className="left">
              <div className="left__title">
                <img src={checkedPool === 'uni_swap' ? icon_uni : SushiIcon} alt="" />
                <span>{checkedPool === 'uni_swap' ? 'Uni Swap' : 'Sushi Swap'}</span>
              </div>
              <div className="wrap flex-row">
                {independency === 'INPUT' && !isEmpty(bestTrade) ? (
                  <>
                    <Col size="4" className="item">
                      <span className="above">{bestTrade?.midPriceInvert}</span>
                      <span className="below">
                        {inputToken?.symbol} per {outputToken?.symbol}
                      </span>
                    </Col>
                    <Col size="4" className="item">
                      <span className="above">{bestTrade?.midPrice}</span>
                      <span className="below">
                        {outputToken?.symbol} per {inputToken?.symbol}
                      </span>
                    </Col>
                  </>
                ) : independency === 'OUTPUT' && !isEmpty(bestTrade) ? (
                  <>
                    <Col size="4" className="item">
                      <span className="above">{bestTrade?.midPrice}</span>
                      <span className="below">
                        {outputToken?.symbol} per {inputToken?.symbol}
                      </span>
                    </Col>
                    <Col size="4" className="item">
                      <span className="above">{bestTrade?.midPriceInvert}</span>
                      <span className="below">
                        {inputToken?.symbol} per {outputToken?.symbol}
                      </span>
                    </Col>
                  </>
                ) : (
                  <Col size="8" className="item"></Col>
                )}

                <Col size="4" className="item">
                  <span className="above">{activePercent}%</span>
                  <span className="below">Slippage Tolerance</span>
                </Col>
              </div>
            </Col>
            <Col size="6" className="right">
              <div className="wrap">
                <div className="item">
                  <span className="property">
                    {independency === 'INPUT' ? 'Minimum Received' : 'Maximum sold'}
                    <Tooltip title={text_received} placement="right" arrow>
                      <img src={icon_pr} alt="" />
                    </Tooltip>
                  </span>
                  {!isEmpty(bestTrade) && (
                    <span className="value">
                      {bestTrade[
                        independency === 'INPUT' ? 'minimumAmountOut' : 'maximumAmountIn'
                      ]?.toString()}{' '}
                      {independency === 'INPUT' ? outputToken?.symbol : inputToken?.symbol}
                    </span>
                  )}
                </div>
                <div className="item">
                  <span className="property">
                    {' '}
                    Price Impact{' '}
                    <Tooltip title={text_price_impact} placement="right" arrow>
                      <img src={icon_pr} alt="" />
                    </Tooltip>
                  </span>
                  {!isEmpty(bestTrade) && (
                    <span
                      className={classNames(
                        {
                          'value-waring':
                            statusBtn === 'warning' ||
                            statusBtn === 'dangerus' ||
                            statusBtn === 'disable_warning'
                        },
                        'value'
                      )}
                    >
                      {bestTrade?.priceImpact < 0.01 ? '<0.01' : bestTrade?.priceImpact?.toString()}{' '}
                      %
                    </span>
                  )}
                </div>
                <div className="item">
                  <span className="property">
                    {' '}
                    Liquidity Provider Free{' '}
                    <Tooltip title={text_liquidity_provider_free} placement="right" arrow>
                      <img src={icon_pr} alt="" />
                    </Tooltip>
                  </span>
                  {!isEmpty(bestTrade) && (
                    <span className="value">
                      {bestTrade?.realizedLPFee} {inputToken?.symbol}{' '}
                    </span>
                  )}
                </div>
                {!isEmpty(bestTrade) && bestTrade.routes.length > 2 && (
                  <div className="item">
                    <span className="property">
                      {' '}
                      Route{' '}
                      <Tooltip title={text_route} placement="right" arrow>
                        <img src={icon_pr} alt="" />
                      </Tooltip>
                    </span>
                    {renderRoute()}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      )}

      <div className="footer-swap">
        {displayApprove &&
        ((statusText === 'Swap' && statusBtn === 'success') ||
          (statusText === 'Swap Anyway' && statusBtn === 'warning')) ? (
          <div
            className={classNames(
              {
                'btn--disable': statusConfirm === 'wating-approve' || statusConfirm === 'confirmed',
                'btn--success': displayApprove === true
              },
              'btn'
            )}
            onClick={() => {
              onApprove();
              setStatusConfirm('wating-approve');
            }}
          >
            Approve {inputToken.symbol}{' '}
            {(statusConfirm === 'wating-approve' || statusConfirm === 'confirmed') && (
              <Spinner className="loading-approve-btn" />
            )}
          </div>
        ) : (
          <div onClick={() => setOpenModalConfirmSwap(true)} className={`btn btn--${statusBtn}`}>
            {statusText}
          </div>
        )}

        <div className="btn-clear-all" onClick={onClearForm}>
          Clear All
        </div>
      </div>
      {renderModalListToken}
      {renderModalConfirmSwap}
      {renderModalWating}
    </Row>
  );
}

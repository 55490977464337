import { useState, useCallback } from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import ListingIcon from '../../../../../assets/listing.png';
import CreateIcon from '../../../../../assets/create.png';
import BidCancelIcon from '../../../../../assets/bid-cancel.png';
import BidIcon from '../../../../../assets/bid.png';
import TransferIcon from '../../../../../assets/transfer.png';
import SaleIcon from '../../../../../assets/sale.png';

import { GET_TRADING_HISTORY } from '../../../../../graphql/opensea/TradingQuery';

const client = new ApolloClient({
  uri: process.env.REACT_APP_OPENSEA_GRAPHQL_API,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
});

const getEventTypeFromGraph = (eventType, address) => {
  switch (eventType) {
    case 'OFFER_ENTERED': {
      return { text: 'Offer', icon: BidIcon };
    }
    case 'TRANSFER': {
      return {
        text: Number(address) === 0 ? 'Created' : 'Transfer',
        icon: Number(address) === 0 ? CreateIcon : TransferIcon
      };
    }
    case 'CREATED': {
      return {
        text: 'List',
        icon: ListingIcon
      };
    }
    case 'BID_ENTERED': {
      return { text: 'Bid', icon: BidIcon };
    }
    case 'BID_WITHDRAWN': {
      return { text: 'Bid Cancel', icon: BidCancelIcon };
    }
    case 'SUCCESSFUL': {
      return { text: 'Sale', icon: SaleIcon };
    }

    case 'CANCELLED': {
      return { text: 'Cancel', icon: BidCancelIcon };
    }
    default: {
      return;
    }
  }
};

const useTradingHistory = bundleId => {
  const [tradingHistory, setTradingHistory] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [endCursor, setEndCursor] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchTradingHistoryByToken = useCallback(
    async (fetchAll = false, filterField) => {
      try {
        setLoading(true);

        if (bundleId && (fetchAll || hasNextPage)) {
          const result = await client.query({
            query: GET_TRADING_HISTORY,
            variables: {
              archetype: null,
              bundle: bundleId,
              categories: null,
              chains: null,
              collections: null,
              count: 10,
              cursor: !fetchAll ? endCursor : null,
              eventTypes: filterField,
              identity: null,
              showAll: true
            }
          });

          if (result.data) {
            const data = result.data.assetEvents.edges;

            if (data && data.length > 0) {
              let updateTradingHistory = [];
              if (!fetchAll) updateTradingHistory = [...tradingHistory];

              setTradingHistory([
                ...updateTradingHistory,
                ...data.map(trading => {
                  const {
                    eventType,
                    price,
                    fromAccount,
                    toAccount,
                    eventTimestamp,
                    id,
                    seller,
                    winnerAccount,
                    transaction
                  } = trading.node;

                  return {
                    id,
                    eventType: getEventTypeFromGraph(eventType, fromAccount?.address),
                    price: price
                      ? new BigNumber(price.quantity)
                          .div(Math.pow(10, price.asset.decimals))
                          .toFixed()
                      : undefined,
                    priceSymbol: price ? price.asset.symbol : undefined,
                    from: fromAccount
                      ? {
                          address:
                            (fromAccount.user && fromAccount.user.publicUsername) ||
                            fromAccount.address,
                          imageUrl: fromAccount.imageUrl,
                          userAddress: fromAccount.address
                        }
                      : eventType === 'SUCCESSFUL' || eventType === 'CANCELLED'
                      ? {
                          address: (seller.user && seller.user.publicUsername) || seller.address,
                          imageUrl: seller.imageUrl,
                          userAddress: seller.address
                        }
                      : undefined,
                    to: toAccount
                      ? {
                          address:
                            (toAccount.user && toAccount.user.publicUsername) || toAccount.address,
                          imageUrl: toAccount.imageUrl,
                          userAddress: toAccount.address
                        }
                      : eventType === 'SUCCESSFUL'
                      ? {
                          address:
                            (winnerAccount.user && winnerAccount.user.publicUsername) ||
                            winnerAccount.address,
                          imageUrl: winnerAccount.imageUrl,
                          userAddress: winnerAccount.address
                        }
                      : undefined,
                    timeStamp: moment.utc(eventTimestamp).fromNow(),
                    cursor: trading.cursor,
                    blockExplorerLink: transaction && transaction.blockExplorerLink
                  };
                })
              ]);
            } else setTradingHistory([]);

            setHasNextPage(result.data.assetEvents.pageInfo.hasNextPage);
            setEndCursor(result.data.assetEvents.pageInfo.endCursor);
          }

          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bundleId, hasNextPage, endCursor]
  );

  return { tradingHistory, fetchTradingHistoryByToken, loading };
};

export default useTradingHistory;

import React, { useEffect, useState, useRef } from 'react';
import Page from 'components/Page/Page';
import { useDispatch } from 'react-redux';
import { changeTitlePage } from '_actions/titlePage.actions';
import { Row, Col, Container } from 'components/GridSystem/GridSystem';
import { connect } from 'react-redux';
import './SendTransaction.scss';
import icon_search from '../../assets/swap/search_icon.svg';
import { config } from '../../config';
import Item from './Item';
import ModalCommon from 'components/ModalCommon/ModalCommon';
import img_icon from '../../assets/close.svg';
import icon_close from '../../assets/swap/close_icon.svg';
import ModalSuccess from '../../components/ModalSuccess/ModalSuccess';
import HeaderCommon from 'components/HeaderCommon/HeaderCommon';
import { BigNumber } from 'bignumber.js';
import { utils } from 'ethers';
import {
  noDecimalFormatter as formatter,
  formatMoneyWithoutDollarSign
} from '../../util/CurrencyFormatter';
import _, { find } from 'lodash';
import {
  isNotValidASCIINumber,
  trimLeadingZerosWithDecimal,
  isPreventASCIICharacters
} from '../../util/input';
import Select from '../../components/Select/Select';
import useListTokenBalance from './hooks/useListTokenBalance';
import { toFixed } from 'util/liquidity-utils';
import { CHAIN_ID } from '../Swap/uni/const';
import { getCurrencyToken } from '../Swap/function';
import { TokenAmount, ETHER } from '@uniswap/sdk';

BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_DOWN });
const networkName = process.env.REACT_APP_NETWORK_NAME;

const Web3 = require('web3');
const Tx = require('ethereumjs-tx').Transaction;
const INFURA_URL = process.env.REACT_APP_INFURA_URL;
const NETWORK_NAME = config.chain_name;
const web3 = new Web3(new Web3.providers.HttpProvider(INFURA_URL));
const ABI = require('./ABI.json').abi;
const identicon = require('identicon');

const SendTransaction = props => {
  const { user } = props;
  const { address: userAddress } = user;
  const [modalAddToken, setModalAddToken] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [contractAddress, setContractAddress] = useState('');
  const [nameToken, setNameToken] = useState('');
  const [decimals, setDecimals] = useState('');
  const [symbol, setSymbol] = useState('');
  const [symbolConfirm, setSymbolConfirm] = useState('');
  const [token, setToken] = useState('');
  const [txHash, setTxHash] = useState('');

  const [selectedToken, setSelectedToken] = useState({
    label: 'ETH - Ethereum',
    value: ''
  });

  const [amount, setAmount] = useState(0);
  const [address, setAddress] = useState('');
  const [listToken, setListToken] = useState([]);
  const [searchToken, setSearchToken] = useState([]);
  const [keySearch, setKeySearch] = useState('');
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [gasLimit, setGasLimit] = useState('');
  const [defaultGasLimit, setDefaultGasLimit] = useState(0);
  const [isValidated, setIsValidated] = useState(false);
  const [validToken, setValidToken] = useState(true);
  const [reloadToken, setReloadToken] = useState(true);
  const addressAvatar = useRef(null);
  const [dataBalance, setDataBalance] = useState([]);
  const [dataBalanceETH, setDataBalanceETH] = useState(0);

  const listTokenBalance = useListTokenBalance(
    setDataBalance,
    listToken,
    reloadToken,
    setReloadToken
  );

  useEffect(() => {
    addressAvatar &&
      address &&
      identicon.generate({ id: address, size: 150 }, function(err, buffer) {
        if (err) throw err;
        var img = new Image();
        img.src = buffer;
        addressAvatar.current.appendChild(img);
      });
  }, [addressAvatar, address]);

  const dataToken = localStorage.getItem('dataToken')
    ? JSON.parse(localStorage.getItem('dataToken'))
    : []; // null

  const onClear = () => {
    setContractAddress('');
    setNameToken('');
    setSymbol('');
    setDecimals('');
    setAddress('');
    setAmount(0);
  };

  const resetTokenInfo = () => {
    setNameToken('');
    setSymbol('');
    setDecimals('');
  };

  useEffect(() => {
    if (token === '') {
      const balanceETH = async () => {
        let result = await new web3.eth.getBalance(user.address);
        setDataBalanceETH(+result);
      };
      balanceETH();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dataToken && dataToken.length && dataToken[0] !== null && setListToken(dataToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getBalance = async contractToken => {
      return await new web3.eth.Contract(ABI, contractToken).methods.balanceOf(user.address).call();
    };
    let contract = listToken.map(item => item.contractAddress);
    Promise.all(contract).then(x => {
      let y = x.map(async item => {
        let itemBalance = {};
        itemBalance[item] = await getBalance(item);
        return itemBalance;
      });
      Promise.all(y).then(z => {
        setDataBalance(z);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listToken]);

  useEffect(() => {
    const asyncTmp = async () => {
      setIsValidated(false);
      if (address !== '' && amount > 0) {
        let validator = web3.utils.isAddress(address);
        if (validator) {
          if (token === '') {
            try {
              const gasFee = await web3.eth.getGasPrice();
              const valueToSend = +dataBalanceETH - +gasFee;
              const amountValue = await web3.utils.toWei(`${+amount}`, 'ether');

              if (+amountValue <= +valueToSend) {
                setIsValidated(true);
              } else {
                setIsValidated(false);
              }
            } catch (error) {
              setIsValidated(false);
            }
          } else {
            try {
              const Erc20contract = new web3.eth.Contract(ABI, token);
              const decimalToken = await Erc20contract.methods.decimals().call();
              const iterator = dataBalance.keys();
              let currentBalance = 0;
              for (const key of iterator) {
                if (dataBalance[key][token]) {
                  currentBalance = dataBalance[key][token];
                }
              }
              const amountValue = new BigNumber(amount);

              if (+amountValue <= utils.formatUnits(currentBalance, decimalToken)) {
                setIsValidated(true);
              } else {
                setIsValidated(false);
              }
            } catch (error) {
              setIsValidated(false);
            }
          }
        }
      }
    };
    asyncTmp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, address]);

  useEffect(() => {
    localStorage.setItem('dataToken', JSON.stringify(listToken));
  }, [listToken]);

  const dispatch = useDispatch();

  const CheckEntireBalance = async () => {
    if (token === '') {
      const dataBalance = await new web3.eth.getBalance(user.address);
      const gasFee = 0.01 * Math.pow(10, 18);
      const valueToSend = +dataBalance - +gasFee;
      console.log(valueToSend);
      let balanceETH = new TokenAmount(ETHER, valueToSend).toFixed();
      setAmount(balanceETH);
    } else {
      const Erc20contract = new web3.eth.Contract(ABI, token);
      const valueToSend = await Erc20contract.methods.balanceOf(user.address).call();
      let tokenCurrent = dataToken
        .map(item => {
          return {
            ...item,
            address: item.contractAddress,
            chainId: CHAIN_ID,
            decimals: Number(item.decimals)
          };
        })
        .filter(item => {
          return item.address === token;
        });
      let balanceToken = new TokenAmount(getCurrencyToken(tokenCurrent[0]), valueToSend).toExact();
      setAmount(balanceToken);
    }
  };

  //khai bao contract token

  const checkToken = async contractAddress => {
    try {
      const contractToken = new web3.eth.Contract(ABI, contractAddress);
      let name = await contractToken.methods.name().call();
      let decimals = await contractToken.methods.decimals().call();
      let symbol = await contractToken.methods.symbol().call();
      return {
        name,
        decimals,
        symbol
      };
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    onClear();
  }, [token]);

  useEffect(() => {
    try {
      if (contractAddress !== '') {
        let getToken = async () => {
          let data = await checkToken(contractAddress);
          console.log(data);
          if (data) {
            setNameToken(data?.name);
            setDecimals(data?.decimals);
            setSymbol(data?.symbol);
            setValidToken(true);
          } else {
            resetTokenInfo();
            setValidToken(false);
          }
        };
        getToken();
        return;
      } else {
        resetTokenInfo();
      }
      setValidToken(false);
    } catch (error) {
      console.log(error);
    }
  }, [contractAddress]);

  const addToken = async contractAddress => {
    if (dataToken.length > 0) {
      if (
        !dataToken.some(
          item => item.contractAddress.toLowerCase() === contractAddress.toLowerCase()
        )
      ) {
        let data = await checkToken(contractAddress);
        if (data) {
          setNameToken(data.name);
          setDecimals(data.decimals);
          setSymbol(data.symbol);
          let dataToken = {
            contractAddress: contractAddress,
            name: data.name,
            decimals: data.decimals,
            symbol: data.symbol
          };
          setListToken([...listToken, dataToken]);
          setReloadToken(true);
          onClear();
        }
        setModalAddToken(false);
        return;
      } else {
        setModalAddToken(false);
        onClear();
        return;
      }
    } else {
      let data = await checkToken(contractAddress);
      console.log(data);
      if (data) {
        setNameToken(data.name);
        setDecimals(data.decimals);
        setSymbol(data.symbol);
        let dataToken = {
          contractAddress: contractAddress,
          name: data.name,
          decimals: data.decimals,
          symbol: data.symbol
        };
        setListToken([...listToken, dataToken]);
        onClear();
      }
      setModalAddToken(false);
      onClear();
    }
  };

  const sendEthWc = async address => {
    try {
      await window.web3.eth
        .sendTransaction(
          {
            from: user.address,
            to: address,
            value: web3.utils.toWei(`${amount}`, 'ether')
          },
          function(err, transactionHash) {
            if (err) {
              console.log('err', err);
            } else {
              setTxHash(transactionHash);
              setIsShowModalSuccess(true);
              onClear();
              console.log('transactionHash', transactionHash);
            }
          }
        )
        .once('confirmation', () => {
          setReloadToken(true);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const sendTokenWc = async (address, amount) => {
    try {
      const Erc20contract = new window.web3.eth.Contract(ABI, token);
      const decimalToken = await Erc20contract.methods.decimals().call();
      const sendAmount = new BigNumber(amount).multipliedBy(Math.pow(10, decimalToken));

      Erc20contract.methods
        .transfer(address, sendAmount.toFixed())
        .send(
          {
            from: user.address
          },
          function(err, transactionHash) {
            if (err) {
              console.log('err', err);
            } else {
              setTxHash(transactionHash);
              setIsShowModalSuccess(true);
              onClear();
              console.log('transactionHash', transactionHash);
            }
          }
        )
        .once('confirmation', () => {
          setReloadToken(true);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const sendEthMetamask = async address => {
    try {
      const web3 = new Web3(Web3.givenProvider);
      await web3.eth
        .sendTransaction(
          {
            from: user.address,
            to: address,
            value: web3.utils.toWei(`${amount}`, 'ether')
          },
          function(err, transactionHash) {
            if (err) {
              console.log('err', err);
            } else {
              setTxHash(transactionHash);
              setIsShowModalSuccess(true);
              console.log('transactionHash', transactionHash);
            }
          }
        )
        .once('confirmation', () => {
          setReloadToken(true);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const sendTokenMetamask = async (address, amount) => {
    try {
      const web3 = new Web3(Web3.givenProvider);
      const Erc20contract = new web3.eth.Contract(ABI, token);
      const decimalToken = await Erc20contract.methods.decimals().call();
      const sendAmount = new BigNumber(amount).multipliedBy(Math.pow(10, decimalToken));

      Erc20contract.methods
        .transfer(address, sendAmount.toFixed())
        .send(
          {
            from: user.address
          },
          function(err, transactionHash) {
            if (err) {
              console.log('err', err);
            } else {
              setIsShowModalSuccess(true);
              setTxHash(transactionHash);
              console.log('transactionHash', transactionHash);
            }
          }
        )
        .once('confirmation', () => {
          setReloadToken(true);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const sendETH = async (address, amount) => {
    try {
      const rawTx = {
        nonce: web3.utils.toHex(await web3.eth.getTransactionCount(user.address, 'pending')),
        gasLimit: 21000,
        gasPrice: web3.utils.toHex(await web3.eth.getGasPrice()),
        to: address,
        data: '0x',
        value: web3.utils.toHex(amount * 10 ** 18) // 0
      };
      console.log(rawTx);
      const tx = new Tx(rawTx, { chain: config.chain_name.toLowerCase() });
      const privateKey = Buffer.from(user.privateKey, 'hex');
      tx.sign(privateKey);
      var serializedTx = tx.serialize();
      web3.eth
        .sendSignedTransaction('0x' + serializedTx.toString('hex'), function(err, hash) {
          if (!err) {
            onClear();
            console.log(hash);
          } else {
            console.log(err);
          }
        })
        .once('confirmation', () => {
          setReloadToken(true);
        })
        .on('receipt', console.log)
        .once('transactionHash', e => {
          setTxHash(e);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const sendToken = async () => {
    console.log('vao day');
    try {
      const Erc20contract = new web3.eth.Contract(ABI, token);
      const decimalToken = await Erc20contract.methods.decimals().call();
      const sendAmount = new BigNumber(amount).multipliedBy(Math.pow(10, decimalToken));

      const data = Erc20contract.methods.transfer(address, sendAmount.toFixed());

      // 2. create approve tx parameters
      const approveTxParams = {
        nonce: web3.utils.toHex(await web3.eth.getTransactionCount(user.address, 'pending')),
        gasLimit:
          gasLimit > 21000 && gasLimit <= 5000000
            ? Number(gasLimit)
            : web3.utils.toHex(await data.estimateGas({ from: user.address })),
        gasPrice: web3.utils.toHex(await web3.eth.getGasPrice()),
        to: token,
        data: data.encodeABI(),
        value: '0x00' // 0
      };
      console.log(approveTxParams);

      // 3. sign transaction
      const tx = new Tx(approveTxParams, { chain: config.chain_name.toLowerCase() });
      const privateKey = Buffer.from(user.privateKey, 'hex');

      tx.sign(privateKey);
      const serializeTx = tx.serialize();

      // 4. send signed transaction
      await web3.eth
        .sendSignedTransaction('0x' + serializeTx.toString('hex'), function(err, hash) {
          if (!err) {
            onClear();
            console.log(hash); // "0x7f9fade1c0d57a7af66ab4ead79fade1c0d57a7af66ab4ead7c2c2eb7b11a91385"
          } else {
            console.log(err);
          }
        })
        .once('confirmation', () => {
          setReloadToken(true);
        })
        .once('transactionHash', e => {
          setTxHash(e);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(changeTitlePage('Send Transaction'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderModalAddToken = () => {
    let modalProps = {
      open: modalAddToken,
      handleClose: () => {
        setModalAddToken(false);
        onClear();
      }
    };
    return (
      <ModalCommon {...modalProps}>
        <div
          style={{
            background: '#1e2024',
            width: '537px',
            height: '370px'
          }}
        >
          <div
            style={{
              height: '10px',
              padding: '9px'
            }}
          >
            <img
              className="btn-icon-close"
              src={icon_close}
              onClick={() => {
                console.log('111');
                onClear();
                setModalAddToken(false);
              }}
              alt=""
            ></img>
          </div>

          <Row
            className="label-modal head"
            style={{
              fontSize: '18px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            Add Custom Tokens
          </Row>
          <div style={{ padding: '0 20px' }}>
            <Row size="4" className="">
              <Col size="4" className="label-modal">
                Contract Address
              </Col>
              <Col size="8">
                <input
                  className="input-add-token"
                  onChange={e => setContractAddress(String(e.target.value))}
                  value={contractAddress}
                ></input>
              </Col>
            </Row>
            <Row>
              <Col size="4" className="label-modal">
                Name Token
              </Col>
              <Col size="8">
                <input className="input-add-token" disabled value={nameToken}></input>
              </Col>
            </Row>
            <Row>
              <Col size="4" className="label-modal">
                Symbol
              </Col>
              <Col size="8">
                <input className="input-add-token" disabled value={symbol}></input>
              </Col>
            </Row>
            <Row>
              <Col size="4" className="label-modal">
                Decimals
              </Col>
              <Col size="8">
                <input className="input-add-token" disabled value={decimals}></input>
              </Col>
            </Row>
            {!validToken && <button className="btn-send-disable">Save</button>}
            {validToken && (
              <button
                className="btn-save-token"
                onClick={() => {
                  addToken(contractAddress);
                }}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </ModalCommon>
    );
  };

  const estimateGasLimit = async () => {
    try {
      if (amount > 0 && address) {
        if (token !== '') {
          const Erc20contract = new web3.eth.Contract(ABI, token);
          const decimalToken = await Erc20contract.methods.decimals().call();
          const data = Erc20contract.methods.transfer(
            address,
            toFixed(`${amount * 10 ** decimalToken}`)
          );
          let x = await data.estimateGas({ from: user.address });
          setDefaultGasLimit(x);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderModalConfirm = React.useMemo(() => {
    if (user.isMetaMask || user.isWC) {
      return;
    }
    console.log('token', token);
    if (token !== '') {
      const fetchData = async () => {
        let x = await checkToken(token);
        console.log('vao 4');
        setSymbolConfirm(x.symbol);
      };
      fetchData();
    }

    let modalProps = {
      open: modalConfirm,
      handleClose: () => setModalConfirm(false)
    };

    return (
      <ModalCommon {...modalProps}>
        <div className="modal_confirm">
          <div className="title_confirm" style={{ position: 'relative' }}>
            Confirmation
            <img
              onClick={() => setModalConfirm(false)}
              alt=""
              src={icon_close}
              style={{
                position: 'absolute',
                right: 10,
                top: '50%',
                transform: 'translateY(-60%)',
                cursor: 'pointer'
              }}
            />
          </div>
          <div className="modal-content">
            <div className="box-form">
              <div className="box-form-value">
                {formatMoneyWithoutDollarSign(formatter.format(amount))}{' '}
                {token === '' ? 'ETH' : symbolConfirm}
              </div>
              <div className="title-from-address">From Address</div>
              <div className="user-address">{user.address}</div>
            </div>

            <div className="down-arrow">&darr;</div>

            <div className="box-form">
              <div className="box-form-value">
                {formatMoneyWithoutDollarSign(formatter.format(amount))}{' '}
                {token === '' ? 'ETH' : symbolConfirm}
              </div>
              <div className="title-from-address">To Address</div>
              <div className="user-address">{address}</div>
            </div>

            {user.privateKey && (
              <div className="box-information">
                <div className="title-box-information">Detail Information</div>
                <div className="row-box-information">
                  <div className="row-box-label">Network</div>
                  <div className="row-box-value">
                    {token === '' ? 'ETH' : symbolConfirm} by {NETWORK_NAME} Test
                  </div>
                </div>
                <div className="row-box-information">
                  <div className="row-box-label">
                    {' '}
                    Gas Litmit ( usually ranges from 21,000 to 500,000)
                  </div>
                  <div className="row-box-value">
                    {formatMoneyWithoutDollarSign(
                      formatter.format(gasLimit === '' ? defaultGasLimit : gasLimit)
                    )}
                  </div>
                </div>
                <div className="row-box-information">
                  <div className="row-box-label">Gas Price ( in Gwei )</div>
                  <div className="row-box-value">1 Gwei</div>
                </div>
                <div className="row-box-information">
                  <div className="row-box-label">Transaction Fee</div>
                  <div className="row-box-value">
                    {gasLimit === ''
                      ? trimLeadingZerosWithDecimal(
                          `${Number(0.000000001 * defaultGasLimit).toFixed(9)}`
                        )
                      : trimLeadingZerosWithDecimal(
                          `${Number(0.000000001 * gasLimit).toFixed(9)}`
                        )}{' '}
                    ETH{' '}
                  </div>
                </div>
              </div>
            )}
            <button
              className="btn-send"
              onClick={() => {
                user.privateKey && setIsShowModalSuccess(true);
                try {
                  token === '' ? sendETH(address, amount) : sendToken();
                } catch (error) {
                  console.log('error', error);
                }
                setModalConfirm(false);
                onClear();
              }}
            >
              Confirm and Send
            </button>
          </div>
        </div>
      </ModalCommon>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, amount, symbol, address, modalConfirm, user, gasLimit, defaultGasLimit]);

  useEffect(() => {
    if (keySearch !== '' && dataToken) {
      if (dataToken) {
        let result = _.filter(dataToken, item =>
          item.symbol.toLowerCase().includes(keySearch.toLowerCase())
        );
        console.log(result.filter(item => !!item));
        setSearchToken(result.filter(item => !!item));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keySearch]);

  return (
    <Page title="Send Transaction" className="withdraw-tokens">
      <Container>
        <HeaderCommon user={user} />
        <Row>
          <Col size="8" className="GridBox">
            {/* <TabAction /> */}
            <Row>
              <Col size="4">
                <label className="label-form">Type</label>
                <Select
                  className="form__select_ta"
                  onChange={valueType => {
                    if (valueType != null) {
                      setToken(valueType.value);
                      setSelectedToken(valueType);
                    }
                  }}
                  value={selectedToken}
                  options={[
                    { label: 'ETH - Ethereum', value: '' },
                    ...listToken
                      .filter(e => {
                        const tokenBalance = find(
                          listTokenBalance,
                          token => token.address === e.contractAddress
                        );
                        return !tokenBalance || new BigNumber(tokenBalance.balance).gt(0);
                      })
                      .map(item => {
                        return {
                          label: `${item.symbol} - ${item.name}`,
                          value: item.contractAddress
                        };
                      })
                  ]}
                />
              </Col>
              <Col size="8">
                <div className="flex-double-label">
                  <label className="label-form">Amount</label>
                  <button
                    className="btn-add-token"
                    onClick={() => {
                      CheckEntireBalance();
                    }}
                  >
                    Entire Balance
                  </button>
                </div>
                <input
                  placeholder="Amount"
                  className="form__input"
                  onChange={e => {
                    setAmount(e.target.value);
                  }}
                  value={amount}
                  onKeyDown={e => isNotValidASCIINumber(e.keyCode, true) && e.preventDefault()}
                  onKeyPress={e => isPreventASCIICharacters(e.key) && e.preventDefault()}
                  maxLength={16}
                  type="number"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="flex-double-label">
                  <label className="label-form">To Address</label>
                  <button
                    onClick={() => navigator.clipboard.writeText(address)}
                    className="btn-add-token"
                  >
                    Copy
                  </button>
                </div>
                <div className="to-address-input-group" ref={addressAvatar}>
                  <img alt="" src="https://via.placeholder.com/150" />
                  <input
                    placeholder="Please enter the address"
                    className="form__input"
                    onChange={e => setAddress(e.target.value)}
                    value={address}
                  />
                </div>
              </Col>
            </Row>
            {user.privateKey && token !== '' && (
              <React.Fragment>
                <Row>
                  <Col>
                    <div className="flex-double-label">
                      <label className="label-form">Transaction Fee</label>
                    </div>
                    <div>1.00 Gwei</div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="label-form">
                      Gas Limit (usually ranges from 21,000 to 500,000)
                    </label>
                    <br />
                    <input
                      placeholder="Gas Limit (usually ranges from 21,000 to 500,000)"
                      className="form__input"
                      onChange={e => setGasLimit(e.target.value)}
                      value={gasLimit}
                      onKeyDown={e => isNotValidASCIINumber(e.keyCode, true) && e.preventDefault()}
                      onKeyPress={e => isPreventASCIICharacters(e.key) && e.preventDefault()}
                      onBlur={e => setGasLimit(trimLeadingZerosWithDecimal(e.target.value))}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            )}

            <Row className="row-send">
              {!isValidated && <button className="btn-send-disable">Send Transaction</button>}

              {/* {!validAddress && !errorAmount && (
                <button className="btn-send-disable">Invalid address</button>
              )}
              {errorAmount && validAddress && enterAmount && (
                <button className="btn-send-disable">Insufficient Balance</button>
              )} */}

              {isValidated && (
                <button
                  onClick={() => {
                    if (user.isMetaMask) {
                      token === '' ? sendEthMetamask(address) : sendTokenMetamask(address, amount);
                    } else if (user.isWC) {
                      token === '' ? sendEthWc(address) : sendTokenWc(address, amount);
                    }
                    amount > 0 && address !== '' && setModalConfirm(true);
                    if (user.privateKey) {
                      estimateGasLimit();
                    }
                  }}
                  className="btn-send"
                >
                  Send Transaction
                </button>
              )}
              {renderModalConfirm}
            </Row>
            <button
              style={{ margin: '0 auto', display: 'block' }}
              className="btn-add-token"
              onClick={() => {
                onClear();
              }}
            >
              Clear All
            </button>
          </Col>
          <Col size="4" className="GridBox">
            <div className="add-token">
              <div>Token</div>

              <div>
                <img className="btn-icon" src={img_icon} alt="" />
                <button
                  onClick={() => {
                    setModalAddToken(true);
                  }}
                  className="btn-add-token"
                >
                  Custom Tokens
                </button>
              </div>
            </div>
            <div className="list-token">
              <div className="search-button-group">
                <input
                  value={keySearch}
                  className="search-button form__input"
                  onChange={e => setKeySearch(e.target.value)}
                  placeholder="Search"
                />
                <img alt="" src={icon_search} />
              </div>

              {keySearch === '' &&
                listToken &&
                listToken.length > 0 &&
                listToken.map(item => {
                  return <Item item={item} user={user} listTokenBalance={listTokenBalance} />;
                })}
              {keySearch !== '' &&
                (searchToken.length > 0 ? (
                  searchToken.map(item => {
                    return <Item item={item} user={user} listTokenBalance={listTokenBalance} />;
                  })
                ) : (
                  <div style={{ textAlign: 'center', paddingTop: '10px' }}>No Result</div>
                ))}
            </div>
          </Col>
          {renderModalAddToken()}
          <ModalSuccess
            open={isShowModalSuccess}
            handleClose={() => {
              onClear();
              setIsShowModalSuccess(false);
            }}
            message="Transaction Sent."
          >
            <a
              href={`https://${networkName ? `${networkName}.` : ''}etherscan.io/tx/${txHash}`}
              className="check-etherscan"
              style={{ color: '#7ac231 !important', width: '100%', display: 'inline-block' }}
              target="_blank"
              rel="noreferrer"
            >
              Check Status on Etherscan.io
            </a>
          </ModalSuccess>
        </Row>
      </Container>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, null)(SendTransaction);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import IdleTimer from 'react-idle-timer';

import PrivateRoute from 'components/PrivateRoute/PrivateRoute';

import Spinner from 'components/Spinner/Spinner';
import AppLayout from 'components/AppLayout/AppLayout';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import NotificationsPublisher from 'components/NotificationsPublisher/NotificationsPublisher';

import Account from 'Pages/Account/Account';
import DashboardContainer from 'Pages/Dashboard/DashboardContainer';
import MarketOverview from 'Pages/Market/MarketOverviewContainer';
import MarketDetails from 'Pages/Market/MarketDetailsContainer';
import NotFound from 'Pages/NotFound/NotFound';
import WalletContainer from 'Pages/Wallet/WalletContainer';
import Farming from 'Pages/Farming';
import SendTransaction from 'Pages/SendTransaction/SendTransaction';

import SwapPage from 'Pages/Swap/SwapPage';
import Stake from 'Pages/Stake/Stake';

import { getAccount } from '_actions/account.actions';
import { removeNotification } from '_actions/notifications.actions';
import { logoutUser } from '_actions/user.actions';

import { IDLE_TIMEOUT } from '_constants';
import { trackPageView } from '_vendors/analytics';

import './ProtectedArea.scss';
import Liquidity from '../../../Pages/Liquidity/Liquidity';
import OpenSeaWithApollo from 'Pages/OpenSea';
import { clearBaseHeaderControlStorage } from 'Pages/OpenSea/screen/commons/base_component/BaseHeader/helper';

export class ProtectedArea extends Component {
  componentWillMount() {
    const { user, getAccount } = this.props;
    getAccount(user.accountId);
  }

  componentDidMount() {
    this.trackPageView(this.props.location.pathname);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      this.trackPageView(location.pathname);
    }
  }

  trackPageView(pathname) {
    // do not track market redirect
    if (pathname === 'market') {
      return;
    }

    trackPageView(pathname);
  }

  renderLoading() {
    return (
      <div className="AppLoading">
        <div className="AppLoading__loader">
          <Spinner size="big" color="blue" />
        </div>
      </div>
    );
  }

  render() {
    const { notifications, removeNotification, logoutUser } = this.props;

    return (
      <AppLayout>
        <ErrorBoundary retry="Try again">
          <Switch>
            <PrivateRoute path="/dashboard" component={DashboardContainer} />
            <PrivateRoute exact path="/" component={() => <Redirect to="/dashboard" />} />
            <PrivateRoute path="/farming" component={Farming} />
            <PrivateRoute path="/Stake" component={Stake} />
            <PrivateRoute path="/account" component={Account} />
            <PrivateRoute path="/market/:exchangeName/:marketName" component={MarketDetails} />
            <PrivateRoute path="/market/:exchangeName" component={MarketOverview} />
            <PrivateRoute path="/market" component={() => <Redirect to="/market/BINANCE" />} />
            <PrivateRoute path="/wallet/:exchangeName" component={WalletContainer} />
            <PrivateRoute path="/wallet" component={WalletContainer} />
            <PrivateRoute path="/sendtransaction" component={SendTransaction} />
            <PrivateRoute path="/swap" component={SwapPage} />
            <PrivateRoute path="/liquidity" component={Liquidity} />
            {/* Opensea route start */}
            <PrivateRoute
              exact={true}
              path="/opensea/:param1/:param2/:param3"
              component={props => <OpenSeaWithApollo {...props} />}
            />
            <PrivateRoute
              exact={true}
              path="/opensea/:param1/:param2"
              component={props => <OpenSeaWithApollo {...props} />}
            />
            <PrivateRoute
              exact={true}
              path="/opensea/:param1"
              component={props => <OpenSeaWithApollo {...props} />}
            />
            <PrivateRoute
              exact={true}
              path="/opensea"
              component={() => {
                clearBaseHeaderControlStorage();
                return <Redirect to="/opensea/assets" />;
              }}
            />
            {/* Opensea route end */}
            <PrivateRoute component={NotFound} />
          </Switch>
          <NotificationsPublisher
            notifications={notifications}
            removeNotification={removeNotification}
          />
          <IdleTimer onIdle={logoutUser} timeout={IDLE_TIMEOUT} />
        </ErrorBoundary>
      </AppLayout>
    );
  }
}

const mapsStateToProps = ({ user, account, notifications }) => ({
  user,
  account,
  notifications
});

const mapDispatchToProps = {
  removeNotification,
  getAccount,
  logoutUser
};

const connectedComponent = connect(mapsStateToProps, mapDispatchToProps)(ProtectedArea);

export default withRouter(connectedComponent);

import { ApolloError } from "@apollo/client";
import { AssetSearchQueryResponseType } from "Pages/OpenSea/static/type";
import { createContext, useContext } from "react";
import { AssetSearchQueryVariableType } from "../../../static/type";

export type WalletGlobalContent = {
  variables: AssetSearchQueryVariableType;
  setNewVariables: (variables: AssetSearchQueryVariableType) => void;
  currentData: AssetSearchQueryResponseType;
  mappingDataStorage: AssetSearchQueryResponseType;
  loading: boolean;
  error: ApolloError | undefined;
  currentPage: number;
  isLoadingMore: boolean;
  handleChangedPage: (newPage: number) => void;
};
export const WalletGlobalContext = createContext<WalletGlobalContent>({} as WalletGlobalContent);
export const useWalletGlobalContext = () => useContext(WalletGlobalContext);

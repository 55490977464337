import React, { useEffect, useCallback, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { DatePicker, useDateInput } from 'react-nice-dates';
import NumberFormat from 'react-number-format';
import { enGB } from 'date-fns/locale';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import 'react-nice-dates/build/style.css';

import Page from '../../../../components/Page/Page.js';
import { Container } from 'components/GridSystem/GridSystem';
import useFetch from './hooks/useFetch';
import useETHPrice from '../commons/nfts_hooks/useETHPrice.js';
import useNFTTokenDetails from './hooks/useNFTTokenDetails';
import useOrdersQuery from './hooks/useOrdersQuery';
import useTradingHistory from './hooks/useTradingHistory';
import useOfferCancelAction from '../commons/nfts_hooks/useOfferCancelAction.js';
import useOfferOrderAction from './hooks/useOfferOrderAction';
import useNFTBuyAction from './hooks/useNFTBuyAction';
import useWalletBalance from '../commons/nfts_hooks/useWalletBalance.js';
import useOrderCheckout from '../commons/nfts_hooks/useOrderCheckout.js';

import Loading from '../commons/loading_component/OpenSeaLoading';
import AssetList from './AssetList';
import PageDetailLeft from './PageDetailLeft';
import TradingHistory from './TradingHistory';
import Offers from './Offers';
import CustomDialog from '../../../../components/CustomDialog';
import IconSubmitted from '../../../../assets/icons/icon-submitted.svg';
import EthereumIcon from '../../../../assets/eth-v3.svg';
import PlaceHolder from '../../../../assets/placeholder.png';
import { trimLeadingZerosWithDecimal } from '../../../../util/input';

import './index.scss';

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID || 1;
const BUNDLE_API_URL = `${process.env.REACT_APP_PROXY_URL}${process.env.REACT_APP_OPENSEA_BUNDLE_API}`;

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600]
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

const REGEX_NUMBER = /^-?[0-9]{0,}[.]{0,1}[0-9]{0,6}$/;

const AssetBundle = props => {
  const { bundleId } = props;
  const [date, setDate] = useState(new Date());

  const [inputError, setInputError] = React.useState({ val: false });
  const [openBuyPopup, setOpenBuyPopup] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openBuyTxHashPopup, setOpenBuyTxHashPopup] = useState(false);
  const [openCancelOfferTxHashPopup, setOpenCancelOfferTxHashPopup] = useState(false);

  const [offerAmount, setOfferAmount] = React.useState('');
  const [cursor, setCursor] = React.useState(null);
  const [offerCursor, setOfferCursor] = React.useState(null);
  const [lastTrading, setLastTrading] = React.useState(null);
  const [lastOffer, setLastOffer] = React.useState(null);
  const [tradingFilterField, setTradingFilterFiled] = useState(null);
  const [checkBoxPolicy, setCheckboxPolicy] = useState({
    checkboxA: false,
    checkboxB: false
  });

  const user = useSelector(state => state.user);
  const { ethPrice: ethToUsdPrice } = useETHPrice();
  const { canBeBidding, tokenDetails, fetchNFTTokenDetails } = useNFTTokenDetails(bundleId);
  const { orders, loading: loadingOrders, fetchOrdersByToken } = useOrdersQuery(
    bundleId,
    offerCursor
  );
  const { tradingHistory, fetchTradingHistoryByToken } = useTradingHistory(bundleId, cursor);
  const { data: assetBundle } = useFetch(
    bundleId ? `/${bundleId}` : null,
    false,
    {},
    `${BUNDLE_API_URL}${bundleId}/`
  );
  const {
    cancelOffer,
    txHash: orderCancelTxHash,
    setTxHash: setOrderCancelTxHash
  } = useOfferCancelAction();
  const { handleOfferMaking, loading: offerMakingLoading } = useOfferOrderAction(
    bundleId,
    assetBundle,
    offerAmount,
    date,
    canBeBidding
  );
  const {
    handleNFTTokenPurchase,
    loading: NFTBuyLoading,
    txHash: buyTxHash,
    setTxHash: setBuyTxHash
  } = useNFTBuyAction(tokenDetails, bundleId);
  const { fetchUserWalletBalance, walletBalance } = useWalletBalance();
  const { fetchOrderCheckout, orderCheckout, loading: orderCheckoutLoading } = useOrderCheckout(
    tokenDetails
  );

  const assetTokenPrice = tokenDetails
    ? tokenDetails.currentPriceToDollar
    : new BigNumber(tokenDetails?.currentPrice).multipliedBy(ethToUsdPrice).toFixed(2);

  const scrollHandler = useCallback(
    e => {
      var element = e.target;
      if (element.scrollHeight - element.scrollTop <= element.clientHeight) {
        setCursor(lastTrading);
      }
    },
    [lastTrading]
  );

  const offersScrollHandler = useCallback(
    e => {
      var element = e.target;
      if (Math.floor(element.scrollHeight - element.scrollTop) <= element.clientHeight) {
        setOfferCursor(lastOffer);
      }
    },
    [lastOffer]
  );

  const handleClose = () => {
    setInputError({ val: false });
    setOpen(false);
    setCheckboxPolicy({ checkboxB: false, checkboxA: false });

    tokenDetails.currentPrice && canBeBidding
      ? setOfferAmount(tokenDetails.currentPrice)
      : setOfferAmount('');
    setDate(new Date());
  };

  const handleInputChange = async e => {
    const value = e.target.value.replaceAll(',', '');
    if (value !== '' && REGEX_NUMBER.test(value)) {
      setOfferAmount(value);

      tokenDetails && new BigNumber(value).gt(walletBalance)
        ? setInputError({
            message: 'Not enough WETH to make offer',
            val: true
          })
        : setInputError({
            val: false
          });
    } else {
      setOfferAmount('');
    }
  };

  const handlePolicyCheckboxChange = event => {
    setCheckboxPolicy({ ...checkBoxPolicy, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    if (orderCancelTxHash) {
      setCheckboxPolicy({ checkboxB: false, checkboxA: false });
      setOpenCancelOfferTxHashPopup(true);
    }
  }, [orderCancelTxHash]);

  useEffect(() => {
    if (buyTxHash) {
      setCheckboxPolicy({ checkboxB: false, checkboxA: false });
      setOpenBuyTxHashPopup(true);
    }
  }, [buyTxHash]);

  useEffect(() => {
    bundleId && fetchNFTTokenDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundleId]);

  useEffect(() => {
    tokenDetails &&
      tokenDetails.currentPrice &&
      canBeBidding &&
      setOfferAmount(tokenDetails.currentPrice);
  }, [tokenDetails, canBeBidding]);

  useEffect(() => {
    tradingHistory &&
      tradingHistory.length > 0 &&
      setLastTrading(tradingHistory[tradingHistory.length - 1].cursor);
  }, [tradingHistory]);

  useEffect(() => {
    orders && orders.length > 0 && setLastOffer(orders[orders.length - 1].cursor);
  }, [orders]);

  useEffect(() => {
    const fetchAsync = async () => {
      if (cursor) {
        cursor && (await fetchTradingHistoryByToken(false, tradingFilterField));
      }
    };

    fetchAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor, tradingFilterField]);

  useEffect(() => {
    offerCursor && !loadingOrders && fetchOrdersByToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerCursor, loadingOrders]);

  useEffect(() => {
    const fetchOrders = async () => {
      if (bundleId) {
        await fetchOrdersByToken();
        await fetchUserWalletBalance();
      }
    };

    fetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundleId]);

  const timeInputProps = useDateInput({
    date,
    format: 'HH:mm',
    locale: enGB,
    onDateChange: setDate
  });

  const handleOfferCancel = async offer => {
    await cancelOffer(offer.oldOrder);
    await fetchTradingHistoryByToken(true);
    await fetchOrdersByToken(true);
  };

  return (
    <Page title="Asset Details" className="Swap-page">
      <Container>
        {tokenDetails ? (
          <div className="wrapper">
            <div className="item-wrapper">
              <PageDetailLeft tokenDetails={tokenDetails} />
              <section className="detail-item__main">
                <strong style={{ textDecoration: 'none', fontSize: 18, color: '#08b689' }}>
                  {tokenDetails.name}
                </strong>
                <p className="detail-item__name">{tokenDetails.name}</p>
                {tokenDetails.currentPrice && tokenDetails.bestAsk && (
                  <>
                    {canBeBidding && (
                      <p className="sale-in">
                        {' '}
                        Sale ends in{' '}
                        {Math.ceil(
                          moment
                            .duration(moment(tokenDetails?.saleEndsIn).diff(new Date()))
                            .asDays()
                        )}{' '}
                        days (
                        {moment
                          .utc(tokenDetails.saleEndsIn)
                          .local()
                          .format('MMM DD,YYYY hh:mm A')}
                        )
                      </p>
                    )}
                    <div className="detail-item__price">
                      <h3>
                        {tokenDetails.bestBid && tokenDetails?.bestAsk?.closedAt
                          ? 'Top bid -- Reserve price met!'
                          : canBeBidding
                          ? 'Minimum bid -- Reserve price not met.'
                          : 'Current Price'}
                      </h3>
                      <p>
                        <span className="detail-item__price-current">
                          {tokenDetails.bestBid && tokenDetails?.bestAsk?.closedAt
                            ? tokenDetails.bestBidPrice
                            : tokenDetails.currentPrice}
                        </span>
                        <span className="detail-item__price-text">
                          {tokenDetails?.paymentToken?.symbol || 'ETH'}
                        </span>
                      </p>
                      <p className="detail-item__price-1">
                        (~$
                        {tokenDetails.bestBid && tokenDetails?.bestAsk?.closedAt
                          ? tokenDetails.bestBidPriceToDollar ||
                            new BigNumber(tokenDetails?.bestBidPrice)
                              .multipliedBy(ethToUsdPrice)
                              .toFixed(2)
                          : tokenDetails.currentPriceToDollar ||
                            new BigNumber(tokenDetails?.currentPrice)
                              .multipliedBy(ethToUsdPrice)
                              .toFixed(2)}
                        )
                      </p>
                      {user.address.toLowerCase() !== tokenDetails.maker.address.toLowerCase() && (
                        <button
                          className="detail-item__buy"
                          onClick={async () => {
                            if (!canBeBidding) {
                              setOpenBuyPopup(true);
                              await fetchOrderCheckout();
                            } else setOpen(true);
                          }}
                        >
                          {canBeBidding ? 'Place bid' : 'Buy Bundle'}
                        </button>
                      )}
                    </div>
                  </>
                )}
                <Offers
                  orders={orders}
                  tokenDetails={tokenDetails}
                  handleOfferCancel={handleOfferCancel}
                  offersScrollHandler={offersScrollHandler}
                  ethToUsdPrice={ethToUsdPrice}
                  handlePopupOpen={() => setOpen(true)}
                />
                <AssetList tokenDetails={tokenDetails} />
              </section>
            </div>
            <TradingHistory
              setTradingFilterFiled={setTradingFilterFiled}
              tokenDetails={tokenDetails}
              fetchTradingHistoryByToken={fetchTradingHistoryByToken}
              tradingHistory={tradingHistory}
              scrollHandler={scrollHandler}
              tradingFilterField={tradingFilterField}
            />
          </div>
        ) : (
          <Loading />
        )}
      </Container>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="dialog"
      >
        <DialogTitle id="form-dialog-title" className="dialog__title">
          <p style={{ marginBottom: 0 }}>{!canBeBidding ? `Make an offer` : 'Place a bid'}</p>
          <IconButton
            aria-label="close"
            className="Dialog__icon Dialog__icon--close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog__content">
          <div className="dialog__input-wrapper">
            <label>Price</label>
            <div className="dialog__input-inner">
              <div className="dialog__input--left">
                <div>
                  <img src={EthereumIcon} alt="" />
                  <span>WETH</span>
                </div>
                <NumberFormat
                  placeholder={'0'}
                  thousandSeparator={true}
                  onChange={handleInputChange}
                  decimalScale={6}
                  value={offerAmount}
                  max={walletBalance}
                  min={canBeBidding ? tokenDetails?.currentPrice : 0}
                  maxLength={255}
                  className="dialog__input"
                  onBlur={() => {
                    const offerAmountAfterClear =
                      offerAmount !== '0' ? trimLeadingZerosWithDecimal(offerAmount) : '';
                    !offerAmountAfterClear &&
                      setInputError({
                        message: '*This field is required.',
                        val: true
                      });
                    setOfferAmount(offerAmountAfterClear);
                  }}
                />
              </div>
              <span className="dialog__input-convert">
                {offerAmount
                  ? `$${new BigNumber(offerAmount).multipliedBy(ethToUsdPrice).toFixed()}`
                  : `$0`}
              </span>
            </div>
            {inputError.val && (
              <span style={{ color: 'red', marginTop: 10, display: 'inline-block' }}>
                {inputError.message}
              </span>
            )}
          </div>
          {!canBeBidding && (
            <div className="dialog__input-wrapper">
              <label>Offer Expiration</label>
              <div className="dialog__input-inner">
                <div style={{ display: 'flex' }}>
                  <DatePicker
                    date={date}
                    minimumDate={new Date()}
                    onDateChange={setDate}
                    locale={enGB}
                    format="dd/MM/yyyy"
                  >
                    {({ inputProps, focused }) => (
                      <input className={'input' + (focused ? ' -focused' : '')} {...inputProps} />
                    )}
                  </DatePicker>
                  <input
                    className="input"
                    style={{ marginLeft: 16, width: 80 }}
                    {...timeInputProps}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="dialog__policy">
            <FormControlLabel
              control={
                <GreenCheckbox
                  onChange={handlePolicyCheckboxChange}
                  checked={checkBoxPolicy.checkboxA}
                  name="checkboxA"
                />
              }
              label="By checking this box, I acknowledge that this item has not been reviewed or approved by OpenSea."
            />
            <FormControlLabel
              control={
                <GreenCheckbox
                  onChange={handlePolicyCheckboxChange}
                  checked={checkBoxPolicy.checkboxB}
                  name="checkboxB"
                />
              }
              label={
                <span>
                  By checking this box, I agree to OpenSea's{' '}
                  <a rel="noreferrer" href={process.env.TERMS_OF_SERVICE_OPENSEA} target="_blank">
                    Terms of Service
                  </a>
                </span>
              }
            />
          </div>
        </DialogContent>
        <DialogActions className="dialog__actions">
          <Button
            onClick={async () => {
              await handleOfferMaking();
              handleClose();
              await fetchTradingHistoryByToken(true);
              await fetchOrdersByToken(true);
            }}
            disabled={
              (canBeBidding &&
                tokenDetails &&
                new BigNumber(offerAmount).lt(new BigNumber(tokenDetails.currentPrice))) ||
              offerMakingLoading ||
              new BigNumber(walletBalance).lt(offerAmount) ||
              new BigNumber(offerAmount || 0).lte(0) ||
              !checkBoxPolicy.checkboxA ||
              !checkBoxPolicy.checkboxB ||
              inputError.val
            }
            color="primary"
            className="dialog__button"
          >
            {offerMakingLoading && (
              <CircularProgress style={{ color: 'white', marginRight: 10 }} size={20} />
            )}
            {canBeBidding ? 'Place bid' : 'Make Offer'}
          </Button>
          <Button className="dialog__button" component={Link} to="/swap">
            Convert ETH
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openBuyPopup}
        onClose={() => {
          setOpenBuyPopup(false);
          setCheckboxPolicy({ checkboxB: false, checkboxA: false });
        }}
      >
        <DialogTitle id="form-dialog-title" className="dialog__title">
          <p style={{ marginBottom: 0 }}>Checkout</p>
          <IconButton
            aria-label="close"
            className="Dialog__icon Dialog__icon--close"
            onClick={() => {
              setOpenBuyPopup(false);
              setCheckboxPolicy({ checkboxB: false, checkboxA: false });
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {!orderCheckoutLoading ? (
          <>
            <DialogContent className="dialog__content">
              <div className="dialog__label--symmetric">
                <span>Item</span>
                <span>
                  <span>Price</span>
                  <span>Quantity</span>
                  <span>Subtotal</span>
                </span>
              </div>
              <div className="dialog__block">
                <div className="dialog__block--left">
                  <div className="dialog__image">
                    {tokenDetails?.assets[0].imageUrl ? (
                      tokenDetails?.assets[0].imageUrl.endsWith('.mp4') ? (
                        <video
                          src={tokenDetails.assets[0].imageUrl}
                          style={{ width: '100%' }}
                          autoPlay="autoPlay"
                          loop
                        />
                      ) : (
                        <img src={tokenDetails?.assets[0].imageUrl} alt="assetImage" />
                      )
                    ) : (
                      <img src={PlaceHolder} alt="" />
                    )}
                  </div>
                  <div className="dialog__image-annotation">
                    <span className="dialog__annotation--bold">{tokenDetails?.name}</span>
                  </div>
                </div>
                <div className="dialog__block--container">
                  <div className="dialog__block--right">
                    <span style={{ fontWeight: 'bold' }}>
                      {new BigNumber(tokenDetails?.currentPrice || 0)
                        .div(orderCheckout?.quantity || 1)
                        .toFixed()}
                    </span>
                    <span style={{ color: 'rgb(138, 147, 155)', fontSize: 12 }}>
                      (${new BigNumber(assetTokenPrice).div(orderCheckout?.quantity || 1).toFixed()}
                      )
                    </span>
                  </div>
                  <span>{orderCheckout && (orderCheckout.quantity || 0)}</span>
                  <div className="dialog__block--right">
                    <span style={{ fontWeight: 'bold' }}>{tokenDetails?.currentPrice}</span>
                    <span style={{ color: 'rgb(138, 147, 155)', fontSize: 12 }}>
                      ($
                      {new BigNumber(assetTokenPrice).toFixed()})
                    </span>
                  </div>
                </div>
              </div>
              <div className="dialog__label--stretch">
                <span>Total</span>
                <div style={{ textAlign: 'right' }}>
                  <span style={{ fontWeight: 'bold', color: '#7ac231' }}>
                    {tokenDetails?.currentPrice}
                  </span>
                  ($
                  {new BigNumber(assetTokenPrice).toFixed()})
                </div>
              </div>
              <div className="dialog__policy">
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      onChange={handlePolicyCheckboxChange}
                      checked={checkBoxPolicy.checkboxA}
                      name="checkboxA"
                    />
                  }
                  label="By checking this box, I acknowledge that this item has not been reviewed or approved by OpenSea."
                />
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      onChange={handlePolicyCheckboxChange}
                      checked={checkBoxPolicy.checkboxB}
                      name="checkboxB"
                    />
                  }
                  label={
                    <span>
                      By checking this box, I agree to OpenSea's{' '}
                      <a
                        rel="noreferrer"
                        href={process.env.TERMS_OF_SERVICE_OPENSEA}
                        target="_blank"
                      >
                        Terms of Service
                      </a>
                    </span>
                  }
                />
              </div>
            </DialogContent>
            <DialogActions className="dialog__actions">
              <Button
                disabled={
                  NFTBuyLoading ||
                  orderCheckoutLoading ||
                  new BigNumber(orderCheckout ? orderCheckout.balance : 0).lt(
                    tokenDetails?.currentPrice
                  ) ||
                  !checkBoxPolicy.checkboxA ||
                  !checkBoxPolicy.checkboxB ||
                  inputError.val
                }
                onClick={async () => {
                  await handleNFTTokenPurchase();
                  setOpenBuyPopup(false);
                  await fetchTradingHistoryByToken(true);
                  await fetchOrdersByToken(true);
                  await fetchNFTTokenDetails();
                }}
                color="primary"
                className="dialog__button"
              >
                {NFTBuyLoading && (
                  <CircularProgress style={{ color: 'white', marginRight: 10 }} size={20} />
                )}
                Checkout
              </Button>
              <Button className="dialog__button" component={Link} to="/swap">
                Convert ETH
              </Button>
            </DialogActions>
          </>
        ) : (
          <div className="loading-progress">
            <CircularProgress style={{ color: 'white' }} />
          </div>
        )}
      </Dialog>
      <CustomDialog
        title="Transaction Submitted"
        isOpened={openBuyTxHashPopup}
        handleDialogClose={() => {
          setOpenBuyTxHashPopup(false);
          setBuyTxHash('');
        }}
      >
        <div className="Farming-box__submitted_dialog">
          <img alt="" src={IconSubmitted} />
          <a
            className="view-on-ether"
            target="_blank"
            href={`${
              CHAIN_ID === '4'
                ? `https://rinkeby.etherscan.io/tx/${buyTxHash}`
                : `https://etherscan.io/tx/${buyTxHash}`
            }`}
            rel="noreferrer"
          >
            View on Etherscan
          </a>
          <div className="close-wrapper">
            <Button
              onClick={() => {
                setOpenBuyTxHashPopup(false);
                setBuyTxHash('');
              }}
              className="dialog__button"
            >
              Close
            </Button>
          </div>
        </div>
      </CustomDialog>
      <CustomDialog
        title="Transaction Submitted"
        isOpened={openCancelOfferTxHashPopup}
        handleDialogClose={() => {
          setOpenCancelOfferTxHashPopup(false);
          setOrderCancelTxHash('');
        }}
      >
        <div className="Farming-box__submitted_dialog">
          <img alt="" src={IconSubmitted} />
          <a
            className="view-on-ether"
            target="_blank"
            href={`${
              CHAIN_ID === '4'
                ? `https://rinkeby.etherscan.io/tx/${orderCancelTxHash}`
                : `https://etherscan.io/tx/${orderCancelTxHash}`
            }`}
            rel="noreferrer"
          >
            View on Etherscan
          </a>
          <div className="close-wrapper">
            <Button
              onClick={() => {
                setOpenCancelOfferTxHashPopup(false);
                setOrderCancelTxHash('');
              }}
              className="dialog__button"
            >
              Close
            </Button>
          </div>
        </div>
      </CustomDialog>
    </Page>
  );
};

export default withRouter(AssetBundle);

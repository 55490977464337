import { createMuiTheme } from "@material-ui/core/styles";
import { ZIndex } from "@material-ui/core/styles/zIndex";
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

declare module "@material-ui/core/styles/zIndex" {
  interface ZIndex {
    negative: number;
  }
}

declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    zIndex: ZIndex;
  }

  interface ThemeOptions {
    custom?: any;
  }
}

export const createLemonadeTheme = (options: ThemeOptions = {}) => {
  return createMuiTheme(options);
};

const themeOptions = {
  colors: {
    primary: "#000000",
    white: "#FFFFFF",
    secondary: "#3A39BB",
    primaryText: "#636363",
    secondaryText: "#363636",
    metamask: "#FF8F44",
    error: "#BD3939",

    midGray: "707070",
    midGrayRgba05: "rgba(98, 99, 103, 0.5)",

    colorBombay: "#AEAFB0",

    colorWhiteRgba085: "rgba(255, 255, 255, 0.85)",
    colorWhiteRgba06: " rgba(255, 255, 255, 0.6)",
    colorWhiteRgba005: " rgba(255, 255, 255, 0.05)",

    colorSilver: "#C4C4C4",

    colorGreen: "#08b689",
    colorSushi: "#8bbf4b",
    colorGreenLight: "#078a68",
    colorGreenDark: "#078a68",
    colorGreenMuted: "#0d5054",
    colorGreenVeryDark: "#11444d",

    colorBlue: "#008ed4",
    colorBlueDisabled: "#0f1f35",
    colorBlueText: "#132a4a",
    colorBlueDark: "#355b82",
    colorBlueDarkFaint: "#3d6496",
    colorBlueSlate: "#243c5e",
    colorBlueQuiteDark: "#1d395d",
    colorBlueUltraDark: "#11253f",
    colorBlueVeryDark: "#101e32",
    colorBlueMuted: "#162f4c",
    colorBlueActive: "#172d4b",
    colorBlueHighlight: "#87b5f3",

    colorBlueAction: "#4a90e2",
    colorBlue50: "#233b5a",
    colorBlue90: "#152a46",
    colorBlue100: "#0f2037",
    colorBlueFooterLegend: "#25639f",

    colorBlueFormBorder: "#0081ff",
    colorBlueFormBg: "#1a3455",
    colorBlueFormBgFocus: "#132a4a",

    colorLightGreen: "#08b689",
    colorLightAtlantis: "#7ac231",
    colorDarkGray: "#1a304d",
    colorDarkShark: "#2a2b31",
    colorDarkMako: "#43444b",
    colorDarkMidGray: "#606166",
    colorDarkAbbey: "#505156",
    colorDarkTuna: "#323235",

    colorRed: "#d3005b",
    colorRedHover: "#b53424",
    colorRedPale: "#e05140",
    colorRedDark: "#b2004c",
    colorRedMuted: "#613649",
    colorRedVeryDark: "#3e2e3f",

    buttonChoiceBackgroundColor: "#0e1e34",
    buttonChoiceLabelColor: "#233b5a",
    buttonChoiceBorderColor: "#233b5a",

    colorYellow: "#f5a623",
    colorYellowDark: "#e58623",
    colorYellowVeryDark: "#474437",

    backgroundColorCanvasPublic: "#12233a",
    backgroundColorCanvas: "#18191d",
    backgroundColorLeft: "#1e2024",

    backgroundMain: "#182e4b",
    backgroundBox: "#162c48",
    colorAtlantis: "#7ac131",
    colorSunsetOrange: "#ff4747",
    colorRenoSand: "#ab5e16",
    colorShark: "#2a2b30"
  },

  fontSize: {
    h1: "57.33px",
    h2: "47.78px",
    h3: "39.81px",
    h4: "33.18px",
    h5: "27.65px",
    h6: "18px"
  },

  fontFamily: {
    FontMetropolisRegular: "Metropolis-Regular",
    FontFamilyRegular: `'Roboto', sans-serif, Metropolis`
  },

  fontWeight: {
    Normal: 400,
    Bold: 500,
    ExtraBold: 700
  },

  bodyText: {
    large: "19.2px",
    regular: "16px",
    small: "12px",
    extraSmall: "11.11px"
  },

  width: {
    BaseLayout: "1440px",
    leftLayout: "350px",
    BreakpointXs: "320px",
    BreakpointSm: "510px",
    BreakpointMd: "750px",
    BreakpointLg: "990px",
    BreakpointXl: "1230px",
    BreakpointXxl: "1470px"
  },

  ZIndex: {
    ModalContent: 10001,
    ModalOverlay: 10000,
    AccountNavMenu: 1000,
    ExchangeMenu: 200,
    SelectMenu: 100
  }
};

const defaultTheme = createLemonadeTheme({
  custom: themeOptions
});

export { themeOptions };
export default defaultTheme;

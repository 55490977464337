import BaseService from './BaseService';
import TickerService from './TickerService';
import exchangeLookup from '../exchange/exchange-lookup';
const fiatRateClient = require('../convert/fiat-rate-client');
const exchangeRate = require('../convert/exchange-rate');

class MarketService extends BaseService {
  addUSDValues(input) {
    return fiatRateClient.fetchRates().then(fiatRates => {
      //Get fiat returns symbol pair BTC/USD for some reason??
      delete fiatRates['BTC/USD'];

      const cryptoRates = {};

      Object.keys(input).forEach((key, index) => {
        cryptoRates[key] = input[key].price;
      });
      const allRates = Object.assign(fiatRates, cryptoRates);

      for (let key in input) {
        const symbols = key.split('/');

        try {
          input[key].price_usd = exchangeRate.convertToUsd(
            symbols[1],
            input[key].price || 0,
            allRates
          );
          input[key].volume_24h_usd = exchangeRate.convertToUsd(
            symbols[1],
            input[key].volume_24h,
            allRates
          );
        } catch (error) {
          console.log('Error', error, symbols[1], input[key].price, allRates);
        }
      }

      return input;
    });
  }
  addMarketInfo(marketData, tickers) {
    for (let key in tickers) {
      if (marketData[key] !== undefined) {
        tickers[key].precision = marketData[key].precision;
        tickers[key].maker = marketData[key].maker;
        tickers[key].taker = marketData[key].taker;
      }
    }
  }
  // Make sure that every ticker is in the Exchange's market.
  // https://github.com/binance-exchange/binance-java-api/issues/9
  filter(tickers, market) {
    const filteredData = {};

    for (let key in tickers) {
      if (key.includes('BCH/')) {
        continue;
      }

      if (market[key] !== undefined) {
        filteredData[key] = tickers[key];
      }
    }

    return filteredData;
  }
  async fetchMarket(exchangeName) {
    return TickerService.tickerAll(exchangeName);
  }
  async getMarkets(exchangeName) {
    const rawMarketData = await this.fetchMarket(exchangeName);
    const markets = await exchangeLookup[exchangeName].loadMarkets();
    const filteredData = this.filter(rawMarketData, markets);
    this.addMarketInfo(markets, filteredData);

    const result = this.addUSDValues(filteredData);
    return result;
  }
  async getMarketInfo(exchangeName, symbol) {
    const client = exchangeLookup[exchangeName];
    const market = await client.market(symbol);
    return { market: market };
  }
}

export default MarketService.getInstance();

import { useState } from "react";
import { OPENSEA_LOCAL_STORAGE_KEY } from "../../constants";
import { useLocalStorage } from "../../hooks";
import BaseHeader, { DataHeaderProps } from "./BaseHeader";
import { getHiddenDisplayFromStorage, updateStorageHeaderDatas } from "./helper";

export type WithBaseHeaderType = {
  dataHeader: DataHeaderProps;
};

export const withBaseHeader = <T extends object & WithBaseHeaderType>(
  WrappedComponent: React.FC<T>
) => {
  const EnhancedComponent: React.FC<T> = (props): JSX.Element => {
    const { dataHeader } = props;
    const [, setStorageHeaderDatas, fetchStorageHeaderDatas] = useLocalStorage<DataHeaderProps[]>(
      OPENSEA_LOCAL_STORAGE_KEY.BASE_HEADER_CONTROL,
      [] as DataHeaderProps[]
    );

    const [isOpen, setIsOpen] = useState(
      !getHiddenDisplayFromStorage(fetchStorageHeaderDatas(), dataHeader)
    );
    return (
      <>
        <BaseHeader
          icon={dataHeader.icon ?? "No icon"}
          title={dataHeader.title ?? "No title"}
          isOpen={isOpen}
          setIsOpen={(isOpenNew: boolean) => {
            setStorageHeaderDatas(
              updateStorageHeaderDatas(fetchStorageHeaderDatas(), dataHeader, isOpenNew)
            );
            setIsOpen(isOpenNew);
          }}
        />
        {isOpen && <WrappedComponent {...props} />}
      </>
    );
  };
  return EnhancedComponent;
};

import { useState, useRef, useEffect } from 'react';
import { Contract, Provider } from 'ethers-multicall';
import { ethers } from 'ethers';
import MasterchefJSON from '../../../abi/Masterchef.json';

const MASTERCHEF_ADDRESS = process.env.REACT_APP_MASTERCHEF_ADDRESS_MAINNET || '';
const INFURA_KEY = process.env.INFURA_KEY;

const usePendingSushiBalances = pools => {
  const [pendingSushiBalances, setPendingSushiBalances] = useState([]);
  const firstTime = useRef(true);

  useEffect(() => {
    const getUserPendingSushi = async pools => {
      firstTime.current = false;

      const provider = new ethers.providers.InfuraProvider('mainnet', INFURA_KEY);

      const ethcallProvider = new Provider(provider);
      await ethcallProvider.init();

      const masterChefContract = new Contract(MASTERCHEF_ADDRESS, MasterchefJSON);

      // Testing in here => should be pendingSushi(pool.id, address) (address from useSelector)
      const calls = pools.map(pool =>
        masterChefContract.pendingSushi(pool.id, '0xaa1917fe9f4cc187f0475349abfc70a327982fbd')
      );

      let pendingSushiBalances = await ethcallProvider.all(calls);

      setPendingSushiBalances(pendingSushiBalances);
    };

    firstTime.current && pools && pools.length > 0 && getUserPendingSushi(pools);
  }, [pools]);

  return [pendingSushiBalances];
};

export default usePendingSushiBalances;

import { useState, useCallback } from 'react';
import { Contract } from 'ethers';
import Web3 from 'web3';
import { getTransactionSigner, signTransaction } from '../../../../../util/transaction';
import { getContractInstance } from '../../../../../util/getContractInstance';
import WyvernExchange_ABI from '../../../../../abi/WyvernExchage.json';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
const INFURA_URL = process.env.REACT_APP_INFURA_URL;

const useOfferCancelAction = () => {
  const { isMetaMask, privateKey, address, isWC } = useSelector(state => state.user);
  const [txHash, setTxHash] = useState('');

  const cancelOffer = useCallback(async oldOrder => {
    try {
      const {
        maker_relayer_fee,
        taker_relayer_fee,
        maker_protocol_fee,
        taker_protocol_fee,
        base_price,
        extra,
        listing_time,
        expiration_time,
        salt,
        exchange,
        maker,
        taker,
        fee_recipient,
        target,
        static_target,
        payment_token,
        fee_method,
        side,
        sale_kind,
        calldata,
        how_to_call,
        replacement_pattern,
        static_extradata,
        v,
        r,
        s
      } = oldOrder;

      const wyvernExchangeAddress = process.env.REACT_APP_WYVERN_EXCHANGE_ADDRESS;

      const params = [
        [
          exchange,
          maker.address,
          taker.address,
          fee_recipient.address,
          target,
          static_target,
          payment_token
        ],
        [
          maker_relayer_fee,
          taker_relayer_fee,
          maker_protocol_fee,
          taker_protocol_fee,
          base_price,
          extra,
          listing_time,
          expiration_time,
          salt
        ],
        fee_method,
        side,
        sale_kind,
        how_to_call,
        calldata,
        replacement_pattern,
        static_extradata,
        v,
        r,
        s
      ];

      const signer = await getTransactionSigner(CHAIN_ID, false, isWC);

      if (isMetaMask || isWC) {
        const contract = new Contract(wyvernExchangeAddress, WyvernExchange_ABI, signer);

        const transaction = await contract.cancelOrder_(...params);
        setTxHash(transaction.hash);

        await transaction.wait(1);
        toast.success('Cancel an offer successfully');
      } else if (!isMetaMask && privateKey) {
        const web3 = new Web3(INFURA_URL);
        const contract = getContractInstance(web3, WyvernExchange_ABI, wyvernExchangeAddress);
        const data = contract.methods.cancelOrder_(...params);
        const txParams = {
          nonce: web3.utils.toHex(await web3.eth.getTransactionCount(address)),
          gasLimit: web3.utils.toHex(
            await data.estimateGas({
              from: address,
              value: '0x00'
            })
          ),
          gasPrice: web3.utils.toHex(await web3.eth.getGasPrice()),
          to: contract._address,
          data: data.encodeABI(),
          value: '0x00'
        };

        await signTransaction(txParams, privateKey, hash => {
          setTxHash(hash);
          console.log(hash);
        });
        toast.success('Cancel an offer successfully');
      }
    } catch (err) {
      toast.error(err.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { cancelOffer, txHash, setTxHash };
};

export default useOfferCancelAction;

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => {
  return {
    appHeader: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      marginTop: 6,
      marginBottom: 25,
      minHeight: 42,
    },

    leftAppHeader: {
      fontWeight: 600,
      fontSize: 24,
      lineHeight: '24px',
      margin: 0,
      color:  theme?.custom?.colors?.white,
    },

    appHeaderTitlePage: {},

    iconUser: {
      width: 42,
      height: 42,
      marginRight: 24,
    },

    addressUser: {},

    appHeaderAccount: {
      marginLeft: 'auto',
      display: 'flex',
    },
    
    appHeaderOpenseaButton: {
      color: `${theme?.custom?.colors?.colorWhiteRgba085} !important`,
      cursor: 'pointer',
      height: 35,
      minWidth: 120,
      display: 'flex',
      padding: 10,
      overflow: 'hidden',
      fontSize: 12,
      background: theme?.custom?.colors?.colorDarkShark,
      transition: 'all 0.2s ease 0s',
      alignItems: 'center',
      fontWeight: 400,
      lineHeight: '12px',
      borderRadius: 2,
      justifyContent: 'center',
      marginRight: 15,

      '&.active': {
        background:  theme?.custom?.colors?.white,
        color:  `${theme?.custom?.colors?.colorLightAtlantis} !important`,
        fontWeight: 700,
      },
      
      '&:hover': {
        color:  `${theme?.custom?.colors?.colorLightAtlantis} !important`,
      }
    },
  };
});

export default useStyles;
import { ActivitySearchQueryVariableType } from "Pages/OpenSea/static/type";
import { gql } from "@apollo/client";

/**
 * Account activity - collection
 */
export const ActivitySearchQuery = gql`
  query ActivitySearchQuery(
    $categories: [CollectionSlug!]
    $chains: [ChainScalar!]
    $collectionQuery: String
    $collection: CollectionSlug
    $collections: [CollectionSlug!]
    $collectionSortBy: CollectionSort
    $includeHiddenCollections: Boolean
    $isSingleCollection: Boolean!
  ) {
    query {
      ...ActivitySearch_data_1Nzzg1
    }
  }

  fragment ActivitySearchFilter_data_3mLsus on Query {
    ...CollectionFilter_data_3aeBqg
  }

  fragment ActivitySearch_data_1Nzzg1 on Query {
    collection(collection: $collection) @include(if: $isSingleCollection) {
      includeTradingHistory
      id
    }
    ...CollectionHeadMetadata_data_2YoIWt
    ...CategoryBar_data
    ...ActivitySearchFilter_data_3mLsus
    ...SearchPills_data_2Kg4Sq
  }

  fragment CategoryBar_data on Query {
    categories {
      imageUrl
      name
      slug
    }
  }

  fragment CollectionFilter_data_3aeBqg on Query {
    selectedCollections: collections(first: 25, collections: $collections, includeHidden: true) {
      edges {
        node {
          assetCount
          imageUrl
          name
          slug
          id
        }
      }
    }
    collections(
      chains: $chains
      first: 100
      includeHidden: $includeHiddenCollections
      parents: $categories
      query: $collectionQuery
      sortBy: $collectionSortBy
    ) {
      edges {
        node {
          assetCount
          imageUrl
          name
          slug
          id
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }

  fragment CollectionHeadMetadata_data_2YoIWt on Query {
    collection(collection: $collection) {
      bannerImageUrl
      description
      imageUrl
      name
      id
    }
  }

  fragment CollectionModalContent_data on CollectionType {
    description
    imageUrl
    name
    slug
  }

  fragment SearchPills_data_2Kg4Sq on Query {
    selectedCollections: collections(first: 25, collections: $collections, includeHidden: true) {
      edges {
        node {
          imageUrl
          name
          slug
          ...CollectionModalContent_data
          id
        }
      }
    }
  }
`;

export const ACTIVITY_SEARCH_INIT_VARIABLES: ActivitySearchQueryVariableType = {
  categories: null,
  chains: null,
  collectionQuery: null,
  collection: null,
  collections: [],
  collectionSortBy: "SEVEN_DAY_VOLUME",
  eventTypes: [],
  identity: {},
  includeHiddenCollections: true,
  isSingleCollection: false
};

import { reduxForm } from 'redux-form';

export const LoginForm = ({ handleSubmit, data }) => {
  console.log(data);
  return (
    <>
      {!data && (
        <form onSubmit={handleSubmit} className="form" noValidate>
          <div className="form__row form__row--actions">
            <button type="submit" className="button button--block">
              Unlock Wallet
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default reduxForm({ form: 'login' })(LoginForm);

import { FC, useEffect, useRef } from "react";
import { useActivityGlobalContext } from "../context/ActivityGlobalContext";
import ActivitySearchItem from "./ActivitySearchItem";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import _ from "lodash";
import { TIME_RESTORE_IMG_PATH } from "../../../static/images";
import OpenSeaLoading from "../../commons/loading_component/OpenSeaLoading";
import { OPENSEA_MESSAGE } from "../../commons/constants";
import TableRecordLoading from "../../commons/loading_component/TableRecordLoading";
import { convertActivityItemsFromEventHistoryResponse } from "./ActivitySearchHelper";

const ActivitySearchView: FC = (): JSX.Element => {
  const state = useSelector(state => state);
  const user = _.get(state, "user.address", null);
  const styles = useStyles();

  const {
    eventHistoryData: data,
    eventHistoryLoading: loading,
    eventHistoryError: error,
    isLoadingMore,
    fetchMoreEventHistory
  } = useActivityGlobalContext();

  /* Lazy loading begin */
  const tbodyRef = useRef<HTMLTableSectionElement>(null);
  const handleScroll = () => {
    const tbodyElement = tbodyRef.current;
    if (tbodyElement) {
      if (tbodyElement.offsetHeight + tbodyElement.scrollTop >= tbodyElement.scrollHeight * 0.9) {
        if (tbodyElement) {
          if (_.get(data, "assetEvents.pageInfo.hasNextPage", false) && !isLoadingMore) {
            fetchMoreEventHistory();
          }
        }
      }
    }
  };

  useEffect(() => {
    // function handleScroll have dependency [data, isLoadingMore]
    if (data) {
      const tbodyElement = tbodyRef.current;
      if (tbodyElement) {
        tbodyElement.addEventListener("scroll", handleScroll);
      }
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const tbodyElement = tbodyRef.current;
      if (tbodyElement) {
        tbodyElement.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoadingMore]);
  /* Lazy loading end */

  if (loading) return <OpenSeaLoading />;
  if (error || !data)
    return <div className={styles.noData}>{OPENSEA_MESSAGE.ACTIVITY_NO_ITEM}</div>;

  const listItem = data.assetEvents.edges;

  return (
    <>
      <div className={styles.rectangle}>
        <div className={styles.topRectangle}>
          <img
            className={styles.iconTradingHistory}
            style={{ marginTop: "-3px" }}
            src={TIME_RESTORE_IMG_PATH}
            alt=""
          />{" "}
          <span>Trading History</span>
        </div>

        {listItem?.length > 0 && (
          <table className={styles.offerTable}>
            <thead className={styles.tHead}>
              <tr className={styles.trHeadTable}>
                <td>Event</td>
                <td className={styles.width300}>Items</td>
                <td>Price</td>
                <td>Quantity</td>
                <td>From</td>
                <td>To</td>
                <td>Date</td>
              </tr>
            </thead>

            <tbody ref={tbodyRef} style={{ maxHeight: "400px" }}>
              {convertActivityItemsFromEventHistoryResponse(data, user).map(item => {
                return (
                  <ActivitySearchItem
                    key={item.key}
                    isExpied={item.isExpied}
                    eventType={item.eventType}
                    from={item.from}
                    to={item.to}
                    price={item.price}
                    timeStamp={item.timeStamp}
                    pathImage={item.pathImage}
                    assetName={item.assetName}
                    assetQuantity={item.assetQuantity}
                    blockExplorerLink={item.blockExplorerLink}
                    assetsLink={item.assetsLink}
                    listImages={item.listImages}
                  />
                );
              })}
              {isLoadingMore && (
                <tr className={styles.contentTable}>
                  <td colSpan={7} className={styles.contentLoading}>
                    <TableRecordLoading />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        {listItem.length === 0 && (
          <div className={styles.noData}>{OPENSEA_MESSAGE.ACTIVITY_NO_ITEM}</div>
        )}
      </div>
    </>
  );
};

export default ActivitySearchView;

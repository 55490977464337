import { FC, useEffect, useRef, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles, Zoom } from "@material-ui/core";

type EllipsisTooltipProps = {
  text: string;
  className?: string;
};

const useStyles = makeStyles((theme: any) => {
  return {
    rootContent: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  };
});

const EllipsisTooltip: FC<EllipsisTooltipProps> = (props): JSX.Element => {
  const { text, className } = props;
  const styles = useStyles();

  const [canShowTooltip, setCanShowTooltip] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef && contentRef.current && !canShowTooltip) {
      if (contentRef.current.offsetWidth < contentRef.current.scrollWidth) {
        // when this content need show tooltip, we dont need set FALSE any more.
        setCanShowTooltip(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRef?.current]);

  const renderContent = () => {
    return (
      <div ref={contentRef} className={` ${className} ${styles.rootContent}`}>
        {text}&nbsp;
      </div>
    );
  };

  return (
    <>
      <Tooltip
        TransitionComponent={Zoom}
        title={text}
        placement="top"
        disableHoverListener={!canShowTooltip}
        arrow
      >
        {renderContent()}
      </Tooltip>
    </>
  );
};

EllipsisTooltip.defaultProps = {
  className: ""
};

export default EllipsisTooltip;

import HeaderCommon from '../../components/HeaderCommonV2/HeaderCommon';
import useETHBalance from '../../components/HeaderCommonV2/hooks/useETHBalance';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import './Liquidity.scss';
import {Col, Container, Row} from '../../components/GridSystem/GridSystem';
import Page from '../../components/Page/Page';
import {useDispatch, useSelector} from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import cls from 'classnames';
import AddLiquidity from './AddLiquidity/AddLiquidity';
import ListLiquidity from './ListLiquidity/ListLiquidity';
import RemoveLiquidity from './RemoveLiquidity/RemoveLiquidity';
import Web3 from 'web3';
import {changeTitlePage} from '../../_actions/titlePage.actions';
import {save_token_in_list, reset_token_in_list} from '../../_actions/swap.action';

export default function Liquidity(props) {
  const settingTransaction = localStorage.getItem('setting_transaction')
    ? JSON.parse(localStorage.getItem('setting_transaction'))
    : null;

  const [selectedPool, setSelectedPool] = useState('uni');
  const [selectedTab, setSelectedTab] = useState('add');
  const [selectedLiquidity, setSelectedLiquidity] = useState(null);
  const [changedLiquidity, setChangedLiquidity] = useState();
  const [isSelectFromList, setIsSelectFromList] = useState(false);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

  const [activePercent, setActivePercent] = useState(0.5);
  const [deadline, setDeadline] = useState(20);
  const [checkedExpert, setCheckedExpert] = useState(false);
  const [checkedMultihop, setCheckedMultihop] = useState(false);
  const [fetchETHBalance, balance, loadingBalance] = useETHBalance();

  const removeRef = useRef();

  const web3 = useMemo(() => {
    if (user.isWC) {
      return new Web3(window.web3);
    } else if (user.privateKey) {
      return new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_URL));
    } else {
      return new Web3(window.ethereum);
    }
  }, [user]);

  useEffect(() => {
    const fetchETHBalanceAsync = async () => {
      await fetchETHBalance();
    }

    fetchETHBalanceAsync();
  }, []);

  useEffect(() => {
    const dataTokenInfor = localStorage.getItem('dataTokenInfor')
      ? JSON.parse(localStorage.getItem('dataTokenInfor'))
      : null; // null

    if (dataTokenInfor) {
      // tinh o day
      dispatch(save_token_in_list(dataTokenInfor));
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3]);

  useEffect(() => {
    return () => {
      dispatch(reset_token_in_list());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(changeTitlePage('Liquidity'));
    if (settingTransaction) {
      setActivePercent(settingTransaction.activePercent);
      setDeadline(settingTransaction.dealine);
      setCheckedExpert(settingTransaction.checkedExpert);
      setCheckedMultihop(settingTransaction.checkedMultihop);
    } else {
      const dataSettingTransaction = {
        activePercent,
        deadline,
        checkedExpert,
        checkedMultihop
      };
      localStorage.setItem('setting_transaction', JSON.stringify(dataSettingTransaction));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Liquidity" className="liquidity-page-container">
      <Container>
        <HeaderCommon user={user} loadingBalance={loadingBalance} balance={balance} fetchData={fetchETHBalance} />
        <div className="content-container">
          <div className="pool-container">
            <div className="label-pool">Select Pool:</div>
            <RadioGroup
              defaultValue="uni"
              value={selectedPool}
              onChange={e => setSelectedPool(e.target.value)}
            >
              <FormControlLabel value="uni" control={<Radio />} label="Uni Swap" />
              <FormControlLabel value="sushi" control={<Radio />} label="Sushi Swap" />
            </RadioGroup>
          </div>
          <Row>
            <Col size="6" className="add-remove-col">
              <div className="add-remove-content">
                <div className="input__group">
                  <div
                    onClick={() => setSelectedTab('add')}
                    className={cls({active: selectedTab === 'add'})}
                  >
                    Add
                  </div>
                  <div
                    onClick={() => {
                      setSelectedTab('remove');
                      setIsSelectFromList(false);
                    }}
                    className={cls({active: selectedTab === 'remove'})}
                  >
                    Remove
                  </div>
                </div>
                {selectedTab === 'add' ? (
                  <AddLiquidity
                    web3={web3}
                    selectedLiquidity={selectedLiquidity}
                    setSelectedLiquidity={setSelectedLiquidity}
                    setChangedLiquidity={setChangedLiquidity}
                    selectedPool={selectedPool}
                    activePercent={activePercent}
                    setActivePercent={setActivePercent}
                    deadline={deadline}
                    setDeadline={setDeadline}
                    checkedExpert={checkedExpert}
                    setCheckExport={setCheckedExpert}
                    multihops={checkedMultihop}
                    setCheckedMultiphop={setCheckedMultihop}
                    fetchETHBalance={fetchETHBalance}
                  />
                ) : (
                  <RemoveLiquidity
                    ref={removeRef}
                    web3={web3}
                    selectedPool={selectedPool}
                    selectedLiquidity={selectedLiquidity}
                    setSelectedPair={setSelectedLiquidity}
                    setChangedLiquidity={setChangedLiquidity}
                    activePercent={activePercent}
                    setActivePercent={setActivePercent}
                    deadline={deadline}
                    setDeadline={setDeadline}
                    checkedExpert={checkedExpert}
                    setCheckExport={setCheckedExpert}
                    multihops={checkedMultihop}
                    setCheckedMultiphop={setCheckedMultihop}
                    isSelectFromList={isSelectFromList}
                    fetchETHBalance={fetchETHBalance}
                  />
                )}
              </div>
            </Col>
            <Col size="6">
              <ListLiquidity
                onClickRemove={pair => {
                  setSelectedTab('remove');
                  setSelectedLiquidity(pair);
                  setTimeout(() => {
                    removeRef.current.set100Percent();
                  });
                }}
                selectedPool={selectedPool}
                changedLiquidity={changedLiquidity}
                setChangedLiquidity={setChangedLiquidity}
                onClickAdd={pair => {
                  setSelectedTab('add');
                  setSelectedLiquidity(pair);
                  setIsSelectFromList(true);
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </Page>
  );
}

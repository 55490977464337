import { ApolloProvider } from "@apollo/client";
import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getClient } from "./graphql/config/client";
import OpenSeaRouter from "./OpenSeaRoute";

const OpenSeaWithApollo: React.FC = (props): JSX.Element => {
  const state = useSelector(state => state);
  const address = _.get(state, "user.address", "") as string;
  const client = useMemo(() => getClient(address), [address]);
  return (
    <ApolloProvider client={client}>
      <OpenSeaRouter {...props} />
    </ApolloProvider>
  );
};

export default OpenSeaWithApollo;

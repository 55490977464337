import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
  return {
    scrollGroupCategorys: {
      width: "100%",
      overflowY: "auto"
    },
    groupCategorys: {
      borderBottom: `1px solid ${theme?.custom?.colors?.colorDarkShark}`,
      display: "flex",
      alignItems: "center"
    },

    itemGrid: {
      padding: "0 32px",
      textAlign: "center",
      fontSize: 14,
      lineHeight: "14px",
      color: theme?.custom?.colors?.colorWhiteRgba06,
      marginBottom: -1,
      cursor: "pointer",
      overflow: "hidden",
      transition: "all 0.2s ease 0s",

      "&:hover": {
        color: theme?.custom?.colors?.white
      }
    },

    itemCategory: {
      padding: "15px 0",
      borderBottom: ` 4px solid transparent`,

      "&.active": {
        borderColor: theme?.custom?.colors?.colorLightAtlantis,

        fontWeight: "bold",
        color: theme?.custom?.colors?.white
      }
    }
  };
});

export default useStyles;

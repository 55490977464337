import { gql } from '@apollo/client';

export const POOLS_QUERY = gql`
  query poolsQuery(
    $first: Int! = 1000
    $skip: Int! = 0
    $orderBy: String! = "timestamp"
    $orderDirection: String! = "desc"
  ) {
    pools(first: $first, skip: $skip, orderBy: $orderBy, orderDirection: $orderDirection) {
      id
      pair
      allocPoint
      lastRewardBlock
      accSushiPerShare
      balance
      userCount
      slpBalance
      owner {
        id
        totalAllocPoint
        __typename
        sushiPerBlock
      }
      timestamp
      __typename
    }
  }
`;

export const POOLS_USER_QUERY = gql`
  query poolUserQuery($address: String!, $amount_gt: Int! = 0) {
    users(where: { address: $address, amount_gt: $amount_gt }) {
      ...PoolUser
      __typename
    }
  }
  fragment PoolUser on User {
    id
    address
    pool {
      id
      pair
      balance
      accSushiPerShare
      lastRewardBlock
      __typename
    }
    amount
    rewardDebt
    entryUSD
    exitUSD
    sushiAtLockup
    sushiHarvested
    sushiHarvestedUSD
    sushiHarvestedSinceLockup
    sushiHarvestedSinceLockupUSD
    __typename
  }
`;

import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ErrorMessage } from '../../utils';
import { Shakeable } from 'components/Shakeable';
import { openExternalLink } from '_helpers/links';
import hdkey from 'hdkey';
import * as bip39 from 'bip39';

import Web3 from 'web3';
import LoginForm from '../LoginForm/LoginForm';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './LoginPage.scss';
import imageMetaMask from '../../../../assets/MetaMask.png';
import WalletConnect from '@walletconnect/web3-provider';
import WalletConnectQRCodeModal from '@walletconnect/qrcode-modal';
import { INFURA } from '../../../Swap/uni/const';
import { config } from '../../../../config';

const LOGIN_BY = {
  METAMASK_AND_WALLETCONNECT: 'metamask_and_walletconnect',
  PRIVATEKEY_AND_MNEMONIC: 'privatekey_and_mnemonic'
};

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY;
export class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      privateKey: '',
      isMetaMask: true,
      tab: 'metamask',
      error_metamask: '',
      mnemonicPhrase: '',
      provider: null,
      error: null,
      clientV: null,
      isWc: false,
      loginMode: LOGIN_BY.METAMASK_AND_WALLETCONNECT
    };
    this.onInputchange = this.onInputchange.bind(this);
    this.onHandle = this.onHandle.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.onChangeMnemonicPhrase = this.onChangeMnemonicPhrase.bind(this);
    this.connectWC = this.connectWC.bind(this);
    this.isLoginByMMAndWC = this.isLoginByMMAndWC.bind(this);
    this.handleSwitchLoginMode = this.handleSwitchLoginMode.bind(this);
    this.renderLoginByMMAndWC = this.renderLoginByMMAndWC.bind(this);
    this.renderLoginByPKAndMN = this.renderLoginByPKAndMN.bind(this);
  }

  delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  onChangeMnemonicPhrase(event) {
    let list_mnemonic = event.target.value;
    const root = hdkey.fromMasterSeed(Buffer.from(bip39.mnemonicToSeedSync(list_mnemonic)));
    const addrnode = root.derive("m/44'/60'/0'/0/0");
    const private_key_mnemonic = addrnode.privateKey.toString('hex');
    this.setState({
      [event.target.name]: private_key_mnemonic
    });
  }

  async onHandle() {
    window.loginSessionWaiting = window.loginSessionWaiting || false;

    const { privateKey, isMetaMask, mnemonicPhrase } = this.state;
    if (isMetaMask && !window.ethereum) {
      window.open(process.env.METAMASH_ADDON_CHROME, '_blank');
    }
    if (window.loginSessionWaiting) {
      this.setState({ error: 'Waiting login with Metamask.' });
      this.setState({ error_metamask: 'Waiting login with Metamask.' });
    } else {
      this.handleLogin(privateKey, isMetaMask, mnemonicPhrase);
    }
  }

  async handleLogin(privateKey, isMetaMask, mnemonicPhrase) {
    try {
      window.loginSessionWaiting = true;
      await this.props.handleSubmit({ privateKey, isMetaMask, mnemonicPhrase });
    } catch (e) {
      window.xxxxx = { x: e };
      console.log(e);
      const { tab } = this.state;
      if (tab === 'mnemonic' && !mnemonicPhrase) {
        this.setState({ error: 'The Mnemonic Phrase is required.' });
      } else if (tab === 'privateKey' && !privateKey) {
        this.setState({ error: 'The private key is required.' });
      } else if ([' Invalid hex string', 'bad secret key size'].indexOf(e.message) !== -1) {
        const errorType = tab === 'mnemonic' ? 'Mnemonic Phrase' : 'private key';
        this.setState({ error: `Please enter the correct ${errorType}` });
      } else {
        this.setState({ error: 'Can not connect Metamask.' });
        if (isMetaMask) {
          this.setState({ error_metamask: 'Can not connect Metamask.' });
        }
      }
    }
    window.loginSessionWaiting = false;
  }

  changeTab(isMetaMask, tab, isWc) {
    this.setState({ isMetaMask, tab, error: '', privateKey: '', mnemonicPhrase: '', isWc });
  }

  renderErrors() {
    const { user } = this.props;
    let { errorMessage } = user.meta;

    if (errorMessage === 'Failed to fetch') {
      errorMessage = 'Sorry, the service is not available. Please try again in few minutes.';
    }

    if (user.meta.error) {
      return <ErrorMessage error={errorMessage} />;
    }

    return null;
  }

  renderHint() {
    return (
      <p>
        {'Need a Depo account? '}
        <Link to="/auth/register" className="form__link">
          Create an account
        </Link>
      </p>
    );
  }

  renderIssueLink() {
    const link =
      'http://landingpage-moonassist.sotatek.works/support#faq-if-i-lose-my-2fa-how-do-i-log-in-or-reset-my-moon-assist-account';

    return (
      <div className="form__row form__row--center">
        <a href={link} className="form__link link--primary" onClick={openExternalLink}>
          Problem logging in with 2FA?
        </a>
      </div>
    );
  }

  setupWC = async connect => {
    try {
      const provider = await connect();
      window.web3 = new Web3(provider);
      this.props.handleSubmit({ account: provider.accounts[0], isWc: true });
      let w = window;
      w.provider = provider;
      this.setState({
        ...this.state,
        provider: provider,
        error: null
      });
      provider.send(
        {
          method: 'web3_clientVersion'
        },
        (e, result) => {
          if (e) {
            this.setState({
              ...this.state,
              error: null
            });
          } else {
            this.setState({
              ...this.state,
              clientV: result
            });
          }
        }
      );

      provider.once('stop', () => {
        this.setState({
          ...this.state,
          clientV: null,
          provider: null,
          error: null
        });
      });

      // some web3 setup or whatever
    } catch (error) {
      console.log('Error connecting WC', error);
      this.setState({
        ...this.state,
        error: null
      });
    }
  };

  connectWC = () => {
    const wcProvider = new WalletConnect({
      rpc: {
        1: 'https://mainnet.infura.io',
        3: 'https://ropsten.infura.io',
        4: INFURA,
        5: 'https://goerli.infura.io',
        56: 'https://bsc-dataseed.binance.org/',
        100: 'https://dai.poa.network'
      },
      chainId: config.chain_id,
      infuraId: INFURA_KEY
    });

    if (wcProvider && wcProvider.connected) {
      wcProvider._storage.removeSession();
      wcProvider.connector.killSession(); // remove any leftover connections
    }

    return new Promise(async (resolve, reject) => {
      // connection refused in wallet
      // catch WC modal closure
      await wcProvider.enable().catch(err => {
        console.log(wcProvider.connector);
        // wcProvider.wc.killSession();
        reject(err);
      });

      wcProvider.wc.on('disconnect', () => {
        WalletConnectQRCodeModal.close();
        reject(new Error('Connection refused'));
      });

      // ==> ham login
      // if everything worked

      resolve(wcProvider);
    });
  };

  isLoginByMMAndWC() {
    return this.state.loginMode === LOGIN_BY.METAMASK_AND_WALLETCONNECT;
  }

  handleSwitchLoginMode() {
    const currentIsLoginByMMAndWC = this.isLoginByMMAndWC();
    // mapping with action this.changeTab
    let objectChangeTab = {};

    if (currentIsLoginByMMAndWC) {
      // mapping with action this.changeTab(false, "privateKey", false);
      objectChangeTab = {
        isMetaMask: false,
        tab: 'privateKey',
        error: '',
        privateKey: '',
        mnemonicPhrase: '',
        isWc: false
      };
    } else {
      // mapping with action this.changeTab(true, "metamask", false);
      objectChangeTab = {
        isMetaMask: true,
        tab: 'metamask',
        error: '',
        privateKey: '',
        mnemonicPhrase: '',
        isWc: false
      };
    }

    this.setState({
      ...this.state,
      ...objectChangeTab,
      loginMode: currentIsLoginByMMAndWC
        ? LOGIN_BY.PRIVATEKEY_AND_MNEMONIC
        : LOGIN_BY.METAMASK_AND_WALLETCONNECT
    });
  }

  /**
   * Render list tab login by
   * MetaMask and WalletConnect
   * @returns
   */
  renderLoginByMMAndWC() {
    return (
      <>
        <Tabs className={'custom-tabs'}>
          <TabList>
            <Tab onClick={() => this.changeTab(true, 'metamask', false)}>MetaMask</Tab>
            <Tab
              onClick={() => {
                this.changeTab(false, '', true);
                this.setupWC(this.connectWC);
              }}
            >
              Wallet Connect
            </Tab>
          </TabList>

          <TabPanel>
            <div className={'image__metamask'}>
              <img src={imageMetaMask} alt="" />
            </div>
            <p className="PublicArea__MetaMask__intro">Login using Metamask</p>
            <p className={'PublicArea__MetaMask__recommended'}>
              Please make sure you enable Rinkeby Network on Metamask
            </p>
          </TabPanel>
          <TabPanel></TabPanel>
        </Tabs>
      </>
    );
  }

  /**
   * Render list tab login by
   * PrivateKey and Mnemonic
   * @returns
   */
  renderLoginByPKAndMN() {
    return (
      <>
        <Tabs className={'custom-tabs'}>
          <TabList>
            <Tab onClick={() => this.changeTab(false, 'privateKey', false)}>Private Key</Tab>
            <Tab onClick={() => this.changeTab(false, 'mnemonic', false)}>Mnemonic</Tab>
          </TabList>
          <TabPanel>
            <div className="input-login">
              <input
                type="text"
                name="privateKey"
                placeholder={'Enter your private key'}
                value={this.state.privateKey}
                onChange={this.onInputchange}
              />
              <i className={'icon__input icon__input__private__key'} />
            </div>
            {/*{this.props.error && <p className={'error-login'}>{this.props.error}</p>}*/}
            {this.state.error && <p className={'error-login'}>{this.state.error}</p>}
            <p className={'note__recommended'}>
              NOT RECOMMENDED <br />
              <br />
              This is not a recommended way to access your wallet. Due to the sensitivity of the
              information involved, these options should only be used in offline settings by
              experienced users.
            </p>
          </TabPanel>
          <TabPanel>
            <div className="input-login">
              <input
                type="text"
                name="mnemonicPhrase"
                placeholder={'Please type in your Mnemonic Phrase'}
                onChange={this.onChangeMnemonicPhrase}
              />
              <i className={'icon__input icon__input__private__key'} />
            </div>
            {/*{this.props.error && <p className={'error-login'}>{this.props.error}</p>}*/}
            {this.state.error && <p className={'error-login'}>{this.state.error}</p>}
            <p className={'note__recommended'}>
              NOT RECOMMENDED <br />
              <br />
              This is not a recommended way to access your wallet. Due to the sensitivity of the
              information involved, these options should only be used in offline settings by
              experienced users.
            </p>
          </TabPanel>
        </Tabs>
      </>
    );
  }

  render() {
    const { user, handleSubmitError, submitError } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>Log in - Depo</title>
        </Helmet>

        <Shakeable shouldShake={user.meta.error || submitError}>
          <div>
            <h2 className="PublicArea__headline">Import your wallet</h2>
            <p className="PublicArea__headline__intro">
              Do not have a wallet? <Link to="/auth/register">Create a new wallet </Link>
            </p>
            {this.renderErrors()}
            {this.state.loginMode === LOGIN_BY.METAMASK_AND_WALLETCONNECT &&
              this.renderLoginByMMAndWC()}
            {this.state.loginMode === LOGIN_BY.PRIVATEKEY_AND_MNEMONIC &&
              this.renderLoginByPKAndMN()}
            <div className="PublicArea__form">
              <LoginForm
                onSubmit={this.onHandle}
                data={this.state.isWc}
                onSubmitFail={handleSubmitError}
              />
            </div>
            <div
              onClick={() => {
                this.handleSwitchLoginMode();
              }}
              className="LoginPage__switchmode"
            >
              {this.isLoginByMMAndWC()
                ? 'Unable to access?'
                : 'Login using Metamask or Walletconnect'}
            </div>
          </div>
        </Shakeable>
      </React.Fragment>
    );
  }
}

export default LoginPage;

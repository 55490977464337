import _ from "lodash";
import { AssetSearchQueryResponseType, SearchResultType } from "Pages/OpenSea/static/type";
import update from "immutability-helper";
import { ASSETS_BUNDLE_LIMIT } from "../../constants";

export const getTotalCurrentPageFromDataResponse = (dataResponse: AssetSearchQueryResponseType) => {
  if (dataResponse) {
    const listItem = dataResponse?.query?.search?.edges ?? [];
    const listItemChunkPage = _.chunk(listItem, ASSETS_BUNDLE_LIMIT);
    return listItemChunkPage.length;
  }
  return 1;
};

export const getCanFetchMoreDataFromDataResponse = (dataResponse: AssetSearchQueryResponseType) => {
  return _.get(dataResponse, ["query", "search", "pageInfo", "hasNextPage"], false);
};

export const chunkResponseDataByCurrentPage = (
  responseData: AssetSearchQueryResponseType,
  currentPage: number
) => {
  if (responseData) {
    const listItem = responseData?.query?.search?.edges ?? [];
    let newListItem = [] as {
      cursor: string;
      node: SearchResultType;
    }[];
    const listItemChunkPage = _.chunk(listItem, ASSETS_BUNDLE_LIMIT);
    if (listItemChunkPage.length > currentPage - 1) {
      newListItem = listItemChunkPage[currentPage - 1];
    }
    return update(responseData, { query: { search: { edges: { $set: newListItem } } } });
  }
  return responseData;
};

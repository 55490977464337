import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
  return {
    assetSearchViewResultsHeader: {
      display: "flex",
      alignItems: "center",
      padding: "0 15px",
      marginTop: 26,
      marginBottom: 6
    },
    assetSearchViewResultsHeaderPills: {
      fontSize: 14,
      color: theme?.custom?.colors?.colorWhiteRgba085
    },
    assetSearchViewResultsHeaderDropdowns: {
      marginLeft: "auto",
      display: "flex"
    }
  };
});

export default useStyles;

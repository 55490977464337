import { list_network, config } from '../config';

export const getAddress = address => {
  return address[config.chain_id];
};

export const getMasterchefAddress = (chainId = 1) => {
  const name = chainId === 1 ? 'MAINNET' : 'RINKEBY';
  return list_network[name].masterchef_address;
};

export const shortenAddress = address => {
  if (address.startsWith('0x')) {
    return address
      .split('')
      .slice(2, 8)
      .join('')
      .toUpperCase();
  }

  return address;
};

import VECTOR from "./Vector.svg";
import ARROW_LEFT_RIGHT from "./fluent_arrow-swap-20-regular.svg";
import BAR_CHART from "./bx_bxs-bar-chart-alt-2.svg";
import SHOPING_CART from "./shopping_cart.svg";
import TAG_2_ICON from "./tag 2.svg";
import TIME_RESTORE from "./zmdi_time-restore.svg";
import SMILE_ICON from "./smile_icon.svg";
import BID_ICON from "./bid_cancel_icon.svg";
import STROLLER_ICON from "./stroller_icon.svg";

export const VECTOR_IMG_PATH = VECTOR.toString();
export const ARROW_LEFT_RIGHT_IMG_PATH = ARROW_LEFT_RIGHT.toString();
export const BAR_CHART_IMG_PATH = BAR_CHART.toString();
export const SHOPING_CART_IMG_PATH = SHOPING_CART.toString();
export const TAG_2_IMG_PATH = TAG_2_ICON.toString();
export const TIME_RESTORE_IMG_PATH = TIME_RESTORE.toString();
export const SMILE_ICON_IMG_PATH = SMILE_ICON.toString();
export const BID_ICON_IMG_PATH = BID_ICON.toString();
export const STROLLER_ICON_IMG_PATH = STROLLER_ICON.toString();

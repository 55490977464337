import _ from "lodash";

/**
 * Example:
 * input = 0x69bD8c07e16Ca572592A45e3Bf1f826eEeACAc2b,
 * output = 0x69......Ac2b
 * @param inputString
 * @returns
 */
export const formatPrefixesAndSuffixesString = (inputString: string) => {
  if (inputString.length <= 8) return inputString;
  let prefixesString = inputString.slice(0, 4);
  let suffixesString = inputString.substr(inputString.length - 4);
  const returnString = _.join([prefixesString, suffixesString], "......");
  return returnString;
};

/**
 * Example:
 * input = 0x69bD8c07e16Ca572592A45e3Bf1f826eEeACAc2b,
 * output = 69BD8C
 * @param inputString
 * @returns
 */
export const formatPrefixesUpCaseString = (inputString: string) => {
  if (inputString.length < 8) return inputString;
  let prefixesString = inputString.slice(2, 8);
  const returnString = _.toUpper(prefixesString);
  return returnString;
};

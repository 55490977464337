import { useCallback } from 'react';
import { getTotalAllocPoint } from '../../../util/masterchef';

const AVERAGE_BLOCK_TIME = 13.5;
const SECONDS_IN_YEAR = 60 * 60 * 24 * 365;

const useFarmingAPY = (pools, pairs) => {
  const calculatePoolsApy = useCallback(
    async sushiPrice => {
      const totalAllocPoint = await getTotalAllocPoint();

      if (sushiPrice && pairs.length > 0) {
        return pools.map(pool => {
          const tvl = pool.slpBalance * (pool.reserveUSD / pool.totalSupply);
          const sushiPerBlock = (pool.allocPoint / totalAllocPoint) * 100;
          const apy =
            ((sushiPrice * (sushiPerBlock * (SECONDS_IN_YEAR / AVERAGE_BLOCK_TIME))) / tvl) * 100;
          return apy;
        });
      }
    },
    [pools, pairs]
  );

  return [calculatePoolsApy];
};

export default useFarmingAPY;

import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { Tooltip } from '@material-ui/core';

import Offer from 'assets/icons/offer.svg';
import { shortenAddress } from '../../../../../util/getAddresses';
import useLoadWhenScroll from '../../commons/nfts_hooks/useLoadWhenScroll';

const Offers = ({
  tokenDetails,
  orders,
  handleOfferCancel,
  offersScrollHandler,
  ethToUsdPrice,
  handlePopupOpen
}) => {
  const [showListMap, setShowListMap] = useState(false);
  const offersTableRef = useRef(null);

  const user = useSelector(state => state.user);

  useLoadWhenScroll(offersTableRef, orders && orders.length > 0, offersScrollHandler);

  return (
    <div className="detail-item__offer">
      <div className="detail-item__offer-tit" onClick={() => setShowListMap(!showListMap)}>
        <div className="detail-item__style-titOffer">
          <img src={Offer} alt="" />
          <span className="detail-item__text-offer">Offers</span>
        </div>
        <div className="detail-item__btn-ip">
          {(function() {
            return showListMap ? (
              <svg
                width="10"
                height="7"
                viewBox="0 0 10 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.175 6.1748L5 2.35814L8.825 6.1748L10 4.9998L5 -0.000195503L0 4.9998L1.175 6.1748Z"
                  fill="#7AC231"
                />
              </svg>
            ) : (
              <svg
                width="10"
                height="7"
                viewBox="0 0 10 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.175 0L5 3.81667L8.825 0L10 1.175L5 6.175L0 1.175L1.175 0Z"
                  fill="#7AC231"
                />
              </svg>
            );
          })()}
        </div>
      </div>
      <div className={`detail-item__offer-table ${showListMap && 'hidden_map'}`}>
        {orders && orders.length > 0 ? (
          <table className="orders">
            <thead className="orders__title">
              <th>From</th>
              <th>Price</th>
              <th>Expiration</th>
              <th></th>
            </thead>
            <tbody ref={offersTableRef}>
              {orders.map(order => (
                <tr key={order.id} className="orders__rows">
                  <td className="orders__inner">
                    <img className="orders__img-acc" src={order.maker.imageUrl} alt="" />
                    <p className="orders__address">
                      {order?.maker?.address === user.address.toLowerCase()
                        ? 'You'
                        : shortenAddress(order.maker.address)}
                    </p>
                  </td>
                  <td>
                    <Tooltip title={<p>order.oldOrder.payment_token_contract.symbol</p>}>
                      <>
                        <span className="orders__price">{order.currentPrice}</span>
                        &nbsp;
                        <span className="orders__subprice">
                          ~(${' '}
                          {order.currentPriceToDollar ||
                            new BigNumber(order?.currentPrice)
                              .multipliedBy(ethToUsdPrice)
                              .toFixed(2)}
                          )
                        </span>
                      </>
                    </Tooltip>
                  </td>
                  <td>{order.expiredIn}</td>
                  <td>
                    {order.showCancel && (
                      <button
                        style={{ color: 'blue' }}
                        onClick={() => handleOfferCancel(order)}
                        className="btn-cancel"
                      >
                        Cancel
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="detail-item__notify">
            <span>No offers yet.</span>
          </div>
        )}
        {tokenDetails?.availableToOffer && (
          <button className="make-offer" onClick={handlePopupOpen} style={{ margin: '20px 0px' }}>
            Make Offer
          </button>
        )}
      </div>
    </div>
  );
};

export default Offers;

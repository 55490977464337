import React, { FC } from "react";
import { useLocation } from "react-router";

import AppHeader from "./AppHeader";

import "./AppHeader.scss";
import AppHeaderOpensea from "./AppHeaderOpensea";

const AppHeaderWrapped: FC = (props): JSX.Element => {
  const useLocationResult = useLocation();
  const openSeaScreen = new RegExp("\\/opensea", "g");

  if (openSeaScreen.test(useLocationResult.pathname)) {
    return (
      <>
        <AppHeaderOpensea />
      </>
    );
  }

  // default
  return (
    <>
      <AppHeader />
    </>
  );
};

export default AppHeaderWrapped;

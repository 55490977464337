import { gql } from '@apollo/client';

export const GET_PAIR_INFO = gql`
  fragment pairTokenFields on Token {
    id
    name
    symbol
    totalSupply
    derivedETH
    decimals
    __typename
  }

  query pairSubsetQuery(
    $first: Int! = 1000
    $pairAddresses: [Bytes]!
    $orderBy: String! = "trackedReserveETH"
    $orderDirection: String! = "desc"
  ) {
    pairs(
      first: $first
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: { id_in: $pairAddresses }
    ) {
      id
      reserveUSD
      reserveETH
      volumeUSD
      untrackedVolumeUSD
      trackedReserveETH
      token0 {
        ...pairTokenFields
        __typename
      }
      token1 {
        ...pairTokenFields
        __typename
      }
      reserve0
      reserve1
      token0Price
      token1Price
      totalSupply
      txCount
      timestamp
      __typename
    }
  }
`;

import ccxt from 'ccxt';
import store from '../_store/configureStore';

export default class BaseRequest {
  static getInstance() {
    return new this();
  }

  getExchangeKey(exchangeName = 'binance') {
    const state = store.getState();
    const exchanges = state.exchanges;
    const len = exchanges.length;
    for (let i = 0; i < len; i++) {
      if (exchangeName.toLowerCase() === exchanges[i].exchangeName.toLowerCase()) {
        return exchanges[i];
      }
    }
    return {
      apiKey: '',
      secret: ''
    };
  }
  contructor() {}

  getExchangeApi(exchangeName = 'binance', apiKey = null, secret = null) {
    const exchangeKey = this.getExchangeKey(exchangeName);
    const exchangeId = exchangeName.toLocaleLowerCase(),
      exchangeClass = ccxt[exchangeId],
      exchange = new exchangeClass({
        apiKey: apiKey ? apiKey : exchangeKey.apiKey,
        secret: secret ? secret : exchangeKey.secret,
        timeout: 30000,
        enableRateLimit: true,
        proxy: process.env.REACT_APP_PROXY_URL,
        proxies: {
          http: process.env.REACT_APP_PROXY_URL,
          https: process.env.REACT_APP_PROXY_URL
        },
        options: {
          warnOnFetchOpenOrdersWithoutSymbol: false,
          adjustForTimeDifference: true,
          verbose: true, // if needed, not mandatory
          recvWindow: 30000 // not really needed
        }
      });
    return exchange;
  }
}

import './Profile.scss';

const Profile = ({ account, user }) => {
  return (
    <div className="Profile">
      <div className="Profile__avatar" />
      <div className="Profile__info">
        <dl className="Profile__details">
          <div>{user.address}</div>
        </dl>
      </div>
    </div>
  );
};

export default Profile;

import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import AccountNav from "./AccountNav/AccountNav";

import "./AppHeader.scss";
import { DEFAULT_LINK_SCREEN } from "Pages/OpenSea/screen/commons/constants";
import { clearBaseHeaderControlStorage } from "Pages/OpenSea/screen/commons/base_component/BaseHeader/helper";
import { changeTitlePage } from "_actions/titlePage.actions";
import { formatPrefixesAndSuffixesString } from "Pages/OpenSea/screen/commons/helper";

const AppHeaderOpensea: FC = (): JSX.Element => {
  const styles = useStyles();
  const useLocationResult = useLocation();
  const openAccountcScreen = new RegExp("\\/opensea/account", "g");
  const stateRedux = useSelector((state: any) => state);

  // Change title header
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeTitlePage("OpenSea"));
  }, [dispatch]);

  return (
    <>
      <div className={styles.appHeader}>
        <div className={styles.leftAppHeader}>
          {openAccountcScreen.test(useLocationResult.pathname) ? (
            <>
              <img className={styles.iconUser} src="/images/icons/icon-user-wallet.svg" alt="" />
              <span className={styles.addressUser}>
                {formatPrefixesAndSuffixesString(stateRedux?.user?.address)}
              </span>
            </>
          ) : (
            <h2 className={styles.appHeaderTitlePage}>{stateRedux.titlePage?.title}</h2>
          )}
        </div>
        <div className={styles.appHeaderAccount}>
          <Link
            className={
              styles.appHeaderOpenseaButton +
              " " +
              (useLocationResult?.pathname.slice(0, 15) === "/opensea/assets" ? "active" : "")
            }
            to={DEFAULT_LINK_SCREEN.LISTING}
            onClick={() => {
              clearBaseHeaderControlStorage();
            }}
          >
            Maketplace
          </Link>
          <Link
            className={
              styles.appHeaderOpenseaButton +
              " " +
              (useLocationResult?.pathname?.slice(0, 17) === "/opensea/account/" ? "active" : "")
            }
            to={DEFAULT_LINK_SCREEN.WALLET}
            onClick={() => {
              clearBaseHeaderControlStorage();
            }}
          >
            My Profile
          </Link>
          <AccountNav />
        </div>
      </div>
    </>
  );
};

export default AppHeaderOpensea;

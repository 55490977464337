import { SearchPillDataType } from "Pages/OpenSea/static/type";
import { FC } from "react";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "./styles";

type SearchPillResultItemProps = {
  searchPillData: SearchPillDataType;
  onChooseItem: (removedSearchPill: SearchPillDataType) => void;
};

const SearchPillResultItem: FC<SearchPillResultItemProps> = (props): JSX.Element => {
  const styles = useStyles();
  const { searchPillData, onChooseItem } = props;
  return (
    <>
      <div
        onClick={() => {
          onChooseItem(searchPillData);
        }}
        className={styles.searchPillResultItem}
      >
        {searchPillData.text}
        <CloseIcon className={styles.iconCloseSearchPillResultItem} />
      </div>
    </>
  );
};

export default SearchPillResultItem;

import contracts from './contracts';
import QuoteToken from './quoteTokens';
const farms = [
  {
    pid: 0,
    lpSymbol: 'ZAP-DYN',
    lpAddresses: {
      4: '0x4c5cecb666dd21139e7f3082a7394b7ca9cb70dd'
    },
    tokenSymbol: 'DYN',
    tokenAddresses: {
      4: '0xa802cdaed5440b593f14f5f01a151b1ef4982850'
    },
    quoteTokenSymbol: QuoteToken.ZAP,
    quoteTokenAdresses: contracts.zap
  },
  {
    pid: 1,
    lpSymbol: 'DUCK-ZAP',
    lpAddresses: {
      4: '0x84dc0c020e6198514417dc1e631963ea35eac6a5'
    },
    tokenSymbol: 'DUCK',
    tokenAddresses: {
      4: '0x0fdf4b623594fdc2786c72c7577adf468b15e57a'
    },
    quoteTokenSymbol: QuoteToken.ZAP,
    quoteTokenAdresses: contracts.zap
  }
];

export default farms;

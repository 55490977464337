import { CategoriesDataType } from "Pages/OpenSea/static/type";
import React, { FC } from "react";
import { CHOOSE_ITEM } from "../../commons/filter_component/Featured/FeaturedItem";
import ListingCategoryItem from "./ListingCategoryItem";
import useStyles from "./styles";

type ListingCategoriesProps = {
  categoriesData: CategoriesDataType;
  handleFilterByCategories: (selectedCategorieSlug: string[] | null) => void;
};

const ListingCategories: FC<ListingCategoriesProps> = (props): JSX.Element => {
  const styles = useStyles();
  const { categoriesData, handleFilterByCategories } = props;

  const onChooseItem = (choosedSlug: string, action: CHOOSE_ITEM) => {
    let selectedCategoriesData = null as string[] | null;
    if (action === CHOOSE_ITEM.CHECK) {
      selectedCategoriesData = [choosedSlug];
    }
    handleFilterByCategories(selectedCategoriesData);
  };
  return (
    <div className={styles.scrollGroupCategorys}>
      <div className={styles.groupCategorys}>
        {categoriesData.map(categoryData => {
          return (
            <ListingCategoryItem
              key={categoryData.slug}
              name={categoryData.name}
              slug={categoryData.slug}
              isSelected={categoryData.isSelected}
              onChooseItem={onChooseItem}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ListingCategories;

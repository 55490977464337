import { useParams } from "react-router-dom";
import AssetBundle from "./screen/AssetBundle";
import AssetDetails from "./screen/AssestDetail";
import AssetsListingPage from "./screen/AssetsListing";
import AccountOffersPage from "./screen/AccountOffers";
import AccountActivityPage from "./screen/AccountActivity";
import AccountInWalletPage from "./screen/AccountInWallet";
import NotFound from "Pages/NotFound/NotFound";

const OpenSeaRouter: React.FC = (props): JSX.Element => {
  const paramInUrl = useParams<
    object & {
      param1: string;
      param2: string;
      param3: string;
    }
  >();

  const getScreen = (
    paramInUrl: object & {
      param1: string;
      param2: string;
      param3: string;
    }
  ): JSX.Element => {
    if (paramInUrl.param1 === "assets") {
      if (paramInUrl.param2 === "bundles") return <AssetBundle bundleId={paramInUrl.param3} />;
      if (paramInUrl.param2 && paramInUrl.param3) {
        return <AssetDetails address={paramInUrl.param2} tokenId={paramInUrl.param3} />;
      }
      return <AssetsListingPage />;
    } else if (paramInUrl.param1 === "account") {
      if (paramInUrl.param2 === "inwallet") {
        return <AccountInWalletPage />;
      } else if (paramInUrl.param2 === "activity") {
        return <AccountActivityPage />;
      } else if (paramInUrl.param2 === "offers") {
        return <AccountOffersPage />;
      }
    }
    return (
      <>
        <NotFound />
      </>
    );
  };

  return <>{getScreen(paramInUrl)}</>;
};

export default OpenSeaRouter;

import { FC } from "react";
import { useOffersGlobalContext } from "../context/OffersGlobalContext";
import { getCollectionDataFromR2, updateOffersVariablesByCollections } from "../../commons/helper";
import AssetNavigation, {
  ScreenNavigationMode
} from "../../commons/AssetsNavigation/AssetNavigation";
import { CollectionDataType } from "Pages/OpenSea/static/type";

const OffersNavigation: FC = (): JSX.Element => {
  const { variables, setNewVariables, offerSearchData } = useOffersGlobalContext();

  const handleFilterByCollections = (selectedCollections: CollectionDataType[]) => {
    setNewVariables(updateOffersVariablesByCollections(selectedCollections, variables));
  };

  return (
    <>
      <AssetNavigation
        screen={ScreenNavigationMode.OFFERS}
        collectionsData={getCollectionDataFromR2(variables, offerSearchData)}
        handleFilterByCollections={handleFilterByCollections}
      />
    </>
  );
};

export default OffersNavigation;

import update from "immutability-helper";
import _ from "lodash";
import {
  FeaturesDataType,
  AssetSearchQueryVariableType,
  CollectionDataType,
  PaymentAssetsDataType,
  DynamicListStringTraitDataType,
  StringTraitsVariableType,
  DynamicListRangeDataType,
  NumericTraitsVariableType,
  DropDownDataType,
  SearchPillDataType,
  SEARCH_PILL_TYPE,
  ActivitySearchQueryVariableType,
  OfferSearchQueryVariableType
} from "../../../static/type";
import {
  RESULT_MODEL_VARIABLES,
  LISTING_SORT_BY_VARIABLES,
  WALLET_SORT_BY_VARIABLES
} from "../constants";

/*
    STEP3: When an onclick event in the filter elements is activated, 
    Perform an update of the variable based on the previous variable.
    - Get the changed data (which is the type of data that the on-screen component returns) 
      and the previous variables
    - Parse and return the new variable.
*/

/**
 * Update by component FeaturesData.
 * Using in assets listing and account inwallet screen
 * @param selectedFeaturesData data update
 * @param variables current variables need update
 * @returns new variables
 */
export const updateAssetsVariablesByFeaturesData = (
  selectedFeaturesData: FeaturesDataType,
  variables: AssetSearchQueryVariableType
): AssetSearchQueryVariableType => {
  const newToggles = [] as string[];
  _.forEach(selectedFeaturesData, item => {
    newToggles.push(item.slug);
  });
  return update(variables, { toggles: { $set: newToggles } });
};

/**
 * Update by component Collections.
 * Using in assets listing and account inwallet screen
 * @param selectedCollections data update
 * @param variables current variables need update
 * @returns new variables
 */
export const updateAssetsVariablesByCollections = (
  selectedCollections: CollectionDataType[],
  variables: AssetSearchQueryVariableType
): AssetSearchQueryVariableType => {
  const selectedCollectionSlugs = _.map(selectedCollections, item => item.slug);
  return update(variables, {
    collection: { $set: selectedCollectionSlugs.length === 1 ? selectedCollectionSlugs[0] : null },
    collections: { $set: selectedCollectionSlugs }
  });
};

/**
 * Update by component Payments.
 * Using in assets listing and account inwallet screen
 * @param selectedPayments data update
 * @param variables current variables need update
 * @returns new variables
 */
export const updateAssetsVariablesByPayments = (
  selectedPayments: PaymentAssetsDataType,
  variables: AssetSearchQueryVariableType
): AssetSearchQueryVariableType => {
  const selectedPaymentSymbols = _.map(selectedPayments, item => item.symbol);
  return update(variables, {
    paymentAssets: { $set: selectedPaymentSymbols }
  });
};

/**
 * Update by component StringTraits.
 * Using in assets listing and account inwallet screen
 * @param selectedStringTraits data update
 * @param variables current variables need update
 * @returns new variables
 */
export const updateAssetsVariablesByStringTraits = (
  selectedStringTraits: DynamicListStringTraitDataType,
  variables: AssetSearchQueryVariableType
): AssetSearchQueryVariableType => {
  const currentStringTraitsVariables = variables.stringTraits ?? [];
  let newStringTraitsVariables = null as StringTraitsVariableType[] | null;

  const stringTraitDataCheckIndex = _.findIndex(
    currentStringTraitsVariables,
    currentStringTraitsVariable => currentStringTraitsVariable.name === selectedStringTraits.key
  );
  if (stringTraitDataCheckIndex >= 0) {
    // update data
    newStringTraitsVariables = update(currentStringTraitsVariables, {
      [stringTraitDataCheckIndex]: {
        values: { $set: _.map(selectedStringTraits.stringTraits, item => item.value) }
      }
    });
  } else {
    // push new
    newStringTraitsVariables = update(currentStringTraitsVariables, {
      $push: [
        {
          name: selectedStringTraits.key,
          values: _.map(selectedStringTraits.stringTraits, item => item.value)
        }
      ]
    });
  }
  return update(variables, { stringTraits: { $set: newStringTraitsVariables } });
};

/**
 * Update by component NumbericTraits.
 * Using in assets listing and account inwallet screen
 * @param newNumericTraitsData data update
 * @param variables current variables need update
 * @returns new variables
 */
export const updateAssetsVariablesByNumbericTraits = (
  newNumericTraitsData: DynamicListRangeDataType,
  variables: AssetSearchQueryVariableType
): AssetSearchQueryVariableType => {
  const currentNumericTraitsVariables = variables.numericTraits ?? [];
  let newNumericTraitsVariables = null as NumericTraitsVariableType[] | null;

  const numbericTraitDataCheckIndex = _.findIndex(
    currentNumericTraitsVariables,
    currentNumericTraitsVariable => currentNumericTraitsVariable.name === newNumericTraitsData.key
  );

  if (numbericTraitDataCheckIndex >= 0) {
    if (newNumericTraitsData.numericTraits.isModified) {
      // update if still modify
      newNumericTraitsVariables = update(currentNumericTraitsVariables, {
        [numbericTraitDataCheckIndex]: {
          ranges: {
            0: {
              min: { $set: newNumericTraitsData.numericTraits.currentRange[0] },
              max: { $set: newNumericTraitsData.numericTraits.currentRange[1] }
            }
          }
        }
      });
    } else {
      // remove if not yet
      newNumericTraitsVariables = _.filter(
        currentNumericTraitsVariables,
        currentNumericTraitsVariable =>
          currentNumericTraitsVariable.name !== newNumericTraitsData.key
      );
    }
  } else {
    // push new if modify
    newNumericTraitsVariables = update(currentNumericTraitsVariables, {
      $push: [
        {
          name: newNumericTraitsData.key,
          ranges: [
            {
              min: newNumericTraitsData.numericTraits.currentRange[0],
              max: newNumericTraitsData.numericTraits.currentRange[1]
            }
          ]
        }
      ]
    });
  }
  return update(variables, { numericTraits: { $set: newNumericTraitsVariables } });
};

/**
 * Update by component Category.
 * Using in assets listing and account inwallet screen
 * @param selectedCategorieSlug data update
 * @param variables current variables need update
 * @returns new variables
 */
export const updateAssetsVariablesByCategorySlugs = (
  selectedCategorieSlug: string[] | null,
  variables: AssetSearchQueryVariableType
): AssetSearchQueryVariableType => {
  // NOTE: category changed will be clean collections variables
  return update(variables, {
    categories: { $set: selectedCategorieSlug },
    collection: { $set: null },
    collections: { $set: [] }
  });
};

/**
 * Update by component ResultModel.
 * Using in assets listing and account inwallet screen
 * @param newDropDownData data update
 * @param variables current variables need update
 * @returns new variables
 */
export const updateAssetsVariablesByResultModel = (
  newDropDownData: DropDownDataType,
  variables: AssetSearchQueryVariableType
): AssetSearchQueryVariableType => {
  const newResultModel = _.get(RESULT_MODEL_VARIABLES, [newDropDownData.slugSelected], {
    resultModel: null
  });
  return { ...variables, ...newResultModel };
};

/**
 * Update by component SortItem.
 * Using in assets listing screen
 * @param newDropDownData data update
 * @param variables current variables need update
 * @returns new variables
 */
export const updateListingAssetsVariablesBySortItem = (
  newDropDownData: DropDownDataType,
  variables: AssetSearchQueryVariableType
): AssetSearchQueryVariableType => {
  const newSortItem = _.get(
    LISTING_SORT_BY_VARIABLES,
    [newDropDownData.slugSelected],
    LISTING_SORT_BY_VARIABLES.INIT
  );
  return { ...variables, ...newSortItem };
};

/**
 * Update by component SortItem.
 * Using in account inwallet screen
 * @param newDropDownData data update
 * @param variables current variables need update
 * @returns new variables
 */
export const updateWalletAssetsVariablesBySortItem = (
  newDropDownData: DropDownDataType,
  variables: AssetSearchQueryVariableType
): AssetSearchQueryVariableType => {
  const newSortItem = _.get(
    WALLET_SORT_BY_VARIABLES,
    [newDropDownData.slugSelected],
    WALLET_SORT_BY_VARIABLES.INIT
  );
  return { ...variables, ...newSortItem };
};

/**
 * Update by component SearchPill.
 * Using in assets listing and account inwallet screen
 * @param removedSearchPill data update
 * @param variables current variables need update
 * @returns new variables
 */
export const updateAssetsVariablesBySearchPill = (
  removedSearchPill: SearchPillDataType,
  variables: AssetSearchQueryVariableType
): AssetSearchQueryVariableType => {
  switch (removedSearchPill.type) {
    case SEARCH_PILL_TYPE.COLLECTION:
      const newColection = _.filter(
        variables.collections,
        slug => slug !== removedSearchPill.value
      );
      return update(variables, {
        collections: { $set: newColection },
        collection: { $set: newColection.length === 1 ? newColection[0] : null }
      });
    case SEARCH_PILL_TYPE.FEATURES:
      return update(variables, {
        toggles: { $set: _.filter(variables.toggles, slug => slug !== removedSearchPill.value) }
      });
    case SEARCH_PILL_TYPE.PAYMENT:
      return update(variables, {
        paymentAssets: {
          $set: _.filter(variables.paymentAssets, symbol => symbol !== removedSearchPill.value)
        }
      });
    case SEARCH_PILL_TYPE.STRINGTRAIT:
      const stringTraitsVariables = variables.stringTraits ?? [];
      const stringTraitIndex = _.findIndex(
        stringTraitsVariables,
        stringTrait => stringTrait.name === removedSearchPill.value
      );
      if (stringTraitIndex >= 0) {
        const newValues = _.filter(
          stringTraitsVariables[stringTraitIndex].values,
          item => item !== removedSearchPill.text
        );
        if (newValues.length > 0) {
          return update(variables, {
            stringTraits: {
              [stringTraitIndex]: {
                values: { $set: newValues }
              }
            }
          });
        } else {
          // If newValues is empty, remove this stringTrait
          return update(variables, {
            stringTraits: {
              $splice: [[stringTraitIndex, 1]]
            }
          });
        }
      }
      break;
    case SEARCH_PILL_TYPE.NUMBERICTRAIT:
      const numericTraitsVariables = variables.numericTraits ?? [];
      const numericTraitIndex = _.findIndex(
        numericTraitsVariables,
        numericTrait => numericTrait.name === removedSearchPill.value
      );
      if (numericTraitIndex >= 0) {
        return update(variables, {
          numericTraits: {
            $splice: [[numericTraitIndex, 1]]
          }
        });
      }
      break;
    default:
      break;
  }
  return { ...variables };
};

/**
 * Update by component FeaturesData.
 * Using in account activity screen
 * @param selectedFeaturesData data update
 * @param variables current variables need update
 * @returns new variables
 */
export const updateActivityVariablesByFeaturesData = (
  selectedFeaturesData: FeaturesDataType,
  variables: ActivitySearchQueryVariableType
): ActivitySearchQueryVariableType => {
  const newEventTypes = [] as string[];
  _.forEach(selectedFeaturesData, item => {
    newEventTypes.push(item.slug);
  });
  return update(variables, { eventTypes: { $set: newEventTypes } });
};

/**
 * Update by component Collections.
 * Using in account activity screen
 * @param selectedCollections data update
 * @param variables current variables need update
 * @returns new variables
 */
export const updateActivityVariablesByCollections = (
  selectedCollections: CollectionDataType[],
  variables: ActivitySearchQueryVariableType
): ActivitySearchQueryVariableType => {
  const selectedCollectionSlugs = _.map(selectedCollections, item => item.slug);
  return update(variables, {
    collection: { $set: selectedCollectionSlugs.length === 1 ? selectedCollectionSlugs[0] : null },
    collections: { $set: selectedCollectionSlugs }
  });
};

/**
 * Update by component SearchPill.
 * Using in account activity screen
 * @param removedSearchPill data update
 * @param variables current variables need update
 * @returns new variables
 */
export const updateActivityVariablesBySearchPill = (
  removedSearchPill: SearchPillDataType,
  variables: ActivitySearchQueryVariableType
): ActivitySearchQueryVariableType => {
  switch (removedSearchPill.type) {
    case SEARCH_PILL_TYPE.COLLECTION:
      const newColection = _.filter(
        variables.collections,
        slug => slug !== removedSearchPill.value
      );
      return update(variables, {
        collections: { $set: newColection },
        collection: { $set: newColection.length === 1 ? newColection[0] : null }
      });
    case SEARCH_PILL_TYPE.FEATURES:
      return update(variables, {
        eventTypes: {
          $set: _.filter(variables.eventTypes, slug => slug !== removedSearchPill.value)
        }
      });
    default:
      break;
  }
  return { ...variables };
};

/**
 * Update by component Collections.
 * Using in account offers screen
 * @param selectedCollections data update
 * @param variables current variables need update
 * @returns new variables
 */
export const updateOffersVariablesByCollections = (
  selectedCollections: CollectionDataType[],
  variables: OfferSearchQueryVariableType
): OfferSearchQueryVariableType => {
  const selectedCollectionSlugs = _.map(selectedCollections, item => item.slug);
  return update(variables, {
    collections: { $set: selectedCollectionSlugs }
  });
};

/**
 * Update by component SearchPill.
 * Using in account offers screen
 * @param removedSearchPill data update
 * @param variables current variables need update
 * @returns new variables
 */
export const updateOffersVariablesBySearchPill = (
  removedSearchPill: SearchPillDataType,
  variables: OfferSearchQueryVariableType
): OfferSearchQueryVariableType => {
  switch (removedSearchPill.type) {
    case SEARCH_PILL_TYPE.COLLECTION:
      const newColection = _.filter(
        variables.collections,
        slug => slug !== removedSearchPill.value
      );
      return update(variables, {
        collections: { $set: newColection }
      });
    default:
      break;
  }
  return { ...variables };
};

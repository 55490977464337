import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { formatCurrency } from '_helpers/formatting';

export class MarketPrice extends Component {
  static propTypes = {
    market: PropTypes.object,
    trades: PropTypes.array
  };

  renderContent() {
    const { market } = this.props;
    const { precision = {} } = market;
    const price = formatCurrency(market.price, {
      precision: precision.price,
      symbol: market.quote
    });

    const priceTrend = market.change_24h > 0 ? 'change-up' : 'change-down';

    return (
      <React.Fragment>
        <span className="MarketPrice__price-value">{price}</span>
        <span className={`MarketSummary__price-change ${priceTrend}`} />
      </React.Fragment>
    );
  }

  render() {
    return <div className="MarketPrice">{this.renderContent()}</div>;
  }
}

export default MarketPrice;

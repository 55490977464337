import React, { useEffect, useState } from "react";
import { CHOOSE_ITEM } from "../Featured/FeaturedItem";
import StringTraitItem from "./StringTraitItem";
import update from "immutability-helper";
import _ from "lodash";
import {
  DynamicListStringTraitDataType,
  DynamicStringTraitDataType
} from "Pages/OpenSea/static/type";
import { WithBaseHeaderType, withBaseHeader } from "../../base_component/BaseHeader";
import useStyles from "./styles";
import SearchInput, { ACTION_CHANGED } from "../../base_component/SearchInput/SearchInput";

type StringTraitFilterProps = {
  stringTraitsData: DynamicListStringTraitDataType;
  handleFilterByStringTraits: (selectedStringTraits: DynamicListStringTraitDataType) => void;
} & WithBaseHeaderType;

const StringTraitFilter: React.FC<StringTraitFilterProps> = (props): JSX.Element => {
  const styles = useStyles();
  const { stringTraitsData, handleFilterByStringTraits } = props;

  const [stringTraits, setStringTraits] = useState(stringTraitsData.stringTraits);
  const [textSearch, setTextSearch] = useState("");
  useEffect(() => {
    if (stringTraitsData.stringTraits.length > 0) {
      setStringTraits(stringTraitsData.stringTraits);
    }
  }, [stringTraitsData.stringTraits]);

  const onChooseItem = (choosedName: string, action: CHOOSE_ITEM) => {
    const indexUpdate = stringTraits.findIndex(payment => payment.value === choosedName);
    if (indexUpdate >= 0) {
      const newStringTraits = update(stringTraits, {
        [indexUpdate]: { isSelected: { $set: action === CHOOSE_ITEM.CHECK } }
      });
      setStringTraits(newStringTraits);
      const selectedStringTraits = _.cloneDeep(newStringTraits).filter(
        stringTrait => stringTrait.isSelected === true
      );
      handleFilterByStringTraits(
        update(stringTraitsData, {
          stringTraits: { $set: selectedStringTraits }
        })
      );
    }
  };

  const filterStringTraitBySearchInput = (
    stringTraits: DynamicStringTraitDataType[],
    textSearch: string
  ) => {
    if (_.isEmpty(textSearch)) {
      return stringTraits;
    }
    return _.filter(stringTraits, stringTrait =>
      _.includes(_.toLower(stringTrait.value), _.toLower(textSearch))
    );
  };

  const renderDivContainer = () => {
    return (
      <div className={styles.scrollboxreactDivContainer}>
        <div className={styles.scrollboxContent}>
          {filterStringTraitBySearchInput(stringTraits, textSearch).map(stringTrait => {
            return (
              <StringTraitItem
                key={stringTrait.value}
                isChecked={stringTrait.isSelected}
                name={stringTrait.value}
                count={stringTrait.count}
                onChooseItem={(choosedId: string, action: CHOOSE_ITEM) => {
                  setTextSearch("");
                  onChooseItem(choosedId, action);
                }}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const renderStringTraits = () => {
    if (stringTraits.length > 4) {
      return (
        <div className={styles.panelIsContentPadded}>
          <SearchInput
            defaultValue={""}
            handleChangedValue={(value: string, _actionChanged: ACTION_CHANGED) => {
              setTextSearch(value);
            }}
            followActionChanged={[ACTION_CHANGED.DEBOUNCE]}
          />
          {renderDivContainer()}
        </div>
      );
    } else {
      return renderDivContainer();
    }
  };

  return <>{renderStringTraits()}</>;
};

export default withBaseHeader(StringTraitFilter);

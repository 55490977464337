import CID from 'cids';
import { getCodec, rmPrefix } from 'multicodec';
import { decode, toB58String } from 'multihashes';
import { ethers, Contract } from 'ethers';
import { namehash } from 'ethers/lib/utils';

const INFURA_KEY = process.env.INFURA_KEY;

export const REGISTRAR_ADDRESS = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e';

export const RESOLVER_ABI = [
  {
    constant: true,
    inputs: [
      {
        internalType: 'bytes32',
        name: 'node',
        type: 'bytes32'
      }
    ],
    name: 'contenthash',
    outputs: [
      {
        internalType: 'bytes',
        name: '',
        type: 'bytes'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  }
];

export const REGISTRAR_ABI = [
  {
    constant: true,
    inputs: [
      {
        name: 'node',
        type: 'bytes32'
      }
    ],
    name: 'resolver',
    outputs: [
      {
        name: 'resolverAddress',
        type: 'address'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  }
];

export function hexToUint8Array(hex) {
  hex = hex.startsWith('0x') ? hex.substr(2) : hex;
  if (hex.length % 2 !== 0) throw new Error('hex must have length that is multiple of 2');
  const arr = new Uint8Array(hex.length / 2);
  for (let i = 0; i < arr.length; i++) {
    arr[i] = parseInt(hex.substr(i * 2, 2), 16);
  }
  return arr;
}

export function contenthashToUri(contenthash) {
  const UTF_8_DECODER = new TextDecoder();
  const buff = hexToUint8Array(contenthash);
  const codec = getCodec(buff); // the typing is wrong for @types/multicodec
  switch (codec) {
    case 'ipfs-ns': {
      const data = rmPrefix(buff);
      const cid = new CID(data);
      return `ipfs://${toB58String(cid.multihash)}`;
    }
    case 'ipns-ns': {
      const data = rmPrefix(buff);
      const cid = new CID(data);
      const multihash = decode(cid.multihash);
      if (multihash.name === 'identity') {
        return `ipns://${UTF_8_DECODER.decode(multihash.digest).trim()}`;
      } else {
        return `ipns://${toB58String(cid.multihash)}`;
      }
    }
    default:
      throw new Error(`Unrecognized codec: ${codec}`);
  }
}

export function uriToHttp(uri) {
  const protocol = uri.split(':')[0].toLowerCase();
  switch (protocol) {
    case 'https':
      return [uri];
    case 'http':
      return ['https' + uri.substr(4), uri];
    case 'ipfs':
      const hash = uri.match(/^ipfs:(\/\/)?(.*)$/i)?.[2];
      return [`https://cloudflare-ipfs.com/ipfs/${hash}/`, `https://ipfs.io/ipfs/${hash}/`];
    case 'ipns':
      const name = uri.match(/^ipns:(\/\/)?(.*)$/i)?.[2];
      return [`https://cloudflare-ipfs.com/ipns/${name}/`, `https://ipfs.io/ipns/${name}/`];
    default:
      return [];
  }
}

export function resolverContract(resolverAddress, provider) {
  return new Contract(resolverAddress, RESOLVER_ABI, provider);
}

export async function resolveENSContentHash(esnName, provider) {
  const ensRegistrarContract = new Contract(REGISTRAR_ADDRESS, REGISTRAR_ABI, provider);
  const hash = namehash(esnName);
  const resolverAddress = await ensRegistrarContract.resolver(hash);
  if (resolverAddress !== '0x0000000000000000000000000000000000000000') {
    return resolverContract(resolverAddress, provider).contenthash(hash);
  }
}

export function ensResolver(esnName) {
  const provider = new ethers.providers.InfuraProvider('mainnet', INFURA_KEY);
  return resolveENSContentHash(esnName, provider);
}

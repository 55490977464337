import ERC20JSON from '../abi/ERC20.json';
import { getMasterchefAddress } from '../util/getAddresses';
import { getTransactionSigner } from '../util/transaction';
import MasterchefJSON from '../abi/Masterchef.json';
import { Contract } from 'ethers';
import BigNumber from 'bignumber.js';

export const getTokenAllowance = async (tokenAddress, owner, spender, signer) => {
  try {
    const contract = new Contract(tokenAddress, ERC20JSON.abi, signer);

    console.log(owner, spender, tokenAddress);
    const allowance = await contract.allowance(owner, spender);

    return allowance;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getTotalAllocPoint = async () => {
  const CHAIN_ID_MAINNET = 1;
  try {
    const masterchefAddress = getMasterchefAddress();
    const signer = await getTransactionSigner(CHAIN_ID_MAINNET, true);

    const contract = new Contract(masterchefAddress, MasterchefJSON, signer);

    const totalAllocPoint = await contract.totalAllocPoint();

    return totalAllocPoint;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const calSushiDailyYield = (pool, apy, sushiPrice, totalAllocPoint) => {
  const BLOCKS_PER_DAY = (60 * 60 * 24) / 13.5;
  const SUSHI_PER_BLOCK = 100;
  if (apy) {
    const apyPerDay = new BigNumber(apy).div(365);

    const poolWeight = new BigNumber(pool.allocPoint).div(totalAllocPoint);

    const sushiPerDay = poolWeight.times(BLOCKS_PER_DAY).times(SUSHI_PER_BLOCK);
    const totalSushiPrice = sushiPerDay.times(sushiPrice);
    const yieldSushiPer1000 = sushiPerDay
      .times(1000)
      .div(totalSushiPrice)
      .times(apyPerDay)
      .div(100);

    return yieldSushiPer1000;
  }

  return 0;
};

export const getCurrentStakedLPTokens = (pool, testPool) => {
  if (testPool && pool.stakedLPToken) {
    return pool.stakedLPToken.toFormat(4);
  }

  if (!testPool && pool.amount) {
    return new BigNumber(pool.amount).toFormat(4);
  }

  return new BigNumber(0).toFormat(4);
};

import { ApolloError } from "@apollo/client";
import {
  ActivitySearchQueryVariableType,
  ActivitySearchQueryResponseType,
  EventHistoryQueryResponseType
} from "Pages/OpenSea/static/type";
import { createContext, useContext } from "react";

export type ActivityGlobalContent = {
  variables: ActivitySearchQueryVariableType; // just follow main variables
  setNewVariables: (variables: ActivitySearchQueryVariableType) => void;
  activitySearchData: ActivitySearchQueryResponseType; // mapping storage, use for filter data
  eventHistoryData: EventHistoryQueryResponseType; // data content main
  activitySearchLoading: boolean;
  eventHistoryLoading: boolean;
  activitySearchError: ApolloError | undefined;
  eventHistoryError: ApolloError | undefined;
  isLoadingMore: boolean;
  fetchMoreEventHistory: () => Promise<void>;
};
export const ActivityGlobalContext = createContext<ActivityGlobalContent>(
  {} as ActivityGlobalContent
);
export const useActivityGlobalContext = () => useContext(ActivityGlobalContext);

import _ from "lodash";
import { DropDownDataType } from "Pages/OpenSea/static/type";
import { FC } from "react";
import DropDownItems from "../../commons/filter_component/Dropdown";
import { useListingGlobalContext } from "../context/ListingGlobalContext";
import {
  getResultModelDataFromR2,
  getListingSortItemDataFromR2,
  updateAssetsVariablesByResultModel,
  updateListingAssetsVariablesBySortItem
} from "../../commons/helper";
import useStyles from "./styles";
import PagingController from "../../commons/PagingController";
import {
  getCanFetchMoreDataFromDataResponse,
  getTotalCurrentPageFromDataResponse
} from "../../commons/PagingController/helper/PagingHelper";

const ListingContentTop: FC = (): JSX.Element => {
  const styles = useStyles();
  const {
    mappingDataStorage: data,
    variables,
    setNewVariables,
    currentPage,
    loading,
    isLoadingMore,
    handleChangedPage
  } = useListingGlobalContext();
  const resultCount = _.get(data, ["query", "search", "totalCount"], 0);

  return (
    <>
      <div className={styles.assetSearchViewResultsHeader}>
        <div className={styles.assetSearchViewResultsHeaderPills}>{`Results (${resultCount})`}</div>
        <div className={styles.assetSearchViewResultsHeaderDropdowns}>
          <PagingController
            currentPage={currentPage}
            isLoadingData={isLoadingMore || loading}
            totalcurrentPage={getTotalCurrentPageFromDataResponse(data)}
            canFetchMoreData={getCanFetchMoreDataFromDataResponse(data)}
            handleChangedPage={handleChangedPage}
          />
          <DropDownItems
            dropDownData={getResultModelDataFromR2(variables)}
            handleFilterByDropDown={(newDropDownData: DropDownDataType) => {
              setNewVariables(updateAssetsVariablesByResultModel(newDropDownData, variables));
            }}
          />
          <DropDownItems
            dropDownData={getListingSortItemDataFromR2(variables)}
            handleFilterByDropDown={(newDropDownData: DropDownDataType) => {
              setNewVariables(updateListingAssetsVariablesBySortItem(newDropDownData, variables));
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ListingContentTop;

import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
  return {
    featuredFilterItems: {
      display: "grid",
      gridGap: 10,
      gridTemplateColumns: `repeat(auto-fill, calc(50% - 5px))`,
      marginTop: 14
    },

    featuredFilterItem: {
      fontSize: 14,
      lineHeight: 14,
      fontWeight: "bold",
      alignItems: "center",
      background: theme?.custom?.colors?.midGrayRgba05,
      borderRadius: 2,
      color: theme?.custom?.colors?.colorBombay,
      display: "flex",
      justifyContent: "center",
      height: 36,
      padding: 10,
      cursor: "pointer",
      transition: "all 0.2s ease 0s",
      overflow: "hidden",

      "&.active": {
        color: theme?.custom?.colors?.white
      },

      "&:hover": {
        boxShadow: "rgb(14 14 14 / 25%) 0px 0px 8px 0px",
        opacity: 0.8
      },

      "&:nth-child(1)": {
        "&.active": {
          backgroundColor: "rgb(50, 145, 233)",
          borderColor: "rgb(50, 145, 233))"
        }
      },

      "&:nth-child(2)": {
        "&.active": {
          backgroundColor: "rgb(93, 50, 233)",
          borderColor: "rgb(93, 50, 233)"
        }
      },

      "&:nth-child(3)": {
        "&.active": {
          backgroundColor: "rgb(35, 220, 125)",
          borderColor: "rgb(35, 220, 125)"
        }
      },

      "&:nth-child(4)": {
        "&.active": {
          backgroundColor: "rgb(233, 50, 190)",
          borderColor: "rgb(233, 50, 190)"
        }
      }
    }
  };
});

export default useStyles;

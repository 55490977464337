import _ from "lodash";
import { FC } from "react";
import { useHistory, useLocation } from "react-router";
import { clearBaseHeaderControlStorage } from "../base_component/BaseHeader/helper";
import { ACCOUNT_TAB_HEADER } from "../constants";
import useStyles from "./styles";

/**
 * Component used to switch pages.
 * Using in account inwallet, activity, offers screen.
 * @returns Element
 */
const AccountTabHeader: FC = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const styles = useStyles();

  const onChangeTab = (item: any) => {
    clearBaseHeaderControlStorage();
    history.push(`${item.href}`);
  };

  return (
    <>
      <ul className={styles.tabHeaderAccount}>
        {ACCOUNT_TAB_HEADER.map((item, index) => {
          return (
            <li key={index} className={styles.itemTab} onClick={() => onChangeTab(item)}>
              <div
                className={
                  styles.itemBorder + (_.includes(item.href, location.pathname) ? " acvite " : "")
                }
              >
                <img
                  className={styles.iconTab}
                  src={`/images/icons/account/${item.icon}.svg`}
                  alt=""
                />
                {` ${item.name}`}
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default AccountTabHeader;

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Contract, Provider } from 'ethers-multicall';
import { ethers } from 'ethers';
import PAIR_ABI from '../../../abi/Pair.json';

const INFURA_KEY = process.env.INFURA_KEY;
const NETWORK_NAME = process.env.REACT_APP_NETWORK_NAME;

const usePairDetails = pair => {
  const [pairDetails, setPairDetails] = useState(undefined);
  const { address } = useSelector(state => state.user);

  useEffect(() => {
    const getPairDetails = async () => {
      const provider = new ethers.providers.InfuraProvider(NETWORK_NAME.toLowerCase(), INFURA_KEY);
      const ethcallProvider = new Provider(provider);
      await ethcallProvider.init();

      const pairContract = new Contract(pair.liquidityToken.address, PAIR_ABI);

      const calls = [
        pairContract.balanceOf(address),
        pairContract.getReserves(),
        pairContract.totalSupply()
      ];

      const pairDetailsResult = await ethcallProvider.all(calls);

      setPairDetails({
        balance: pairDetailsResult[0],
        reserves: pairDetailsResult[1],
        totalSupply: pairDetailsResult[2],
        token0: pair.token0.address
      });
    };

    pair && getPairDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pair]);

  return pairDetails;
};

export default usePairDetails;

import axios from 'axios';
import { TICKER_API_HOST } from '../_constants';
import { TICKER_TIMEOUT } from '_constants/timeouts';
import MarketService from 'service_v2/MarketService';
import CandlestickService from 'service_v2/CandlestickService';
import OrderService from 'service_v2/OrderService';
import TradeService from 'service_v2/TradeService';

const instance = axios.create({
  baseURL: `https://${TICKER_API_HOST}`,
  timeout: TICKER_TIMEOUT
});

export async function getMarkets(exchangeName) {
  return {
    exchange: exchangeName.toLowerCase(),
    market: await MarketService.getMarkets(exchangeName)
  };
}

export async function getMarketInfo(exchangeName, symbol) {
  return await MarketService.getMarketInfo(exchangeName.toLowerCase(), symbol);
}

export async function getCandleSticks(exchangeName, symbol, resolution, since) {
  try {
    const map = {
      '1': '1m',
      '5': '5m',
      '15': '15m',
      '30': '30m',
      '60': '1h',
      D: '1d'
    };

    const params = {
      exchange: exchangeName.toLowerCase(),
      symbol,
      interval: map[resolution]
    };

    if (since) {
      params.since = since;
    }
    return await CandlestickService.getCandlestick(exchangeName, symbol, map[resolution], since);
  } catch (err) {
    console.log(err.message);
  }
}

export function getTicker(exchangeName, symbol) {
  return instance.get('tickers', {
    params: {
      exchange: exchangeName.toLowerCase(),
      symbol
    }
  });
}

export async function getTrades(exchangeName, symbol) {
  return await TradeService.fetch(exchangeName.toLowerCase(), symbol);
}

export async function getOrderBook(exchangeName, symbol) {
  return await OrderService.fetch(exchangeName.toLowerCase(), symbol);
}

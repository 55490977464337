import Slider from "rc-slider";
import { useEffect, useState } from "react";
import _ from "lodash";
import update from "immutability-helper";
import { useStateStorage } from "./hooks/use-memorize-old-state";
import { DynamicListRangeDataType } from "Pages/OpenSea/static/type";
import { WithBaseHeaderType, withBaseHeader } from "../../base_component/BaseHeader";
import useStyles from "./styles";
import "./slider.scss";

const { Range } = Slider;

export enum RangeType {
  MIN,
  MAX
}

type RangeFilterPanelType = {
  numericTraitsData: DynamicListRangeDataType;
  handleFilterByNumbericTraits: (newNumericTraitsData: DynamicListRangeDataType) => void;
} & WithBaseHeaderType;

const RangeFilterPanel: React.FC<RangeFilterPanelType> = (props): JSX.Element => {
  const styles = useStyles();
  const { numericTraitsData } = props;
  const fixedMinValue = numericTraitsData.numericTraits.min;
  const fixedMaxValue = numericTraitsData.numericTraits.max;
  const propsCurrentRange = numericTraitsData.numericTraits.currentRange;
  const [
    currentRange,
    setCurrentRange,
    isEqualOldCurrentRange,
    updateOldCurrentRange
  ] = useStateStorage(propsCurrentRange ?? [fixedMinValue, fixedMaxValue]);
  const [currentMinValue, setCurrentMinValue] = useState(propsCurrentRange[0] ?? fixedMinValue);
  const [currentMaxValue, setCurrentMaxValue] = useState(propsCurrentRange[1] ?? fixedMinValue);

  useEffect(() => {
    setCurrentMinValue(currentRange[0]);
    setCurrentMaxValue(currentRange[1]);
  }, [currentRange]);

  const handleFilterByRange = (value: [number, number]) => {
    if (!isEqualOldCurrentRange(value)) {
      updateOldCurrentRange(value);
      const newNumericTraits = update(numericTraitsData, {
        numericTraits: {
          currentRange: { $set: value },
          isModified: { $set: !_.isEqual(value, [fixedMinValue, fixedMaxValue]) }
        }
      });
      props.handleFilterByNumbericTraits(newNumericTraits);
    }
  };

  const applyValueToRange = (type: RangeType) => {
    if (type === RangeType.MIN) {
      if (currentMinValue >= fixedMinValue && currentMinValue <= currentRange[1]) {
        const newRange = update(currentRange, { 0: { $set: currentMinValue } });
        setCurrentRange(newRange);
        handleFilterByRange(newRange as [number, number]);
      } else {
        setCurrentMinValue(currentRange[0]);
      }
    } else if (type === RangeType.MAX) {
      if (currentMaxValue <= fixedMaxValue && currentMaxValue >= currentRange[0]) {
        const newRange = update(currentRange, { 1: { $set: currentMaxValue } });
        setCurrentRange(newRange);
        handleFilterByRange(newRange as [number, number]);
      } else {
        setCurrentMaxValue(currentRange[1]);
      }
    }
  };

  return (
    <>
      <div className={styles.panelIsContentPadded}>
        <div className={styles.rangeFilterPanelSlider}>
          <Range
            step={fixedMaxValue >= 10 ? 0.1 : 0.01}
            allowCross={false}
            min={fixedMinValue}
            max={fixedMaxValue}
            value={currentRange}
            onChange={(value: number[]) => {
              setCurrentRange(value as [number, number]);
            }}
            onAfterChange={(value: number[]) => {
              handleFilterByRange(value as [number, number]);
            }}
          />
        </div>
        <div className={styles.rangeFilterPanelRange}>
          <div className={styles.rangeFilterPanelLimit}>
            <div className={styles.rangeFilterPanelLimitLabel}>min</div>
            <input
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              className={styles.rangeFilterPanelLimitInput}
              data-testid="Input"
              spellCheck="false"
              type="number"
              value={currentMinValue}
              onChange={event => {
                if (!_.isNaN(event.target.value)) {
                  setCurrentMinValue(Number(event.target.value));
                }
              }}
              onBlur={() => {
                applyValueToRange(RangeType.MIN);
              }}
              onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === "Enter") {
                  applyValueToRange(RangeType.MIN);
                }
              }}
            />
          </div>
          <div className={styles.rangeFilterPanelRangeDivider}>-</div>
          <div className={styles.rangeFilterPanelLimit}>
            <div className={styles.rangeFilterPanelLimitLabel}>max</div>
            <input
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              className={styles.rangeFilterPanelLimitInput}
              data-testid="Input"
              spellCheck="false"
              type="number"
              value={currentMaxValue}
              onChange={event => {
                if (!_.isNaN(event.target.value)) {
                  setCurrentMaxValue(Number(event.target.value));
                }
              }}
              onBlur={() => {
                applyValueToRange(RangeType.MAX);
              }}
              onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === "Enter") {
                  applyValueToRange(RangeType.MAX);
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default withBaseHeader(RangeFilterPanel);

import {useSelector} from 'react-redux';
import './AddLiquidity.scss';
import {Button} from '../../../components';
import {useEffect, useState, useMemo} from 'react';
import down_icon from '../../../assets/swap/down_icon.svg';
import GreenPlus from '../../../assets/icons/green-plus.svg';
import QuestionMark from '../../../assets/icons/question-mark-circle.svg';
import SettingCircle from '../../../assets/icons/setting-circle.svg';
import ModalCommon from '../../../components/ModalCommon/ModalCommon';
import ListToken from '../ListToken/ListToken.js';
import LqModalWaiting from '../Modal/LiquidityModal';
import {
  approve as SendApproveTx,
  supplyWithEth,
  supply,
  savePairLocal,
  getPairDataForRemove,
  getPairDetails,
  toFixed,
  ONE_BIPS
} from '../../../util/liquidity-utils';
import {Token} from '@uniswap/sdk';
import erc20 from '../../Swap/uni/ERC20.json';
import routerV2 from '../../Swap/uni/RouterV2.json';
import {toast} from 'react-toastify';
import ConfirmSupplyModal from '../Modal/ConfirmSupplyModal';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import SettingTransection from '../../../components/SettingTransection/SettingTransection';
import {isNotValidASCIINumber, isPreventASCIICharacters} from '../../../util/input';
import {WETH, Percent} from '@uniswap/sdk';
import {getTokenSymbol, isEthByDepo} from '../../../util/getTokenSymbol';
import BigNumber from 'bignumber.js';
import usePoolPair from '../hooks/usePoolPair';
import usePoolLibary from '../hooks/usePoolLibary';
import {CHAIN_ID, ROUTE_V2_ADDR, ROUTE_V2_ADDR_SUSHI} from '../../Swap/uni/const';
import {Tooltip} from '@material-ui/core';
import cls from 'classnames';
import {unWrappedNameToken} from '../../../util/getWrappedToken';
import {formatDecimalString} from '../../../_helpers';
import {trimLeadingZerosWithDecimal} from '../../../util/input';

const AddLiquidity = props => {
  const minimumAmount = 0.0000002;
  const user = useSelector(state => state.user);
  const {fetchETHBalance, selectedLiquidity, setChangedLiquidity, selectedPool, web3} = props;
  const [TokenAmount] = usePoolLibary(selectedPool);
  const {
    deadline,
    setDeadline,
    activePercent,
    setActivePercent,
    checkedExpert,
    setCheckExport,
    multihops,
    setCheckedMultiphop
  } = props;

  const [firstTokenAmount, setFirstTokenAmount] = useState('');
  const [secondTokenAmount, setSecondTokenAmount] = useState();
  const [openModalListToken, setOpenModalListToken] = useState(false);
  const [firstToken, setFirstToken] = useState(
    new Token(parseInt(CHAIN_ID), WETH[CHAIN_ID].address, 18, 'ETH', 'Ethereum')
  );
  const [secondToken, setSecondToken] = useState(null);
  const [firstTokenBalance, setFirstTokenBalance] = useState(0);
  const [secondTokenBalance, setSecondTokenBalance] = useState(0);
  const [firstTokenRawBalance, setFirstTokenRawBalance] = useState(0);
  const [secondTokenRawBalance, setSecondTokenRawBalance] = useState(0);
  const [currentTarget, setCurrentTarget] = useState('');
  const [isInput, setIsInput] = useState(true);
  const [allowanceFirstToken, setAllowanceFirstToken] = useState(0);
  const [allowanceSecondToken, setAllowanceSecondToken] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [pairDetails, setPairDetails] = useState();
  const [txHash, setTxHash] = useState();
  const [isApprovingToken, setIsApprovringToken] = useState(false);

  const [waitingModalStatus, setWaitingModalStatus] = useState('waiting');
  const [isOpenWaitingModal, setIsOpenWaitingModal] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

  const [totalPoolSupply, v2Pair, token0Deposited, token1Deposited] = usePoolPair(
    pairDetails,
    selectedLiquidity,
    selectedPool
  );

  const onSetMaxFirstTokenAmount = () => {
    let balance = new BigNumber("0");

    if (firstToken.symbol !== 'ETH') {
      balance = new BigNumber(firstTokenRawBalance)
        .div(new BigNumber(10).pow(firstToken.decimals))
        .toFixed();
      setFirstTokenAmount(balance);
    }

    if (firstToken.symbol === 'ETH') {
      balance = new BigNumber(firstTokenRawBalance)
        .div(new BigNumber(10).pow(firstToken.decimals)).minus(new BigNumber("0.01"))
        .toFixed();

      new BigNumber(balance).gt(0) ? setFirstTokenAmount(balance) : setFirstTokenAmount("0");
    }

    setIsInput(true);
    if (v2Pair) {
      const amount = new BigNumber(balance)
        .times(v2Pair.priceOf(firstToken).toSignificant())
        .toFixed();
      setSecondTokenAmount(amount);
    }
  };

  const onSetMaxSecondTokenAmount = () => {
    let balance = new BigNumber("0");

    if (secondToken.symbol !== 'ETH') {
      balance = new BigNumber(secondTokenRawBalance)
        .div(new BigNumber(10).pow(secondToken.decimals))
        .toFixed();
      setSecondTokenAmount(balance);
    }

    if (secondToken.symbol === 'ETH') {
      balance = new BigNumber(secondTokenRawBalance)
        .div(new BigNumber(10).pow(secondToken.decimals)).minus(new BigNumber("0.01"))
        .toFixed();

      new BigNumber(balance).gt(0) ? setSecondTokenAmount(balance) : setSecondTokenAmount("0");
    }

    setIsInput(false);
    if (v2Pair) {
      const amount = new BigNumber(balance)
        .times(v2Pair.priceOf(secondToken).toSignificant())
        .toFixed();
      setFirstTokenAmount(amount);
    }
  };

  const addLiquidityTokenToWallet = () => {
    if (
      web3 &&
      web3.currentProvider.isMetaMask &&
      web3.currentProvider.request &&
      selectedLiquidity
    ) {
      web3.currentProvider
        .request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: selectedLiquidity.liquidityToken.address,
              symbol: selectedLiquidity.liquidityToken.symbol,
              decimals: selectedLiquidity.liquidityToken.decimals,
              image: selectedLiquidity.liquidityToken.logoURI
            }
          }
        })
        .then(success => {
          console.log(success);
        });
    }
  };

  const isEnableFirstApprove = useMemo(() => {
    if (!firstToken || !secondToken || !firstTokenAmount || !secondTokenAmount) return false;
    if (!selectedLiquidity) return false;
    if (isEthByDepo(firstToken)) return false;
    if (new BigNumber(firstTokenBalance).lt(firstTokenAmount)) return false;
    if (new BigNumber(secondTokenBalance).lt(secondTokenAmount)) return false;
    const firstTokenUnit = firstTokenAmount * 10 ** firstToken.decimals;
    if (new BigNumber(firstTokenUnit).gt(allowanceFirstToken)) return true;
    return false;
  }, [
    firstTokenAmount,
    secondTokenAmount,
    firstToken,
    secondToken,
    allowanceFirstToken,
    firstTokenBalance,
    secondTokenBalance,
    selectedLiquidity
  ]);

  const isEnableSecondApprove = useMemo(() => {
    if (!firstToken || !secondToken || !firstTokenAmount || !secondTokenAmount) return false;
    if (!selectedLiquidity) return false;
    if (isEthByDepo(secondToken)) return false;
    if (new BigNumber(secondTokenBalance).lt(secondTokenAmount)) return false;
    if (new BigNumber(firstTokenBalance).lt(firstTokenAmount)) return false;
    const secondTokenUnit = secondTokenAmount * 10 ** secondToken.decimals;
    if (new BigNumber(secondTokenUnit).gt(allowanceSecondToken)) return true;
    return false;
  }, [
    firstTokenAmount,
    secondTokenAmount,
    firstToken,
    secondToken,
    allowanceSecondToken,
    secondTokenBalance,
    firstTokenBalance,
    selectedLiquidity
  ]);

  const isDisableButtonSupply = useMemo(() => {
    const isInsufficient1 = new BigNumber(firstTokenAmount).gt(firstTokenBalance);
    const isInsufficient2 = new BigNumber(secondTokenAmount).gt(secondTokenBalance);
    const isZero1 = new BigNumber(firstTokenAmount || 0).isZero();
    const isZero2 = new BigNumber(secondTokenAmount || 0).isZero();
    return (
      !selectedLiquidity ||
      isZero1 ||
      isZero2 ||
      !secondToken ||
      isEnableSecondApprove ||
      isEnableFirstApprove ||
      isInsufficient1 ||
      isInsufficient2
    );
  }, [
    firstTokenAmount,
    secondTokenAmount,
    firstTokenBalance,
    secondTokenBalance,
    secondToken,
    isEnableFirstApprove,
    isEnableSecondApprove,
    selectedLiquidity
  ]);

  const renderModalListToken = () => {
    let modalProps = {
      open: openModalListToken,
      handleClose: () => setOpenModalListToken(false)
    };
    return (
      <ModalCommon {...modalProps}>
        <ListToken
          handleClose={modalProps.handleClose}
          user={user}
          setOutputToken={async value => {
            const tokenB = unWrappedNameToken(
              new Token(value.chainId, value.address, value.decimals, value.symbol, value.name)
            );
            tokenB.tokenInfo = {logoURI: value.logoURI};
            setSecondToken(tokenB);
            if (firstToken) {
              tokenB.address === firstToken.address && setFirstToken(secondToken);
              try {
                const dataForRemove = await getPairDataForRemove(
                  tokenB.address === firstToken.address ? secondToken : firstToken,
                  tokenB,
                  user,
                  selectedPool
                );
                props.setSelectedLiquidity(dataForRemove);
              } catch (e) {
                props.setSelectedLiquidity(null);
              }
            }
          }}
          setInputToken={async value => {
            const tokenA = unWrappedNameToken(
              new Token(value.chainId, value.address, value.decimals, value.symbol, value.name)
            );
            tokenA.tokenInfo = {logoURI: value.logoURI};
            setFirstToken(tokenA);
            if (secondToken) {
              tokenA.address === secondToken.address && setSecondToken(firstToken);
              try {
                const dataForRemove = await getPairDataForRemove(
                  tokenA,
                  tokenA.address === secondToken.address ? firstToken : secondToken,
                  user,
                  selectedPool
                );
                props.setSelectedLiquidity(dataForRemove);
              } catch (e) {
                props.setSelectedLiquidity(null);
              }
            }
          }}
          currentTarget={currentTarget}
        />
      </ModalCommon>
    );
  };

  const onConfirmSupply = async () => {
    const router = selectedPool === 'uni' ? ROUTE_V2_ADDR : ROUTE_V2_ADDR_SUSHI;
    const routerUniswap = new web3.eth.Contract(routerV2.abi, router);
    setLoading(true);
    const callBackSuccess = async (e, recipt) => {
      if (recipt && !recipt.status) {
        toast.error('Supply transaction error!');
        return;
      }
      toast.success('Supply transaction successfully!');
      fetchBalance1();
      fetchBalance2();
      savePairLocal(firstToken, secondToken);
      const newPairDetail = await getPairDetails(selectedLiquidity.liquidityToken.address, user);
      setPairDetails(newPairDetail);
      setChangedLiquidity({
        balance: newPairDetail.balance,
        liquidityToken: selectedLiquidity.liquidityToken,
        token0: v2Pair.token0,
        token1: v2Pair.token1
      });

      await fetchETHBalance();
    };
    const callBackHash = txHash => {
      setTxHash(txHash);
      setWaitingModalStatus('success');
      setIsOpenConfirmModal(false);
      setLoading(false);
      if (!checkedExpert) {
        setIsOpenWaitingModal(true);
      }
    };
    const callBackError = async error => {
      console.log(error);
      if (!checkedExpert) {
        setIsOpenWaitingModal(true);
      }
      if (error && error.message === 'Returned error: insufficient funds for gas * price + value') {
        toast.error('Insufficient Transaction Fee');
      }
      setLoading(false);
      setWaitingModalStatus('rejected');
    };
    if (!user.privateKey && !checkedExpert) {
      setIsOpenWaitingModal(true);
      setWaitingModalStatus('waiting');
    }
    if (isEthByDepo(firstToken) || isEthByDepo(secondToken)) {
      const firstTokenIsEth = isEthByDepo(firstToken);
      try {
        await supplyWithEth(
          web3,
          firstTokenIsEth ? secondToken : firstToken,
          firstTokenIsEth ? secondTokenAmount : firstTokenAmount,
          firstTokenIsEth ? firstTokenAmount : secondTokenAmount,
          routerUniswap,
          user,
          callBackSuccess,
          callBackError,
          callBackHash
        );
      } catch (error) {
        setLoading(false);
        setWaitingModalStatus('rejected');
      }
    } else {
      try {
        await supply(
          web3,
          firstToken,
          firstTokenAmount,
          secondToken,
          secondTokenAmount,
          routerUniswap,
          user,
          callBackSuccess,
          callBackError,
          callBackHash
        );
      } catch (error) {
        console.log(error.message);
        setWaitingModalStatus('rejected');
        setLoading(false);
      }
    }
  };

  const onApproveToken = async isFirstToken => {
    const priceContract = new web3.eth.Contract(
      erc20.abi,
      isFirstToken ? firstToken.address : secondToken.address
    );
    setIsApprovringToken(true);
    await SendApproveTx(
      web3,
      priceContract,
      user,
      e => {
        isFirstToken ? fetchAllowance1() : fetchAllowance2();
        setIsApprovringToken(false);
        toast.success('Approve transaction successfully!');
      },
      selectedPool,
      e => {
        console.log(e);
        setIsApprovringToken(false);
      },
      transactionHash => {
        console.log(transactionHash);
      }
    );

    await fetchETHBalance();
  };

  const fetchBalance1 = async () => {
    const tokenAContract = new web3.eth.Contract(erc20.abi, firstToken.address);
    if (isEthByDepo(firstToken)) {
      let balanceCoin = await new web3.eth.getBalance(user.address);
      let balanceCoinConvert = web3.utils.fromWei(balanceCoin);
      setFirstTokenRawBalance(balanceCoin);
      setFirstTokenBalance(Number(balanceCoinConvert));
    } else {
      const dataBalance = await tokenAContract.methods.balanceOf(user.address).call();
      setFirstTokenRawBalance(dataBalance);
      setFirstTokenBalance(
        new BigNumber(dataBalance).div(new BigNumber(10).pow(firstToken.decimals)).toFixed()
      );
    }
  };

  const fetchBalance2 = async () => {
    const tokenBContract = new web3.eth.Contract(erc20.abi, secondToken.address);
    if (isEthByDepo(secondToken)) {
      let balanceCoin = await new web3.eth.getBalance(user.address);
      let balanceCoinConvert = web3.utils.fromWei(balanceCoin);
      setSecondTokenRawBalance(balanceCoin);
      setSecondTokenBalance(Number(balanceCoinConvert));
    } else {
      const dataBalance = await tokenBContract.methods.balanceOf(user.address).call();
      setSecondTokenRawBalance(dataBalance);
      setSecondTokenBalance(
        new BigNumber(dataBalance).div(new BigNumber(10).pow(secondToken.decimals)).toFixed()
      );
    }
  };
  const fetchAllowance1 = async address => {
    const routerAddress = selectedPool === 'uni' ? ROUTE_V2_ADDR : ROUTE_V2_ADDR_SUSHI;
    const priceContract = new web3.eth.Contract(erc20.abi, address ?? firstToken.address);
    const allowanceFirstToken = await priceContract.methods
      .allowance(user.address, routerAddress)
      .call();

    setAllowanceFirstToken(allowanceFirstToken);
  };

  const fetchAllowance2 = async address => {
    const routerAddress = selectedPool === 'uni' ? ROUTE_V2_ADDR : ROUTE_V2_ADDR_SUSHI;
    const priceContract = new web3.eth.Contract(erc20.abi, address ?? secondToken.address);
    const allowanceSecondToken = await priceContract.methods
      .allowance(user.address, routerAddress)
      .call();

    setAllowanceSecondToken(allowanceSecondToken);
  };

  useEffect(() => {
    if (v2Pair && (firstTokenAmount || secondTokenAmount)) {
      if (isInput) {
        const amount = new BigNumber(firstTokenAmount)
          .times(v2Pair.priceOf(selectedLiquidity.token0).toSignificant())
          .toString();
        setSecondTokenAmount(toFixed(amount, false));
      } else {
        const amount = new BigNumber(secondTokenAmount)
          .times(v2Pair.priceOf(selectedLiquidity.token1).toSignificant())
          .toString();
        setFirstTokenAmount(toFixed(amount, false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [v2Pair]);

  useEffect(() => {
    if (firstToken) {
      fetchBalance1();
      fetchAllowance1();
    } else {
      setFirstTokenBalance(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstToken]);

  useEffect(() => {
    if (secondToken) {
      fetchBalance2();
      fetchAllowance2();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondToken]);

  const token1Image = firstToken ? getTokenSymbol(firstToken) : undefined;
  const token2Image = secondToken ? getTokenSymbol(secondToken) : undefined;

  const totalPoolShares = pairDetails
    ? new BigNumber(pairDetails.balance)
      .div(new BigNumber(pairDetails.totalSupply))
      .times(100)
      .toFormat(5)
    : '0.00';

  const liquidityMinted = useMemo(() => {
    if (
      selectedLiquidity &&
      new BigNumber(firstTokenAmount).gt(0) &&
      new BigNumber(secondTokenAmount).gt(0) &&
      v2Pair
    ) {
      const tokenA = new TokenAmount(
        firstToken,
        new BigNumber(firstTokenAmount).times(new BigNumber(10).pow(firstToken.decimals)).toFixed(0)
      );
      const tokenB = new TokenAmount(
        secondToken,
        new BigNumber(secondTokenAmount)
          .times(new BigNumber(10).pow(secondToken.decimals))
          .toFixed(0)
      );
      try {
        return totalPoolSupply && firstTokenAmount && secondTokenAmount
          ? v2Pair.getLiquidityMinted(totalPoolSupply, tokenA, tokenB)
          : null;
      } catch (e) {
        return null;
      }
    } else {
      return 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLiquidity, firstTokenAmount, secondTokenAmount]);

  const poolTokenPercentage = useMemo(() => {
    if (liquidityMinted) {
      return new Percent(liquidityMinted.raw, totalPoolSupply.add(liquidityMinted).raw);
    } else {
      return undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liquidityMinted]);

  useEffect(() => {
    const fetchPairDetail = async () => {
      const newPairDetail = await getPairDetails(selectedLiquidity.liquidityToken.address, user);
      setPairDetails(newPairDetail);
      setFirstToken(selectedLiquidity.token0);
      setSecondToken(selectedLiquidity.token1);
    };
    if (selectedLiquidity) {
      fetchPairDetail();
    } else {
      setPairDetails(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLiquidity]);

  useEffect(() => {
    const asyncTmp = async () => {
      if (firstToken && secondToken) {
        try {
          const dataForRemove = await getPairDataForRemove(
            firstToken,
            secondToken,
            user,
            selectedPool
          );
          props.setSelectedLiquidity(dataForRemove);
          fetchAllowance1(firstToken.address);
          fetchAllowance2(secondToken.address);
        } catch (e) {
          console.log(e);
          props.setSelectedLiquidity(null);
        }
      }
    };
    asyncTmp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPool]);

  const textButton = useMemo(() => {
    if (!secondToken || !firstToken) return 'Invalid Pair';
    if (!selectedLiquidity) return 'Pool Not Found';

    if (
      new BigNumber(firstTokenAmount || 0).isZero() ||
      new BigNumber(secondTokenAmount || 0).isZero()
    ) {
      return 'Enter an Amount';
    } else if (
      new BigNumber(firstTokenAmount).times(10 ** firstToken.decimals).gt(firstTokenRawBalance) ||
      new BigNumber(secondTokenAmount).times(10 ** secondToken.decimals).gt(secondTokenRawBalance)
    ) {
      const isFirstTokenInsufficient = new BigNumber(firstTokenAmount)
        .times(10 ** firstToken.decimals)
        .gt(firstTokenRawBalance);
      return `Insufficient ${isFirstTokenInsufficient ? firstToken.symbol : secondToken.symbol
        } Balance`;
    } else {
      return 'Supply';
    }
  }, [
    firstTokenAmount,
    secondTokenAmount,
    firstTokenRawBalance,
    secondTokenRawBalance,
    firstToken,
    secondToken,
    selectedLiquidity
  ]);

  return (
    <div className="add-liquidity-container">
      <div className="form-icon-help">
        <Tooltip
          classes={{tooltip: 'tooltip_add_liquidity'}}
          title="hi"
          arrow
          placement="bottom"
          TransitionComponent={() => {
            return (
              <div className="tooltip_liquidity">
                {selectedPool === 'uni' ? (
                  'When you add liquidity, you will receive pool tokens representing your position.\n' +
                  '                These tokens automatically earn fees proportional to your share of the pool, and can\n' +
                  '                be redeemed at any time.'
                ) : (
                  <div className="tooltip-add-sushi">
                    <div className="title-sushi">You are the first liquidity provider.</div>
                    <div className="mt-10">
                      The ratio of tokens you add will set the price of this pool.
                    </div>
                    <div className="mt-10">
                      Once you are happy with the rate click supply to review.
                    </div>
                  </div>
                )}
              </div>
            );
          }}
        >
          <img src={QuestionMark} className="question-mark" alt="" />
        </Tooltip>
        <ClickAwayListener onClickAway={() => setOpenSetting(false)}>
          <div className="setting-wrapper">
            <img
              src={SettingCircle}
              onClick={() => setOpenSetting(prev => !prev)}
              className="setting-circle"
              alt=""
            />
            {openSetting ? (
              <SettingTransection
                dealine={deadline}
                activePercent={activePercent}
                checkedExpert={checkedExpert}
                checkedMultihop={multihops}
                setDealine={setDeadline}
                setActivePercent={setActivePercent}
                setCheckedExpert={setCheckExport}
                setCheckedMultihop={setCheckedMultiphop}
              />
            ) : null}
          </div>
        </ClickAwayListener>
      </div>
      <div className="form__row">
        <div className="form-row-label">
          <div className="label-stake">Add</div>
          <div className="balance-stake">
            Balance: {formatDecimalString(6, firstTokenBalance.toString())}
          </div>
        </div>
        <div className="form-row-input">
          <div className="field-group">
            <input
              className="form__input"
              onKeyDown={e => isNotValidASCIINumber(e.keyCode, true) && e.preventDefault()}
              onKeyPress={e => isPreventASCIICharacters(e.key) && e.preventDefault()}
              value={firstTokenAmount}
              autoComplete="off"
              title=""
              onChange={e => {
                const inputVal = e.target.value;
                setIsInput(true);
                setFirstTokenAmount(inputVal);
                if (v2Pair) {
                  if (new BigNumber(inputVal).lt(minimumAmount) || !inputVal) {
                    setSecondTokenAmount(0);
                  } else {
                    const amount = new BigNumber(inputVal)
                      .times(v2Pair.priceOf(firstToken).toSignificant())
                      .toString();
                    setSecondTokenAmount(toFixed(amount, false));
                  }
                }
              }}
              maxLength={255}
              type="number"
              placeholder={0}
            />
            <Button onClick={() => onSetMaxFirstTokenAmount()} className="max-button" small={true}>
              Max
            </Button>
          </div>
          <div
            className="display-token-name"
            id="input-token-name"
            onClick={e => {
              setOpenModalListToken(true);
              setCurrentTarget(e.currentTarget.id);
            }}
          >
            <img src={token1Image} alt="" />
            <div>{firstToken?.symbol ?? 'Select a token'}</div>
            <div className="icon">
              <img src={down_icon} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="plus-icon-row">
        <img alt="" src={GreenPlus} />
      </div>

      <div className="form__row">
        <div className="form-row-label">
          <div className="label-stake">Add</div>
          <div className="balance-stake">
            {secondToken ? `Balance: ${formatDecimalString(6, secondTokenBalance.toString())}` : ''}
          </div>
        </div>
        <div className="form-row-input">
          <div className="field-group">
            <input
              className="form__input"
              onKeyDown={e => isNotValidASCIINumber(e.keyCode, true) && e.preventDefault()}
              onKeyPress={e => isPreventASCIICharacters(e.key) && e.preventDefault()}
              value={secondTokenAmount}
              autoComplete="off"
              onChange={e => {
                const inputVal = e.target.value;
                setIsInput(false);
                setSecondTokenAmount(inputVal);
                if (v2Pair) {
                  if (new BigNumber(inputVal).lt(minimumAmount) || !inputVal) {
                    setFirstTokenAmount(0);
                  } else {
                    const amount = new BigNumber(inputVal)
                      .times(v2Pair.priceOf(secondToken).toSignificant())
                      .toString();
                    setFirstTokenAmount(toFixed(amount, false));
                  }
                }
              }}
              title=""
              maxLength={255}
              type="number"
              placeholder={0}
            />
            {secondToken ? (
              <Button
                onClick={() => onSetMaxSecondTokenAmount()}
                className="max-button"
                small={true}
              >
                Max
              </Button>
            ) : (
              ''
            )}
          </div>
          <div
            className="display-token-name"
            onClick={e => {
              setOpenModalListToken(true);
              setCurrentTarget(e.currentTarget.id);
            }}
          >
            <img src={token2Image} alt="" />
            <div>{secondToken?.symbol ?? 'Select a token'}</div>
            <div className="icon">
              <img src={down_icon} alt="" />
            </div>
          </div>
        </div>
      </div>

      {firstToken && secondToken ? (
        <div className="price-share-pool">
          <div className="price-share-title">Prices and pool share</div>
          <div className="price-share-container">
            <div className="price-share-column border-right ">
              <div className="price-share-value">
                {v2Pair
                  ? v2Pair.token0Price.toSignificant(6)
                  : new BigNumber(firstTokenAmount).gt(0) && new BigNumber(secondTokenAmount).gt(0)
                    ? new BigNumber(secondTokenAmount).div(firstTokenAmount).toString()
                    : '---'}
              </div>
              <div className="price-share-label">
                {v2Pair
                  ? `${v2Pair.token1.symbol} per ${v2Pair.token0.symbol}`
                  : `${secondToken.symbol} per ${firstToken.symbol}`}
              </div>
            </div>
            <div className="price-share-column border-right ">
              <div className="price-share-value">
                {v2Pair
                  ? v2Pair.token1Price.toSignificant(6)
                  : new BigNumber(firstTokenAmount).gt(0) && new BigNumber(secondTokenAmount).gt(0)
                    ? new BigNumber(firstTokenAmount).div(secondTokenAmount).toString()
                    : '---'}
              </div>
              <div className="price-share-label">
                {v2Pair
                  ? `${v2Pair.token0.symbol} per ${v2Pair.token1.symbol}`
                  : `${firstToken.symbol} per ${secondToken.symbol}`}
              </div>
            </div>
            <div className="price-share-column">
              <div className="price-share-value">
                {poolTokenPercentage
                  ? poolTokenPercentage.lessThan(ONE_BIPS)
                    ? '<0.01'
                    : `${poolTokenPercentage.toFixed(2)}`
                  : new BigNumber(firstTokenAmount).gt(0) && new BigNumber(secondTokenAmount).gt(0)
                    ? '100'
                    : 0}
                %
              </div>
              <div className="price-share-label">Share of Pool</div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {isEnableFirstApprove && (
        <div className={cls('button-container')}>
          <Button loading={isApprovingToken} onClick={() => onApproveToken(true)}>
            {isApprovingToken ? 'Approving' : 'Approve'} {firstToken.symbol}
          </Button>
        </div>
      )}

      {isEnableSecondApprove && !isEnableFirstApprove && (
        <div className={cls('button-container')}>
          <Button loading={isApprovingToken} onClick={() => onApproveToken(false)}>
            {isApprovingToken ? 'Approving' : 'Approve'} {secondToken.symbol}
          </Button>
        </div>
      )}

      <div
        className={cls('button-container', {
          hidden: isEnableFirstApprove || isEnableSecondApprove
        })}
      >
        <Button
          disabled={isDisableButtonSupply}
          loading={loading}
          onClick={() => {
            if (checkedExpert) {
              onConfirmSupply();
            } else {
              setIsOpenConfirmModal(true);
            }
          }}
        >
          {textButton}
        </Button>
      </div>

      {selectedLiquidity && pairDetails && new BigNumber(pairDetails.balance).gt(0) ? (
        <div className="box-position-bottom">
          <div className="position-box-title">Your Position</div>
          <div className="position-box-row">
            <div className="position-label image-container">
              <img alt="" src={token1Image} />
              <img alt="" src={token2Image} />
              {selectedLiquidity.token0?.symbol}/{selectedLiquidity.token1?.symbol}
            </div>
            <div className="position-value">
              {trimLeadingZerosWithDecimal(new BigNumber(pairDetails.balance)
                .div(new BigNumber(10).pow(selectedLiquidity.liquidityToken.decimals))
                .toFixed(5))}
            </div>
          </div>
          <div className="position-box-row">
            <div className="position-label image-container">Your Pool Share</div>
            <div className="position-value">{totalPoolShares}%</div>
          </div>
          <div className="position-box-row">
            <div className="position-label image-container">{selectedLiquidity?.token0.symbol}</div>
            <div className="position-value">{token0Deposited?.toSignificant()}</div>
          </div>
          <div className="position-box-row">
            <div className="position-label image-container">{selectedLiquidity?.token1.symbol}</div>
            <div className="position-value">{token1Deposited?.toSignificant()}</div>
          </div>
        </div>
      ) : (
        ''
      )}
      {renderModalListToken()}
      <LqModalWaiting
        isOpen={isOpenWaitingModal}
        handleClose={() => setIsOpenWaitingModal(false)}
        status={waitingModalStatus}
        firstToken={firstToken}
        secondToken={secondToken}
        firstTokenAmount={firstTokenAmount}
        secondTokenAmount={secondTokenAmount}
        txHash={txHash}
        addLiquidityTokenToWallet={addLiquidityTokenToWallet}
        liquidityToken={selectedLiquidity ? selectedLiquidity.liquidityToken : null}
      />
      <ConfirmSupplyModal
        firstToken={firstToken}
        secondToken={secondToken}
        firstTokenAmount={firstTokenAmount}
        secondTokenAmount={secondTokenAmount}
        v2Pair={v2Pair}
        shareOfPool={poolTokenPercentage}
        isOpen={isOpenConfirmModal}
        handleClose={() => setIsOpenConfirmModal(false)}
        onConfirmSupply={onConfirmSupply}
        liquidityMinted={liquidityMinted}
        loading={loading}
        slipPercent={activePercent}
      />
    </div>
  );
};

export default AddLiquidity;

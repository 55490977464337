import { ChainId, Token } from '@uniswap/sdk';

export const DAI = new Token(
  ChainId.GÖRLI,
  '0x73967c6a0904aa032c103b4104747e88c566b1a2',
  18,
  'DAI',
  'Dai Stablecoin'
);

export const USDT = new Token(
  ChainId.GÖRLI,
  '0x509ee0d083ddf8ac028f2a56731412edd63223b9',
  6,
  'USDT',
  'Tether USD'
);
export const AMPL = new Token(
  ChainId.MAINNET,
  '0xD46bA6D942050d489DBd938a2C909A5d5039A161',
  9,
  'AMPL',
  'Ampleforth'
);

const tokens = { USDT, AMPL };

export default tokens;

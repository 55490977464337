import { OperationVariables, useQuery } from "@apollo/client";
import _ from "lodash";
import {
  ActivitySearchQuery,
  ACTIVITY_SEARCH_INIT_VARIABLES
} from "Pages/OpenSea/graphql/queries/ActivitySearchQuery";
import {
  EventHistoryQuery,
  EVENT_HISTORY_INIT_VARIABLES
} from "Pages/OpenSea/graphql/queries/EventHistoryQuery";
import { FC, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import {
  convertActivityQueryStringToVariables,
  convertActivityVariablesToQueryString,
  getActivitySearchPillDataFromR2,
  updateActivityVariablesBySearchPill
} from "../commons/helper";
import ActivitySearchView from "./ActivityContentMain/ActivitySearchView";
import { ActivityGlobalContext } from "./context/ActivityGlobalContext";
import { OPENSEA_LOCAL_STORAGE_KEY } from "../commons/constants/constants";
import ActivityNavigation from "./ActivityNavigation/ActivityNavigation";
import useStyles from "./styles";
import SearchPillResultList from "../commons/filter_component/SearchPillResult";
import { useLocalStorage } from "../commons/hooks";
import AccountTabHeader from "../commons/AccountTabHeader";
import {
  SearchPillDataType,
  EventHistoryQueryResponseType,
  EventHistoryQueryVariableType
} from "Pages/OpenSea/static/type";

const AccountActivityPage: FC = (): JSX.Element => {
  const history = useHistory();
  const styles = useStyles();

  // Get data form path
  const useLocationResult = useLocation();
  const queryString = useLocationResult.search;

  // Parse url to variables
  const wrappedVariables = useMemo(() => {
    const dataFromQueryString = convertActivityQueryStringToVariables(queryString);
    const activitySearchVariables = { ...ACTIVITY_SEARCH_INIT_VARIABLES, ...dataFromQueryString };
    const eventHistoryVariables = {
      ...EVENT_HISTORY_INIT_VARIABLES,
      collections: dataFromQueryString.collections,
      eventTypes: dataFromQueryString.eventTypes
    };
    return {
      variables: activitySearchVariables,
      eventHistoryVariables
    };
  }, [queryString]);

  const [newVariables, setNewVariables] = useState(wrappedVariables.variables);

  useEffect(() => {
    if (!_.isEqual(newVariables, wrappedVariables.variables)) {
      const queryString = convertActivityVariablesToQueryString(newVariables);
      history.push(`/opensea/account/activity${queryString}`);
      window.scrollTo(0, 0);
    }
  }, [history, newVariables, wrappedVariables]);

  // activitySearchData and mappingActivitySearchDataStorage will be different when useQuery loading
  // (in loading, activitySearchData = undefined)
  const {
    data: activitySearchData,
    loading: activitySearchLoading,
    error: activitySearchError
  } = useQuery(ActivitySearchQuery, {
    variables: wrappedVariables.variables,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });
  const {
    data: eventHistoryData,
    loading: eventHistoryLoading,
    error: eventHistoryError,
    fetchMore
  } = useQuery(EventHistoryQuery, {
    variables: wrappedVariables.eventHistoryVariables,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });
  const [mappingActivitySearchDataStorage, setMappingActivitySearchDataStorage] = useLocalStorage(
    OPENSEA_LOCAL_STORAGE_KEY.ACCOUNT_ACTIVITY_DATA,
    activitySearchData
  );
  useEffect(() => {
    if (activitySearchData) {
      setMappingActivitySearchDataStorage(activitySearchData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitySearchData]);

  const handleFilterBySearchPill = (removedSearchPill: SearchPillDataType) => {
    setNewVariables(
      updateActivityVariablesBySearchPill(removedSearchPill, wrappedVariables.variables)
    );
  };

  const handleResetFilter = () => {
    setNewVariables(ACTIVITY_SEARCH_INIT_VARIABLES);
  };

  /* Lazy loading begin */
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const updateQuery = <
    TData extends object & EventHistoryQueryResponseType,
    TVariables = OperationVariables & EventHistoryQueryVariableType
  >(
    previousQueryResult: TData,
    options: {
      fetchMoreResult?: TData;
      variables?: TVariables;
    }
  ): TData => {
    if (!options.fetchMoreResult) {
      return previousQueryResult;
    }
    const newQueryResult = _.cloneDeep(options.fetchMoreResult);
    const previousEdges = previousQueryResult.assetEvents.edges;
    const newEdges = newQueryResult.assetEvents.edges;
    const allEdges = _.concat(previousEdges, newEdges);
    newQueryResult.assetEvents.edges = allEdges;
    return newQueryResult;
  };

  const fetchMoreEventHistory = async () => {
    setIsLoadingMore(true);
    await fetchMore({
      variables: {
        ...wrappedVariables.eventHistoryVariables,
        cursor: _.get(eventHistoryData, "assetEvents.pageInfo.endCursor", null)
      },
      updateQuery
    });
    setIsLoadingMore(false);
  };
  // using in ActivitySearchView component
  /* Lazy loading end */

  return (
    <>
      <ActivityGlobalContext.Provider
        value={{
          variables: wrappedVariables.variables,
          setNewVariables,
          activitySearchData: mappingActivitySearchDataStorage, // data filter
          eventHistoryData, // data content main
          activitySearchLoading,
          eventHistoryLoading,
          activitySearchError,
          eventHistoryError,
          isLoadingMore,
          fetchMoreEventHistory
        }}
      >
        <ActivityNavigation />
        <div className={styles.mainContentSearch}>
          <div>
            <AccountTabHeader />
          </div>
          <SearchPillResultList
            searchPillDatas={getActivitySearchPillDataFromR2(
              wrappedVariables.variables,
              mappingActivitySearchDataStorage
            )}
            handleFilterBySearchPill={handleFilterBySearchPill}
            handleResetFilter={handleResetFilter}
          />
          <ActivitySearchView />
        </div>
      </ActivityGlobalContext.Provider>
    </>
  );
};

export default AccountActivityPage;

import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import BigNumber from 'bignumber.js';
import moment from 'moment';

import { GET_USER_ORDERS } from '../../../../../graphql/opensea/OrderQuery';

const chain_name = process.env.REACT_APP_NETWORK_NAME;

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_PROXY_URL}${process.env.REACT_APP_OPENSEA_GRAPHQL_API}`,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
});

const useOrdersQuery = (bundleId, cursor) => {
  const [orders, setOrders] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [loading, setLoading] = useState(false);

  const { address } = useSelector(state => state.user);

  const fetchOrdersByToken = useCallback(
    async (fetchAll = false) => {
      if (address && bundleId && (fetchAll || hasNextPage)) {
        setLoading(true);

        const result = await client.query({
          query: GET_USER_ORDERS,
          variables: {
            count: 10,
            cursor: !fetchAll ? cursor : null,
            excludeMaker: null,
            isExpired: false,
            isFilled: null,
            isValid: true,
            maker: null,
            makerArchetype: null,
            makerAssetBundle: null,
            makerAssetIsPayment: true,
            sortAscending: null,
            sortBy: 'MAKER_ASSETS_USD_PRICE',
            takerArchetype: null,
            takerAssetBundle: bundleId,
            takerAssetCategories: null,
            takerAssetCollections: null,
            takerAssetIsOwnedBy: null,
            takerAssetIsPayment: null
          },
          context: {
            headers: {
              'x-api-key': process.env.OPENSEA_GRAPHQL_API_KEY,
              'x-build-id': 'eFPMdMDFI3fMUMuY2FCeF',
              'x-viewer-address': address,
              'x-viewer-chain': chain_name
            }
          }
        });

        if (result.data) {
          const data = result.data.orders.edges;

          if (data && data.length > 0) {
            setLoading(false);
            setHasNextPage(result.data.orders.pageInfo.hasNextPage);

            let updateOrders = [];
            if (!fetchAll) updateOrders = [...orders];

            setOrders([
              ...updateOrders,
              ...data.map(order => {
                let { id, oldOrder, maker, closedAt, makerAssetBundle } = order.node;
                let { payment_token_contract, current_price } = oldOrder
                  ? JSON.parse(oldOrder)
                  : {};
                let { usd_price, decimals } = oldOrder ? payment_token_contract : {};

                if (makerAssetBundle && !oldOrder) {
                  usd_price =
                    makerAssetBundle && !oldOrder
                      ? makerAssetBundle.assetQuantities.edges[0].node.asset.usdSpotPrice
                      : undefined;
                  decimals =
                    makerAssetBundle && !oldOrder
                      ? makerAssetBundle.assetQuantities.edges[0].node.asset.decimals
                      : undefined;
                  current_price =
                    makerAssetBundle && !oldOrder
                      ? makerAssetBundle.assetQuantities.edges[0].node.quantity
                      : undefined;
                }
                console.log(JSON.parse(oldOrder));
                const currentPrice = new BigNumber(current_price).div(
                  new BigNumber(10).pow(decimals)
                );
                const temp = moment.duration(moment.utc(closedAt).diff(moment.utc(new Date())));
                const inDays = Math.round(temp.asDays());
                const inHours = Math.round(temp.asHours());
                const inMinutes = Math.round(temp.asMinutes());

                const inTimeDisplay =
                  inDays > 0
                    ? `in ${inDays} days`
                    : inHours > 0
                    ? `in ${inHours} hours`
                    : inMinutes > 0
                    ? `in ${inMinutes} minutes`
                    : undefined;

                return {
                  currentPrice: currentPrice.toFixed(),
                  maker,
                  expiredIn: inTimeDisplay,
                  currentPriceToDollar: usd_price
                    ? currentPrice.multipliedBy(usd_price).toFixed(2)
                    : undefined,
                  id,
                  showCancel: maker.address.toLowerCase() === address.toLowerCase(),
                  cursor: order.cursor,
                  oldOrder: JSON.parse(oldOrder)
                };
              })
            ]);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [address, bundleId, cursor, hasNextPage]
  );

  return { orders, loading, fetchOrdersByToken };
};

export default useOrdersQuery;

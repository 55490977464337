import {
  AssetSearchQueryVariableType,
  NumericTraitsVariableType,
  StringTraitsVariableType,
  ActivitySearchQueryVariableType,
  OfferSearchQueryVariableType
} from "../../../static/type";
import _ from "lodash";

/*
    STEP4: Get a new variable, convert the variable to a query string to call the new path.
    The basic steps include:
     - For each variable type, convert to list of [key:value] 
     - Concatenate keyvalues into a query (in the same order as opensea)
     - Returns a new query string
*/

const deConvertSpecialCharacters = (input: string): string => {
  return _(input)
    .chain()
    .replace(new RegExp(" ", "g"), "%20")
    .value();
};

const joinRawRecordsToQueryString = (rawRecordDatas: string[]) => {
  if (_.isEmpty(rawRecordDatas)) {
    return "";
  }
  return deConvertSpecialCharacters(
    _(["?search"])
      .concat(
        _(rawRecordDatas)
          .chain()
          .join("&search")
          .value()
      )
      .join("")
  );
};

const convertTogglesVariablesToRawRecords = (variables: { toggles: string[] | null }): string[] => {
  let result = [] as string[];
  if (!_.isEmpty(variables.toggles)) {
    result = _(variables.toggles)
      .chain()
      .map((item, index) => {
        return `[toggles][${index}]=${_.replace(item, new RegExp(" ", "g"), "%20")}`;
      })
      .value();
  }
  return result;
};

const convertEventTypesVariablesToRawRecords = (variables: {
  eventTypes: string[] | null;
}): string[] => {
  let result = [] as string[];
  if (!_.isEmpty(variables.eventTypes)) {
    result = _(variables.eventTypes)
      .chain()
      .map((item, index) => {
        return `[eventTypes][${index}]=${_.replace(item, new RegExp(" ", "g"), "%20")}`;
      })
      .value();
  }
  return result;
};

const convertCategoriesVariablesToRawRecords = (variables: {
  categories: string[] | null;
}): string[] => {
  let result = [] as string[];
  if (!_.isEmpty(variables.categories)) {
    result = _(variables.categories)
      .chain()
      .map((item, index) => {
        return `[categories][${index}]=${item}`;
      })
      .value();
  }
  return result;
};

const convertCollectionsVariablesToRawRecords = (variables: {
  collections: string[];
}): string[] => {
  let result = [] as string[];
  if (!_.isEmpty(variables.collections)) {
    result = _(variables.collections)
      .chain()
      .map((item, index) => {
        return `[collections][${index}]=${item}`;
      })
      .value();
  }
  return result;
};

const convertPaymentAssetsVariablesToRawRecords = (variables: {
  paymentAssets: string[] | null;
}): string[] => {
  let result = [] as string[];
  if (!_.isEmpty(variables.paymentAssets)) {
    result = _(variables.paymentAssets)
      .chain()
      .map((item, index) => {
        return `[paymentAssets][${index}]=${item}`;
      })
      .value();
  }
  return result;
};

const convertResultModelVariablesToRawRecords = (variables: {
  resultModel: string | null;
}): string[] => {
  let result = [] as string[];
  if (!_.isEmpty(variables.resultModel)) {
    result = [`[resultModel]=${variables.resultModel}`];
  }
  return result;
};

const convertSortItemVariablesToRawRecords = (variables: {
  sortAscending: null | boolean;
  sortBy: null | string;
}): string[] => {
  let result = [] as string[];
  // variables.sortAscending is boolean and will have data when sortBy have
  if (!_.isEmpty(variables.sortBy)) {
    result = [`[sortAscending]=${variables.sortAscending}`, `[sortBy]=${variables.sortBy}`];
  }
  return result;
};

const convertNumbericTraitVariablesToRawRecords = (variables: {
  numericTraits: NumericTraitsVariableType[] | null;
}): string[] => {
  let result = [] as string[];
  if (!_.isEmpty(variables.numericTraits)) {
    result = _(variables.numericTraits)
      .chain()
      .map((item, index) => {
        const name = `[numericTraits][${index}][name]=${item.name}`;
        const max = `[numericTraits][${index}][ranges][0][max]=${item.ranges[0].max}`;
        const min = `[numericTraits][${index}][ranges][0][min]=${item.ranges[0].min}`;
        return _.concat(name, max, min);
      })
      .flattenDeep()
      .value();
  }
  return result;
};

const convertStringTraitVariablesToRawRecords = (variables: {
  stringTraits: StringTraitsVariableType[] | null;
}): string[] => {
  let result = [] as string[];
  if (!_.isEmpty(variables.stringTraits)) {
    result = _(variables.stringTraits)
      .chain()
      .map((item, index) => {
        const name = `[stringTraits][${index}][name]=${item.name}`;
        const values = item?.values?.map(
          (value, indexValue) => `[stringTraits][${index}][values][${indexValue}]=${value}`
        );
        return _.concat(name, values);
      })
      .flattenDeep()
      .value();
  }
  return result;
};

/**
 * Convert variables to query string.
 * Using in assets listing and account inwallet screen.
 * @param assetSearchQueryVariable main variable in this screen
 * @returns new query string
 */
export const convertAssetsVariablesToQueryString = (
  assetSearchQueryVariable: AssetSearchQueryVariableType
): string => {
  const categoriesStrings = convertCategoriesVariablesToRawRecords({
    categories: assetSearchQueryVariable.categories
  });
  const collectionsStrings = convertCollectionsVariablesToRawRecords({
    collections: assetSearchQueryVariable.collections
  });
  const numericTraitsStrings = convertNumbericTraitVariablesToRawRecords({
    numericTraits: assetSearchQueryVariable.numericTraits
  });
  const paymentAssetsStrings = convertPaymentAssetsVariablesToRawRecords({
    paymentAssets: assetSearchQueryVariable.paymentAssets
  });
  const resultModel = convertResultModelVariablesToRawRecords({
    resultModel: assetSearchQueryVariable.resultModel
  });
  const sortItem = convertSortItemVariablesToRawRecords({
    sortAscending: assetSearchQueryVariable.sortAscending,
    sortBy: assetSearchQueryVariable.sortBy
  });
  const stringTraitsStrings = convertStringTraitVariablesToRawRecords({
    stringTraits: assetSearchQueryVariable.stringTraits
  });
  const togglesStrings = convertTogglesVariablesToRawRecords({
    toggles: assetSearchQueryVariable.toggles
  });

  // note: interested in sorting concat
  const queryStringResult = joinRawRecordsToQueryString(
    _.concat(
      categoriesStrings,
      collectionsStrings,
      numericTraitsStrings,
      paymentAssetsStrings,
      resultModel,
      sortItem,
      stringTraitsStrings,
      togglesStrings
    )
  );
  return queryStringResult;
};

/**
 * Convert variables to query string.
 * Using in account activity screen.
 * @param assetSearchQueryVariable main variable in this screen
 * @returns new query string
 */
export const convertActivityVariablesToQueryString = (
  assetSearchQueryVariable: ActivitySearchQueryVariableType
): string => {
  const collectionsStrings = convertCollectionsVariablesToRawRecords({
    collections: assetSearchQueryVariable.collections
  });
  const eventTypesStrings = convertEventTypesVariablesToRawRecords({
    eventTypes: assetSearchQueryVariable.eventTypes
  });

  // note: interested in sorting concat
  const queryStringResult = joinRawRecordsToQueryString(
    _.concat(collectionsStrings, eventTypesStrings)
  );
  return queryStringResult;
};

/**
 * Convert variables to query string.
 * Using in account offers screen.
 * @param assetSearchQueryVariable main variable in this screen
 * @returns new query string
 */
export const convertOffersVariablesToQueryString = (
  assetSearchQueryVariable: OfferSearchQueryVariableType
): string => {
  const collectionsStrings = convertCollectionsVariablesToRawRecords({
    collections: assetSearchQueryVariable.collections
  });

  // note: interested in sorting concat
  const queryStringResult = joinRawRecordsToQueryString(_.concat(collectionsStrings));
  return queryStringResult;
};

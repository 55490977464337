import { useState, useCallback, useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Contract } from 'ethers';
import MasterchefJSON from '../../../abi/Masterchef.json';
import FarmingContext from '../context/FarmingContext';
import { signTransaction, getTransactionSigner } from '../../../util/transaction';
import { getMasterchefAddress } from '../../../util/getAddresses';
import { getContractInstance } from '../../../util/getContractInstance';

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

const useHarvestAction = setTxHash => {
  const [loadingHarvest, setLoadingHarvest] = useState(false);
  const { fetchDefaultFarms } = useContext(FarmingContext);
  const { isMetaMask, privateKey, address, isWC } = useSelector(state => state.user);

  const web3 = useMemo(() => window.web3, []);

  const handleSushiHarvest = useCallback(async (testPool = false, pool) => {
    const signer = await getTransactionSigner(CHAIN_ID, false, isWC);

    const masterchefAddress = getMasterchefAddress(CHAIN_ID);
    const poolId = testPool ? pool.pid : pool.id;
    const contract = new Contract(masterchefAddress, MasterchefJSON, signer);

    try {
      setLoadingHarvest(true);

      if (isMetaMask || isWC) {
        const transaction = await contract.deposit(poolId, '0');
        setTxHash(transaction.hash);
        await transaction.wait(1);
      } else if (!isMetaMask && privateKey) {
        const contract = getContractInstance(web3, MasterchefJSON, masterchefAddress);

        const data = contract.methods.deposit(poolId, '0');

        const params = {
          nonce: web3.utils.toHex(await web3.eth.getTransactionCount(address)),
          gasLimit: web3.utils.toHex(
            await data.estimateGas({
              from: address,
              value: '0x00'
            })
          ),
          gasPrice: web3.utils.toHex(await web3.eth.getGasPrice()),
          to: contract._address,
          data: data.encodeABI(),
          value: '0x00'
        };

        await signTransaction(params, privateKey, hash => setTxHash(hash));
      }

      await fetchDefaultFarms();
      setLoadingHarvest(false);
    } catch (err) {
      console.log(err.message);
      setLoadingHarvest(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [loadingHarvest, handleSushiHarvest];
};

export default useHarvestAction;

import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import BigNumber from 'bignumber.js';

const useBTCPrice = () => {
  const rate = useSelector(state => state.btcPrice);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        'https://api.etherscan.io/api?module=stats&action=ethprice&apikey=GIYZQT2DS9X6T1BYTVIDVV7W6T2IBVRX5W'
      );
      const data = await response.json();
      if (data.status === '1') {
        dispatch({
          type: 'CHANGE_BTC_PRICE',
          btcPrice: data?.result.ethbtc || 0
        });
      }
    };
    if (new BigNumber(rate).isZero()) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return rate;
};

export default useBTCPrice;

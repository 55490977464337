import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { Switch, Route, withRouter } from 'react-router-dom';

import { getConnectedExchanges, getAvailableExchanges } from '_selectors/exchanges';
import {
  connectExchange,
  connectExchangev2,
  disconnectExchange,
  resetExchangeForm
} from '_actions/exchanges.actions';
import { toast } from 'react-toastify';
import AddExchangeForm from './AddExchangeForm/AddExchangeForm';
import ConnectedExchanges from './ConnectedExchanges/ConnectedExchanges';
import encrypter from '../../../_encrypter/index.js';
import BalanceService from '../../../service_v2/BalanceService';

export class Exchanges extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMes: ''
    };
  }

  componentDidMount() {
    this.setState({ hasError: false, errorMes: '' });
  }

  clearError = () => {
    this.setState({ hasError: false, errorMes: '' });
  };
  handleDisconnect = async exchange => {
    const { account, user, disconnectExchange } = this.props;
    try {
      this.pushToSmartContract(user, exchange, '');
      disconnectExchange(exchange, account.id);
    } catch (e) {
      toast.error(e.message);
      console.log(e);
    }
  };

  handleSubmit = async values => {
    const { user, connectExchangev2, history } = this.props;
    try {
      await BalanceService.binanceApi(
        values.exchangeName,
        values.apiKey,
        values.secret
      ).fetchBalance();
    } catch (e) {
      this.setState({ hasError: true, errorMes: 'The API key is invalid.' });
      return;
    }
    this.pushToSmartContract(user, values);
    connectExchangev2(values, user.address, () => {
      history.replace('/account/exchanges');
    });
  };

  async pushToSmartContract(user, values, apiKey = null) {
    window.isSendingTransaction = true;
    try {
      if (user.isMetaMask) {
        if (apiKey === null) {
          apiKey = await encrypter.encryptByMetaMask(
            user.address,
            values.apiKey + ' ' + values.secret
          );
        }
        await encrypter.setDataContract(user.address, values.exchangeName, apiKey);
      } else {
        if (apiKey === null) {
          apiKey = await encrypter.encryptByPublicKey(
            user.publicKey,
            values.apiKey + ' ' + values.secret
          );
        }
        await encrypter.setDataContractFromPrivateKey(
          user.privateKey,
          user.address,
          values.exchangeName,
          apiKey
        );
      }
      toast.success(
        'Update Information Secret of ' + values.exchangeName + ' in smart contract success.'
      );
    } catch (e) {
      toast.error(e.message);
    }
    window.isSendingTransaction = false;
  }

  render() {
    const title = 'Exchanges';

    return (
      <div className="content">
        <Helmet>
          <title>{title} - Depo</title>
        </Helmet>

        <div className="content__body">
          <div className="content__section">
            <Switch>
              <Route
                path="/account/exchanges/add"
                render={props => (
                  <AddExchangeForm
                    {...props}
                    account={this.props.account}
                    onSubmit={this.handleSubmit}
                    clearError={this.clearError}
                    availableExchanges={this.props.availableExchanges}
                    resetExchangeForm={this.props.resetExchangeForm}
                    hasError={this.state.hasError}
                    errorMes={this.state.errorMes}
                  />
                )}
              />
              <Route
                path="/account/exchanges"
                render={props => (
                  <ConnectedExchanges
                    {...props}
                    exchanges={this.props.exchanges}
                    availableExchanges={this.props.availableExchanges}
                    onDisconnect={this.handleDisconnect}
                  />
                )}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  account: state.account,
  user: state.user,
  exchanges: getConnectedExchanges(state),
  availableExchanges: getAvailableExchanges(state)
});

const mapDispatchToProps = {
  disconnectExchange,
  connectExchange,
  connectExchangev2,
  resetExchangeForm
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Exchanges));

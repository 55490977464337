import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Shakeable } from 'components/Shakeable';
import { ErrorMessage } from 'Pages/Auth/utils';
import hdkey from 'hdkey';
import * as bip39 from 'bip39';
import {
  Button,
  Tooltip,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import { toast } from 'react-toastify';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { CSVLink } from 'react-csv';
import './stylesDialogPageRegister.scss';

export class RegisterPage extends React.Component {
  state = {
    checkedG: false,
    newsletter: false,
    openDialogPage: false,
    list_mnemonic: '',
    list_mnemonic_show: [],
    list_mnemonic_dowload: [],
    mnemonic_random: [],
    list_mnemonic_picker: [],
    active_bit: 12,
    tab: '',
    private_key: '',
    errorPicker: false,
    error: ''
  };

  renderErrors() {
    const { user } = this.props;
    let { error, errorMessage } = user.meta;

    if (errorMessage === 'Failed to fetch') {
      errorMessage = 'Sorry, the service is not available. Please try again in few minutes.';
    }

    if (error) {
      return <ErrorMessage error={errorMessage} />;
    }

    return null;
  }

  componentDidMount() {
    this.setBitMnemonic(128, 12);
  }

  async handleLogin() {
    var privateKey = this.state.private_key.trim();
    var isMetaMask = false;
    try {
      window.loginSessionWaiting = true;
      await this.props.handleSubmit({ privateKey, isMetaMask });
    } catch (e) {
      this.setState({ error: 'Can not connect Metamask.' });
    }
    window.loginSessionWaiting = false;
  }

  setBitMnemonic(bit, active) {
    const list_mnemonic = bip39.generateMnemonic(bit);
    let list_mnemonic_show = list_mnemonic.trim().split(/\s+/g);
    const root = hdkey.fromMasterSeed(Buffer.from(list_mnemonic));
    const addrnode = root.derive("m/44'/60'/0'/0/0");
    const private_key = addrnode.privateKey.toString('hex');
    const chunkSize = 3;
    const list_mnemonic_dowload = list_mnemonic_show
      .map((e, i) => {
        return i % chunkSize === 0 ? list_mnemonic_show.slice(i, i + chunkSize) : null;
      })
      .filter(e => {
        return e;
      });

    this.setState(state => ({
      list_mnemonic: list_mnemonic,
      active_bit: active,
      list_mnemonic_show: list_mnemonic_show,
      list_mnemonic_dowload: list_mnemonic_dowload,
      private_key: private_key
    }));
  }

  onRandomMnemonic = mnemonics => {
    let mnemonic_random = mnemonics.trim().split(/\s+/g);
    mnemonic_random.sort(() => Math.random() - 0.5);
    this.setState(state => ({
      mnemonic_random: mnemonic_random
    }));
  };

  handleChange(event) {
    var checked = event.target.checked;
    this.setState(state => ({
      checkedG: checked
    }));
  }

  handleOpenDialogPage = param => {
    if (param === 'create mnemonic') {
      this.onRandomMnemonic(this.state.list_mnemonic);
    }
    this.setState(state => ({
      openDialogPage: true,
      tab: param,
      list_mnemonic_picker: []
    }));
  };

  handleVerityMnemonic = () => {
    if (this.comparePicker()) {
      return;
    }

    const param = 'confirm create mnemonic';
    if (param === 'create mnemonic') {
      this.onRandomMnemonic(this.state.list_mnemonic);
    }
    this.setState(state => ({
      openDialogPage: true,
      tab: param,
      list_mnemonic_picker: []
    }));
  };

  handleCloseDialogPage = () => {
    this.setState(state => ({
      openDialogPage: false,
      tab: '',
      list_mnemonic_picker: []
    }));
  };

  onCopyText = () => {
    this.textareaPrivateKey.select();
    document.execCommand('copy');
    this.notifySuccess();
  };

  notifySuccess = () => {
    toast.success('Success', {
      position: 'top-right',
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  onPickMnemonic = param => {
    const list_mnemonic_picker_clone = this.state.list_mnemonic_picker;
    const mnemonic_random_clone = this.state.mnemonic_random;

    const removeArray = mnemonic_random_clone.filter(item => item !== param);
    list_mnemonic_picker_clone.push(param);

    this.setState(state => ({
      list_mnemonic_picker: list_mnemonic_picker_clone,
      mnemonic_random: removeArray
    }));
  };

  onRemovePickMnemonic = param => {
    const list_mnemonic_picker_clone = this.state.list_mnemonic_picker;
    const mnemonic_random_clone = this.state.mnemonic_random;

    const removeArray = list_mnemonic_picker_clone.filter(item => item !== param);
    mnemonic_random_clone.push(param);

    this.setState(state => ({
      list_mnemonic_picker: removeArray,
      mnemonic_random: mnemonic_random_clone,
      errorPicker: false
    }));
  };

  comparePicker = () => {
    var prototype = this.state.list_mnemonic_show;
    var compare = this.state.list_mnemonic_picker;

    let isMatch = true;
    for (let i = 0; i < compare.length; i++) {
      if (prototype[i] !== compare[i]) {
        isMatch = false;
        break;
      }
    }
    if (!isMatch) {
      this.setState(state => ({
        errorPicker: true
      }));
    } else {
      this.setState(state => ({
        errorPicker: false
      }));
    }
    return !isMatch;
  };

  DialogPageRegister(...props) {
    const {
      openDialogPage,
      tab,
      private_key,
      mnemonic_random,
      list_mnemonic_picker,
      errorPicker,
      list_mnemonic_show
    } = this.state;

    return (
      <Dialog
        className="DialogPageRegister__dialog"
        onClose={this.handleCloseDialogPage}
        aria-labelledby="customized-dialog-title"
        open={openDialogPage}
      >
        <DialogTitle className="DialogPageRegister__title" onClose={this.handleCloseDialogPage}>
          {tab === 'create mnemonic' && 'Create Wallet by Mnemonic'}
          {tab === 'private key' && 'View Private Key '}
          {(tab === 'confirm create mnemonic' || tab === 'access create mnemonic') &&
            'Create Wallet by Mnemonic '}
          {tab === 'access create mnemonic' ? (
            <IconButton
              className="DialogPageRegister__btn__close"
              color="inherit"
              onClick={this.handleCloseDialogPage}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          ) : (
            <IconButton
              className="DialogPageRegister__btn__close"
              color="inherit"
              onClick={this.handleCloseDialogPage}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent className="DialogPageRegister__content" dividers>
          {tab === 'create mnemonic' && (
            <>
              <div className="DialogPageRegister__dialog__createMnemonicPick">
                <div
                  className={
                    (errorPicker && list_mnemonic_picker.length > 0 ? 'error_memonic_pick' : '') +
                    ' list_random_memonic_pick'
                  }
                >
                  {list_mnemonic_picker.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="item"
                        onClick={() => this.onRemovePickMnemonic(item, index)}
                      >
                        {index + 1}. {item}
                      </div>
                    );
                  })}
                </div>
                {errorPicker && list_mnemonic_picker.length > 0 && (
                  <div className="error">Incorrect Mnemonic Phrase order. Please try again.</div>
                )}
              </div>
              <div className="DialogPageRegister__dialog__createMnemonic">
                <div className="des">Please, tap each word in the corect order</div>
                <div className="list_random_memonic">
                  {mnemonic_random.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="item"
                        onClick={() => this.onPickMnemonic(item, index)}
                      >
                        {index + 1}. {item}
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}

          {tab === 'private key' && (
            <>
              <div className="DialogPageRegister__dialog__privateKey">
                <h2 className="title">Private Key</h2>
                <div className="title_des">
                  We are showing your Private Key, please ensure that no one else is looking at your
                  screen. Back up the text below on paper and keep it somewhere secret and safe.
                </div>
                <input
                  className="value"
                  defaultValue={private_key}
                  readOnly
                  ref={textareaPrivateKey => (this.textareaPrivateKey = textareaPrivateKey)}
                />
              </div>
            </>
          )}

          {tab === 'confirm create mnemonic' && (
            <>
              <div className="DialogPageRegister__dialog__privateKey">
                <div className="title_des">
                  Are you sure you have noted down your Private Key or downloaded your Mnemonic
                  Phrase?
                </div>
              </div>
            </>
          )}

          {tab === 'access create mnemonic' && (
            <>
              <div className="DialogPageRegister__dialog__privateKey">
                <CheckCircleIcon className="icon__access__create__mnemonic" />
                <div className="title_des">You have created your wallet successfully!</div>
              </div>
            </>
          )}

          <DialogActions className="DialogPageRegister__actions">
            {tab === 'create mnemonic' && (
              <>
                <Button
                  className="DialogPageRegister__actions__verityMnemonic"
                  onClick={() => this.handleVerityMnemonic()}
                  color="primary"
                  disabled={
                    errorPicker || list_mnemonic_show.length !== list_mnemonic_picker.length
                  }
                >
                  Verity Mnemonic
                </Button>
              </>
            )}

            {tab === 'private key' && (
              <>
                <Button
                  className="DialogPageRegister__actions__copyPrivateKey"
                  onClick={() => this.onCopyText()}
                  color="primary"
                >
                  Copy
                </Button>
              </>
            )}

            {tab === 'confirm create mnemonic' && (
              <>
                <Button
                  className="DialogPageRegister__actions__confirmCreateMnemonicNo"
                  onClick={this.handleCloseDialogPage}
                  color="primary"
                >
                  Not Yet
                </Button>
                <Button
                  className="DialogPageRegister__actions__confirmCreateMnemonicYes"
                  onClick={() => this.handleOpenDialogPage('access create mnemonic')}
                  color="primary"
                >
                  Yes
                </Button>
              </>
            )}

            {tab === 'access create mnemonic' && (
              <>
                <Button
                  className="DialogPageRegister__actions__accessCreateMnemonic"
                  onClick={() => this.handleLogin()}
                  color="primary"
                >
                  Access Wallet Now !
                </Button>
              </>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }

  render() {
    const { user, submitError } = this.props;
    const { active_bit, list_mnemonic_show, checkedG, list_mnemonic_dowload } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>Get a new wallet - Moon Assist</title>
        </Helmet>

        <Shakeable shouldShake={user.meta.error || submitError}>
          <div className="PublicArea__box">
            <h2 className="PublicArea__headline">Get a new wallet</h2>
            <p className="PublicArea__headline__intro">
              Already have wallet? <Link to="/auth/login">Import your wallet</Link>
            </p>
            <div className="PublicArea__headline__des">
              <div className="PublicArea__headline__des__name">Not Recommended Method</div>
              <div className="PublicArea__headline__des__name__des">
                Create your own Mnemonic Phrase
                <Tooltip
                  classes={{ tooltip: 'tooltip_mnemonic' }}
                  title="Mnemonic phrase is a list of 12-24 words that can be used to access your wallet."
                  arrow
                  placement="top"
                >
                  <span className="PublicArea__icon__warning"></span>
                </Tooltip>
              </div>
            </div>
            <div className="PublicArea__form">
              <div className="PublicArea__mnemonic__head">
                <ul className="mnemonic__nav">
                  <Button
                    variant="contained"
                    className={(active_bit === 12 && 'is_active') + ' item'}
                    onClick={() => this.setBitMnemonic(128, 12)}
                    disabled={active_bit === 12}
                  >
                    12
                  </Button>
                  <Button
                    variant="contained"
                    className={(active_bit === 24 && 'is_active') + ' item'}
                    onClick={() => this.setBitMnemonic(256, 24)}
                    disabled={active_bit === 24}
                  >
                    24
                  </Button>
                </ul>

                <Button
                  variant="contained"
                  className="mnemonic__btn_random"
                  onClick={() => this.setBitMnemonic(active_bit === 12 ? 128 : 256, active_bit)}
                >
                  <RefreshIcon className="icon_random" />
                  Random
                </Button>
              </div>
              <div className="PublicArea__mnemonic__list">
                {list_mnemonic_show.map((item, index) => {
                  return (
                    <div key={index} className="item">
                      {index + 1}. {item}
                    </div>
                  );
                })}
              </div>
              <div className="PublicArea__mnemonic__list__btn">
                <CSVLink data={list_mnemonic_dowload} filename={'DepoWallet.csv'} className="item">
                  Download Mnemonic
                </CSVLink>
                <Button
                  variant="contained"
                  className="item lf-right"
                  onClick={() => this.handleOpenDialogPage('private key')}
                >
                  View Private Key
                </Button>
              </div>
              <div className="PublicArea__mnemonic__FormControlCheckedG">
                <Checkbox
                  checked={checkedG}
                  onChange={event => this.handleChange(event)}
                  name="checkedG"
                  className="checkedG"
                />
                <div className="txt">
                  I understand that My Wallet <span className="storage">DO NOT STORAGE</span> my
                  Private Key or my Mnemonic Phrase. I will note down my Private Key or download my
                  Mnemonic Phrase, keep them secret and responsible for wallet information. I agree
                  with{' '}
                  <Link
                    to="http://landingpage-depo.sotatek.works/terms-and-conditions/"
                    target={'_blank'}
                  >
                    Terms of Service
                  </Link>{' '}
                  and{' '}
                  <Link
                    to="http://landingpage-depo.sotatek.works/privacy-policy/"
                    target={'_blank'}
                  >
                    Privacy Policy
                  </Link>
                </div>
              </div>
              <Button
                variant="contained"
                className="PublicArea__mnemonic__btn_create"
                disabled={!checkedG}
                onClick={() => this.handleOpenDialogPage('create mnemonic')}
              >
                Create Wallet by Mnemonic Phrase
              </Button>
            </div>
          </div>

          {this.DialogPageRegister()}
        </Shakeable>
      </React.Fragment>
    );
  }
}

export default RegisterPage;

import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
  return {
    panelIsContentPadded: {
      width: "100%",
      padding: "12px 0px"
    },

    rangeFilterPanelSlider: {
      display: "block",
      margin: "auto",
      paddingLeft: 12
    },

    rangeFilterPanelRange: {
      alignItems: "center",
      display: "flex",
      marginTop: 12,
      justifyContent: "space-evenly"
    },

    rangeFilterPanelLimit: {
      backgroundColor: `rgb(255, 255, 255)`,
      border: `1px solid rgb(229, 232, 235)`,
      borderRadius: 5,
      padding: 8,
      width: `calc(50% - 15px)`,
      color: `rgb(53, 56, 64)`,
      fontSize: 12
    },

    rangeFilterPanelLimitLabel: {},

    rangeFilterPanelRangeDivider: {
      width: 30,
      textAlign: "center"
    },

    rangeFilterPanelLimitInput: {
      border: `1px solid rgb(229, 232, 235)`,
      width: "100%",
      padding: " 5px 10px",
      borderRadius: 5,
      height: 36,
      marginTop: 5
    }
  };
});

export default useStyles;

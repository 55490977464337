import { useState } from 'react';
import Info from '../../../../../assets/icons/info.svg';

import Viewer from 'react-viewer/dist/index.js';
import PlaceHolder from '../../../../../assets/placeholder.png';
import { shortenAddress } from '../../../../../util/getAddresses';

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID || 1;

const PageDetailLeft = ({ tokenDetails }) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleList, setToggleList] = useState(false);
  const [visible, setVisible] = useState(false);

  const renderDetailAvatarItemVideo = srcPath => {
    return <video src={srcPath} muted={true} loop controls autoplay="autoplay" playsinline />;
  };

  const renderDetailAvatarItemImage = srcPath => {
    return (
      <img src={srcPath} onClick={() => setVisible(true)} style={{ cursor: 'pointer' }} alt="" />
    );
  };

  /**
   * Priority level:
   * animationUrl(mp4) > imageUrl(mp4) > imageUrl(image) >
   * collectionImage > Default Image (Empty)
   * @returns
   */
  const renderDetailAvatarItem = () => {
    if (tokenDetails.animationUrl && tokenDetails.animationUrl.endsWith('.mp4')) {
      return renderDetailAvatarItemVideo(tokenDetails.animationUrl);
    }
    if (tokenDetails.imageUrl && tokenDetails.imageUrl.endsWith('.mp4')) {
      return renderDetailAvatarItemVideo(tokenDetails.imageUrl);
    }
    if (tokenDetails.imageUrl) {
      return renderDetailAvatarItemImage(tokenDetails.imageUrl);
    }
    if (tokenDetails.collection.bigImageUrl) {
      return renderDetailAvatarItemImage(tokenDetails.collection.bigImageUrl);
    }
    return renderDetailAvatarItemImage(PlaceHolder);
  };

  return (
    <div className="detail">
      <div className="detail__img-item">{renderDetailAvatarItem()}</div>
      <div className="detail__desc-title">
        <svg
          width="14"
          height="10"
          viewBox="0 0 14 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 10H14V8H0V10ZM0 0V2H14V0H0ZM0 6H14V4H0V6Z" fill="white" />
        </svg>
        Detail
      </div>
      <div className="detail__inner">
        <div className="detail__desc">
          <div className="detail__text">
            <div className="detail__user-desc">
              {tokenDetails?.userAvatar && (
                <div className="detail__avatar">
                  <img src={tokenDetails.userAvatar} alt="" />
                </div>
              )}
              {tokenDetails.creatorAddress && (
                <>
                  <span>Created By&nbsp;</span>
                  <strong>
                    {shortenAddress(tokenDetails.publicUsername || tokenDetails?.creatorAddress)}
                  </strong>
                </>
              )}
            </div>
            {tokenDetails.description && (
              <p dangerouslySetInnerHTML={{ __html: `${tokenDetails.description.text}` }} />
            )}
          </div>
          <div className="detail__panel">
            <div className="detail__intro">
              <div className="detail__title" onClick={() => setToggleMenu(!toggleMenu)}>
                <div className="detail__styletitle">
                  <img src={Info} alt="" />
                  About {tokenDetails.collection.name}
                </div>
                <div className="detail__btn-icon">
                  {toggleMenu ? (
                    <svg
                      width="10"
                      height="7"
                      viewBox="0 0 10 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.175 6.1748L5 2.35814L8.825 6.1748L10 4.9998L5 -0.000195503L0 4.9998L1.175 6.1748Z"
                        fill="#7AC231"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="10"
                      height="7"
                      viewBox="0 0 10 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.175 0L5 3.81667L8.825 0L10 1.175L5 6.175L0 1.175L1.175 0Z"
                        fill="#7AC231"
                      />
                    </svg>
                  )}
                </div>
              </div>
              <div className={'detail__intro-desc ' + (toggleMenu ? 'show_menu' : 'hidden_menu')}>
                {tokenDetails.collection.imageUrl && (
                  <div style={{ marginBottom: 20 }}>
                    <img src={tokenDetails.collection.imageUrl} alt="" />
                  </div>
                )}
                <p className="detail__text-desc">
                  {tokenDetails.collection.description ||
                    'This collection has no description yet. Contact the owner of this collection about setting it up on OpenSea!'}
                </p>
              </div>
            </div>
          </div>
          <div className="detail__panel panel-1">
            <div className="detail__intro">
              <div className="detail__title" onClick={() => setToggleList(!toggleList)}>
                <div className="detail__styletitle">
                  <img src={Info} alt="" />
                  Chain Info
                </div>
                <div className="detail__btn-icon">
                  {toggleList ? (
                    <svg
                      width="10"
                      height="7"
                      viewBox="0 0 10 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.175 6.1748L5 2.35814L8.825 6.1748L10 4.9998L5 -0.000195503L0 4.9998L1.175 6.1748Z"
                        fill="#7AC231"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="10"
                      height="7"
                      viewBox="0 0 10 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.175 0L5 3.81667L8.825 0L10 1.175L5 6.175L0 1.175L1.175 0Z"
                        fill="#7AC231"
                      />
                    </svg>
                  )}
                </div>
              </div>
              <div className={'detail__intro-desc ' + (toggleList ? 'show_list' : 'hidden_list')}>
                <div className="detail__list">
                  <div className="detail__chain-label">Contract Address</div>
                  <div className="detail__chain-info">
                    <a
                      href={`${
                        CHAIN_ID === '4'
                          ? `https://rinkeby.etherscan.io/address/${tokenDetails.chainInfo.contractAddress}`
                          : `https://etherscan.io/address/${tokenDetails.chainInfo.contractAddress}`
                      }`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {tokenDetails.chainInfo.contractAddress}
                    </a>
                  </div>
                </div>
                <div className="detail__list">
                  <div className="detail__chain-label">Token ID</div>
                  <div className="detail__chain-info">
                    {tokenDetails.chainInfo.tokenId.length > 10
                      ? `${tokenDetails.chainInfo.tokenId.substring(0, 10)}...`
                      : tokenDetails.chainInfo.tokenId}
                  </div>
                </div>
                <div className="detail__list">
                  <div className="detail__chain-label">Blockchain</div>
                  <div className="detail__chain-info">{tokenDetails.chainInfo.blockchain}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Viewer
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        images={[
          {
            src: tokenDetails.imageUrl || tokenDetails.collection.bigImageUrl || PlaceHolder
          }
        ]}
      />
    </div>
  );
};

export default PageDetailLeft;

import React, {useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './Dialog.scss';

const CustomDialog = props => {
  const [openPreConfirm, setOpenPreConfirm] = useState(false);
  const {
    title,
    isOpened,
    loading,
    handleDialogConfirm,
    handleDialogClose,
    footer,
    usePreConfirm,
    preConfirmTitle,
    preConfirmContent,
    disableCondition,
    renderButtonTextCondition
  } = props;
  const displayTitle = title || '_';

  const handleDialogSubmit = () => {
    if (!usePreConfirm) {
      handleDialogConfirm();
      return;
    }

    setOpenPreConfirm(true);
  };

  const handlePreConfirmSubmit = () => {
    handleDialogConfirm();
    setOpenPreConfirm(false);
  };

  return (
    <>
      <Dialog open={isOpened} className="Dialog" aria-labelledby="form-dialog-title">
        <DialogTitle className="Dialog__title">
          <p style={{marginBottom: 0}}>{displayTitle}</p>
          <IconButton
            aria-label="close"
            className="Dialog__icon Dialog__icon--close"
            onClick={handleDialogClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{props.children}</DialogContent>
        {handleDialogConfirm && (
          <DialogActions
            className={`${footer ? 'Dialog__actions' : ''}`}
            style={{marginBottom: `${!footer ? '35px' : '0px'}`}}
          >
            <button
              className="Dialog__btn Dialog__btn--cancel"
              onClick={handleDialogClose}
              color="primary"
            >
              Cancel
            </button>
            <button
              className="Dialog__btn Dialog__btn--confirm"
              disabled={disableCondition || loading}
              onClick={handleDialogSubmit}
            >
              {loading && <CircularProgress size={15} style={{marginRight: 5, color: 'white'}} />}
              {renderButtonTextCondition ? renderButtonTextCondition() : 'Confirm'}
            </button>
          </DialogActions>
        )}

        {footer}
      </Dialog>
      {usePreConfirm && handleDialogConfirm && (
        <Dialog open={openPreConfirm} className="Dialog" aria-labelledby="form-dialog-title">
          <DialogTitle className="Dialog__title">
            <p style={{marginBottom: 0}}>{preConfirmTitle || 'Confirm Dialog'}</p>
            <IconButton
              aria-label="close"
              className="Dialog__icon Dialog__icon--close"
              onClick={() => setOpenPreConfirm(false)}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div style={{textAlign: 'center', color: 'white', marginTop: 30, fontWeight: 600}}>
              {preConfirmContent}
            </div>
          </DialogContent>
          <DialogActions style={{marginBottom: 35}}>
            <button
              className="Dialog__btn Dialog__btn--cancel"
              onClick={() => setOpenPreConfirm(false)}
              color="primary"
            >
              Cancel
            </button>
            <button
              className="Dialog__btn Dialog__btn--confirm"
              onClick={() => handlePreConfirmSubmit()}
            >
              Confirm
            </button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default CustomDialog;

import React from 'react';
import { connect } from 'react-redux';
import { NavLink, Switch, Route, Redirect } from 'react-router-dom';

import AccountSummary from './AccountSummary/AccountSummary';
import Exchanges from './Exchanges/Exchanges';

import { getAccount } from '_actions/account.actions';

import { openExternalLink } from '_helpers/links';

import './Account.scss';
import { changeTitlePage } from '_actions/titlePage.actions';
import HeaderWarning from 'components/HeaderCommon/HeaderWarning';

const ROUTES = {
  summary: { path: '/account/summary', label: 'Overview' },
  personalData: { path: '/account/personal-data', label: 'Personal Data' },
  settings: { path: '/account/settings', label: 'Settings' },
  exchanges: { path: '/account/exchanges', label: 'Connected Exchanges' },
  security: { path: '/account/security', label: 'Security Settings' }
};

export const NavigationItem = ({ route, routeKey }) => (
  <NavLink
    to={route.path}
    className={`Account__nav-link`}
    activeClassName="Account__nav-link--is-active"
  >
    <i className={`Account__nav-link-icon Account__nav-link-icon--${routeKey}`} />
    {route.label}
  </NavLink>
);

export const Navigation = ({ routes }) => {
  return (
    <nav className="Account__nav">
      {Object.keys(routes).map(route => (
        <NavigationItem key={`nav-item-${route}`} routeKey={route} route={routes[route]} />
      ))}
    </nav>
  );
};

export const PageHelp = () => (
  <div className="Account__help">
    <strong className="Account__help-headline">Have questions?</strong>
    <p>
      {'Visit our '}
      <a href="http://landingpage-moonassist.sotatek.works/support#faq" onClick={openExternalLink}>
        FAQ's page
      </a>
      {' or contact our '}
      <a href="http://landingpage-moonassist.sotatek.works/support" onClick={openExternalLink}>
        customer support
      </a>
    </p>
  </div>
);

export class Account extends React.Component {
  componentDidMount() {
    const { user, getAccount, changeTitlePage } = this.props;
    getAccount(user.accountId, false);
    changeTitlePage('Account');
  }

  render() {
    const { user } = this.props;
    return (
      <div className="Container">
        {user.isWC && <HeaderWarning user={user} />}
        {!user.isWC && (
          <div className="Account">
            <div className="Account__info">
              <Switch>
                <Route path={ROUTES.summary.path} component={AccountSummary} />
                <Route path={ROUTES.exchanges.path} component={Exchanges} />
                <Redirect to={ROUTES.summary.path} />
              </Switch>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = {
  getAccount,
  changeTitlePage
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);

import { useState, useEffect } from 'react';
import { formatter, formatMoneyWithoutDollarSign } from '../../../util/CurrencyFormatter';
import sushiIcon from '../../../assets/sushi.png';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import FarmingTableDropdown from '../FarmingTableDropdown';
import BigNumber from 'bignumber.js';

const FarmingTableRecord = ({ pool, poolOpen, setPoolOpen, testPool = false }) => {
  const [opened, setOpened] = useState(false);

  const handleDropdownToggle = () => {
    if (!opened) {
      setPoolOpen(pool.id);
    }

    setOpened(!opened);
  };

  useEffect(() => {
    if (poolOpen && poolOpen !== pool.id) {
      setOpened(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolOpen]);

  const render = () => {
    if (testPool) {
      return (
        <tr className="Farming__tr">
          <td className="Farming__td">
            <img className="Farming__icon" src={sushiIcon} alt="pair icon" />
            {pool.lpSymbol}
          </td>
          <td className="Farming__td">
            <div className="Farming__td-flex">
              <span className="Farming__icon-wrapper">
                <img className="Farming__icon Farming__icon--sm" src={sushiIcon} alt="pair icon" />
              </span>
              <div className="Farming__td-flex-column">
                <p style={{ marginBottom: 0 }}>0.000</p>
                <p className="Farming__text">SUSHI/day</p>
              </div>
            </div>
            <p className="Farming__textbox">{pool.allocPoint.toString()} allocPoint</p>
          </td>
          <td className="Farming__td">
            <p>0,00% (1y)</p>
            <p className="Farming__text">0.00% (1m)</p>
            <p className="Farming__text">0.00% (1d)</p>
          </td>
          <td className="Farming__td">
            <p>
              {formatter.format(
                new BigNumber(pool.lpTotalInQuoteToken).times(pool.tokenPriceVsQuote).toString()
              )}
            </p>
            <p className="Farming__text">
              {formatMoneyWithoutDollarSign(formatter.format(pool.reserve0.toString()))}
              &nbsp;
              {pool.tokenSymbol}
            </p>
            <p className="Farming__text">
              {formatMoneyWithoutDollarSign(formatter.format(pool.reserve1.toString()))}
              &nbsp;
              {pool.quoteTokenSymbol}
            </p>
          </td>
          <td className="Farming__td">
            {pool.stakedLPToken && pool.stakedLPToken.toString()} LP Token
          </td>
          <td className="Farming__td">
            <div className="Farming__td-flex">
              <span className="Farming__icon-wrapper">
                <img className="Farming__icon Farming__icon--sm" src={sushiIcon} alt="pair icon" />
              </span>
              <div className="Farming__td-flex-column">
                <p style={{ marginBottom: 0 }}>{pool.pendingSushi.toFormat(3)}</p>
                <p className="Farming__text">SUSHI</p>
              </div>
            </div>
            <p className="Farming__textbox">
              {pool && (!pool.rewardDebt ? 'No rewards' : 'Rewards')}
            </p>
          </td>
          <td className="Farming__td">
            <div className="Farming__td-inner">
              <button
                className={`Farming__button-detail ${
                  !opened ? 'Farming__button-detail--close' : ''
                }`}
                onClick={handleDropdownToggle}
              >
                Details
                {opened ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </button>
            </div>
          </td>
        </tr>
      );
    }

    return (
      <tr key={pool.id} className="Farming__tr">
        <td className="Farming__td">
          <div className="Farming__td-flex">
            <img className="Farming__icon" src={sushiIcon} alt="pair icon" />
            <span style={{ width: '100%', display: 'inline-block', wordBreak: 'break-word' }}>
              {pool.token0 ? `${pool.token0.symbol}-${pool.token1.symbol}` : '_'}
            </span>
          </div>
        </td>
        <td className="Farming__td">
          <div className="Farming__td-flex">
            <span className="Farming__icon-wrapper">
              <img className="Farming__icon Farming__icon--sm" src={sushiIcon} alt="pair icon" />
            </span>
            <div className="Farming__td-flex-column">
              <p style={{ marginBottom: 0 }}>
                {pool.sushiPerDay ? pool.sushiPerDay.toFormat(3) : '_'}
              </p>
              <p className="Farming__text">SUSHI/day</p>
            </div>
          </div>
          <p className="Farming__textbox">{pool.allocPoint} allocPoint</p>
        </td>
        <td className="Farming__td">
          <p>{pool.apy ? `${new BigNumber(pool.apy).toFormat(2)}% (1y)` : '_'}</p>
          <p className="Farming__text">
            {pool.apy ? `${new BigNumber(pool.apy).div(new BigNumber(12)).toFormat(2)}% (1m)` : '_'}
          </p>
          <p className="Farming__text">
            {pool.apy
              ? `${new BigNumber(pool.apy).div(new BigNumber(365)).toFormat(2)}% (1d)`
              : '_'}
          </p>
        </td>
        <td className="Farming__td">
          <p>{pool.reserveUSD ? `$${new BigNumber(pool.reserveUSD).toFormat(3)}` : '_'}</p>
          <p className="Farming__text">
            {pool.token0
              ? `${formatMoneyWithoutDollarSign(formatter.format(pool.reserve0))} ${
                  pool.token0.symbol
                }`
              : '_'}
          </p>
          <p className="Farming__text">
            {pool.token1
              ? `${formatMoneyWithoutDollarSign(formatter.format(pool.reserve1))} ${
                  pool.token1.symbol
                }`
              : '_'}
          </p>
        </td>
        <td className="Farming__td">
          <p>
            {pool.stakedLPToken ? pool.stakedLPToken.toFormat(3) : new BigNumber(0).toFormat(3)} LP
            Token
          </p>
        </td>
        <td className="Farming__td">
          <div className="Farming__td-flex">
            <span className="Farming__icon-wrapper">
              <img className="Farming__icon Farming__icon--sm" src={sushiIcon} alt="pair icon" />
            </span>
            <div className="Farming__td-flex-column">
              <p style={{ marginBottom: 0 }}>
                {pool.pendingSushi ? pool.pendingSushi.toFormat(3) : new BigNumber(0).toFormat(3)}
              </p>
              <p className="Farming__text">SUSHI</p>
            </div>
          </div>
          <p className="Farming__textbox">
            {pool && (!pool.rewardDebt ? 'No rewards' : 'Rewards')}
          </p>
        </td>
        <td className="Farming__td" style={{ textAlign: 'right' }}>
          <div className="Farming__td-inner">
            <button
              className={`Farming__button-detail ${!opened ? 'Farming__button-detail--close' : ''}`}
              onClick={handleDropdownToggle}
            >
              Details
              {opened ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </button>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      {render()}
      {opened && <FarmingTableDropdown pool={pool} testPool={testPool} />}
    </>
  );
};

export default FarmingTableRecord;

import { FC } from "react";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import { ImageAndTextType } from "Pages/OpenSea/static/type";
import _ from "lodash";

type ActivitySearchItemProps = {
  price: string;
  assetQuantity: string;
  from: ImageAndTextType;
  to: ImageAndTextType;
  eventType: ImageAndTextType;
  timeStamp: string;
  pathImage: string;
  assetName: string;
  blockExplorerLink: string;
  assetsLink: string;
  isExpied: boolean;
  listImages: string[];
};

const ActivitySearchItem: FC<ActivitySearchItemProps> = (props): JSX.Element => {
  const styles = useStyles();
  const {
    price,
    assetQuantity,
    eventType,
    from,
    to,
    timeStamp,
    pathImage,
    assetName,
    blockExplorerLink,
    assetsLink,
    isExpied,
    listImages
  } = props;
  const renderImage = () => {
    if (pathImage && pathImage.slice(-4) === ".mp4") {
      return (
        <video
          autoPlay={false}
          muted={true}
          width="100%"
          className={styles.itemImage}
          controlsList="nodownload"
          loop={true}
          playsInline={true}
          src={pathImage}
        />
      );
    }
    if (listImages && listImages.length >= 2) {
      return (
        <>
          <img
            className={styles.itemTwoImage}
            alt=""
            src={listImages[0] ?? "/images/placeholder.png"}
          />
          <img
            className={styles.itemTwoImage}
            alt=""
            style={{ marginRight: "12px" }}
            src={listImages[1] ?? "/images/placeholder.png"}
          />
        </>
      );
    }
    if (pathImage && !_.isEmpty(pathImage)) {
      return <img className={styles.itemImage} src={pathImage} alt="" />;
    }
    return (
      <img className={`${styles.itemImage} no_image `} src={"/images/placeholder.png"} alt="" />
    );
  };

  const renderDateColumn = () => {
    if (!_.isEmpty(blockExplorerLink)) {
      return (
        <a href={blockExplorerLink} target="_blank" rel="noreferrer">
          {timeStamp}
        </a>
      );
    } else {
      return <>{timeStamp}</>;
    }
  };

  return (
    <>
      <tr className={styles.contentTable}>
        <td className={styles.contentTableTd}>
          <img className={styles.iconEventType} src={eventType?.imageUrl} alt="" />
          {` ${eventType.text}`} {isExpied && <span className={styles.expired}>Expired</span>}
        </td>
        <td className={`${styles.contentTableTd} ${styles.width300}`}>
          <Link to={assetsLink}>
            {renderImage()} <span className={styles.txtName}>{assetName}</span>
          </Link>
        </td>
        <td className={styles.contentTableTd}>
          <span>{price}</span>
        </td>
        <td className={styles.contentTableTd}>{assetQuantity}</td>
        <td className={styles.contentTableTd}>
          <img className={styles.userImage} src={from.imageUrl} alt="" /> {from.text}
        </td>
        <td className={styles.contentTableTd}>
          <img className={styles.userImage} src={to.imageUrl} alt="" /> {to.text}
        </td>
        <td className={styles.contentTableTd}>{renderDateColumn()}</td>
      </tr>
    </>
  );
};

export default ActivitySearchItem;

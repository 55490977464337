import {
  AssetSearchQueryResponseType,
  CollectionsResponseType,
  DynamicCollectionResponseType,
  DynamicNumbericItemType,
  DynamicStringItemType,
  PageInfoType,
  PaymentAssetsResponseType,
  PaymentFilterCollectionResponseType,
  SelectedCollectionsResponseType
} from "../../../static/type";
import {
  CATEGORY_FIXED_ITEMS,
  EVENT_TYPE_ITEMS,
  RESULT_MODEL_ITEMS,
  LISTING_SORT_BY_ITEMS,
  LISTING_SORT_BY_VARIABLES,
  SORT_BY_NONE_DEFAULT_ITEMS,
  FIXED_STATUS_ITEMS,
  WALLET_SORT_BY_ITEMS
} from "../constants/constants";
import _ from "lodash";
import update from "immutability-helper";
import {
  FeaturesDataType,
  AssetSearchQueryVariableType,
  ActivitySearchQueryVariableType,
  CollectionDataType,
  OfferSearchQueryVariableType,
  ActivitySearchQueryResponseType,
  OfferSearchQueryResponseType,
  CollectionsDataType,
  PaymentAssetsDataType,
  DynamicStringTraitDataType,
  DynamicListStringTraitDataType,
  DynamicRangeDataType,
  DynamicListRangeDataType,
  CategoriesDataType,
  DropDownDataType,
  SearchPillDataType,
  SEARCH_PILL_TYPE
} from "Pages/OpenSea/static/type";

/*
    STEP2: After calling graphql with variables and getting the response returned. 
    Use request and data response variables to convert to data, 
    and used them to initialize components on the screen (props data).
    Rules when converting:
    - The list of items displayed on the screen is taken from the data response or fixed
    - The list of selected items is obtained from the variables request
*/

const getFeaturesDataFromR2 = (
  selectedFeaturedSlugs: string[],
  fixedItemFeaturedData: FeaturesDataType
): FeaturesDataType => {
  return _.map(fixedItemFeaturedData, featuredItem => {
    return update(featuredItem, {
      isSelected: {
        $set: selectedFeaturedSlugs.includes(featuredItem.slug) ?? false
      }
    });
  });
};

/**
 * Get FeaturesData.
 * Using in assets listing and account inwallet screen.
 * @param variablesRequest main variables in this screen
 * @returns data init component
 */
export const getAssetsFeaturesDataFromR2 = (
  variablesRequest: AssetSearchQueryVariableType
): FeaturesDataType => {
  return getFeaturesDataFromR2(variablesRequest.toggles || [], FIXED_STATUS_ITEMS);
};

/**
 * Get FeaturesData.
 * Using in account activity screen.
 * @param variablesRequest main variables in this screen
 * @returns data init component
 */
export const getActivityFeaturesDataFromR2 = (
  variablesRequest: ActivitySearchQueryVariableType
): FeaturesDataType => {
  return getFeaturesDataFromR2(variablesRequest.eventTypes || [], EVENT_TYPE_ITEMS);
};

const convertCollectionFromEdgesData = (
  collectionsResponse: CollectionsResponseType | SelectedCollectionsResponseType
): CollectionDataType[] => {
  if (collectionsResponse) {
    return collectionsResponse.edges.map(collectionsResponse => {
      return {
        isSelected: false,
        cursor: collectionsResponse.cursor,
        assetCount: collectionsResponse.node.assetCount,
        imageUrl: collectionsResponse.node.imageUrl,
        name: collectionsResponse.node.name,
        slug: collectionsResponse.node.slug,
        id: collectionsResponse.node.id,
        __typename: collectionsResponse.node.__typename
      };
    });
  }
  return [];
};

/**
 * Get CollectionData.
 * Using in all screen:
 * assets listing, account inwallet, account activity and account offers.
 * @param variablesRequest main variables in this screen
 * @param dataResponse data response in this screen
 * @returns data init component
 */
export const getCollectionDataFromR2 = (
  variablesRequest:
    | AssetSearchQueryVariableType
    | ActivitySearchQueryVariableType
    | OfferSearchQueryVariableType,
  dataResponse:
    | AssetSearchQueryResponseType
    | ActivitySearchQueryResponseType
    | OfferSearchQueryResponseType
): CollectionsDataType => {
  const collections = convertCollectionFromEdgesData(
    _.get(dataResponse, ["query", "collections"], null) as CollectionsResponseType
  );

  // selected need get from variables, don't use in response
  const selectedCollectionSlugs = variablesRequest.collections ?? [];

  return {
    collections: _.map(collections, collection => {
      return update(collection, {
        isSelected: { $set: _.includes(selectedCollectionSlugs, collection.slug) }
      });
    }),
    pageInfo: _.get(dataResponse, ["query", "collections", "pageInfo"], {} as PageInfoType)
  };
};

const getUniquePaymentAssetsData = (listPayment: PaymentAssetsDataType): PaymentAssetsDataType => {
  if (listPayment && listPayment.length > 0) {
    const listUnique = listPayment.filter((item, index) => {
      const firstIndex = listPayment.findIndex(findItem => findItem.symbol === item.symbol);
      return firstIndex === index && !_.isNil(item.symbol) && !_.isEmpty(item.symbol);
    });
    return listUnique;
  }
  return [];
};

const convertPaymentFromPaymentFilterCollection = (
  paymentFilterCollection: PaymentFilterCollectionResponseType | null
): PaymentAssetsDataType => {
  if (paymentFilterCollection) {
    const list = _.map(paymentFilterCollection.paymentAssets, payment => {
      return {
        isSelected: false,
        id: payment.asset.id,
        symbol: payment.asset.symbol
      };
    });
    return getUniquePaymentAssetsData(list);
  }
  return [];
};

const convertPaymentFromEdgesData = (paymentResponseData: PaymentAssetsResponseType | null) => {
  if (paymentResponseData) {
    const list = paymentResponseData.edges.map(payment => {
      return {
        isSelected: false,
        id: payment.node.asset.id,
        symbol: payment.node.asset.symbol
      };
    });
    return getUniquePaymentAssetsData(list);
  }
  return [];
};

enum PAYMENTS_GET_DATA_OPTION {
  ALL,
  FROM_PAYMENTASSETS,
  FROM_PAYMENTFILTER_COLLECTION
}

const getPaymentsDataFromDataResponse = (
  dataResponse: AssetSearchQueryResponseType,
  option: PAYMENTS_GET_DATA_OPTION
): PaymentAssetsDataType => {
  let paymentsData = [] as PaymentAssetsDataType;
  switch (option) {
    case PAYMENTS_GET_DATA_OPTION.FROM_PAYMENTFILTER_COLLECTION: {
      paymentsData = convertPaymentFromPaymentFilterCollection(
        _.get(dataResponse, ["query", "PaymentFilter_collection"], null)
      );
      break;
    }
    case PAYMENTS_GET_DATA_OPTION.FROM_PAYMENTASSETS: {
      paymentsData = convertPaymentFromEdgesData(
        _.get(dataResponse, ["query", "paymentAssets"], null)
      );
      break;
    }
    case PAYMENTS_GET_DATA_OPTION.ALL: {
      const allPaymentsData = _.concat(
        convertPaymentFromPaymentFilterCollection(
          _.get(dataResponse, ["query", "PaymentFilter_collection"], null)
        ),
        convertPaymentFromEdgesData(_.get(dataResponse, ["query", "paymentAssets"], null))
      );
      paymentsData = getUniquePaymentAssetsData(allPaymentsData);
      break;
    }
    default: {
      break;
    }
  }

  return paymentsData;
};

/**
 * Get PaymentsData.
 * Using in assets listing screen and account inwallet.
 * If have one collection filter, get from PaymentFilter_collection.
 * If not, get from paymentAssets
 * @param variablesRequest main variables in this screen
 * @param dataResponse data response in this screen
 * @returns data init component
 */
export const getPaymentsDataFromR2 = (
  variablesRequest: AssetSearchQueryVariableType,
  dataResponse: AssetSearchQueryResponseType
): PaymentAssetsDataType => {
  let paymentsData = [] as PaymentAssetsDataType;
  const collection = _.get(dataResponse, ["query", "collection"], null);
  if (collection) {
    paymentsData = getPaymentsDataFromDataResponse(
      dataResponse,
      PAYMENTS_GET_DATA_OPTION.FROM_PAYMENTFILTER_COLLECTION
    );
  } else {
    paymentsData = getPaymentsDataFromDataResponse(
      dataResponse,
      PAYMENTS_GET_DATA_OPTION.FROM_PAYMENTASSETS
    );
  }

  const selectedPaymentsDataSymbols = variablesRequest.paymentAssets;

  return _.map(paymentsData, item => {
    return update(item, {
      isSelected: { $set: _.includes(selectedPaymentsDataSymbols, item.symbol) }
    });
  });
};

const convertStringTraitItemData = (
  dynamicStringItems: DynamicStringItemType[]
): DynamicStringTraitDataType[] => {
  if (dynamicStringItems) {
    return dynamicStringItems.map(item => {
      return {
        // isSelected change by request data
        isSelected: false,
        count: item.count,
        value: item.value
      };
    });
  }
  return [];
};

const convertStringTraitFromDynamicCollectionData = (
  dynamicCollectionResponse: DynamicCollectionResponseType
): DynamicListStringTraitDataType[] => {
  if (dynamicCollectionResponse && dynamicCollectionResponse.stringTraits) {
    return dynamicCollectionResponse.stringTraits.map(stringTrait => {
      return {
        key: stringTrait.key,
        stringTraits: convertStringTraitItemData(stringTrait.counts)
      };
    });
  }
  return [];
};

/**
 * Get StringTraitData.
 * Using in assets listing screen and account inwallet.
 * @param variablesRequest main variables in this screen
 * @param dataResponse data response in this screen
 * @returns data init component
 */
export const getStringTraitDataFromR2 = (
  variablesRequest: AssetSearchQueryVariableType,
  dataResponse: AssetSearchQueryResponseType
): DynamicListStringTraitDataType[] => {
  const stringTraitDatas = convertStringTraitFromDynamicCollectionData(
    _.get(dataResponse, ["query", "collection"], null) as DynamicCollectionResponseType
  );

  const selectedstringTraitDatas = variablesRequest.stringTraits ?? [];

  return _(stringTraitDatas)
    .chain()
    .map(stringTraitData => {
      const stringTraitDataCheckIndex = _.findIndex(
        selectedstringTraitDatas,
        selectedstringTraitData => selectedstringTraitData.name === stringTraitData.key
      );
      let stringTraits = stringTraitData.stringTraits;
      if (stringTraitDataCheckIndex >= 0) {
        stringTraits = _.map(stringTraits, stringTrait => {
          return update(stringTrait, {
            isSelected: {
              $set: _.includes(
                selectedstringTraitDatas[stringTraitDataCheckIndex].values,
                stringTrait.value
              )
            }
          });
        });
      }
      return update(stringTraitData, { stringTraits: { $set: stringTraits } });
    })
    .sortBy([stringTraitData => stringTraitData.key])
    .value();
};

const convertNumbericTraitItemData = (
  dynamicStringItems: DynamicNumbericItemType
): DynamicRangeDataType => {
  return {
    // isModified & currentRange change by request data
    isModified: false,
    currentRange: [dynamicStringItems.min, dynamicStringItems.max],
    min: dynamicStringItems.min,
    max: dynamicStringItems.max
  };
};

const convertNumbericTraitFromDynamicCollectionData = (
  dynamicCollectionResponse: DynamicCollectionResponseType
): DynamicListRangeDataType[] => {
  let numbericTraitDatas = [] as DynamicListRangeDataType[];
  if (dynamicCollectionResponse && dynamicCollectionResponse.numericTraits) {
    numbericTraitDatas = dynamicCollectionResponse.numericTraits.map(numericTrait => {
      return {
        key: numericTrait.key,
        numericTraits: convertNumbericTraitItemData(numericTrait.value)
      };
    });
  }
  // remove data fixed min === max
  numbericTraitDatas = _.filter(
    numbericTraitDatas,
    numbericTraitData => numbericTraitData.numericTraits.min !== numbericTraitData.numericTraits.max
  );

  return numbericTraitDatas;
};

/**
 * Get NumbericTrait.
 * Using in assets listing screen and account inwallet.
 * @param variablesRequest main variables in this screen
 * @param dataResponse data response in this screen
 * @returns data init component
 */
export const getNumbericTraitDataFromR2 = (
  variablesRequest: AssetSearchQueryVariableType,
  dataResponse: AssetSearchQueryResponseType
): DynamicListRangeDataType[] => {
  const numbericTraitDatas = convertNumbericTraitFromDynamicCollectionData(
    _.get(dataResponse, ["query", "collection"], null) as DynamicCollectionResponseType
  );

  const selectedNumbericTraitDatas = variablesRequest.numericTraits ?? [];

  return _(numbericTraitDatas)
    .chain()
    .map(numbericTraitData => {
      const numbericTraitDataCheckIndex = _.findIndex(
        selectedNumbericTraitDatas,
        selectedNumbericTraitDatas => selectedNumbericTraitDatas.name === numbericTraitData.key
      );
      let numbericTraits = numbericTraitData.numericTraits;
      if (numbericTraitDataCheckIndex >= 0) {
        const numbericTraitDataChecked = selectedNumbericTraitDatas[numbericTraitDataCheckIndex];
        const numbericTraitDataCheckedMin = numbericTraitDataChecked.ranges[0].min;
        const numbericTraitDataCheckedMax = numbericTraitDataChecked.ranges[0].max;
        if (
          numbericTraitDataCheckedMin !== numbericTraits.min ||
          numbericTraitDataCheckedMax !== numbericTraits.max
        ) {
          return update(numbericTraitData, {
            numericTraits: {
              isModified: { $set: true },
              currentRange: {
                $set: [numbericTraitDataCheckedMin, numbericTraitDataCheckedMax]
              }
            }
          });
        }
      }
      return numbericTraitData;
    })
    .sortBy([numbericTraitData => numbericTraitData.key])
    .value();
};

/**
 * Get CategoriesData.
 * Using in assets listing screen. (navigation top)
 * @param variablesRequest main variables in this screen
 * @returns data init component
 */
export const getCategoriesDataFromR2 = (
  variablesRequest: AssetSearchQueryVariableType
): CategoriesDataType => {
  const categoriesVariables = variablesRequest.categories ?? [];
  return CATEGORY_FIXED_ITEMS.map(item => {
    return update(item, { isSelected: { $set: _.includes(categoriesVariables, item.slug) } });
  });
};

/**
 * Get ResultModel.
 * Using in assets listing screen and account inwallet. (content top)
 * @param variablesRequest main variables in this screen
 * @returns data init component
 */
export const getResultModelDataFromR2 = (
  variablesRequest: AssetSearchQueryVariableType
): DropDownDataType => {
  const slugSelected = variablesRequest.resultModel ?? "ALL";
  return { slugSelected, listItems: RESULT_MODEL_ITEMS };
};

/**
 * Get SortItemData.
 * Using in assets listing screen. (content top)
 * @param variablesRequest main variables in this screen
 * @returns data init component
 */
export const getListingSortItemDataFromR2 = (
  variablesRequest: AssetSearchQueryVariableType
): DropDownDataType => {
  const currentSortItem = {
    sortAscending: variablesRequest.sortAscending,
    sortBy: variablesRequest.sortBy
  };
  const slugSelected =
    _.findKey(LISTING_SORT_BY_VARIABLES, objValue => _.isEqual(currentSortItem, objValue)) ??
    "INIT";
  return {
    slugSelected,
    listItems: slugSelected === "INIT" ? LISTING_SORT_BY_ITEMS : SORT_BY_NONE_DEFAULT_ITEMS
  };
};

/**
 * Get SortItemData.
 * Using in account inwallet. (content top)
 * @param variablesRequest main variables in this screen
 * @returns data init component
 */
export const getWalletSortItemDataFromR2 = (
  variablesRequest: AssetSearchQueryVariableType
): DropDownDataType => {
  const currentSortItem = {
    sortAscending: variablesRequest.sortAscending,
    sortBy: variablesRequest.sortBy
  };
  const slugSelected =
    _.findKey(LISTING_SORT_BY_VARIABLES, objValue => _.isEqual(currentSortItem, objValue)) ??
    "INIT";
  return {
    slugSelected,
    listItems: WALLET_SORT_BY_ITEMS
  };
};

const getSearchPillDataFromFeaturedData = (
  featuresData: FeaturesDataType
): SearchPillDataType[] => {
  return _(featuresData)
    .chain()
    .filter(item => item.isSelected)
    .map(item => {
      return {
        type: SEARCH_PILL_TYPE.FEATURES,
        text: item.name,
        value: item.slug
      };
    })
    .value();
};

const getSearchPillDataFromCollectionsData = (
  collectionsData: CollectionsDataType
): SearchPillDataType[] => {
  return _(collectionsData.collections)
    .chain()
    .filter(item => item.isSelected)
    .map(item => {
      return {
        type: SEARCH_PILL_TYPE.COLLECTION,
        text: item.name,
        value: item.slug
      };
    })
    .value();
};

const getPaymentsDataFromR2ForSearchPill = (
  variablesRequest: AssetSearchQueryVariableType,
  dataResponse: AssetSearchQueryResponseType
): PaymentAssetsDataType => {
  let paymentsData = getPaymentsDataFromDataResponse(dataResponse, PAYMENTS_GET_DATA_OPTION.ALL);

  const selectedPaymentsDataSymbols = variablesRequest.paymentAssets;

  return _.map(paymentsData, item => {
    return update(item, {
      isSelected: { $set: _.includes(selectedPaymentsDataSymbols, item.symbol) }
    });
  });
};

const getSearchPillDataFromPaymentData = (
  paymentsData: PaymentAssetsDataType
): SearchPillDataType[] => {
  return _(paymentsData)
    .chain()
    .filter(item => item.isSelected)
    .map(item => {
      return {
        type: SEARCH_PILL_TYPE.PAYMENT,
        text: item.symbol,
        value: item.symbol
      };
    })
    .value();
};

const getSearchPillDataFromStringTraitDatas = (
  stringTraitDatas: DynamicListStringTraitDataType[]
): SearchPillDataType[] => {
  return _(stringTraitDatas)
    .chain()
    .map(stringTraitData => {
      const selectedStringTraits = _.filter(
        stringTraitData.stringTraits,
        stringTrait => stringTrait.isSelected
      );
      return _.map(selectedStringTraits, selectedStringTrait => {
        return {
          type: SEARCH_PILL_TYPE.STRINGTRAIT,
          text: selectedStringTrait.value,
          value: stringTraitData.key
        };
      });
    })
    .flattenDeep()
    .value();
};

const getSearchPillDataFromNumbericTraitDatas = (
  numbericTraitDatas: DynamicListRangeDataType[]
): SearchPillDataType[] => {
  return _(numbericTraitDatas)
    .chain()
    .filter(numbericTraitData => numbericTraitData.numericTraits.isModified)
    .map(numbericTraitData => {
      const currentRange = numbericTraitData.numericTraits.currentRange;
      const name = numbericTraitData.key;
      const text = `${name}: ${currentRange[0]} - ${currentRange[1]}`;
      return {
        type: SEARCH_PILL_TYPE.STRINGTRAIT,
        text,
        value: name
      };
    })
    .value();
};

/**
 * Get SearchPillData.
 * Using in assets listing screen and account inwallet.
 * @param variablesRequest main variables in this screen
 * @param dataResponse data response in this screen
 * @returns data init component
 */
export const getAssetsSearchPillDataFromR2 = (
  variablesRequest: AssetSearchQueryVariableType,
  dataResponse: AssetSearchQueryResponseType
): SearchPillDataType[] => {
  // note: performance need fixed here
  const featuresData = getAssetsFeaturesDataFromR2(variablesRequest);
  const searchPillFeatured = getSearchPillDataFromFeaturedData(featuresData);

  const collectionsData = getCollectionDataFromR2(variablesRequest, dataResponse);
  const searchPillCollections = getSearchPillDataFromCollectionsData(collectionsData);

  // old payment will be lost when filter one colletion, so need get from all payment in data
  const paymentsData = getPaymentsDataFromR2ForSearchPill(variablesRequest, dataResponse);
  const searchPillPayment = getSearchPillDataFromPaymentData(paymentsData);

  const stringTraitDatas = getStringTraitDataFromR2(variablesRequest, dataResponse);
  const searchPillStringTrait = getSearchPillDataFromStringTraitDatas(stringTraitDatas);

  const numbericTraitDatas = getNumbericTraitDataFromR2(variablesRequest, dataResponse);
  const searchPillNumbericTrait = getSearchPillDataFromNumbericTraitDatas(numbericTraitDatas);

  // note: interested in sorting concat
  return _.concat(
    searchPillCollections,
    searchPillStringTrait,
    searchPillNumbericTrait,
    searchPillPayment,
    searchPillFeatured
  );
};

/**
 * Get SearchPillData.
 * Using in account activity screen.
 * @param variablesRequest main variables in this screen
 * @param dataResponse data response in this screen
 * @returns data init component
 */
export const getActivitySearchPillDataFromR2 = (
  variablesRequest: ActivitySearchQueryVariableType,
  dataResponse: ActivitySearchQueryResponseType
): SearchPillDataType[] => {
  // note: performance need fixed here
  const featuresData = getActivityFeaturesDataFromR2(variablesRequest);
  const searchPillFeatured = getSearchPillDataFromFeaturedData(featuresData);

  const collectionsData = getCollectionDataFromR2(variablesRequest, dataResponse);
  const searchPillCollections = getSearchPillDataFromCollectionsData(collectionsData);

  // note: interested in sorting concat
  return _.concat(searchPillCollections, searchPillFeatured);
};

/**
 * Get SearchPillData.
 * Using in account offers screen.
 * @param variablesRequest main variables in this screen
 * @param dataResponse data response in this screen
 * @returns data init component
 */
export const getOffersSearchPillDataFromR2 = (
  variablesRequest: OfferSearchQueryVariableType,
  dataResponse: OfferSearchQueryResponseType
): SearchPillDataType[] => {
  // note: performance need fixed here
  const collectionsData = getCollectionDataFromR2(variablesRequest, dataResponse);
  const searchPillCollections = getSearchPillDataFromCollectionsData(collectionsData);

  // note: interested in sorting concat
  return _.concat(searchPillCollections);
};

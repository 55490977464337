import _ from "lodash";
import {
  AssetSearchQueryResponseType,
  SearchResultAssetBundleType,
  SearchResultAssetType,
  SearchResultOrderDataType,
  SearchResultType
} from "Pages/OpenSea/static/type";

export enum ResultType {
  ASSETS,
  BUNDLE
}

export type ContentItemDataType = {
  type: ResultType;
  collectionName: string;
  pathImage: string;
  itemName: string;
  paymentQuantity: string;
  paymentSymbol: string;
  address: string;
};

export type ContentItemType = {
  cursor: string;
} & ContentItemDataType;

const EMPTY_CONTENT_ITEM: ContentItemType = {
  type: ResultType.ASSETS,
  cursor: "",
  collectionName: "no owner",
  pathImage: "/images/placeholder.png",
  itemName: "no name",
  paymentQuantity: "0",
  paymentSymbol: "",
  address: "#"
};

const parseQuantityFromOrderData = (orderData: SearchResultOrderDataType) => {
  let quantity = {
    paymentQuantity: EMPTY_CONTENT_ITEM.paymentQuantity,
    paymentSymbol: EMPTY_CONTENT_ITEM.paymentSymbol
  };
  if (orderData?.bestAsk) {
    const priceAsk = _.get(orderData, ["bestAsk", "paymentAssetQuantity", "quantity"]);
    const decimals = _.get(orderData, ["bestAsk", "paymentAssetQuantity", "asset", "decimals"]);
    const symbol = _.get(orderData, ["bestAsk", "paymentAssetQuantity", "asset", "symbol"]);
    if (priceAsk) {
      const topBid = (Number(priceAsk) / Math.pow(10, decimals)).toString();
      quantity = {
        paymentQuantity: topBid,
        paymentSymbol: symbol
      };
    }
  } else if (orderData?.bestBid) {
    const priceBid = _.get(orderData, ["bestBid", "paymentAssetQuantity", "quantity"]);
    const decimals = _.get(orderData, ["bestBid", "paymentAssetQuantity", "asset", "decimals"]);
    const symbol = _.get(orderData, ["bestBid", "paymentAssetQuantity", "asset", "symbol"]);
    if (priceBid) {
      const topBid = (Number(priceBid) / Math.pow(10, decimals)).toString();
      quantity = {
        paymentQuantity: topBid,
        paymentSymbol: symbol
      };
    }
  }
  return quantity;
};

const getCollectionFromBundle = (bundleData: SearchResultAssetBundleType) => {
  const theFirstAsset = _.get(bundleData, ["assetQuantities", "edges", 0, "node"]);
  return _.get(theFirstAsset, ["asset", "collection", "name"], EMPTY_CONTENT_ITEM.collectionName);
};

const getPathImageFromBundle = (bundleData: SearchResultAssetBundleType) => {
  const theFirstAsset = _.get(bundleData, ["assetQuantities", "edges", 0, "node"]);
  return _.get(theFirstAsset, ["asset", "imageUrl"], EMPTY_CONTENT_ITEM.pathImage);
};

const getItemNameFromBundle = (bundleData: SearchResultAssetBundleType) => {
  return _.get(bundleData, ["name"], EMPTY_CONTENT_ITEM.itemName);
};

const getPaymentQuantityFromBundle = (bundleData: SearchResultAssetBundleType) => {
  return parseQuantityFromOrderData(_.get(bundleData, ["orderData"]));
};

const getAddressFromBundle = (bundleData: SearchResultAssetBundleType) => {
  const addressBundle = _.get(bundleData, ["slug"]);
  if (addressBundle) {
    return `/bundles/${addressBundle}`;
  }
  return EMPTY_CONTENT_ITEM.address;
};

/**
 * Convert data if the item is bundle
 * @param bundleData
 * @returns
 */
const convertBundleContentItemFromSearhResult = (
  bundleData: SearchResultAssetBundleType
): ContentItemDataType => {
  const payment = getPaymentQuantityFromBundle(bundleData);
  return {
    type: ResultType.BUNDLE,
    collectionName: getCollectionFromBundle(bundleData),
    pathImage: getPathImageFromBundle(bundleData),
    itemName: getItemNameFromBundle(bundleData),
    paymentQuantity: payment.paymentQuantity,
    paymentSymbol: payment.paymentSymbol,
    address: getAddressFromBundle(bundleData)
  };
};

const getCollectionFromAsset = (assetData: SearchResultAssetType) => {
  return _.get(assetData, ["collection", "name"], EMPTY_CONTENT_ITEM.collectionName);
};

const getPathImageFromAsset = (assetData: SearchResultAssetType) => {
  let pathImage = EMPTY_CONTENT_ITEM.pathImage;
  if (assetData.imageUrl) {
    pathImage = _.get(assetData, ["imageUrl"]);
  } else if (assetData.collection.imageUrl) {
    pathImage = _.get(assetData, ["collection", "imageUrl"]);
  }
  return pathImage;
};

const getItemNameFromAsset = (assetData: SearchResultAssetType) => {
  return _.get(assetData, ["name"], EMPTY_CONTENT_ITEM.itemName);
};

const getPaymentQuantityFromAsset = (assetData: SearchResultAssetType) => {
  return parseQuantityFromOrderData(_.get(assetData, ["orderData"]));
};

const getAddressFromAsset = (assetData: SearchResultAssetType) => {
  const addressAssets = _.get(assetData, ["assetContract", "account", "address"]);
  const tokenAssets = _.get(assetData, ["tokenId"]);

  let query = "";
  const identifier = _.get(assetData, ["assetContract", "account", "chain", "identifier"]);
  if (identifier && !_.isEqual(_.toUpper(identifier), process.env.REACT_APP_NETWORK_NAME)) {
    query = `?identifier=${identifier}`;
  }

  if (addressAssets && tokenAssets) {
    return `/${addressAssets}/${tokenAssets}${query}`;
  }
  return EMPTY_CONTENT_ITEM.address;
};

/**
 * Convert data if the item is asset
 * @param assetData
 * @returns
 */
const convertAssetContentItemFromSearhResult = (
  assetData: SearchResultAssetType
): ContentItemDataType => {
  const payment = getPaymentQuantityFromAsset(assetData);
  return {
    type: ResultType.ASSETS,
    collectionName: getCollectionFromAsset(assetData),
    pathImage: getPathImageFromAsset(assetData),
    itemName: getItemNameFromAsset(assetData),
    paymentQuantity: payment.paymentQuantity,
    paymentSymbol: payment.paymentSymbol,
    address: getAddressFromAsset(assetData)
  };
};

/**
 * Item have 2 type:
 * Asset or Bundle
 * @param nodeAssetData
 * @returns
 */
const convertContentItemFromSearhResult = (
  nodeAssetData: SearchResultType
): ContentItemDataType => {
  if (nodeAssetData.asset) {
    return convertAssetContentItemFromSearhResult(nodeAssetData.asset);
  } else if (nodeAssetData.assetBundle) {
    return convertBundleContentItemFromSearhResult(nodeAssetData.assetBundle);
  }
  return EMPTY_CONTENT_ITEM;
};

/**
 * Convert data from graphql to data
 * for props component assets list.
 * Logic here is pure fuction.
 * @param data
 * @returns
 */
export const convertContentItemsFromAssetSearchResponse = (
  data: AssetSearchQueryResponseType
): ContentItemType[] => {
  const searchResults = data.query.search;
  if (searchResults && searchResults.edges) {
    return _.map(searchResults.edges, item => {
      const contentItemData = convertContentItemFromSearhResult(item.node);
      return { cursor: item.cursor, ...contentItemData };
    });
  }
  return [];
};

import _ from "lodash";
import { FC, useState, useRef, useCallback, useEffect } from "react";
import update from "immutability-helper";
import { DropDownDataType } from "Pages/OpenSea/static/type";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import useStyles from "./styles";

type DropDownItemsProps = {
  dropDownData: DropDownDataType;
  handleFilterByDropDown: (newDropDownData: DropDownDataType) => void;
};

const DropDownItems: FC<DropDownItemsProps> = (props): JSX.Element => {
  const removeIsShowAllItem = useRef<HTMLElement>(null);
  const styles = useStyles();
  const { dropDownData, handleFilterByDropDown } = props;
  const [isShowAllItem, setIsShowAllItem] = useState(false);
  const [itemSelected, setItemSelected] = useState(
    _.filter(dropDownData.listItems, dropDownItem =>
      _.isEqual(dropDownItem.slug, dropDownData.slugSelected)
    )[0]
  );
  const [listItemNotSelected, setListItemNotSelected] = useState(
    _.filter(
      dropDownData.listItems,
      dropDownItem => !_.isEqual(dropDownItem.slug, dropDownData.slugSelected)
    )
  );

  const handleItemSelectedChanged = (newSlugSelected: string) => {
    setItemSelected(
      _.filter(dropDownData.listItems, dropDownItem =>
        _.isEqual(dropDownItem.slug, newSlugSelected)
      )[0]
    );
    setListItemNotSelected(
      _.filter(
        dropDownData.listItems,
        dropDownItem => !_.isEqual(dropDownItem.slug, newSlugSelected)
      )
    );
    handleFilterByDropDown(update(dropDownData, { slugSelected: { $set: newSlugSelected } }));
  };

  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (!(removeIsShowAllItem.current! as any)?.contains(e.target)) {
        setIsShowAllItem(false);
      } else {
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [removeIsShowAllItem.current]
  );

  useEffect(() => {
    document.addEventListener("click", clickListener);
    return () => {
      document.removeEventListener("click", clickListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section
        ref={removeIsShowAllItem}
        onClick={() => {
          setIsShowAllItem(!isShowAllItem);
        }}
        className={styles.boxDropdown}
        tabIndex={-1}
      >
        <div className={styles.dropdownHeader}>
          <div>{itemSelected.name}</div>
          {isShowAllItem ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </div>
        {isShowAllItem && (
          <ul className={styles.dropdownItems}>
            {listItemNotSelected.map(optionItem => {
              return (
                <li
                  key={optionItem.slug}
                  onClick={() => {
                    handleItemSelectedChanged(optionItem.slug);
                  }}
                  className={styles.dropdownItem}
                >
                  {optionItem.name}
                </li>
              );
            })}
          </ul>
        )}
      </section>
    </>
  );
};

export default DropDownItems;

import { SearchPillDataType } from "Pages/OpenSea/static/type";
import { FC } from "react";
import SearchPillResultItem from "./SearchPillResultItem";
import useStyles from "./styles";

type SearchPillResultListProps = {
  searchPillDatas: SearchPillDataType[];
  handleFilterBySearchPill: (removedSearchPill: SearchPillDataType) => void;
  handleResetFilter: () => void;
};

const SearchPillResultList: FC<SearchPillResultListProps> = (props): JSX.Element => {
  const styles = useStyles();
  const { searchPillDatas, handleFilterBySearchPill, handleResetFilter } = props;
  return (
    <>
      <div className={styles.searchPillResultList} style={{ display: "flex" }}>
        {searchPillDatas.map(searchPillData => {
          return (
            <SearchPillResultItem
              key={searchPillData.value}
              searchPillData={searchPillData}
              onChooseItem={handleFilterBySearchPill}
            />
          );
        })}
        {searchPillDatas.length > 0 && (
          <div
            onClick={() => {
              handleResetFilter();
            }}
            className={styles.btnClearAll}
          >
            Clear all
          </div>
        )}
      </div>
    </>
  );
};

export default SearchPillResultList;

import './ModalConfirmTransection.scss';
import close_icon from '../../assets/swap/close_icon.svg';
import classNames from 'classnames';
import BigNumber from 'bignumber.js';
import truncate from 'lodash/truncate';
import Tooltip from '@material-ui/core/Tooltip';

export default function ModalConfirmTransection(props) {
  const {
    handleClose,
    title,
    iconMid,
    note,
    tokenInOut,
    renderDetail,
    onConfirm,
    statusBtn
  } = props;

  return (
    <div className="wrap-modal-confirm">
      <div className="wrap-modal-confirm__header">
        {title}
        <img src={close_icon} onClick={handleClose} alt="" />
      </div>
      <div className="wrap-modal-confirm__body">
        <div className="content_in_out">
          <div className="token_in_out">
            <Tooltip
              title={`${new BigNumber(tokenInOut[0]?.amount)?.toFixed()}`}
              placement="left"
              arrow
            >
              <div className="amount">
                {truncate(new BigNumber(tokenInOut[0]?.amount)?.toFixed(), {
                  length: 14
                })}
              </div>
            </Tooltip>

            <div className="wrap-token-name">
              <img src={tokenInOut[0]?.token?.logoURI} alt="" />
              <div className="name">{tokenInOut[0]?.token?.symbol}</div>
            </div>
          </div>

          <div className="mid-icon">
            <img src={iconMid} alt="" />
          </div>
          <div className="token_in_out">
            <Tooltip
              title={`${new BigNumber(tokenInOut[1]?.amount)?.toFixed()}`}
              placement="left"
              arrow
            >
              <div
                className={classNames(
                  {
                    'amount-warning': statusBtn === 'warning'
                  },
                  'amount'
                )}
              >
                {truncate(new BigNumber(tokenInOut[1]?.amount)?.toFixed(), {
                  length: 14
                })}
              </div>
            </Tooltip>

            <div className="wrap-token-name">
              <img src={tokenInOut[1]?.token?.logoURI} alt="" />
              <div className="name">{tokenInOut[1]?.token?.symbol}</div>
            </div>
          </div>
        </div>

        <div className="note">{note}</div>
        <div className="detail">{renderDetail()}</div>
      </div>
      <div className="wrap-modal-confirm__footer">
        <div
          className={classNames(
            {
              warning: statusBtn === 'warning'
              // disable: showAcceptChanges === true
            },
            'btn-confirm'
          )}
          onClick={onConfirm}
        >
          {statusBtn === 'warning' ? 'Swap Anyway' : 'Confirm Swap'}
        </div>
      </div>
    </div>
  );
}

import { CHANGE_TITLE_PAGE } from '_actions/titlePage.actions';

const initialState = {
  title: 'Depo'
};

const titlePage = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_TITLE_PAGE: {
      return {
        ...state,
        title: action.title
      };
    }

    default:
      return state;
  }
};

export default titlePage;

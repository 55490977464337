import { FC } from "react";
import AssetsContentView from "../../commons/AssetsContentMain";
import { OPENSEA_MESSAGE } from "../../commons/constants";
import { useListingGlobalContext } from "../context/ListingGlobalContext";
import { chunkResponseDataByCurrentPage } from "../../commons/PagingController/helper";

const ListingSearchView: FC = (): JSX.Element => {
  const {
    currentData: data,
    loading,
    error,
    currentPage,
    isLoadingMore
  } = useListingGlobalContext();

  // custom here before render AssetsContentView
  return (
    <AssetsContentView
      data={chunkResponseDataByCurrentPage(data, currentPage)}
      loading={loading}
      error={error}
      emptyMessage={OPENSEA_MESSAGE.LISTING_NO_ITEM}
      isLoadingMore={isLoadingMore}
    />
  );
};

export default ListingSearchView;

import React, { FC, useState } from "react";
import _ from "lodash";
import { FeaturesDataType } from "Pages/OpenSea/static/type";
import FeaturedItem, { CHOOSE_ITEM } from "./FeaturedItem";
import update from "immutability-helper";
import useStyles from "./styles";
import { WithBaseHeaderType, withBaseHeader } from "../../base_component/BaseHeader";

type FeaturedFilterProps = {
  featuresData: FeaturesDataType;
  handleFilterByFeatures: (selectedFeaturesData: FeaturesDataType) => void;
} & WithBaseHeaderType;

const FeaturedFilter: FC<FeaturedFilterProps> = (props): JSX.Element => {
  const styles = useStyles();
  const { handleFilterByFeatures } = props;
  const [featuresData, setFeaturesData] = useState(props.featuresData);

  const onChooseItem = (choosedId: string, action: CHOOSE_ITEM) => {
    const indexUpdate = featuresData.findIndex(feature => feature.slug === choosedId);
    if (indexUpdate >= 0) {
      const newFeaturesData = update(featuresData, {
        [indexUpdate]: { isSelected: { $set: action === CHOOSE_ITEM.CHECK } }
      });
      setFeaturesData(newFeaturesData);
      const selectedFeaturesData = _.cloneDeep(newFeaturesData).filter(
        feature => feature.isSelected === true
      );
      handleFilterByFeatures(selectedFeaturesData);
    }
  };

  return (
    <>
      <div className={styles.featuredFilterItems}>
        {featuresData.map(feature => (
          <FeaturedItem
            key={feature.slug}
            id={feature.slug}
            name={feature.name}
            onChooseItem={onChooseItem}
            isActived={feature.isSelected}
          />
        ))}
      </div>
    </>
  );
};

export default withBaseHeader(FeaturedFilter);

const axios = require('axios');

/**
 Service to look up fiat exchange rates
 https://openexchangerates.org
 https://openexchangerates.org/api/latest.json?app_id=2d0698400f234de2895041810a8b1702
 **/

function fetchRates() {
  console.log('Calling FIAT API');
  return axios
    .get(
      'https://api.coingecko.com/api/v3/coins/markets?vs_currency=btc&order=market_cap_desc&per_page=1000'
    )
    .then(res => {
      const rates = {};
      const list_rates = res.data;
      const len = list_rates.length;
      for (let i = 0; i < len; i++) {
        rates[list_rates[i].symbol.toUpperCase() + '/USD'] = 1 / list_rates[i].current_price;
      }
      return rates;
    });
}

function convert(currency, amount) {
  return fetchRates().then(rates => {
    console.log('Currency', currency, 'Rate', rates[currency], 'amount', amount);

    return (1 / rates[currency]) * amount;
  });
}

module.exports = {
  convert,
  fetchRates
};

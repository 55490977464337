import { CHOOSE_ITEM } from "../Featured/FeaturedItem";
import useStyles from "./styles";

type PaymentItemType = {
  isChecked: boolean;
  name: string;
  onChooseItem: (choosedName: string, action: CHOOSE_ITEM) => void;
};

const PaymentItem: React.FC<PaymentItemType> = (props): JSX.Element => {
  const styles = useStyles();
  const { isChecked, name, onChooseItem } = props;
  return (
    <>
      <div
        onClick={() => {
          onChooseItem(name, isChecked ? CHOOSE_ITEM.UNCHECK : CHOOSE_ITEM.CHECK);
        }}
        className={styles.paymentFilterItem}
      >
        <label className={styles.styleLable}>
          <input
            className={styles.paymentFilterCheckbox}
            checked={isChecked}
            readOnly
            type="checkbox"
          />
          <span className={`${styles.checkmark} ${isChecked && "activeCheckmark"}`}></span>
        </label>
        {name}
      </div>
    </>
  );
};

export default PaymentItem;

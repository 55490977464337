import { FC } from "react";
import { useActivityGlobalContext } from "../context/ActivityGlobalContext";
import {
  getCollectionDataFromR2,
  getActivityFeaturesDataFromR2,
  updateActivityVariablesByCollections,
  updateActivityVariablesByFeaturesData
} from "../../commons/helper";
import AssetNavigation from "../../commons/AssetsNavigation";
import { ScreenNavigationMode } from "../../commons/AssetsNavigation/AssetNavigation";
import { FeaturesDataType, CollectionDataType } from "Pages/OpenSea/static/type";

const ActivityNavigation: FC = (): JSX.Element => {
  const { variables, setNewVariables, activitySearchData } = useActivityGlobalContext();

  const handleFilterByFeatures = (selectedFeaturesData: FeaturesDataType) => {
    setNewVariables(updateActivityVariablesByFeaturesData(selectedFeaturesData, variables));
  };

  const handleFilterByCollections = (selectedCollections: CollectionDataType[]) => {
    setNewVariables(updateActivityVariablesByCollections(selectedCollections, variables));
  };

  return (
    <>
      <AssetNavigation
        screen={ScreenNavigationMode.ACTIVITY}
        featuresData={getActivityFeaturesDataFromR2(variables)}
        handleFilterByFeatures={handleFilterByFeatures}
        collectionsData={getCollectionDataFromR2(variables, activitySearchData)}
        handleFilterByCollections={handleFilterByCollections}
      />
    </>
  );
};

export default ActivityNavigation;

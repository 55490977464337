import ModalCommon from '../../../components/ModalCommon/ModalCommon';
import './ConfirmSupplyModal.scss';
import { Button } from '../../../components';
import icon_close from '../../../assets/swap/close_icon.svg';
import BigNumber from 'bignumber.js';

export default function ConfirmSupplyModal(props) {
  const {
    firstToken,
    secondToken,
    firstTokenAmount,
    secondTokenAmount,
    v2Pair,
    shareOfPool,
    isOpen,
    handleClose,
    onConfirmSupply,
    liquidityMinted,
    loading,
    slipPercent
  } = props;

  return (
    <ModalCommon open={isOpen} handleClose={() => handleClose()}>
      <div className="confirm-supply-modal">
        <div className="confirm-supply-title">
          You will Receive
          <img
            className="btn-icon-close"
            alt=""
            src={icon_close}
            onClick={() => {
              handleClose();
            }}
          />
        </div>
        <div className="confirm-supply-content">
          <div className="amount-receive">
            {(liquidityMinted && liquidityMinted.toSignificant()) || ''}
          </div>
          <div className="pool-label">
            {firstToken?.symbol}/{secondToken?.symbol} Pool Tokens
          </div>
          <div className="pool-helper">
            Output is estimated. If the price changes by more than {slipPercent ? slipPercent : 1}%
            your transaction will revert
          </div>

          <div className="pool-box-information">
            <div className="pool-box-row">
              <div className="pool-row-label">{firstToken?.symbol} Deposited</div>
              <div className="pool-row-value">
                <img alt="" src={firstToken?.logoURI} />
                <span>{firstTokenAmount}</span>
              </div>
            </div>
            <div className="pool-box-row">
              <div className="pool-row-label">{secondToken?.symbol} Deposited</div>
              <div className="pool-row-value">
                <img alt="" src={secondToken?.logoURI} />
                <span>{secondTokenAmount}</span>
              </div>
            </div>
            <div className="pool-box-row">
              <div className="pool-row-label">Rates</div>
              <div className="pool-row-value">
                <div>
                  1 {v2Pair ? v2Pair.token0.symbol : firstToken?.symbol} ={' '}
                  {v2Pair
                    ? v2Pair.token0Price.toSignificant(4)
                    : new BigNumber(firstTokenAmount).gt(0) &&
                      new BigNumber(secondTokenAmount).gt(0)
                    ? new BigNumber(secondTokenAmount).div(firstTokenAmount).toString()
                    : '---'}{' '}
                  {v2Pair ? v2Pair.token1.symbol : secondToken?.symbol}
                </div>
                <div>
                  1 {v2Pair ? v2Pair.token1.symbol : secondToken?.symbol} ={' '}
                  {v2Pair
                    ? v2Pair.token1Price.toSignificant(4)
                    : new BigNumber(firstTokenAmount).gt(0) &&
                      new BigNumber(secondTokenAmount).gt(0)
                    ? new BigNumber(firstTokenAmount).div(secondTokenAmount).toString()
                    : '---'}{' '}
                  {v2Pair ? v2Pair.token0.symbol : firstToken?.symbol}
                </div>
              </div>
            </div>
            <div className="pool-box-row">
              <div className="pool-row-label">Share of Pool</div>
              <div className="pool-row-value">
                {(shareOfPool && shareOfPool.toSignificant(4)) || 100}%
              </div>
            </div>
          </div>
          <div className="button-container">
            <Button loading={loading} onClick={() => onConfirmSupply()}>
              Confirm Supply
            </Button>
          </div>
        </div>
      </div>
    </ModalCommon>
  );
}

import React from 'react';
import ModalCommon from '../ModalCommon/ModalCommon';
import icon_close from '../../assets/swap/close_icon.svg';
import greenCheckCircle from '../../assets/icons/green-check-circle.svg';
import './ModalSuccess.scss';

export default function ModalSuccess(props) {
  return (
    <ModalCommon {...props}>
      <div className="modal-success">
        <div className="title-modal">
          Success
          <img onClick={props.handleClose} alt="" src={icon_close} />
        </div>
        <div className="modal-content">
          <div className="icon-modal">
            <img alt="" src={greenCheckCircle} />
          </div>

          <div className="message-modal">{props.message}</div>
          <div className="close-button-modal" onClick={props.handleClose}>
            <button>Close</button>
          </div>
          {props.children}
        </div>
      </div>
    </ModalCommon>
  );
}

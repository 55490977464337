import { OperationVariables, useQuery } from "@apollo/client";
import _ from "lodash";
import {
  OFFER_SEARCH_INIT_VARIABLES,
  OfferSearchQuery,
  ORDERS_INIT_VARIABLES,
  OrdersQuery
} from "Pages/OpenSea/graphql/queries";
import { FC, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import OfferSearchView from "./OffersContentMain/OfferSearchView";
import { OffersGlobalContext } from "./context/OffersGlobalContext";
import { OPENSEA_LOCAL_STORAGE_KEY } from "../commons/constants/constants";
import useStyles from "./styles";
import {
  convertOffersQueryStringToVariables,
  convertOffersVariablesToQueryString,
  getOffersSearchPillDataFromR2,
  updateOffersVariablesBySearchPill
} from "../commons/helper";
import OffersNavigation from "./OffersNavigation";
import SearchPillResultList from "../commons/filter_component/SearchPillResult";
import { useLocalStorage } from "../commons/hooks";
import AccountTabHeader from "../commons/AccountTabHeader";
import {
  SearchPillDataType,
  OrdersQueryResponseType,
  OrdersQueryVariableType
} from "Pages/OpenSea/static/type";

const AccountOffersPage: FC = (): JSX.Element => {
  const history = useHistory();
  const styles = useStyles();

  // Get data form path
  const useLocationResult = useLocation();
  const queryString = useLocationResult.search;

  // Parse url to variables
  const wrappedVariables = useMemo(() => {
    const dataFromQueryString = convertOffersQueryStringToVariables(queryString);
    const offerSearchVariables = { ...OFFER_SEARCH_INIT_VARIABLES, ...dataFromQueryString };
    const ordersVariables = {
      ...ORDERS_INIT_VARIABLES,
      takerAssetCollections: dataFromQueryString.collections
    };
    return {
      variables: offerSearchVariables,
      ordersVariables
    };
  }, [queryString]);

  const [newVariables, setNewVariables] = useState(wrappedVariables.variables);

  useEffect(() => {
    if (!_.isEqual(newVariables, wrappedVariables.variables)) {
      const queryString = convertOffersVariablesToQueryString(newVariables);
      history.push(`/opensea/account/offers${queryString}`);
      window.scrollTo(0, 0);
    }
  }, [history, newVariables, wrappedVariables]);

  const { data: offerSearchData, loading: offerSearchLoading, error: offerSearchError } = useQuery(
    OfferSearchQuery,
    {
      variables: wrappedVariables.variables,
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first"
    }
  );
  const { data: ordersData, loading: ordersLoading, error: ordersError, fetchMore } = useQuery(
    OrdersQuery,
    {
      variables: wrappedVariables.ordersVariables,
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first"
    }
  );
  const [mappingOfferSearchDataStorage, setMappingOfferSearchDataStorage] = useLocalStorage(
    OPENSEA_LOCAL_STORAGE_KEY.ACCOUNT_OFFERS_DATA,
    offerSearchData
  );
  useEffect(() => {
    if (offerSearchData) {
      setMappingOfferSearchDataStorage(offerSearchData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerSearchData]);

  const handleFilterBySearchPill = (removedSearchPill: SearchPillDataType) => {
    setNewVariables(
      updateOffersVariablesBySearchPill(removedSearchPill, wrappedVariables.variables)
    );
  };

  const handleResetFilter = () => {
    setNewVariables(OFFER_SEARCH_INIT_VARIABLES);
  };

  /* Lazy loading begin */
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const updateQuery = <
    TData extends object & OrdersQueryResponseType,
    TVariables = OperationVariables & OrdersQueryVariableType
  >(
    previousQueryResult: TData,
    options: {
      fetchMoreResult?: TData;
      variables?: TVariables;
    }
  ): TData => {
    if (!options.fetchMoreResult) {
      return previousQueryResult;
    }
    const newQueryResult = _.cloneDeep(options.fetchMoreResult);
    const previousEdges = previousQueryResult.orders.edges;
    const newEdges = newQueryResult.orders.edges;
    const allEdges = _.concat(previousEdges, newEdges);
    newQueryResult.orders.edges = allEdges;
    return newQueryResult;
  };

  const fetchMoreOrders = async () => {
    setIsLoadingMore(true);
    await fetchMore({
      variables: {
        ...wrappedVariables.ordersVariables,
        cursor: _.get(ordersData, "orders.pageInfo.endCursor", null)
      },
      updateQuery
    });
    setIsLoadingMore(false);
  };
  // using in OfferSearchView component
  /* Lazy loading end */

  return (
    <>
      <OffersGlobalContext.Provider
        value={{
          variables: wrappedVariables.variables,
          setNewVariables,
          offerSearchData: mappingOfferSearchDataStorage, // data filter
          ordersData, // data content main
          offerSearchLoading,
          ordersLoading,
          offerSearchError,
          ordersError,
          isLoadingMore,
          fetchMoreOrders
        }}
      >
        <OffersNavigation />
        <div className={styles.mainContentSearch}>
          <div>
            <AccountTabHeader />
          </div>
          <SearchPillResultList
            searchPillDatas={getOffersSearchPillDataFromR2(
              wrappedVariables.variables,
              mappingOfferSearchDataStorage
            )}
            handleFilterBySearchPill={handleFilterBySearchPill}
            handleResetFilter={handleResetFilter}
          />
          <OfferSearchView />
        </div>
      </OffersGlobalContext.Provider>
    </>
  );
};

export default AccountOffersPage;

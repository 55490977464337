import update from "immutability-helper";
import _ from "lodash";
import { OPENSEA_LOCAL_STORAGE_KEY } from "../../../constants";
import { DataHeaderProps } from "../BaseHeader";

export const getHiddenDisplayFromStorage = (
  storageHeaderDatas: DataHeaderProps[],
  defaultDataHeader: DataHeaderProps
): boolean => {
  if (storageHeaderDatas) {
    const headerDataIndex = _.findIndex(
      storageHeaderDatas,
      item => item.icon === defaultDataHeader.icon && item.title === defaultDataHeader.title
    );
    if (headerDataIndex >= 0) {
      return !!storageHeaderDatas[headerDataIndex].hiddenDisplay;
    }
  }
  return !!defaultDataHeader.hiddenDisplay;
};

export const updateStorageHeaderDatas = (
  storageHeaderDatas: DataHeaderProps[],
  defaultDataHeader: DataHeaderProps,
  isOpenNew: boolean
): DataHeaderProps[] => {
  if (storageHeaderDatas) {
    const headerDataIndex = _.findIndex(
      storageHeaderDatas,
      item => item.icon === defaultDataHeader.icon && item.title === defaultDataHeader.title
    );
    if (headerDataIndex >= 0) {
      return update(storageHeaderDatas, {
        [headerDataIndex]: { hiddenDisplay: { $set: !isOpenNew } }
      });
    } else {
      return _.concat(storageHeaderDatas, [
        {
          ...defaultDataHeader,
          hiddenDisplay: !isOpenNew
        }
      ]);
    }
  }
  return storageHeaderDatas;
};
export const clearBaseHeaderControlStorage = () => {
  window.localStorage.removeItem(OPENSEA_LOCAL_STORAGE_KEY.BASE_HEADER_CONTROL);
};

import { useState } from 'react';
import './DetailRemove.scss';
import LineDownArrow from '../../../../assets/icons/linedown-arrow.svg';
import GreenPlus from '../../../../assets/icons/green-plus.svg';
import down_icon from '../../../../assets/swap/down_icon.svg';
import ModalCommon from '../../../../components/ModalCommon/ModalCommon';
import ListToken from '../../../Swap/ListToken/ListToken';
import { useSelector } from 'react-redux';
import { getTokenSymbol } from '../../../../util/getTokenSymbol';
import { Token } from '@uniswap/sdk';
import {
  isNotValidASCIINumber,
  isPreventASCIICharacters,
  trimLeadingZerosWithDecimal
} from '../../../../util/input';
import { getPairDataForRemove } from '../../../../util/liquidity-utils';
import BigNumber from 'bignumber.js';

export default function DetailRemove(props) {
  const {
    output1token,
    output2token,
    setOutput2Token,
    setOutput1Token,
    liquidityTokenBalance,
    output1Amount,
    output2Amount,
    setOutput1Amount,
    setOutput2Amount,
    liquidityAmount,
    setSelectedPair,
    selectedPool,
    setLiquidityAmount,
    setAmountPercent,
    onChangePercent,
    setIsChangeLqAmount,
    v2Pair,
    token0Deposited,
    token1Deposited
  } = props;

  const [currentTarget, setCurrentTarget] = useState();
  const [openModalListToken, setOpenModalListToken] = useState(false);
  const user = useSelector(state => state.user);

  const token0Image = output1token ? getTokenSymbol(output1token) : undefined;
  const token1Image = output2token ? getTokenSymbol(output2token) : undefined;

  const fetchSinglePairData = async (tokenA, tokenB) => {
    if (tokenA && tokenB) {
      try {
        const data = await getPairDataForRemove(tokenA, tokenB, user, selectedPool);
        if (data) {
          setSelectedPair(data);
        }
      } catch (e) {
        setSelectedPair(null);
        console.log(e);
      }
    }
  };

  const renderModalListToken = () => {
    let modalProps = {
      open: openModalListToken,
      handleClose: () => setOpenModalListToken(false)
    };
    return (
      <ModalCommon {...modalProps}>
        <ListToken
          handleClose={modalProps.handleClose}
          user={user}
          setOutputToken={value => {
            const tokenB = new Token(
              value.chainId,
              value.address,
              value.decimals,
              value.symbol,
              value.name
            );
            tokenB.tokenInfo = { logoURI: value.logoURI };
            setOutput2Token(tokenB);
            if (output1token) {
              const isSwapToken =
                tokenB.address.toLowerCase() === output1token.address.toLowerCase();
              isSwapToken && setOutput1Token(output2token);
              fetchSinglePairData(isSwapToken ? output2token : output1token, tokenB);
            }
          }}
          setInputToken={value => {
            const tokenA = new Token(
              value.chainId,
              value.address,
              value.decimals,
              value.symbol,
              value.name
            );
            tokenA.tokenInfo = { logoURI: value.logoURI };
            setOutput1Token(tokenA);
            if (output2token) {
              const isSwapToken =
                tokenA.address.toLowerCase() === output2token.address.toLowerCase();
              isSwapToken && setOutput2Token(output1token);
              fetchSinglePairData(tokenA, isSwapToken ? output1token : output2token);
            }
          }}
          currentTarget={currentTarget}
        />
      </ModalCommon>
    );
  };

  return (
    <div className="detail-remove-liquidity">
      <div className="form-row">
        <div className="form-row-label">
          <div className="amount-label">Input</div>
          <div className="ml-auto">Balance : {liquidityTokenBalance}</div>
        </div>
        <div className="form-row-label mt-10">
          <div className="text-value">
            <input
              className="form__input"
              onKeyDown={e => isNotValidASCIINumber(e.keyCode, true) && e.preventDefault()}
              onKeyPress={e => isPreventASCIICharacters(e.key) && e.preventDefault()}
              onBlur={e => setLiquidityAmount(trimLeadingZerosWithDecimal(e.target.value))}
              value={liquidityAmount}
              onChange={e => {
                const inputVal = e.target.value;
                setIsChangeLqAmount(true);
                if (Number(inputVal) < 0) {
                  e.preventDefault();
                  return;
                }
                setLiquidityAmount(inputVal);
                if (liquidityTokenBalance > 0) {
                  const percent = new BigNumber(inputVal || 0)
                    .div(liquidityTokenBalance)
                    .times(100)
                    .toString();
                  setAmountPercent(inputVal ? percent : 0);
                  onChangePercent(percent, true);
                }
              }}
              title=""
              maxLength={255}
              type="number"
            />
          </div>
          <div className="ml-auto text-value pair-wrapper-sushi">
            {token0Image && <img alt="" src={token0Image} />}
            {token1Image && <img alt="" src={token1Image} />}
            <span>
              {output1token?.symbol ?? '---'}/{output2token?.symbol ?? '---'}
            </span>
          </div>
        </div>
      </div>
      <div className="line-down-arrow">
        <img alt="" src={LineDownArrow} />
      </div>

      <div className="form-row-sushi">
        <div className="form-row-label">
          <div className="amount-label">Output</div>
          <input
            className="form__input"
            onKeyDown={e => isNotValidASCIINumber(e.keyCode, true) && e.preventDefault()}
            onKeyPress={e => isPreventASCIICharacters(e.key) && e.preventDefault()}
            onBlur={e => setOutput1Amount(trimLeadingZerosWithDecimal(e.target.value))}
            value={output1Amount}
            onChange={e => {
              setIsChangeLqAmount(false);
              const inputVal = e.target.value;
              setOutput1Amount(inputVal);
              if (!inputVal) {
                setOutput2Amount(0);
                setLiquidityAmount(0);
                setAmountPercent(0);
                return;
              }
              if (v2Pair) {
                const percent = new BigNumber(inputVal)
                  .div(token0Deposited.toSignificant())
                  .times(100)
                  .toString();
                const amount = new BigNumber(inputVal)
                  .times(v2Pair.priceOf(output1token).toSignificant())
                  .toString();
                if (percent === '100') {
                  setOutput2Amount(token1Deposited.toSignificant());
                } else {
                  setOutput2Amount(amount);
                }
              }
              if (liquidityTokenBalance > 0) {
                const percent = new BigNumber(inputVal || 0)
                  .div(token0Deposited.toSignificant())
                  .times(100)
                  .toString();
                setAmountPercent(percent);
                setLiquidityAmount(
                  new BigNumber(liquidityTokenBalance)
                    .times(percent)
                    .div(100)
                    .toString()
                );
              }
            }}
            title=""
            maxLength={255}
            type="number"
          />
        </div>
        <div
          className="display-token-name"
          id="input-token-name"
          onClick={e => {
            setOpenModalListToken(true);
            setCurrentTarget(e.currentTarget.id);
          }}
        >
          <img src={token0Image} alt="" />
          <div>{output1token?.symbol ?? 'Select a token'}</div>
          <div className="icon">
            <img src={down_icon} alt="" />
          </div>
        </div>
      </div>

      <div className="line-down-arrow">
        <img alt="" src={GreenPlus} />
      </div>

      <div className="form-row-sushi">
        <div className="form-row-label">
          <div className="amount-label">Output</div>
          <input
            className="form__input"
            onKeyDown={e => isNotValidASCIINumber(e.keyCode, true) && e.preventDefault()}
            onKeyPress={e => isPreventASCIICharacters(e.key) && e.preventDefault()}
            onBlur={e => setOutput2Amount(trimLeadingZerosWithDecimal(e.target.value))}
            value={output2Amount}
            onChange={e => {
              setIsChangeLqAmount(false);
              const inputVal = e.target.value;
              setOutput2Amount(e.target.value);
              if (!inputVal) {
                setOutput1Amount(0);
                setLiquidityAmount(0);
                setAmountPercent(0);
                return;
              }
              if (v2Pair) {
                const percent = new BigNumber(inputVal || 0)
                  .div(token1Deposited.toSignificant())
                  .times(100)
                  .toString();
                const amount = new BigNumber(inputVal)
                  .times(v2Pair.priceOf(output2token).toSignificant())
                  .toString();
                if (percent === '100') {
                  setOutput1Amount(token0Deposited.toSignificant());
                } else {
                  setOutput1Amount(amount);
                }
              }
              if (liquidityTokenBalance > 0) {
                const percent = new BigNumber(inputVal || 0)
                  .div(token1Deposited.toSignificant())
                  .times(100)
                  .toString();
                setAmountPercent(percent);
                setLiquidityAmount(
                  new BigNumber(liquidityTokenBalance)
                    .times(percent)
                    .div(100)
                    .toString()
                );
              }
            }}
            title=""
            maxLength={255}
            type="text"
          />
        </div>
        <div
          className="display-token-name"
          onClick={e => {
            setOpenModalListToken(true);
            setCurrentTarget(e.currentTarget.id);
          }}
        >
          <img src={token1Image} alt="" />
          <div>{output2token?.symbol ?? 'Select a token'}</div>
          <div className="icon">
            <img src={down_icon} alt="" />
          </div>
        </div>
      </div>

      {renderModalListToken()}
    </div>
  );
}

import _ from "lodash";
import { FC } from "react";
import { Link } from "react-router-dom";
import EllipsisTooltip from "../EllipsisTooltip";
import useStyles from "./styles";

type AssetsContentItemProps = {
  collectionName: string;
  pathImage: string;
  assetName: string;
  paymentQuantity: string;
  paymentSymbol: string;
  address: string;
};

const AssetsContentItem: FC<AssetsContentItemProps> = (props): JSX.Element => {
  const styles = useStyles();
  const { collectionName, pathImage, assetName, paymentQuantity, paymentSymbol, address } = props;

  const renderImage = () => {
    if (pathImage && pathImage.slice(-4) === ".mp4") {
      return (
        <video
          autoPlay={true}
          muted={true}
          width="100%"
          className={styles.assetMediaVideo}
          controlsList="nodownload"
          loop={true}
          playsInline={true}
          src={pathImage}
        />
      );
    } else if (pathImage && !_.isEmpty(pathImage)) {
      return <img src={pathImage} alt="" />;
    } else {
      return <img src={"/images/placeholder.png"} alt="" />;
    }
  };

  return (
    <>
      <Link className={styles.boxItem} to={`/opensea/assets${address}`}>
        <div className={styles.containerImg}>{renderImage()}</div>
        <div className={styles.infoItem}>
          <EllipsisTooltip className={styles.assetName} text={assetName} />
          <div className={styles.desItem}>
            <EllipsisTooltip className={styles.collectionName} text={collectionName} />
            <div className={styles.priceContainer}>
              <EllipsisTooltip className={styles.priceItem} text={paymentQuantity} />
              <div className={styles.priceItem}>{paymentSymbol}</div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default AssetsContentItem;

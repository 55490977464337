import { useState, useCallback } from 'react';
import Web3 from 'web3';
import { useSelector } from 'react-redux';
import { OpenSeaPort, Network } from 'opensea-js';
import { OrderSide } from 'opensea-js/lib/types';
import { signTransaction } from '../../../../../util/transaction';
import { getContractInstance } from '../../../../../util/getContractInstance';
import WyvernExchange_ABI from '../../../../../abi/WyvernExchage.json';
import { getTransactionSignerV2 } from '../../../../../util/transaction';
import { toast } from 'react-toastify';

const INFURA_URL = process.env.REACT_APP_INFURA_URL;
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
const MAINNET_CHAIN_ID = 1;
const wyvernExchangeAddress = process.env.REACT_APP_WYVERN_EXCHANGE_ADDRESS;
const NULL_BLOCK_HASH = '0x0000000000000000000000000000000000000000000000000000000000000000';

const useNFTBuyAction = (tokenId, tokenAddress) => {
  const [loading, setLoading] = useState(false);
  const [txHash, setTxHash] = useState('');
  const { isMetaMask, privateKey, address, isWC } = useSelector(state => state.user);

  let seaport = new OpenSeaPort(window.ethereum, {
    networkName: CHAIN_ID === MAINNET_CHAIN_ID ? Network.Main : Network.Rinkeby
  });

  const handleNFTTokenPurchase = useCallback(async () => {
    try {
      setLoading(true);

      // Get Order details by tokenID and token address
      const order = await seaport.api.getOrder({
        side: OrderSide.Sell,
        asset_contract_address: tokenAddress,
        token_id: tokenId,
        sale_kind: 0
      });

      if (isMetaMask || isWC) {
        if (isWC) {
          const signer = await getTransactionSignerV2(CHAIN_ID, false, isWC);

          // eslint-disable-next-line react-hooks/exhaustive-deps
          seaport = new OpenSeaPort(signer, {
            networkName: CHAIN_ID === MAINNET_CHAIN_ID ? Network.Main : Network.Rinkeby
          });
        }
        /* Call Smart Contract to fulfill order using Metamask */
        const transactionHash = await seaport.fulfillOrder({ order, accountAddress: address });
        setTxHash(transactionHash);
        toast.success('Buy NFT Successfully!');

        setLoading(false);
      } else {
        const web3 = new Web3(INFURA_URL);
        const contract = getContractInstance(web3, WyvernExchange_ABI, wyvernExchangeAddress);
        let matchingOrder = seaport._makeMatchingOrder({
          order: order,
          accountAddress: address,
          recipientAddress: address
        });
        var utils = require('opensea-js/lib/utils/utils');
        let orderData = utils.assignOrdersToSides(order, matchingOrder);
        let buyerData = orderData.buy;
        let sellerData = orderData.sell;

        //Opensea smart contract data
        const atomicData = contract.methods.atomicMatch_(
          [
            buyerData.exchange,
            buyerData.maker,
            buyerData.taker,
            buyerData.feeRecipient,
            buyerData.target,
            buyerData.staticTarget,
            buyerData.paymentToken,
            sellerData.exchange,
            sellerData.maker,
            sellerData.taker,
            sellerData.feeRecipient,
            sellerData.target,
            sellerData.staticTarget,
            sellerData.paymentToken
          ],
          [
            buyerData.makerRelayerFee.toFixed(),
            buyerData.takerRelayerFee.toFixed(),
            buyerData.makerProtocolFee.toFixed(),
            buyerData.takerProtocolFee.toFixed(),
            buyerData.basePrice.toFixed(),
            buyerData.extra.toFixed(),
            buyerData.listingTime.toFixed(),
            buyerData.expirationTime.toFixed(),
            buyerData.salt.toFixed(),
            sellerData.makerRelayerFee.toFixed(),
            sellerData.takerRelayerFee.toFixed(),
            sellerData.makerProtocolFee.toFixed(),
            sellerData.takerProtocolFee.toFixed(),
            sellerData.basePrice.toFixed(),
            sellerData.extra.toFixed(),
            sellerData.listingTime.toFixed(),
            sellerData.expirationTime.toFixed(),
            sellerData.salt.toFixed()
          ],
          [
            buyerData.feeMethod,
            buyerData.side,
            buyerData.saleKind,
            buyerData.howToCall,
            sellerData.feeMethod,
            sellerData.side,
            sellerData.saleKind,
            sellerData.howToCall
          ],
          buyerData.calldata,
          sellerData.calldata,
          buyerData.replacementPattern,
          sellerData.replacementPattern,
          buyerData.staticExtradata,
          sellerData.staticExtradata,
          [buyerData.v || 0, sellerData.v || 0],
          [
            buyerData.r || NULL_BLOCK_HASH,
            buyerData.s || NULL_BLOCK_HASH,
            sellerData.r || NULL_BLOCK_HASH,
            sellerData.s || NULL_BLOCK_HASH,
            '0x0000000000000000000000000000000000000000000000000000000000000000'
          ]
        );

        //Transaction data
        const txParams = {
          nonce: web3.utils.toHex(await window.web3.eth.getTransactionCount(address)),
          gasLimit: web3.utils.toHex(
            await atomicData.estimateGas({
              from: address,
              value: web3.utils.toHex(order.currentPrice.toFixed())
            })
          ),
          gasPrice: web3.utils.toHex(await window.web3.eth.getGasPrice()),
          to: wyvernExchangeAddress,
          data: atomicData.encodeABI(),
          value: web3.utils.toHex(order.currentPrice.toFixed()) // 0
        };

        await signTransaction(txParams, privateKey, hash => {
          console.log(hash);
          setTxHash(hash);
        });
        setLoading(false);
        toast.success('Buy NFT Successfully!');
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  }, [tokenId, tokenAddress]);

  return { loading, handleNFTTokenPurchase, txHash, setTxHash };
};

export default useNFTBuyAction;

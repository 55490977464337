import RouterV2 from './uni/RouterV2.json';
import ERC20 from './uni/ERC20.json';
import intermediaryTokens, { AMPL, DAI } from './uni/IntermediaryTokens';
import { ChainId, WETH, JSBI, Percent } from '@sushiswap/sdk';

const settingTransaction = localStorage.getItem('setting_transaction')
  ? JSON.parse(localStorage.getItem('setting_transaction'))
  : null;

export const WETH_TOKEN = process.env.REACT_APP_TOKEN_USE_FOR_PURCHASE; //rinkeby

export const USER_ADDRESS = '0x31D7Cf18075A790B47021c8C3Cc22EDC7035Eec8';
export const SMARTCONTRACT_ADDRESS = '0x7a250d5630b4cf539739df2c5dacb4c659f2488d';
export const PRIVATE_KEY = '51fe06e0e7e6114a9bf77b3ce4f4a9759993f19a42f2a826309c73ca60582fa8';

export const WBTC_CONTRACT_ADDRESS = '0x577d296678535e4903d59a4c929b718e1d575e0a'; //rinkeby
export const UNISWAP_FACTORY_SM_ADDRESS = '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f';
export const ROUTER_UNISWAP_SM_ADDRESS = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';

export const FACTORY_CONTRACT_ADDRESS = process.env.REACT_APP_FACTORY_CONTRACT_ADDRESS;
export const UNISWAP_FACTORY_ADDRESS = process.env.REACT_APP_UNISWAP_FACTORY_ADDRESS;

export const INFURA = process.env.REACT_APP_INFURA_URL;
export const INFURA_KEY = process.env.REACT_APP_INFURA_KEY;
export const ROUTE_V2_ADDR = process.env.REACT_APP_ROUTE_V2_ADDR; // Smart contract address
export const ROUTE_V2_ABI = RouterV2.abi;
export const ERC20_ABI = ERC20.abi;

export const CHAIN = process.env.REACT_APP_NETWORK_NAME.toLowerCase();
export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(50, 10000);
export const MAX_HOPS = 3;
export const ALLOW_MULTIPLE_HOPS = settingTransaction ? settingTransaction.checkedMultihop : true;

export const LOWEST_PRICE_IMPACT = new Percent(JSBI.BigInt(30), JSBI.BigInt(10000));
export const BASE_FEE = new Percent(JSBI.BigInt(30), JSBI.BigInt(10000));
export const ONE_HUNDRED_PERCENT = new Percent(JSBI.BigInt(10000), JSBI.BigInt(10000));
export const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE);

export const WETH_ONLY = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.RINKEBY]: [WETH[ChainId.RINKEBY]],
  [ChainId.ROPSTEN]: [WETH[ChainId.ROPSTEN]],
  [ChainId.GÖRLI]: [WETH[ChainId.GÖRLI]],
  [ChainId.KOVAN]: [WETH[ChainId.KOVAN]]
};

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], ...Object.values(intermediaryTokens)]
};

export const CUSTOM_BASES = {
  [ChainId.MAINNET]: {
    [AMPL.address]: [DAI, WETH[ChainId.MAINNET]]
  }
};

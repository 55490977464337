import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { Contract, Provider } from 'ethers-multicall';
import ERC20_ABI from '../../../abi/ERC20.json';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';

const INFURA_KEY = process.env.INFURA_KEY;
const NETWORK_NAME = process.env.REACT_APP_NETWORK_NAME;

const useListTokenBalance = (setDataBalance, listToken, reloadToken, setReloadToken) => {
  const [listTokenBalance, setListTokenBalance] = useState();
  const user = useSelector(state => state.user);
  useEffect(() => {
    const getDataBalance = async () => {
      const provider = new ethers.providers.InfuraProvider(NETWORK_NAME.toLowerCase(), INFURA_KEY);
      const ethcallProvider = new Provider(provider);
      await ethcallProvider.init();

      const balanceListToken = listToken.map(e => {
        const contract = new Contract(e.contractAddress, ERC20_ABI.abi);
        return contract.balanceOf(user.address);
      });
      const pairsBalanceResult = await ethcallProvider.all(balanceListToken);

      setListTokenBalance(
        pairsBalanceResult.map((e, index) => {
          const token = {};
          token.address = listToken[index].contractAddress;
          token.balance = e / 10 ** listToken[index].decimals;
          return token;
        })
      );

      setDataBalance(
        pairsBalanceResult.map((e, index) => {
          const token = {};
          token[listToken[index].contractAddress] = new BigNumber(e).toFixed();
          return token;
        })
      );

      setReloadToken(false);
    };
    reloadToken && listToken.length > 0 && getDataBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listToken, reloadToken]);

  return listTokenBalance;
};

export default useListTokenBalance;

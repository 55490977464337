import { FC } from "react";
import DropDownItems from "../../commons/filter_component/Dropdown";
import { useWalletGlobalContext } from "../context/WalletGlobalContext";
import { WALLET_ASSET_SEARCH_INIT_VARIABLES } from "Pages/OpenSea/graphql/queries";
import SearchPillResultList from "../../commons/filter_component/SearchPillResult/SearchPillResultList";
import useStyles from "./styles";
import {
  updateAssetsVariablesBySearchPill,
  getAssetsSearchPillDataFromR2,
  getResultModelDataFromR2,
  updateAssetsVariablesByResultModel,
  getWalletSortItemDataFromR2,
  updateWalletAssetsVariablesBySortItem
} from "../../commons/helper";
import { SearchPillDataType, DropDownDataType } from "Pages/OpenSea/static/type";
import PagingController from "../../commons/PagingController";
import {
  getTotalCurrentPageFromDataResponse,
  getCanFetchMoreDataFromDataResponse
} from "../../commons/PagingController/helper";

const WalletContentTop: FC = (): JSX.Element => {
  const styles = useStyles();
  const {
    mappingDataStorage: data,
    variables,
    setNewVariables,
    currentPage,
    loading,
    isLoadingMore,
    handleChangedPage
  } = useWalletGlobalContext();

  const handleFilterBySearchPill = (removedSearchPill: SearchPillDataType) => {
    setNewVariables(updateAssetsVariablesBySearchPill(removedSearchPill, variables));
  };

  const handleResetFilter = () => {
    setNewVariables(WALLET_ASSET_SEARCH_INIT_VARIABLES);
  };

  return (
    <>
      <div className={styles.assetSearchViewResultsHeader}>
        <SearchPillResultList
          searchPillDatas={getAssetsSearchPillDataFromR2(variables, data)}
          handleFilterBySearchPill={handleFilterBySearchPill}
          handleResetFilter={handleResetFilter}
        />
        <div className={styles.assetSearchViewResultsHeaderDropdowns}>
          <PagingController
            currentPage={currentPage}
            isLoadingData={isLoadingMore || loading}
            totalcurrentPage={getTotalCurrentPageFromDataResponse(data)}
            canFetchMoreData={getCanFetchMoreDataFromDataResponse(data)}
            handleChangedPage={handleChangedPage}
          />
          <DropDownItems
            dropDownData={getResultModelDataFromR2(variables)}
            handleFilterByDropDown={(newDropDownData: DropDownDataType) => {
              setNewVariables(updateAssetsVariablesByResultModel(newDropDownData, variables));
            }}
          />
          <DropDownItems
            dropDownData={getWalletSortItemDataFromR2(variables)}
            handleFilterByDropDown={(newDropDownData: DropDownDataType) => {
              setNewVariables(updateWalletAssetsVariablesBySortItem(newDropDownData, variables));
            }}
          />
        </div>
      </div>
    </>
  );
};

export default WalletContentTop;

import { ApolloError } from "@apollo/client";
import {
  OfferSearchQueryVariableType,
  OfferSearchQueryResponseType,
  OrdersQueryResponseType
} from "Pages/OpenSea/static/type";
import { createContext, useContext } from "react";

export type OffersGlobalContent = {
  variables: OfferSearchQueryVariableType; // just follow main variables
  setNewVariables: (variables: OfferSearchQueryVariableType) => void;
  offerSearchData: OfferSearchQueryResponseType; // mapping storage, use for filter data
  ordersData: OrdersQueryResponseType; // data content main
  offerSearchLoading: boolean;
  ordersLoading: boolean;
  offerSearchError: ApolloError | undefined;
  ordersError: ApolloError | undefined;
  isLoadingMore: boolean;
  fetchMoreOrders: () => Promise<void>;
};
export const OffersGlobalContext = createContext<OffersGlobalContent>({} as OffersGlobalContent);
export const useOffersGlobalContext = () => useContext(OffersGlobalContext);

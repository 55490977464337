import { useEffect } from 'react';

const useLoadWhenScroll = (elementRef, enableScrollCondition, scrollHandler) => {
  useEffect(() => {
    elementRef &&
      elementRef.current &&
      enableScrollCondition &&
      elementRef.current.addEventListener('scroll', scrollHandler);
  }, [elementRef, enableScrollCondition, scrollHandler]);

  return () => {
    elementRef && elementRef.current.removeEventListener('scroll', scrollHandler);
  };
};

export default useLoadWhenScroll;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Page from 'components/Page/Page';
import { Container } from 'components/GridSystem/GridSystem';
import { changeTitlePage } from '_actions/titlePage.actions';
import { connect } from 'react-redux';
import HeaderCommon from '../../components/HeaderCommon/HeaderCommon';
import BodySwap from './BodySwap/BodySwap';
import Web3 from 'web3';
import { getDefaultListToken, INFURA } from './uni/const';
import { save_balance_eth } from '../../_actions/swap.action';

let web3 = new Web3(new Web3.providers.HttpProvider(INFURA));

function SwapPage(props) {
  const dispatch = useDispatch();
  const { user } = props;
  const [, setLoading] = useState(false);
  const [listDataToken, setListDataToken] = useState([]);

  const [transactionSuccess, setTransactionSuccess] = useState(false);

  console.log('transactionSuccess', transactionSuccess);

  useEffect(() => {
    props.changeTitlePage('Swap');
    const fetchData = async () => {
      try {
        setLoading(true);
        let chain_id = await new web3.eth.net.getId();
        const listDataToken = getDefaultListToken(chain_id);

        setListDataToken(listDataToken);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchDataToken = async () => {
      let balanceCoin = await new web3.eth.getBalance(user.address);
      let balanceCoinConvert = web3.utils.fromWei(balanceCoin);

      dispatch(save_balance_eth(Number(balanceCoinConvert)));
    };
    fetchDataToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionSuccess]);

  return (
    <Page title="SWAP" className="Swap-page">
      <Container>
        <HeaderCommon user={user} />
        <BodySwap
          user={user}
          dataTokenETH={listDataToken[0]}
          setTransactionSuccess={setTransactionSuccess}
        />
      </Container>
    </Page>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};
const mapDispatchToProps = dispatch => {
  return {
    changeTitlePage: title => dispatch(changeTitlePage(title))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SwapPage);

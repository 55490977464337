import BaseService from './BaseService';
import exchangeLookup from '../exchange/exchange-lookup';

const BITTREX_INTERVALS = ['1m', '5m', '30m', '1h', '1d'];

class CandlestickService extends BaseService {
  validate(event) {
    const errors = [];

    switch (event.exchange) {
      case 'bittrex':
        if (!BITTREX_INTERVALS.includes(event.interval)) {
          errors.push('Invalid interval, valid values are ', BITTREX_INTERVALS);
        }
        break;

      default:
    }

    return errors;
  }

  // It seems CCXT library feature since is not working correctly -- GDAX
  // TODO check performance of this filter for larger datasets
  filter(candleSticks, since) {
    if (!since) {
      return candleSticks;
    }

    return candleSticks.filter(candle => {
      return candle[0] >= since;
    });
  }

  addDate(candleSticks) {
    candleSticks.forEach(candle => {
      const date = new Date(candle[0]);
      candle.push(date);
    });
  }

  getHashForEvent(event) {
    return event.exchange + '_' + event.symbol + '_' + event.interval + '_' + event.since;
  }

  async getCandlestick(exchangeName, symbol, interval, since) {
    exchangeName = exchangeName.toLowerCase();
    let candleSticks = await exchangeLookup[exchangeName].fetchOHLCV(symbol, interval);
    candleSticks = this.filter(candleSticks, since);
    this.addDate(candleSticks);

    const result = {
      exchange: exchangeName.toLowerCase(),
      candleSticks: candleSticks
    };
    return result;
  }
}

export default CandlestickService.getInstance();

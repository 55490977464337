import React, {useRef, useState} from 'react';
import {Row, Col} from '../GridSystem/GridSystem';
import copy from '../../assets/swap/copy.svg';
import more_dote from '../../assets/swap/more_dot.svg';
import switch_icon_small from '../../assets/swap/switch_icon_small.svg';
import QRcodeIcon from '../../assets/qr-code-icon.svg';
import {INFURA} from '../../Pages/Swap/uni/const';
import Web3 from 'web3';
import './HeaderCommon.scss';
import {toast} from 'react-toastify';
import Spinner from '../Spinner';
import ModalCommon from '../ModalCommon/ModalCommon';
import QRCode from 'qrcode.react';
import {formatCryptocurrency} from '../../_helpers';
import useBTCPrice from './hooks/useBTCPrice';
import BigNumber from 'bignumber.js';
import icon_close from '../../assets/swap/close_icon.svg';

let web3 = new Web3(new Web3.providers.HttpProvider(INFURA));

export default function HeaderCommon({user, balance = 0, loadingBalance = false, fetchData}) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalQRCode, setModalQRCode] = useState(false);
  const rateBTC = useBTCPrice();
  const addressTextArea = useRef(null);
  const addressQrcode = useRef(null);

  const notifySuccess = () => {
    toast.success('Success', {
      position: 'top-right',
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  const onCopyAddress = () => {
    addressTextArea.current.select();
    document.execCommand('copy');
    notifySuccess();
  };

  const onCopyAddressQrcode = () => {
    addressQrcode.current.select();
    document.execCommand('copy');
    notifySuccess();
  };

  return (
    <Row className="header">
      <Col size="6" className="header__address">
        <Row>
          <Col size="11" className="left">
            <img src="https://i.imgur.com/ovvlncy.jpg" alt="" />
            <div className="content">
              <p>Address</p>
              <input ref={addressTextArea} value={user.address} readOnly />
            </div>
          </Col>
          <Col size="1" className="right">
            <div onClick={() => onCopyAddress()} className="top">
              <img src={copy} alt="" />
            </div>
            <div onClick={() => setModalQRCode(true)} className="bottom">
              <img alt="" src={QRcodeIcon} />
            </div>
          </Col>
        </Row>
      </Col>
      <Col size="6" className="header__balance">
        <Row>
          <Col size="11" className="left">
            <img src="https://i.imgur.com/ovvlncy.jpg" alt="" />
            <div className="content">
              <p>Balance</p>
              <span>
                {balance} ETH {loadingBalance ? <Spinner /> : ''}
              </span>
            </div>
          </Col>
          <Col size="1" className="right">
            <div onClick={() => setIsOpenModal(true)} className="top">
              <img src={more_dote} alt="" />
            </div>
            <div onClick={fetchData} className="bottom">
              <img src={switch_icon_small} alt="" />
            </div>
          </Col>
        </Row>
      </Col>
      <ModalCommon open={isOpenModal} handleClose={() => setIsOpenModal(false)}>
        <div className="modal-header-balance">
          <div className="title-header-balance">
            Balance
            <img
              className="btn-icon-close"
              alt=""
              src={icon_close}
              onClick={() => {
                setIsOpenModal(false);
              }}
            />
          </div>
          <div className="content-header-balance">
            <div className="content-row-header">
              <div className="content-row-label">Total Balance</div>
              <div className="content-row-value"> {balance} ETH</div>
            </div>
            <div className="content-row-header">
              <div className="content-row-label">Equivalent Values</div>
              <div className="content-row-value">
                {' '}
                {formatCryptocurrency(new BigNumber(balance).times(new BigNumber(rateBTC)).toString())} BTC
              </div>
            </div>
          </div>
        </div>
      </ModalCommon>

      <ModalCommon open={modalQRCode} handleClose={() => setModalQRCode(false)}>
        <div className="qr-code-header">
          <div className="qr-code-title">
            Address
            <img
              className="btn-icon-close"
              alt=""
              src={icon_close}
              onClick={() => {
                setModalQRCode(false);
              }}
            />
          </div>
          <div className="qrcode-wrapper">
            <QRCode value={user.address} size={200} />
          </div>
          <div className="address-qrcode">
            <textarea ref={addressQrcode} value={user.address} readOnly />
          </div>

          <div className="copy-section">
            <span onClick={() => onCopyAddressQrcode()}>Copy</span>
          </div>
        </div>
      </ModalCommon>
    </Row>
  );
}

import { FC } from "react";
import { chunkResponseDataByCurrentPage } from "../../commons/PagingController/helper";
import AssetsContentView from "../../commons/AssetsContentMain";
import { OPENSEA_MESSAGE } from "../../commons/constants";
import { useWalletGlobalContext } from "../context/WalletGlobalContext";

const AssetsSearchView: FC = (): JSX.Element => {
  const {
    currentData: data,
    loading,
    error,
    currentPage,
    isLoadingMore
  } = useWalletGlobalContext();

  // custom here before render WalletSearchView
  return (
    <AssetsContentView
      data={chunkResponseDataByCurrentPage(data, currentPage)}
      loading={loading}
      error={error}
      emptyMessage={OPENSEA_MESSAGE.WALLET_NO_ITEM}
      isLoadingMore={isLoadingMore}
    />
  );
};

export default AssetsSearchView;

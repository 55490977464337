import { useEffect, useState } from "react";
import { CHOOSE_ITEM } from "../Featured/FeaturedItem";
import PaymentItem from "./PaymentItem";
import update from "immutability-helper";
import _ from "lodash";
import { PaymentAssetsDataType } from "Pages/OpenSea/static/type";
import { WithBaseHeaderType, withBaseHeader } from "../../base_component/BaseHeader";
import useStyles from "./styles";

type PaymentFilterProps = {
  paymentsData: PaymentAssetsDataType;
  handleFilterByPayments: (selectedPayments: PaymentAssetsDataType) => void;
} & WithBaseHeaderType;

const PaymentFilter: React.FC<PaymentFilterProps> = (props): JSX.Element => {
  const styles = useStyles();
  const { handleFilterByPayments } = props;
  const [paymentsData, setPaymentsData] = useState(props.paymentsData);

  useEffect(() => {
    if (props.paymentsData.length > 0) {
      setPaymentsData(props.paymentsData);
    }
  }, [props.paymentsData]);

  const onChooseItem = (choosedName: string, action: CHOOSE_ITEM) => {
    const indexUpdate = paymentsData.findIndex(payment => payment.symbol === choosedName);
    if (indexUpdate >= 0) {
      const newPaymentsData = update(paymentsData, {
        [indexUpdate]: { isSelected: { $set: action === CHOOSE_ITEM.CHECK } }
      });
      setPaymentsData(newPaymentsData);
      const selectedPaymentsData = _.cloneDeep(newPaymentsData).filter(
        payment => payment.isSelected === true
      );
      handleFilterByPayments(selectedPaymentsData);
    }
  };

  return (
    <div className={styles.scrollboxreactDivContainer}>
      <div className={styles.scrollboxContent}>
        {paymentsData.map(payment => {
          return (
            <PaymentItem
              key={payment.id}
              onChooseItem={onChooseItem}
              isChecked={payment.isSelected}
              name={payment.symbol}
            />
          );
        })}
      </div>
    </div>
  );
};

export default withBaseHeader(PaymentFilter);

import "./style.scss";

const TableRecordLoading = () => {
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default TableRecordLoading;

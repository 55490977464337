import React from 'react';
import {Row} from '../GridSystem/GridSystem';
import './HeaderCommon.scss';

export default function HeaderWarning({
  text = "Walletconnect authentication does not support centralized systems"
}) {
  return (
    <div>
      <Row className="header-warning">{text}</Row>
    </div>
  );
}

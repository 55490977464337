const initialState = {
  listToken: [],
  checkedPool: 'uni_swap',
  balanceETH: Number
};

const swapPage = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_TOKEN_IN_LIST': {
      return initialState;
    }

    case 'SAVE_TOKEN_IN_LIST': {
      return {
        ...state,
        listToken: [...state.listToken, ...action.tokens]
      };
    }
    case 'ADD_TOKEN_IN_LIST': {
      return {
        ...state,
        listToken: [...state.listToken, action.listTokenAdd]
      };
    }
    case 'REMOTE_TOKEN_IN_LIST': {
      let listTokenUpdate = state.listToken.filter(item => {
        return item.name !== action.listTokenRemove.name;
      });
      return {
        ...state,
        listToken: listTokenUpdate
      };
    }
    case 'SET_CHECK_POOL': {
      return {
        ...state,
        checkedPool: action.poolName
      };
    }
    case 'SAVE_BALANCE_ETH': {
      return {
        ...state,
        balanceETH: action.blanane
      };
    }
    case 'REMOVE_ONE_TOKEN_ADDED_BY_ADDRESS': {
      let listTokenUpdate = state.listToken.filter(item => {
        // JIG-447: Hot-Fix Wrap and Unwrap WETH
        // Begin Hot-Fix
        return item?.address !== action.tokenAddress || item?.symbol.toLowerCase() === 'eth';
        // End Hot-Fix
      });
      return {
        ...state,
        listToken: listTokenUpdate
      };
    }

    default:
      return state;
  }
};

export default swapPage;

import {useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {ApolloClient, InMemoryCache} from '@apollo/client';
import BigNumber from 'bignumber.js';
import moment from 'moment';

import {GET_TRADING_PRICE_HISTORY} from '../../../../../graphql/opensea/PriceHistoryQuery';

const client = new ApolloClient({
  uri: process.env.REACT_APP_OPENSEA_GRAPHQL_API,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
});

const chain_name = process.env.REACT_APP_NETWORK_NAME;

const useTradingPriceHistory = (tokenDetails, chainName) => {
  const [tokenPrices, setTokenPrices] = useState([]);
  const [loading, setLoading] = useState(false);

  const {address} = useSelector(state => state.user);

  const fetchTokenPrices = useCallback(
    async cutOff => {
      if (address && tokenDetails) {
        setLoading(true);

        const result = await client.query({
          query: GET_TRADING_PRICE_HISTORY,
          variables: {
            bucketSize: 'DAY',
            collection: null,
            cutoff: cutOff
              ? moment
                .utc(new Date())
                .subtract(cutOff, 'days')
                .toISOString()
              : null,
            archetype: chainName
              ? {
                assetContractAddress: tokenDetails.chainInfo.contractAddress,
                tokenId: tokenDetails.chainInfo.tokenId,
                chain: chainName
              }
              : {
                assetContractAddress: tokenDetails.chainInfo.contractAddress,
                tokenId: tokenDetails.chainInfo.tokenId
              }
          },
          context: {
            headers: {
              'x-api-key': '0d647f060a9e4e01b490f28d678eae5c',
              'x-build-id': 'eFPMdMDFI3fMUMuY2FCeF',
              'x-viewer-address': address,
              'x-viewer-chain': chain_name
            }
          }
        });

        if (result.data) {
          const data = result.data.tradeHistory.results;

          if (data && data.length > 0) {
            setLoading(false);

            setTokenPrices(
              data.map(historyPrice => {
                const {bucketStart, quantity, volume} = historyPrice;
                const {asset, quantity: volumeQuantity} = volume;

                return {
                  bucketStart,
                  quantity,
                  volume: {
                    quantity: new BigNumber(volumeQuantity)
                      .div(Math.pow(10, asset.decimals))
                      .toFixed()
                  },
                  avgPrice: new BigNumber(volumeQuantity)
                    .div(Math.pow(10, asset.decimals))
                    .div(quantity)
                    .toFixed()
                };
              })
            );
          } else setTokenPrices([]);
        }
      }
    },
    [address, tokenDetails, chainName]
  );

  return {tokenPrices, loading, fetchTokenPrices};
};

export default useTradingPriceHistory;

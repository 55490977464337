import { useState, useEffect, useCallback } from 'react';
import axios from '../../../../../api/axios';

const useFetch = (uri, suspendRender = false, config = {}, customURI = undefined) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState('');

  const fetchDataFromUri = useCallback(
    async customURI => {
      setLoading(true);

      try {
        let response;
        if (customURI) {
          response = await fetch(customURI);
        } else response = await axios.get(uri, config);

        response = await response.json();

        response && setData(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uri]
  );

  useEffect(() => {
    uri && !suspendRender && fetchDataFromUri(customURI);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uri, customURI, suspendRender]);

  return {
    loading,
    error,
    data
  };
};

export default useFetch;

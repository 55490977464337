import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
  return {
    boxDropdown: {
      cursor: "pointer",
      outline: "none",
      position: "relative",
      marginLeft: 15
    },

    dropdownHeader: {
      background: "#2A2B31",
      alignItems: "center",
      display: "flex",
      height: 48,
      justifyContent: "space-between",
      padding: "0px 12px",
      fontSize: 12,
      color: "rgba(255, 255, 255, 0.85)",
      minWidth: 120,
      borderRadius: 2,
      transition: " all 0.2s ease 0s",

      "&:hover": {
        opacity: "0.85"
      }
    },

    dropdownItems: {
      background: "#2a2b31",
      margin: 0,
      overflowY: "auto",
      maxHeight: 440,
      height: "fit-content",
      position: "absolute",
      minWidth: 200,
      top: "100%",
      marginTop: 5,
      right: 0,
      zIndex: 5,
      borderRadius: 2,
      overflow: "auto",
      paddingLeft: 0,
      listStyleType: "none"
    },

    dropdownItem: {
      alignItems: "center",
      color: "rgba(255, 255, 255, 0.85)",
      display: "flex",
      minHeight: "48px",
      padding: " 14px 20px",
      fontSize: 12,
      borderRadius: 2,

      "&:hover": {
        background: "rgba(255, 255, 255, 0.1)"
      }
    }
  };
});

export default useStyles;

import LineDownArrow from '../../../../assets/icons/linedown-arrow.svg';
import React, { useMemo } from 'react';
import { getTokenSymbol } from '../../../../util/getTokenSymbol';
import BigNumber from 'bignumber.js';

export default function SimpleRemove(props) {
  const { output1token, output2token, output1Amount, output2Amount } = props;

  const [token0Image, token1Image] =
    output1token && output2token
      ? [getTokenSymbol(output1token), getTokenSymbol(output2token)]
      : [undefined, undefined];

  const bigOutput1Amount = useMemo(() => {
    return new BigNumber(output1Amount);
  }, [output1Amount]);

  const bigOutput2Amount = useMemo(() => {
    return new BigNumber(output2Amount);
  }, [output2Amount]);

  return (
    <React.Fragment>
      <div className="line-down-arrow">
        <img alt="" src={LineDownArrow} />
      </div>
      <div className="form-row">
        <div className="form-row-data">
          <div className="form-row-amount">
            {bigOutput1Amount.gt(0) ? bigOutput1Amount.toString() : '---'}
          </div>
          <div className="form-row-token">
            {token0Image && <img src={token0Image} alt="" />}
            <div>{output1token?.symbol ?? '---'}</div>
          </div>
        </div>
        <div className="form-row-data mt-10">
          <div className="form-row-amount">
            {bigOutput2Amount.gt(0) ? bigOutput2Amount.toString() : '---'}
          </div>
          <div className="form-row-token">
            {token1Image && <img src={token1Image} alt="" />}
            <div>{output2token?.symbol ?? '---'}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

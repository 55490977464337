import { useCallback } from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { useState } from 'react';
import BigNumber from 'bignumber.js';
import moment from 'moment';

import { GET_ASSET_INFO } from '../../../../../graphql/opensea/AssetQuery';

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_PROXY_URL}${process.env.REACT_APP_OPENSEA_GRAPHQL_API}`,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
});

const useNFTTokenDetails = (address, tokenId, chainName) => {
  const [tokenDetails, setTokenDetails] = useState(undefined);
  const [canBeBidding, setCanBeBidding] = useState(false);

  const fetchNFTTokenDetails = useCallback(async () => {
    if (address && tokenId) {
      await client
        .query({
          query: GET_ASSET_INFO,
          variables: {
            archetype: chainName
              ? {
                  assetContractAddress: address,
                  tokenId,
                  chain: chainName
                }
              : {
                  assetContractAddress: address,
                  tokenId
                },
            chain: chainName
          }
        })
        .then(result => {
          if (result.data) {
            const { archetype, tradeSummary } = result.data;
            const {
              assetContract,
              creator,
              collection,
              name,
              imageUrl,
              animationUrl,
              numVisitors,
              description,
              tokenId,
              assetOwners
            } = archetype.asset;

            const { user, imageUrl: userAvatar, address: creatorAddress } = creator || {};

            const { bestAsk, bestBid } = tradeSummary;
            const tokenDetailsBuyInfo = {};
            let paymentToken;

            if (bestAsk && bestAsk.oldOrder) {
              const { quantity_remaining_takerAssetBundle } = bestAsk;
              const oldOrder = JSON.parse(bestAsk.oldOrder);
              const { decimals, usd_price } = oldOrder ? oldOrder.payment_token_contract : {};
              paymentToken = oldOrder && oldOrder.payment_token_contract;
              const currentPrice = oldOrder
                ? new BigNumber(oldOrder.current_price).div(new BigNumber(10).pow(decimals))
                : new BigNumber(0);

              tokenDetailsBuyInfo.currentPrice = currentPrice.toFixed();
              tokenDetailsBuyInfo.currentPriceToDollar = usd_price
                ? currentPrice.multipliedBy(usd_price).toFixed(2)
                : undefined;
              tokenDetailsBuyInfo.saleEndsIn = bestAsk.closedAt;
              tokenDetailsBuyInfo.assetId =
                quantity_remaining_takerAssetBundle.assetQuantities.edges[0].node.asset.id;
            }

            if (bestBid && bestBid.makerAssetBundle) {
              const { assetQuantities } = bestBid.makerAssetBundle;
              const { decimals, usdSpotPrice } = assetQuantities.edges[0].node.asset;
              paymentToken = assetQuantities.edges[0].node.asset;
              const currentPrice = new BigNumber(assetQuantities.edges[0].node.quantity).div(
                new BigNumber(10).pow(decimals)
              );
              tokenDetailsBuyInfo.bestBidPrice = currentPrice.toFixed();
              tokenDetailsBuyInfo.bestBidPriceToDollar = usdSpotPrice
                ? currentPrice.multipliedBy(usdSpotPrice).toFixed(2)
                : undefined;
              // tokenDetailsBuyInfo.saleEndsIn = bestBid.closedAt;
              tokenDetailsBuyInfo.assetId = assetQuantities.edges[0].node.asset.id;
            }

            let collectionImageUrl;

            if (collection.imageUrl) {
              collectionImageUrl = collection.imageUrl.split('_');
              const prefixPart = collectionImageUrl.slice(0, collectionImageUrl.length - 1);
              let lastPart = collectionImageUrl[collectionImageUrl.length - 1];
              let seperatedLastPart = lastPart.split('=');
              lastPart = `${seperatedLastPart[0]}=s0`;
              collectionImageUrl = [...prefixPart, lastPart].join('_');
            }

            const tokenDetails = {
              publicUsername: user ? user.publicUsername : '',
              imageUrl,
              animationUrl,
              userAvatar,
              collection: {
                name: collection.name || collection.slug,
                imageUrl: collection.imageUrl,
                description: collection.description,
                slug: collection.slug,
                bigImageUrl: collectionImageUrl
              },
              chainInfo: {
                contractAddress: assetContract.account.address,
                blockchain: assetContract.account.chain.identifier,
                tokenId
              },
              numVisitors,
              name,
              creatorAddress,
              ...tokenDetailsBuyInfo,
              description: description
                ? {
                    isHTML: description.includes('↵'),
                    text: description.replaceAll('↵', '')
                  }
                : undefined,
              assetOwner: assetOwners.edges[0].node.owner,
              availableToOffer: bestAsk || bestBid,
              paymentToken,
              id: bestAsk ? bestAsk.id : bestBid ? bestBid.id : undefined,
              bestAsk,
              bestBid
            };

            bestAsk &&
              bestAsk.closedAt &&
              moment
                .utc(bestAsk.closedAt)
                .local()
                .isAfter(moment.utc(new Date()).local()) &&
              setCanBeBidding(true);
            setTokenDetails(tokenDetails);
          }
        })
        .catch(err => console.log(err.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, tokenId]);

  return {
    fetchNFTTokenDetails,
    canBeBidding,
    tokenDetails
  };
};

export default useNFTTokenDetails;

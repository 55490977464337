import { CategoryDataType } from "Pages/OpenSea/static/type";
import { FC } from "react";
import { CHOOSE_ITEM } from "../../commons/filter_component/Featured/FeaturedItem";
import useStyles from "./styles";

type ListingCategoryItemProps = {
  onChooseItem: (choosedSlug: string, action: CHOOSE_ITEM) => void;
} & CategoryDataType;
const ListingCategoryItem: FC<ListingCategoryItemProps> = (props): JSX.Element => {
  const styles = useStyles();
  const { onChooseItem, name, slug, isSelected } = props;
  return (
    <>
      <div
        className={styles.itemGrid}
        role="button"
        onClick={() => {
          onChooseItem(slug, isSelected ? CHOOSE_ITEM.UNCHECK : CHOOSE_ITEM.CHECK);
        }}
      >
        <div className={styles.itemCategory + (isSelected ? " active " : "")}>{name}</div>
      </div>
    </>
  );
};

export default ListingCategoryItem;

import useTrackedPairs from '../hooks/useTrackedPairs';
import useTokenPairsBalance from '../hooks/useTokenPairsBalance';
import LiquidityItem from './LiquidityItem/LiquidityItem';

import './ListLiquidity.scss';

export default function ListLiquidity(props) {
  const {selectedPool, changedLiquidity, setChangedLiquidity} = props;
  const trackedTokenPairs = useTrackedPairs(selectedPool);
  const tokenPairsWithBalance = useTokenPairsBalance(
    selectedPool,
    trackedTokenPairs,
    changedLiquidity,
    setChangedLiquidity
  );
  return (
    <div className="list-liquidity-container">
      <div className="list-liquidity-title">Your Liquidity</div>
      <div className="list-item-liquidity">
        {tokenPairsWithBalance.map(pair => {
          return (
            <LiquidityItem
              onClickRemove={props.onClickRemove}
              onClickAdd={props.onClickAdd}
              key={pair.liquidityToken.address}
              pair={pair}
              changedLiquidity={props.changedLiquidity}
              selectedPool={selectedPool}
            />
          );
        })}
      </div>
    </div>
  );
}

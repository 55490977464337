import Web3 from 'web3';
import IUniswapV2Router02 from './IUniswapV2Router02.json';
import ERC20 from './ERC20.json';
import wethJson from './WETH.json';
import factoryJson from './uni/factoryABI.json';
import pairJson from './uni/pairABI.json';
import flatMap from 'lodash.flatmap';
import { Contract, Provider } from 'ethers-multicall';
import { ethers } from 'ethers';

import BigNumber from 'bignumber.js';

import CID from 'cids';
import { getCodec, rmPrefix } from 'multicodec';
import { decode, toB58String } from 'multihashes';
import * as ClassLib from 'ethers';
import { namehash } from 'ethers/lib/utils';

import {
  JSBI,
  Trade,
  TokenAmount,
  Percent,
  Pair,
  ETHER,
  Token,
  WETH,
  currencyEquals,
  CurrencyAmount
} from '@uniswap/sdk';

import {
  WETH_TOKEN,
  INFURA,
  CHAIN,
  CHAIN_ID,
  BETTER_TRADE_LESS_HOPS_THRESHOLD,
  MAX_HOPS,
  // ALLOW_MULTIPLE_HOPS,
  LOWEST_PRICE_IMPACT,
  ONE_HUNDRED_PERCENT,
  INPUT_FRACTION_AFTER_FEE,
  BASES_TO_CHECK_TRADES_AGAINST,
  CUSTOM_BASES,
  REGISTRAR_ADDRESS,
  RESOLVER_ABI,
  REGISTRAR_ABI
} from './uni/const';

const Tx = require('ethereumjs-tx').Transaction;

let web3Provider = new Web3(Web3.givenProvider);

export const getParams = currency => {
  const { address, chainId, decimals, name, symbol } = currency;
  return [chainId, address, decimals, symbol, name];
};

export const MIN_ETH = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)); // .01 ETH

/**
 * Given some token amount, return the max that can be spent of it
 *  currencyAmount to return max of
 */
export function maxAmountSpend(currencyAmount) {
  if (!currencyAmount) return undefined;
  if (currencyAmount.currency === ETHER) {
    if (JSBI.greaterThan(currencyAmount.raw, MIN_ETH)) {
      return CurrencyAmount.ether(JSBI.subtract(currencyAmount.raw, MIN_ETH));
    } else {
      return CurrencyAmount.ether(JSBI.BigInt(0));
    }
  }
  return currencyAmount;
}

export function getCurrencyToken(currency) {
  const params = getParams(currency);
  const token = new Token(...params);
  return token;
}

export function getContractInstance(web3Instance) {
  const contractAbi = IUniswapV2Router02.abi;
  return new web3Instance.eth.Contract(contractAbi, process.env.REACT_APP_ROUTE_V2_ADDR);
}

export async function sendTransaction(
  serializeTx,
  web3,
  callbackHash,
  callbackError,
  callbackSuccess,
  setDisplayApprove,
  setStatusConfirm
) {
  await web3.eth
    .sendSignedTransaction('0x' + serializeTx.toString('hex'), function(err, hash) {
      if (!err) {
        console.log('hash code : ', hash);
      } else {
        console.log(err);
        callbackError();
      }
    })
    .once('error', e => {
      if (e) {
        callbackError();
      }
    })
    .once('transactionHash', e => {
      callbackHash(e);
    })
    .once('confirmation', (number, receipt) => {
      if (receipt.status) {
        callbackSuccess(receipt);
        setStatusConfirm('approve-success');
        setDisplayApprove(false);
      } else {
        callbackError();
      }
    });
}

export async function approve(
  addressFrom,
  amount_in,
  user,
  callbackError,
  callbackReject,
  callbackHash,
  callbackSuccess,
  setDisplayApprove,
  setStatusConfirm
) {
  const trader = user.address; //P
  // let web3 = new Web3(new Web3.providers.HttpProvider(INFURA));
  let web3 = window.web3;

  const token = new web3.eth.Contract(ERC20.abi, addressFrom);

  // const decimals = await token.methods.decimals().call();
  // const amountIn = (amount_in * 10 ** decimals).toString();
  const amountIn = '115792089237316195423570985008687907853269984665640564039457584007913129639935';
  // 1. Approve
  if (user.privateKey || user.isMnemomic === true) {
    let contract = await getContractInstance(web3);
    const approveData = token.methods.approve(contract._address, amountIn);
    const approveTxParams = {
      nonce: web3.utils.toHex(await web3.eth.getTransactionCount(trader)),
      gasLimit: web3.utils.toHex(await approveData.estimateGas({ from: trader })),
      gasPrice: web3.utils.toHex(await web3.eth.getGasPrice()),
      to: addressFrom,
      data: approveData.encodeABI(),
      value: '0x00' // 0
    };
    const tx = new Tx(approveTxParams, { chain: CHAIN });
    tx.sign(Buffer.from(user.privateKey, 'hex'));
    const serializeTx = tx.serialize();
    await sendTransaction(
      serializeTx,
      web3,
      callbackHash,
      callbackError,
      callbackSuccess,
      setDisplayApprove,
      setStatusConfirm
    );
  } else if (user.isMetaMask === true) {
    let contract = await getContractInstance(web3Provider);

    token.methods
      .approve(contract._address, amountIn)
      .send({ from: user.address }, (error, transactionHash) => {
        if (error) {
          setStatusConfirm('approve-not-confirm');
        } else {
          console.log('transactionHash---11', transactionHash);
          callbackHash(transactionHash);
        }
      })
      .once('error', error => {
        callbackError();
      })
      .once('confirmation', (number, receipt) => {
        if (receipt.status) {
          callbackSuccess();
          setStatusConfirm('approve-success');
          setDisplayApprove(false);
        } else {
          callbackError();
        }
      });
  } else if (user.isWC === true) {
    let contract = new window.web3.eth.Contract(
      IUniswapV2Router02.abi,
      process.env.REACT_APP_ROUTE_V2_ADDR
    );
    token.methods
      .approve(contract._address, amountIn)
      .send({ from: user.address }, (error, transactionHash) => {
        if (error) {
          setStatusConfirm('approve-not-confirm');
        } else {
          console.log('transactionHash---11', transactionHash);
          callbackHash(transactionHash);
          callbackSuccess();
          setStatusConfirm('approve-success');
          setDisplayApprove(false);
        }
      })
      .once('error', error => {
        callbackError();
      })
      .once('confirmation', (number, receipt) => {
        if (receipt.status) {
          callbackSuccess();
          setStatusConfirm('approve-success');
          setDisplayApprove(false);
        } else {
          callbackError();
        }
      });
  }
}

export async function allowance(path, userAddress) {
  let web3 = new Web3(new Web3.providers.HttpProvider(INFURA));
  // let web3 = window.web3;
  const token = new web3.eth.Contract(ERC20.abi, path[0]);
  const allowance = await token.methods
    .allowance(userAddress, process.env.REACT_APP_ROUTE_V2_ADDR)
    .call();
  return allowance;
}

export async function swap(paramsData) {
  const {
    valueIndepen,
    valueDependen,
    path,
    user,
    deadline,
    isDepen,
    amountCalculationForSlip,
    callbackError,
    callbackReject,
    callbackHash,
    callbackSuccess,
    decimalsA,
    decimalsB,
    inputToken,
    outputToken
  } = paramsData;
  const trader = user.address;

  let web3 = new Web3(new Web3.providers.HttpProvider(INFURA));

  // let web3 = window.web3;

  // const token = new web3.eth.Contract(ERC20.abi, path[0]);
  // const decimals = await token.methods.decimals().call();

  const amountIn = new BigNumber(valueIndepen).multipliedBy(Math.pow(10, decimalsA)).toFixed();
  const amountOutMin = new BigNumber(amountCalculationForSlip)
    .multipliedBy(Math.pow(10, decimalsB))
    .toFixed();

  // console.log(
  //   new BigNumber(Math.floor(new BigNumber(NaN + "").toFixed(decimalsB) * 10 ** decimalsB)).toFixed()
  // );

  // const amountIn = new BigNumber(+valueIndepen * 10 ** decimals).toFixed();
  // const amountOutMin = new BigNumber(amountCalculationForSlip * 10 ** decimals).toFixed();

  const amountInMax = '10628166001024840138847335484';

  const amountOut = new BigNumber(valueIndepen).multipliedBy(Math.pow(10, decimalsB)).toFixed();

  const amountDependen = new BigNumber(valueDependen)
    .multipliedBy(Math.pow(10, decimalsA))
    .toFixed();

  // const amountOut = new BigNumber(+valueIndepen * 10 ** decimals).toFixed();
  // const amountDependen = new BigNumber(+valueDependen * 10 ** decimals).toFixed();

  //JIG-447: Hot-Fix Wrap and Unwrap WETH
  // Begin Hot-Fix
  if (
    ((inputToken.symbol.toLowerCase() === 'eth' && outputToken.symbol.toLowerCase() === 'weth') ||
      (inputToken.symbol.toLowerCase() === 'weth' && outputToken.symbol.toLowerCase() === 'eth')) &&
    inputToken.address.toLowerCase() === WETH_TOKEN.toLowerCase() &&
    outputToken.address.toLowerCase() === WETH_TOKEN.toLowerCase()
  ) {
    const wethToken = new web3.eth.Contract(wethJson.abi, WETH_TOKEN);
    let value;
    let data;

    if (inputToken.symbol.toLowerCase() === 'eth') {
      // Data for Wrap WETH
      value = web3.utils.toHex(amountIn);
      data = '0x';
    } else {
      // Data for Unwap WETH
      value = web3.utils.toHex(0);
      data = wethToken.methods.withdraw(amountIn).encodeABI();
    }
    // Send transaction
    if (user.privateKey || user.isMnemomic === true) {
      const params = {
        nonce: web3.utils.toHex(await web3.eth.getTransactionCount(trader)),
        gasLimit: 300000,
        gasPrice: web3.utils.toHex(await web3.eth.getGasPrice()),
        to: WETH_TOKEN,
        data: data,
        value
      };

      const tx = new Tx(params, { chain: CHAIN });
      tx.sign(Buffer.from(user.privateKey, 'hex'));
      const serializeTx = tx.serialize();
      await sendTransaction(serializeTx, web3, callbackHash, callbackError, callbackSuccess);
    } else if (user.isMetaMask === true) {
      if (inputToken.symbol.toLowerCase() === 'eth') {
        await web3Provider.eth
          .sendTransaction(
            {
              from: user.address,
              to: WETH_TOKEN,
              value: value
            },
            (error, transactionHash) => {
              if (error) {
                callbackReject();
              } else {
                callbackHash(transactionHash);
              }
            }
          )
          .once('confirmation', (number, receipt) => {
            if (receipt.status) {
              callbackSuccess(receipt);
            } else {
              callbackError();
            }
          });
      } else {
        let wethContract = new web3Provider.eth.Contract(wethJson.abi, WETH_TOKEN);
        await wethContract.methods
          .withdraw(amountIn)
          .send({ from: user.address }, (error, transactionHash) => {
            if (error) {
              callbackReject();
            } else {
              callbackHash(transactionHash);
            }
          })
          .once('confirmation', (number, receipt) => {
            if (receipt.status) {
              callbackSuccess(receipt);
            } else {
              callbackError();
            }
          });
      }
    } else if (user.isWC === true) {
      if (inputToken.symbol.toLowerCase() === 'eth') {
        await window.web3.eth
          .sendTransaction(
            {
              from: user.address,
              to: WETH_TOKEN,
              value: value
            },
            (error, transactionHash) => {
              if (error) {
                callbackReject();
              } else {
                callbackHash(transactionHash);
              }
            }
          )
          .once('confirmation', (number, receipt) => {
            if (receipt.status) {
              callbackSuccess(receipt);
            } else {
              callbackError();
            }
          });
      } else {
        let wethContract = new window.web3.eth.Contract(wethJson.abi, WETH_TOKEN);
        await wethContract.methods
          .withdraw(amountIn)
          .send({ from: user.address }, (error, transactionHash) => {
            if (error) {
              callbackReject();
            } else {
              callbackHash(transactionHash);
            }
          })
          .once('confirmation', (number, receipt) => {
            if (receipt.status) {
              callbackSuccess(receipt);
            } else {
              callbackError();
            }
          });
      }
    }
    //End Hot-Fix
  } else {
    let methodName = '';
    if (isDepen === 'INPUT') {
      if (path[0] === WETH_TOKEN && inputToken.symbol.toLowerCase() === 'eth') {
        methodName = 'swapExactETHForTokens'; //4
      } else if (
        path[path.length - 1] === WETH_TOKEN &&
        outputToken.symbol.toLowerCase() === 'eth'
      ) {
        methodName = 'swapExactTokensForETH'; //5
      } else {
        methodName = 'swapExactTokensForTokens'; //5
      }
    } else {
      if (path[0] === WETH_TOKEN && inputToken.symbol.toLowerCase() === 'eth') {
        methodName = 'swapETHForExactTokens'; //4 nguoc  eth - token , output la token
      } else if (
        path[path.length - 1] === WETH_TOKEN &&
        outputToken.symbol.toLowerCase() === 'eth'
      ) {
        methodName = 'swapTokensForExactETH'; //5 nguoc token - eth , output eth // dau tien
      } else {
        methodName = 'swapTokensForExactTokens'; //5
      }
    }

    console.log('methodName', methodName);

    const input1 = [amountIn];
    const input2 = [!isNaN(amountOutMin) ? amountOutMin : '0', path, trader, deadline];

    // detech dk : amountOutMin

    const input3 = [amountOut, path, trader, deadline];

    const input4 = [amountOut, amountInMax, path, trader, deadline];

    let input = [];
    let data = {
      from: trader
    };
    if (isDepen === 'INPUT') {
      if (path[0] === WETH_TOKEN && inputToken.symbol.toLowerCase() === 'eth') {
        input = input2;
        data = {
          ...data,
          value: amountIn
        };
      } else {
        input = input1.concat(input2);
      }
    } else {
      if (path[0] === WETH_TOKEN && inputToken.symbol.toLowerCase() === 'eth') {
        input = input3;
        data = {
          ...data,
          value: amountDependen
        };
      } else {
        input = input4;
      }
    }

    if (user.privateKey || user.isMnemomic === true) {
      let contract = await getContractInstance(web3);
      let swapData = contract.methods[methodName](...input);
      let value;
      if (isDepen === 'INPUT') {
        if (path[0] === WETH_TOKEN && inputToken.symbol.toLowerCase() === 'eth') {
          value = web3.utils.toHex(amountIn);
        } else {
          value = 0;
        }
      } else {
        if (path[0] === WETH_TOKEN && inputToken.symbol.toLowerCase() === 'eth') {
          value = web3.utils.toHex(amountDependen);
        } else {
          value = 0;
        }
      }
      const swapParams = {
        nonce: web3.utils.toHex(await web3.eth.getTransactionCount(trader)),
        gasLimit: 300000,
        gasPrice: web3.utils.toHex(await web3.eth.getGasPrice()),
        to: contract._address,
        data: swapData.encodeABI(),
        value
      };

      const tx = new Tx(swapParams, { chain: CHAIN });
      tx.sign(Buffer.from(user.privateKey, 'hex'));
      const serializeTx = tx.serialize();
      await sendTransaction(serializeTx, web3, callbackHash, callbackError, callbackSuccess);
    } else if (user.isMetaMask === true) {
      let contract = await getContractInstance(web3Provider);
      contract.methods[methodName](...input)
        .send(data, (error, transactionHash) => {
          if (error) {
            callbackReject();
          } else {
            callbackHash(transactionHash);
          }
        })
        // .once("error", error => {
        //   console.log("chay vao day1");
        //   callbackError();
        // })
        .once('confirmation', (number, receipt) => {
          // sau khi thanh cong tinh toan lai balance
          if (receipt.status) {
            console.log('chay vao 0');
            callbackSuccess(receipt);
          } else {
            callbackError();
          }
        });
    } else if (user.isWC === true) {
      let contract = new window.web3.eth.Contract(
        IUniswapV2Router02.abi,
        process.env.REACT_APP_ROUTE_V2_ADDR
      );

      contract.methods[methodName](...input)
        .send(data, (error, transactionHash) => {
          if (error) {
            callbackReject();
          } else {
            callbackHash(transactionHash);
          }
        })
        // .once("error", error => {
        //   callbackError();
        // })
        .once('confirmation', (number, receipt) => {
          // sau khi thanh cong tinh toan lai balance
          if (receipt.status) {
            callbackSuccess(receipt);
          } else {
            callbackError();
          }
        });
    }
  }
}

export async function fetchToShowBestTradeExactIn(
  currencyAmountIn,
  currencyOut,
  activePercent,
  checkedMultihop
) {
  let bestTrade = await findBestTradeExactIn(currencyAmountIn, currencyOut, checkedMultihop);

  if (bestTrade) {
    const percentage = new Percent(JSBI.BigInt(activePercent * 100), JSBI.BigInt(10000));
    const routes = bestTrade.route.path;
    const outputAmount = bestTrade.outputAmount;
    const midPrice = bestTrade.executionPrice.toSignificant(6);
    const midPriceInvert = bestTrade.executionPrice.invert().toSignificant(6);
    const minimumAmountOut = bestTrade.minimumAmountOut(percentage).toSignificant(4);

    const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakDown(bestTrade);
    const priceImpact = priceImpactWithoutFee.toSignificant(6);
    const _realizedLPFee = realizedLPFee.toSignificant(4);
    const priceImpactDisplay = realizedLPFee.lessThan(LOWEST_PRICE_IMPACT) ? '<0.01' : priceImpact;

    return {
      routes,
      outputAmount,
      minimumAmountOut,
      priceImpact,
      realizedLPFee: _realizedLPFee,
      priceImpactDisplay,
      midPrice,
      midPriceInvert
    };
  }
}

export async function fetchToShowBestTradeExactOut(
  currencyIn,
  currencyAmountOut,
  activePercent,
  checkedMultihop
) {
  const bestTrade = await findBestTradeExactOut(currencyIn, currencyAmountOut, checkedMultihop);

  if (bestTrade) {
    const percentage = new Percent(JSBI.BigInt(activePercent * 100), JSBI.BigInt(10000));
    const routes = bestTrade.route.path;
    const inputAmount = bestTrade.inputAmount;
    const midPrice = bestTrade.executionPrice.toSignificant(6);
    const midPriceInvert = bestTrade.executionPrice.invert().toSignificant(6);
    const maximumAmountIn = bestTrade.maximumAmountIn(percentage).toSignificant(4);

    const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakDown(bestTrade);
    const priceImpact = priceImpactWithoutFee.toSignificant(6);
    const _realizedLPFee = realizedLPFee.toSignificant(4);
    const priceImpactDisplay = realizedLPFee.lessThan(LOWEST_PRICE_IMPACT) ? '<0.01' : priceImpact;

    return {
      routes,
      inputAmount,
      maximumAmountIn,
      priceImpact,
      realizedLPFee: _realizedLPFee,
      priceImpactDisplay,
      midPrice,
      midPriceInvert
    };
  }
}

async function findBestTradeExactIn(currencyAmountIn, currencyOut, checkedMultihop) {
  const allowedPairs = await getCommonPairs(currencyAmountIn.currency, currencyOut);

  if (currencyAmountIn && currencyOut && allowedPairs.length > 0) {
    if (checkedMultihop) {
      return (
        Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, currencyOut, {
          maxHops: 1,
          maxNumResults: 1
        })[0] ?? null
      );
      // ?? null
    }
    // search through trades with varying hops, find best trade out of them
    let bestTradeSoFar = null;
    for (let i = 1; i <= MAX_HOPS; i++) {
      const currentTrade =
        Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, currencyOut, {
          maxHops: i,
          maxNumResults: 1
        })[0] ?? null;
      // ?? null

      // if current trade is best yet, save it
      if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
        bestTradeSoFar = currentTrade;
      }
    }

    return bestTradeSoFar;
  }
}

async function findBestTradeExactOut(currencyIn, currencyAmountOut, checkedMultihop) {
  const allowedPairs = await getCommonPairs(currencyIn, currencyAmountOut.currency);

  if (currencyIn && currencyAmountOut && allowedPairs.length > 0) {
    if (checkedMultihop) {
      return (
        Trade.bestTradeExactOut(allowedPairs, currencyIn, currencyAmountOut, {
          maxHops: 1,
          maxNumResults: 1
        })[0] ?? null
      );
    }
    // search through trades with varying hops, find best trade out of them
    let bestTradeSoFar = null;
    for (let i = 1; i <= MAX_HOPS; i++) {
      const currentTrade =
        Trade.bestTradeExactOut(allowedPairs, currencyIn, currencyAmountOut, {
          maxHops: i,
          maxNumResults: 1
        })[0] ?? null;

      // if current trade is best yet, save it
      if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
        bestTradeSoFar = currentTrade;
      }
    }

    return bestTradeSoFar;
  }
}

function computeTradePriceBreakDown(trade) {
  if (trade) {
    const realizedLPFee = ONE_HUNDRED_PERCENT.subtract(
      trade.route.pairs.reduce(
        (currentFee, pair) => currentFee.multiply(INPUT_FRACTION_AFTER_FEE),
        ONE_HUNDRED_PERCENT
      )
    );
    const { numerator, denominator } = trade.priceImpact.subtract(realizedLPFee);
    const priceImpactWithoutFeePercent = new Percent(numerator, denominator);
    const realizedLPAmount = new TokenAmount(
      trade.inputAmount.token,
      realizedLPFee.multiply(trade.inputAmount.raw).quotient
    );

    return {
      priceImpactWithoutFee: priceImpactWithoutFeePercent,
      realizedLPFee: realizedLPAmount
    };
  }

  return undefined;
}

async function getCommonPairs(currencyA, currencyB) {
  // Get all base tokens for intermediary by chain id ( network id )
  const bases = CHAIN_ID ? BASES_TO_CHECK_TRADES_AGAINST[CHAIN_ID] : [];
  const [tokenA, tokenB] = CHAIN_ID
    ? [getWrappedCurrency(currencyA), getWrappedCurrency(currencyB)]
    : [undefined, undefined];

  const basePairs = flatMap(bases, base => {
    return bases
      .map(otherBase => [base, otherBase])
      .filter(([t0, t1]) => t0.address !== t1.address);
  });

  const allPairsCombination = getAllPairCombinations([tokenA, tokenB], basePairs);

  const allPairs = await getPairExists(allPairsCombination);

  return allPairs;
}

function getWrappedCurrency(currency) {
  return CHAIN_ID && currency === ETHER
    ? WETH[CHAIN_ID]
    : currency instanceof Token
    ? currency
    : undefined;
}

function isTradeBetter(tradeA, tradeB, minimumDelta) {
  const ZERO_PERCENT = new Percent('0');
  const ONE_HUNDRED_PERCENT = new Percent('1');

  if (tradeA && !tradeB) return false;
  if (tradeB && !tradeA) return true;
  if (!tradeA || !tradeB) return undefined;

  if (
    tradeA.tradeType !== tradeB.tradeType ||
    !currencyEquals(tradeA.inputAmount.currency, tradeB.inputAmount.currency) ||
    !currencyEquals(tradeA.outputAmount.currency, tradeB.outputAmount.currency)
  ) {
    throw new Error('Trades are not comparable');
  }

  if (minimumDelta === ZERO_PERCENT) {
    return tradeA.executionPrice.lessThan(tradeB.executionPrice);
  } else {
    return tradeA.executionPrice.raw
      .multiply(minimumDelta.add(ONE_HUNDRED_PERCENT))
      .lessThan(tradeB.executionPrice);
  }
}

function getAllPairCombinations([tokenA, tokenB], basePairs) {
  const bases = CHAIN_ID ? BASES_TO_CHECK_TRADES_AGAINST[CHAIN_ID] : [];
  return tokenA && tokenB
    ? [
        [tokenA, tokenB],
        ...bases.map(base => [tokenA, base]),
        ...bases.map(base => [tokenB, base]),
        ...basePairs
      ]
        .filter(tokens => Boolean(tokens[0] && tokens[1]))
        .filter(([t0, t1]) => t0.address !== t1.address)
        .filter(([tokenA, tokenB]) => {
          if (!CHAIN_ID) return true;
          const customBase = CUSTOM_BASES[CHAIN_ID];
          if (!customBase) return true;

          const customBaseA = customBase[tokenA.address];
          const customBaseB = customBase[tokenB.address];

          if (!customBaseA && !customBaseB) return true;

          if (customBaseA && !customBaseA.find(base => tokenA.equals(base))) {
            return false;
          }

          if (customBaseB && !customBaseB.find(base => tokenB.equals(base))) {
            return false;
          }

          return true;
        })
    : [];
}

async function getPairExists(currencies) {
  const tokens = currencies.map(([currencyA, currencyB]) => {
    return [getWrappedCurrency(currencyA), getWrappedCurrency(currencyB)];
  });

  const pairsWithReserves = await getAllPairReserves(tokens);

  return Object.values(
    pairsWithReserves.reduce((memo, current) => {
      memo[current.liquidityToken.address] = memo[current.liquidityToken.address] ?? current;
      // memo[current.liquidityToken.address] = memo[current.liquidityToken.address];
      return memo;
    }, {})
  );
}

async function getAllPairReserves(tokens) {
  const CHAIN_ID = process.env.REACT_APP_CHAIN_ID || 1;
  const factoryContract = new Contract(process.env.REACT_APP_FACTORY_CONTRACT_ADDRESS, factoryJson);

  const provider = new ethers.providers.InfuraProvider(CHAIN, process.env.REACT_APP_INFURA_KEY);

  const ethcallProvider = new Provider(provider, CHAIN_ID);

  await ethcallProvider.init();

  // Use for group multiple contract calls into one by using Multicall SmartContract and ethers lib
  const pairsExistContractCall = tokens.map(([tokenA, tokenB]) =>
    factoryContract.getPair(tokenA.address, tokenB.address)
  );

  let pairs = await ethcallProvider.all(pairsExistContractCall);

  // filter all the pair that doesn't has a pool
  const validTokenPairs = [];

  pairs = pairs.filter((pair, index) => {
    if (Number(pair) !== 0) {
      validTokenPairs.push(tokens[index]);
      return true;
    }
    return false;
  });

  // Multicall smart contract for getting reserves of all pairs
  const pairsReserveContractCall = pairs.map(pair => {
    const pairContract = new Contract(pair, pairJson);

    return pairContract.getReserves();
  });

  let pairsWithReserves = await ethcallProvider.all(pairsReserveContractCall);

  return pairsWithReserves.map((pair, index) => {
    const [tokenA, tokenB] = validTokenPairs[index];
    const { _reserve0, _reserve1 } = pair;

    const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA];

    return new Pair(
      new TokenAmount(token0, _reserve0.toString()),
      new TokenAmount(token1, _reserve1.toString())
    );
  });
}

//------------------------------------------------------------------------

export function hexToUint8Array(hex) {
  hex = hex.startsWith('0x') ? hex.substr(2) : hex;
  if (hex.length % 2 !== 0) throw new Error('hex must have length that is multiple of 2');
  const arr = new Uint8Array(hex.length / 2);
  for (let i = 0; i < arr.length; i++) {
    arr[i] = parseInt(hex.substr(i * 2, 2), 16);
  }
  return arr;
}

export function contenthashToUri(contenthash) {
  //2
  const UTF_8_DECODER = new TextDecoder();
  const buff = hexToUint8Array(contenthash);
  const codec = getCodec(buff); // the typing is wrong for @types/multicodec
  switch (codec) {
    case 'ipfs-ns': {
      const data = rmPrefix(buff);
      const cid = new CID(data);
      return `ipfs://${toB58String(cid.multihash)}`;
    }
    case 'ipns-ns': {
      const data = rmPrefix(buff);
      const cid = new CID(data);
      const multihash = decode(cid.multihash);
      if (multihash.name === 'identity') {
        return `ipns://${UTF_8_DECODER.decode(multihash.digest).trim()}`;
      } else {
        return `ipns://${toB58String(cid.multihash)}`;
      }
    }
    default:
      throw new Error(`Unrecognized codec: ${codec}`);
  }
}

export function uriToHttp(uri) {
  const protocol = uri.split(':')[0].toLowerCase();
  switch (protocol) {
    case 'https':
      return [uri];
    case 'http':
      return ['https' + uri.substr(4), uri];
    case 'ipfs':
      const hash = uri.match(/^ipfs:(\/\/)?(.*)$/i)?.[2];
      return [`https://cloudflare-ipfs.com/ipfs/${hash}/`, `https://ipfs.io/ipfs/${hash}/`];
    case 'ipns':
      const name = uri.match(/^ipns:(\/\/)?(.*)$/i)?.[2];
      return [`https://cloudflare-ipfs.com/ipns/${name}/`, `https://ipfs.io/ipns/${name}/`];
    default:
      return [];
  }
}

export function resolverContract(resolverAddress, provider) {
  return new ClassLib.Contract(resolverAddress, RESOLVER_ABI, provider);
}

export async function resolveENSContentHash(esnName, provider) {
  const ensRegistrarContract = new ClassLib.Contract(REGISTRAR_ADDRESS, REGISTRAR_ABI, provider);
  const hash = namehash(esnName);
  const resolverAddress = await ensRegistrarContract.resolver(hash);
  if (resolverAddress !== '0x0000000000000000000000000000000000000000') {
    return resolverContract(resolverAddress, provider).contenthash(hash);
  }
}

export function ensResolver(esnName) {
  const provider = new ClassLib.ethers.providers.InfuraProvider(
    'mainnet',
    process.env.REACT_APP_INFURA_KEY
  );
  return resolveENSContentHash(esnName, provider);
}

import { gql } from "@apollo/client";
import { OfferSearchQueryVariableType } from "Pages/OpenSea/static/type";

/**
 * Account offers - collection
 */
export const OfferSearchQuery = gql`
  query OfferSearchQuery(
    $categories: [CollectionSlug!]
    $chains: [ChainScalar!]
    $collectionQuery: String
    $collections: [CollectionSlug!]
    $collectionSortBy: CollectionSort
    $includeHiddenCollections: Boolean
  ) {
    query {
      ...OfferSearch_data_hZKT3
    }
  }

  fragment CollectionFilter_data_3aeBqg on Query {
    selectedCollections: collections(first: 25, collections: $collections, includeHidden: true) {
      edges {
        node {
          assetCount
          imageUrl
          name
          slug
          id
        }
      }
    }
    collections(
      chains: $chains
      first: 100
      includeHidden: $includeHiddenCollections
      parents: $categories
      query: $collectionQuery
      sortBy: $collectionSortBy
    ) {
      edges {
        node {
          assetCount
          imageUrl
          name
          slug
          id
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }

  fragment CollectionModalContent_data on CollectionType {
    description
    imageUrl
    name
    slug
  }

  fragment OfferSearchFilter_data_3mLsus on Query {
    ...CollectionFilter_data_3aeBqg
  }

  fragment OfferSearch_data_hZKT3 on Query {
    ...OfferSearchFilter_data_3mLsus
    ...SearchPills_data_2Kg4Sq
  }

  fragment SearchPills_data_2Kg4Sq on Query {
    selectedCollections: collections(first: 25, collections: $collections, includeHidden: true) {
      edges {
        node {
          imageUrl
          name
          slug
          ...CollectionModalContent_data
          id
        }
      }
    }
  }
`;

export const OFFER_SEARCH_INIT_VARIABLES: OfferSearchQueryVariableType = {
  categories: null,
  chains: null,
  collectionQuery: null,
  collections: [],
  collectionSortBy: "SEVEN_DAY_VOLUME",
  identity: {},
  includeHiddenCollections: true
};

import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
  return {
    mainContentSearch: {
      width: "calc(100% - 30px - 350px)",
      marginLeft: 30,
      padding: 25,
      minHeight: "calc(100vh - 72px)",
      backgroundColor: theme?.custom?.colors?.backgroundColorLeft
    },

    pagingBottom: {
      display: "flex",
      justifyContent: "center"
    }
  };
});

export default useStyles;

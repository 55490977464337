import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { FC } from "react";
import "./styles.scss";

type PagingControllerProps = {
  currentPage: number; // start page = 1
  isLoadingData: boolean;
  totalcurrentPage: number;
  canFetchMoreData: boolean;
  handleChangedPage: (newPage: number) => void;
};

const PagingController: FC<PagingControllerProps> = (props): JSX.Element => {
  const {
    currentPage,
    isLoadingData,
    canFetchMoreData,
    handleChangedPage,
    totalcurrentPage
  } = props;

  const isDisabledPreButton = () => {
    if (isLoadingData || currentPage <= 1) {
      return true;
    }
    return false;
  };

  const isDisabledNextButton = () => {
    if (
      isLoadingData ||
      totalcurrentPage === 0 ||
      (totalcurrentPage === currentPage && !canFetchMoreData)
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="pagingControlContainer">
      <div
        role="button"
        className={`pagingControlButton ${isDisabledPreButton() ? " disableButton " : ""}`}
        onClick={() => {
          if (!isDisabledPreButton()) {
            handleChangedPage(currentPage - 1);
          }
        }}
      >
        <div className="pagingControlContent">
          <ArrowBackIosIcon className="iconmaterial" />
        </div>
        <div className="pagingControlContent prev">Prev</div>
      </div>
      <div
        role="button"
        className={`pagingControlButton ${isDisabledNextButton() ? " disableButton " : ""}`}
        onClick={() => {
          if (!isDisabledNextButton()) {
            handleChangedPage(currentPage + 1);
          }
        }}
      >
        <div className="pagingControlContent next">Next</div>
        <div className="pagingControlContent">
          <ArrowForwardIosIcon className="iconmaterial" />
        </div>
      </div>
    </div>
  );
};

export default PagingController;

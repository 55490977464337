import ModalCommon from '../../../components/ModalCommon/ModalCommon';
import Spinner from '../../../components/Spinner';
import IconSubmitted from '../../../assets/icons/icon-submitted.svg';
import IconRejected from '../../../assets/icons/icon-rejected.svg';
import IconMetaMask from '../../../assets/icons/icon-metamask.svg';
import React, { useCallback, useMemo } from 'react';
import './ModalWating.scss';
import icon_close from '../../../assets/swap/close_icon.svg';
import { Button } from '../../../components';
import { CHAIN_ID } from '../../Swap/uni/const';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';

export default function ModalWating(props) {
  const { status, txHash, tokenInOut, user, isWrapOrUnwrapWETH } = props;

  const web3 = useMemo(() => {
    if (user.isWC) {
      return new Web3(window.web3);
    } else if (user.privateKey) {
      return new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_URL));
    } else {
      return new Web3(window.ethereum);
    }
  }, [user]);

  const renderTitle = useCallback(() => {
    if (status === 'confirmed') {
      return 'Transaction Submitted';
    } else if (status === 'rejected') {
      return 'Error';
    } else if (status === 'error') {
      return 'Error';
    } else {
      return 'Waiting For Confirmation';
    }
  }, [status]);

  const renderIcon = () => {
    if (props.status === 'confirmed') {
      return <img alt="" src={IconSubmitted} />;
    } else if (props.status === 'rejected') {
      return <img alt="" src={IconRejected} />;
    } else if (props.status === 'error') {
      return <img alt="" src={IconRejected} />;
    } else {
      return <Spinner />;
    }
  };

  const addTokenToMetamask = () => {
    if (web3 && web3.currentProvider.isMetaMask && web3.currentProvider.request && tokenInOut[1]) {
      web3.currentProvider
        .request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: tokenInOut[1]?.token?.address,
              symbol: tokenInOut[1]?.token?.symbol,
              decimals: tokenInOut[1]?.token?.decimals,
              image: tokenInOut[1]?.token?.logoURI
            }
          }
        })
        .then(success => {
          console.log(success);
        });
    }
  };

  console.log('statusstatus', status);

  const RenderText = () =>
    useMemo(() => {
      if (status === 'confirmed') {
        return (
          <div className="content-success">
            <a
              className="view-on-ether"
              target="_blank"
              href={`${
                CHAIN_ID === '4'
                  ? `https://rinkeby.etherscan.io/tx/${txHash}`
                  : `https://etherscan.io/tx/${txHash}`
              }`}
              rel="noreferrer"
            >
              View on Etherscan
            </a>
            {user.isMetaMask && !isWrapOrUnwrapWETH && (
              <div className="add-token-metamask" onClick={() => addTokenToMetamask()}>
                Add {tokenInOut[1]?.token?.symbol} to MetaMask
                <img src={IconMetaMask} alt="" />
              </div>
            )}

            <div className="close-wrapper">
              <Button
                onClick={() => {
                  props.handleClose();
                  props.onClearForm();
                  props.setOpenModalConfirmSwap(false);
                }}
              >
                Close
              </Button>
            </div>
          </div>
        );
      } else if (status === 'rejected') {
        return (
          <div className="content-rejected">
            <div className="message-rejected">Transaction rejected.</div>
            <div className="dismiss-wrapper">
              <Button
                onClick={() => {
                  props.handleClose();
                  props.setStatusConfirm('waiting');
                }}
              >
                Dismiss
              </Button>
            </div>
          </div>
        );
      } else if (status === 'error') {
        return (
          <div className="content-rejected">
            <div className="message-rejected">Insufficient Transaction Fee</div>
          </div>
        );
      } else {
        //JIG-447: Hot-Fix Wrap and Unwrap WETH
        // Begin Hot-Fix
        if (isWrapOrUnwrapWETH) {
          let ethAmount =
            tokenInOut[0].amount !== undefined ? tokenInOut[0].amount : tokenInOut[1].amount;
          return (
            <div className="content-waiting">
              <div className="content-waiting-row1">
                Swapping {ethAmount} {tokenInOut[0]?.token?.symbol} for {ethAmount}{' '}
                {tokenInOut[1]?.token?.symbol}
              </div>
              <div className="content-waiting-row2">Confirm this transaction in your wallet</div>
            </div>
          );
          // End Hot-Fix
        } else {
          return (
            <div className="content-waiting">
              <div className="content-waiting-row1">
                Swapping {new BigNumber(tokenInOut[0]?.amount).toFixed()}{' '}
                {tokenInOut[0]?.token?.symbol} for {new BigNumber(tokenInOut[1]?.amount).toFixed()}{' '}
                {tokenInOut[1]?.token?.symbol}
              </div>
              <div className="content-waiting-row2">Confirm this transaction in your wallet</div>
            </div>
          );
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [txHash, status, tokenInOut]);

  return (
    <ModalCommon open={props.isOpen} handleClose={() => props.handleClose}>
      <div className="waiting-liquidity-modal">
        <div className="waiting-lq-title">
          {renderTitle()}
          <img
            className="btn-icon-close"
            alt=""
            src={icon_close}
            onClick={() => {
              props.handleClose();
              props.setStatusConfirm('waiting');
              status === 'confirmed' && props.onClearForm();

              props.setOpenModalConfirmSwap(false);
            }}
          />
        </div>
        <div className="waiting-lq-content">
          <div className="spinner-wrapper">{renderIcon()}</div>
          <div className="content-text">{RenderText()}</div>
        </div>
      </div>
    </ModalCommon>
  );
}

import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
  return {
    searchPillResultList: {
      padding: "0 15px",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center"
    },

    btnClearAll: {
      marginBottom: 5,
      cursor: "pointer",

      "&:hover": {
        color: theme?.custom?.colors?.colorLightAtlantis
      }
    },

    searchPillResultItem: {
      border: "1px solid rgba(255, 255, 255, 0.1)",
      borderRadius: 2,
      height: 32,
      minWidth: 87,
      marginRight: 12,
      marginBottom: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: 12,
      lineHeight: "12px",
      color: theme?.custom?.colors?.colorWhiteRgba085,
      padding: "5px 10px 5px 15px"
    },

    iconCloseSearchPillResultItem: {
      fontSize: 16,
      marginLeft: 10,
      cursor: "pointer",

      "&:hover": {
        color: theme?.custom?.colors?.colorLightAtlantis
      }
    }
  };
});

export default useStyles;

import { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BigNumber from 'bignumber.js';
import { useQuery, useApolloClient } from '@apollo/client';
import { ethers } from 'ethers';
import FarmingTable from './FarmingTable';
import useSushiPrice from './hooks/useSushiPrice';
import usePendingSushiBalances from './hooks/usePendingSushiBalances';
import useLPTokensBalance from './hooks/useLPTokensBalance';
import useFarmingAPY from './hooks/useFarmingApy';
import useFarms from './hooks/useFarms';
import FarmingContext from './context/FarmingContext';
import LookupIcon from '../../assets/lookup.png';
import HeaderCommon from '../../components/HeaderCommon/HeaderCommon.js';
import Page from '../../components/Page/Page.js';
import { Container } from 'components/GridSystem/GridSystem';
import { getTotalAllocPoint, calSushiDailyYield } from '../../util/masterchef';
import { POOLS_USER_QUERY, POOLS_QUERY } from '../../graphql/pool';
import { GET_PAIR_INFO } from '../../graphql/pair';
import { changeTitlePage } from '_actions/titlePage.actions';
import './Farming.scss';

const INFURA_KEY = process.env.INFURA_KEY;

const Farming = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const user = useSelector(state => state.user);

  const [pools, setPools] = useState(null);
  const [pairAddresses, setPairAddresses] = useState([]);
  const [pairs, setPairs] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [pendingSushiBalances] = usePendingSushiBalances(pools);
  // Testing in here => should be useLPTokensBalance(pools, address) (address from useSelector)
  const [LPTokensBalance] = useLPTokensBalance(pools, '0xaa1917fe9f4cc187f0475349abfc70a327982fbd');
  const [calculatePoolsApy] = useFarmingAPY(pools, pairs);
  const [testPools, fetchDefaultFarms] = useFarms();
  const [sushiPrice] = useSushiPrice();

  const infuraProvider = useMemo(() => {
    return new ethers.providers.InfuraProvider('mainnet', INFURA_KEY);
  }, []);

  const { data: userPoolsData } = useQuery(POOLS_USER_QUERY, {
    context: {
      clientName: 'masterchef'
    },
    variables: {
      address: user.address,
      amount_gt: 0
    },
    skip: !pools || pools.length === 0,
    pollInterval: 10000
  });

  const { data: poolsData } = useQuery(POOLS_QUERY, {
    context: {
      clientName: 'masterchef'
    }
  });

  useEffect(() => {
    dispatch(changeTitlePage('Sushi Farms'));
  }, [dispatch]);

  useEffect(() => {
    if (
      sushiPrice &&
      pairs.length > 0 &&
      LPTokensBalance.length > 0 &&
      pendingSushiBalances.length > 0 &&
      userPoolsData
    ) {
      const preparePoolStatistics = async () => {
        const poolsWithApy = await calculatePoolsApy(sushiPrice, infuraProvider);
        const totalAllocPoint = await getTotalAllocPoint();

        const poolsWithStatistics = pools.map((pool, index) => {
          const apy = poolsWithApy[index];

          return {
            ...pool,
            balance: new BigNumber(LPTokensBalance[index]).div(new BigNumber(10).pow(18)),
            pendingSushi: new BigNumber(pendingSushiBalances[index]).div(new BigNumber(10).pow(18)),
            apy,
            sushiPerDay: calSushiDailyYield(pool, apy, sushiPrice, totalAllocPoint)
          };
        });

        const userPoolsID = userPoolsData.users.map(user => user.pool.id);
        const poolsWithAmount = poolsWithStatistics.map(pool => {
          const index = userPoolsID.indexOf(pool.id);

          if (index >= 0) {
            return {
              ...pool,
              stakedLPToken: new BigNumber(userPoolsData.users[index].amount).div(
                new BigNumber(10).pow(18)
              ),
              rewardDebt: new BigNumber(userPoolsData.users[index].rewardDebt)
            };
          }

          return pool;
        });

        setPools(poolsWithAmount);
      };

      preparePoolStatistics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingSushiBalances, LPTokensBalance, sushiPrice, userPoolsData, pairs]);

  useEffect(() => {
    if (pairAddresses && pairAddresses.length > 0) {
      client
        .query({
          query: GET_PAIR_INFO,
          variables: {
            first: 1000,
            orderBy: 'trackedReserveETH',
            orderDirection: 'desc',
            pairAddresses
          },
          context: {
            clientName: 'exchange'
          }
        })
        .then(result => {
          const pairs = result.data.pairs;
          const sortedPairs = [...pairs].sort((a, b) => (a.id > b.id ? 1 : -1));
          const sortedPairsID = sortedPairs.map(pair => pair.id);
          const poolWithPairInfo = [
            ...pools
              .map((pool, index) => {
                if (sortedPairs[index] && sortedPairsID.indexOf(pool.pair) >= 0) {
                  return {
                    ...pool,
                    reserve0: sortedPairs[index].reserve0,
                    reserve1: sortedPairs[index].reserve1,
                    token0: sortedPairs[index].token0,
                    token1: sortedPairs[index].token1,
                    reserveUSD: sortedPairs[index].reserveUSD,
                    totalSupply: sortedPairs[index].totalSupply
                  };
                }
                // TODO map need return result => fix logic here
                return;
              })
              .filter(pool => pool)
          ].sort((poolA, poolB) => (poolA.pair > poolB.pair ? 1 : -1));

          console.log(sortedPairs, poolWithPairInfo);

          setPools(poolWithPairInfo);
          setPairs(sortedPairs);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pairAddresses]);

  useEffect(() => {
    if (poolsData) {
      const { pools } = poolsData;

      let sortedPools = [...pools].sort((a, b) => (a.pair > b.pair ? 1 : -1));

      const pairAddresses = sortedPools.map(pool => {
        return pool.pair;
      });

      sortedPools && sortedPools.length > 0 && setPools(sortedPools);
      setPairAddresses(pairAddresses);
    }
  }, [poolsData]);

  return (
    <Page title="Sushi Farms" className="Swap-page">
      <Container>
        <HeaderCommon user={user} />
        <FarmingContext.Provider
          value={{
            testPools,
            pairs,
            pendingSushiBalances,
            pools,
            fetchDefaultFarms,
            sushiPrice,
            inputSearch
          }}
        >
          <div style={{ width: '100%' }}>
            <div className="Farming__input-wrapper">
              <input
                type="text"
                placeholder="Search"
                className="Farming__input-search"
                onChange={e => setInputSearch(e.target.value)}
                value={inputSearch}
              />
              <img src={LookupIcon} className="Farming__input-icon" alt="search-icon" />
            </div>
            <FarmingTable testPools={testPools} pools={pools} />
          </div>
        </FarmingContext.Provider>
      </Container>
    </Page>
  );
};

export default Farming;

import BigNumber from "bignumber.js";
import _ from "lodash";
import moment from "moment";
import { formatPrefixesUpCaseString } from "../../commons/helper";
import { ImageAndTextType, OrdersQueryResponseType, OrderV2Type } from "./../../../static/type";

export type OffersSearchHelperType = {
  // key
  key: string;

  // items
  assetName: string;
  pathImage: string[];
  assetsLink: string;

  // Price
  price: string;

  // Quantity
  assetQuantity: string;

  // From
  from: ImageAndTextType;

  // Expiration
  timeStamp: string;

  // Status
  status: string;

  // ButtonCancel
  showCancel: boolean;
  oldOrderString: string; // JSON string
};

const getKeyFromOrdersResult = (cursor: string) => {
  return { key: cursor };
};

const getPathImageFromFirstAssetQuantity = (nodeData: OrderV2Type) => {
  let pathImage = [""] as string[];
  const bunldeSlug = nodeData.takerAssetBundleDisplay?.slug ?? null;
  const asset = nodeData.takerAssetBundleDisplay?.assetQuantities?.edges[0]?.node?.asset ?? null;
  if (bunldeSlug) {
    const listEdges = nodeData.takerAssetBundleDisplay?.assetQuantities?.edges ?? [];
    pathImage = _.map(listEdges, item => item.node?.asset?.imageUrl ?? "");
  } else if (asset && asset.imageUrl) {
    pathImage = [asset.imageUrl];
  } else if (asset && asset.collection?.imageUrl) {
    pathImage = [asset.collection.imageUrl];
  }
  return pathImage;
};

const getAssetNameFromNodeData = (nodeData: OrderV2Type) => {
  let assetName = "no name";
  const takerAssetBundleDisplay = nodeData.takerAssetBundleDisplay;
  if (takerAssetBundleDisplay) {
    assetName =
      takerAssetBundleDisplay.name ?? // is bundle
      takerAssetBundleDisplay.assetQuantities?.edges[0].node?.asset?.name ??
      takerAssetBundleDisplay.assetQuantities?.edges[0].node?.asset?.collection?.name ??
      "no name";
  }
  return assetName;
};

const getAssetsLinkFromNodeData = (nodeData: OrderV2Type) => {
  let assetsLink = "#";
  const takerAssetBundleDisplay = nodeData.takerAssetBundleDisplay;
  const bunldeSlug = nodeData.takerAssetBundleDisplay?.slug ?? null;
  if (bunldeSlug) {
    assetsLink = `/opensea/assets/bundles/${bunldeSlug}`;
  } else if (takerAssetBundleDisplay.assetQuantities) {
    const address =
      takerAssetBundleDisplay.assetQuantities?.edges[0].node?.asset?.assetContract?.account
        ?.address;
    const tokenId = takerAssetBundleDisplay.assetQuantities?.edges[0].node?.asset?.tokenId;
    if (address && tokenId) {
      assetsLink = `/opensea/assets/${address}/${tokenId}`;
    }
  }
  return assetsLink;
};

const getItemColumnFromOrdersResult = (nodeData: OrderV2Type) => {
  const pathImage = getPathImageFromFirstAssetQuantity(nodeData);
  const assetName = getAssetNameFromNodeData(nodeData);
  const assetsLink = getAssetsLinkFromNodeData(nodeData);

  return {
    pathImage,
    assetName,
    assetsLink
  };
};

const getPriceColumnFromEventHistoryResult = (nodeData: OrderV2Type) => {
  const price = nodeData.quantity_remaining_makerAssetBundle
    ? new BigNumber(
        nodeData.quantity_remaining_makerAssetBundle.assetQuantities.edges[0].node.quantity
      )
        .div(
          Math.pow(
            10,
            nodeData.quantity_remaining_makerAssetBundle.assetQuantities.edges[0].node.asset
              .decimals
          )
        )
        .toFixed()
    : "";
  return { price };
};

const getQuantityColumnFromOrdersResult = (nodeData: OrderV2Type) => {
  const assetQuantity =
    nodeData.quantity_remaining_takerAssetBundle?.assetQuantities?.edges[0].node?.quantity ?? "";
  return { assetQuantity };
};

const getFromColumnFromOrdersResult = (
  nodeData: OrderV2Type,
  addressUser: string
): { from: ImageAndTextType } => {
  let from = {
    text: "",
    imageUrl: ""
  };
  if (nodeData.maker) {
    from = {
      text:
        addressUser.toLowerCase() === nodeData.maker.address.toLowerCase()
          ? "You"
          : formatPrefixesUpCaseString(nodeData.maker.address),
      imageUrl: nodeData.maker.imageUrl
    };
  }
  return { from };
};

const getExpirationColumnFromOrdersResult = (nodeData: OrderV2Type) => {
  const timeStamp = moment.utc(nodeData.closedAt).fromNow();
  return { timeStamp };
};

const getStatusColumnFromOrdersResult = (nodeData: OrderV2Type) => {
  const status = nodeData.isValid ? "Valid" : "Expired";
  return {
    status
  };
};

const getButtonCancelColumnFromOrdersResult = (nodeData: OrderV2Type, addressUser: string) => {
  const showCancel = addressUser.toLowerCase() === nodeData.maker.address.toLowerCase();
  const oldOrderString = nodeData.oldOrder;
  return {
    showCancel,
    oldOrderString
  };
};

export const convertOfferItemsFromOrdersResponse = (
  data: OrdersQueryResponseType,
  addressUser: string
): OffersSearchHelperType[] => {
  const listEdges = data.orders.edges;

  if (listEdges) {
    return _.map(
      listEdges,
      (item): OffersSearchHelperType => {
        return {
          ...getKeyFromOrdersResult(item.cursor),
          ...getItemColumnFromOrdersResult(item.node),
          ...getPriceColumnFromEventHistoryResult(item.node),
          ...getQuantityColumnFromOrdersResult(item.node),
          ...getFromColumnFromOrdersResult(item.node, addressUser),
          ...getExpirationColumnFromOrdersResult(item.node),
          ...getStatusColumnFromOrdersResult(item.node),
          ...getButtonCancelColumnFromOrdersResult(item.node, addressUser)
        };
      }
    );
  }

  return [];
};

import "./style.scss";

const OpenSeaLoading = () => {
  return (
    <div className={"lds-spinner-loading"}>
      <div className={"lds-spinner"}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default OpenSeaLoading;

import Web3 from 'web3';
import GetSet from './GetSet.json';

var ethUtil = require('ethereumjs-util');
var sigUtil = require('eth-sig-util');
var Buffer = require('buffer/').Buffer;

const getSetSCAddr = process.env.REACT_APP_GETSET_SC_ADDR;

const encrypter = {
  web3: window.web3,
  ethUtil: ethUtil,
  sigUtil: sigUtil,
  Buffer: Buffer,
  addr: getSetSCAddr,
  // kiểm tra đã cài extension metamask chưa
  checkMetaMaskExist() {
    return !!window.ethereum;
  },
  async setDataContract(address, key, value = '') {
    try {
      let res = await this.__getContractInstance(window.web3)
        .methods['setUserData'](key, value)
        .send({ from: address });
      return res;
    } catch (e) {
      // toast.error(e.message);
      throw e;
    }
  },
  async setDataContractFromPrivateKey(privateKey, address, key, value = '') {
    try {
      let smartContract = await this.__getContractInstance(window.web3);
      const encodeABI = smartContract.methods.setUserData(key, value).encodeABI();
      return await this.sendFuncOfSmartContract(privateKey, address, smartContract, encodeABI);
    } catch (e) {
      // toast.error(e.message);
      throw e;
    }
  },
  async sendFuncOfSmartContract(privateKey, address, smartContract, encodeABI) {
    // const value = await window.web3.eth.estimateGas({
    //   to: smartContract._address,
    //   data: encodeABI
    // })
    const tx = {
      // this could be provider.addresses[0] if it exists
      from: address,
      contractAddress: smartContract._address,
      // target address, this could be a smart contract address
      to: smartContract._address,
      // optional if you want to specify the gas limit
      // gas: 30000,
      // optional if you are invoking say a payable function
      //value: value,
      // this encodes the ABI of the method and the arguements
      data: encodeABI
    };
    tx.gas = Math.ceil(await window.web3.eth.estimateGas(tx));
    const signPromise = await window.web3.eth.accounts.signTransaction(tx, privateKey);
    const sentTx = await window.web3.eth.sendSignedTransaction(signPromise.rawTransaction);
    console.log(sentTx);
    return sentTx;
  },
  async getDataContract(address, key) {
    try {
      let res = await this.__getContractInstance(window.web3)
        .methods['getUserData'](address, key)
        .call();
      return res;
    } catch (e) {
      return e;
    }
  },
  __getContractInstance(web3Instance) {
    const contractAbi = GetSet.abi;
    return new web3Instance.eth.Contract(contractAbi, this.addr);
  },
  async connectToMetaMask() {
    if (this.checkMetaMaskExist()) {
      /* eslint-disable-next-line no-undef */
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const account = await this.getLoginAccount();
      return account;
    } else {
      // TODO: chưa cài extension metamask
      // let env = process.env.VUE_APP_BLOCKCHAIN_ENV ?? 'mainnet';
      // const provider = new Web3.providers.HttpProvider(
      //   'https://' + env + '.infura.io/v3/' + process.env.VUE_APP_INFURA_ID
      // );
      // window.web3 = new Web3(provider);
    }
    return null;
  },
  async getLoginAccount() {
    let accounts;
    accounts = await window.web3.eth.getAccounts((error, accounts) => {
      if (error) return;
      window.web3.eth.defaultAccount = accounts[0];
    });

    if (accounts && accounts.length > 0) {
      return accounts[0];
    }
    return null;
  },

  async encryptByMetaMask(address, data) {
    let publicKey;
    if (this.checkMetaMaskExist()) {
      publicKey = await window.ethereum.request({
        method: 'eth_getEncryptionPublicKey',
        params: [address] // you must have access to the specified account
      });
    } else {
      // TODO: throw exception
      publicKey = '';
    }

    const encryptedMessage = this.encrypt(publicKey, data);

    return encryptedMessage;
  },
  async decryptByMetaMask(address, data) {
    if (this.checkMetaMaskExist()) {
      let decrypt = await window.ethereum.request({
        method: 'eth_decrypt',
        params: [data, address]
      });

      return decrypt;
    }
  },

  async encryptByPublicKey(publicKey, data) {
    const encryptedMessage = this.encrypt(publicKey, data);

    return encryptedMessage;
  },
  async decryptByPrivateKey(privateKey, data) {
    const _privateKey = this.remove0xFromHex(privateKey);
    const _data = this.remove0xFromHex(data);

    const buffer = Buffer.from(_data, 'hex');
    const strData = buffer.toString();
    const rawData = JSON.parse(strData);

    const decrypt = await sigUtil.decrypt(rawData, _privateKey);

    return decrypt;
  },

  encrypt(publicKey, data) {
    const encryptedMessage = this.ethUtil.bufferToHex(
      this.Buffer.from(
        JSON.stringify(this.sigUtil.encrypt(publicKey, { data }, 'x25519-xsalsa20-poly1305')),
        'utf8'
      )
    );

    return encryptedMessage;
  },
  getPublicKeyFromPrivateKey(privateKey) {
    const _privateKey = this.remove0xFromHex(privateKey);
    const publicKey = this.sigUtil.getEncryptionPublicKey(_privateKey);
    return publicKey;
  },
  remove0xFromHex(data) {
    return data[0] === '0' && data[1] === 'x' ? data.substring(2) : data;
  }
};
export default encrypter;

import {
  FeaturesDataType,
  CollectionsDataType,
  CollectionDataType,
  PaymentAssetsDataType,
  DynamicListRangeDataType,
  DynamicListStringTraitDataType
} from "Pages/OpenSea/static/type";
import { FC } from "react";
import { DATA_HEADER } from "../constants";
import CollectionFilter from "../filter_component/Collection";
import RangeFilterPanel from "../filter_component/DynamicNumbericTrait";
import StringTraitFilter from "../filter_component/DynamicStringtrait";
import FeaturedFilter from "../filter_component/Featured";
import PaymentFilter from "../filter_component/Payment";
import useStyles from "./styles";

type FeaturedProps = {
  featuresData: FeaturesDataType;
  handleFilterByFeatures: (selectedFeaturesData: FeaturesDataType) => void;
};

type CollectionProps = {
  collectionsData: CollectionsDataType;
  handleFilterByCollections: (selectedCollections: CollectionDataType[]) => void;
};

type PaymentProps = {
  paymentsData: PaymentAssetsDataType;
  handleFilterByPayments: (selectedPayments: PaymentAssetsDataType) => void;
};

type NumbericProps = {
  numbericTraitsDatas: DynamicListRangeDataType[];
  handleFilterByNumbericTraits: (newNumericTraitsData: DynamicListRangeDataType) => void;
};

type StringTraitProps = {
  stringTraitsDatas: DynamicListStringTraitDataType[];
  handleFilterByStringTraits: (selectedStringTraits: DynamicListStringTraitDataType) => void;
};

export enum ScreenNavigationMode {
  LISTING,
  WALLET,
  ACTIVITY,
  OFFERS
}

type ListingAndWalletNavigationProps = {
  screen: ScreenNavigationMode.LISTING | ScreenNavigationMode.WALLET;
} & FeaturedProps &
  CollectionProps &
  PaymentProps &
  NumbericProps &
  StringTraitProps;

type ActivityNavigationProps = {
  screen: ScreenNavigationMode.ACTIVITY;
} & FeaturedProps &
  CollectionProps;

type OffersNavigationProps = {
  screen: ScreenNavigationMode.OFFERS;
} & CollectionProps;

type AssetNavigationProps =
  | ListingAndWalletNavigationProps
  | ActivityNavigationProps
  | OffersNavigationProps;

/**
 * This component just general layout left navigation.
 * Important: NO LOGIC HERE, only passing the props element from the screen
 * and sort them from top to bottom (if this filter type is displayed).
 * @param props list passing props
 * @returns JSX.Element
 */
const AssetNavigation: FC<AssetNavigationProps> = (props): JSX.Element => {
  const styles = useStyles();

  /**
   * Render featured filter.
   * Display on listing and wallet screen as "Status".
   * Display on activity screen as "Event Type".
   * @returns Component filter
   */
  const renderFeaturedFilter = () => {
    return (
      <>
        {(props.screen === ScreenNavigationMode.LISTING ||
          props.screen === ScreenNavigationMode.WALLET ||
          props.screen === ScreenNavigationMode.ACTIVITY) && (
          <div className={styles.collectionFilterreactDivContainer}>
            <FeaturedFilter
              dataHeader={
                props.screen === ScreenNavigationMode.ACTIVITY
                  ? DATA_HEADER.EVENT_TYPE
                  : DATA_HEADER.STATUS
              }
              featuresData={props.featuresData}
              handleFilterByFeatures={props.handleFilterByFeatures}
            />
          </div>
        )}
      </>
    );
  };

  /**
   * Render Collection filter.
   * Display on all screen (listing, wallet, activity, offers).
   * @returns Component filter
   */
  const renderCollectionFilter = () => {
    return (
      <div className={styles.collectionFilterreactDivContainer}>
        <CollectionFilter
          dataHeader={DATA_HEADER.COLLECTION}
          defaultSearchValue={""}
          collectionsData={props.collectionsData}
          handleFilterByCollections={props.handleFilterByCollections}
        />
      </div>
    );
  };

  /**
   * Render Payment filter.
   * Display on listing and wallet screen.
   * @returns Component filter
   */
  const renderPaymentFilter = () => {
    return (
      <>
        {(props.screen === ScreenNavigationMode.LISTING ||
          props.screen === ScreenNavigationMode.WALLET) && (
          <div className={styles.collectionFilterreactDivContainer}>
            <PaymentFilter
              dataHeader={DATA_HEADER.PAYMENT}
              paymentsData={props.paymentsData}
              handleFilterByPayments={props.handleFilterByPayments}
            />
          </div>
        )}
      </>
    );
  };

  /**
   * Render Dynamic stringtrait filter.
   * Display on listing and wallet screen.
   * @returns Component filter
   */
  const renderStringTraitFilter = () => {
    return (
      <>
        {(props.screen === ScreenNavigationMode.LISTING ||
          props.screen === ScreenNavigationMode.WALLET) &&
          props.stringTraitsDatas.map(stringTraitsData => {
            return (
              <div key={stringTraitsData.key} className={styles.collectionFilterreactDivContainer}>
                <StringTraitFilter
                  dataHeader={{
                    ...DATA_HEADER.STRINGTRAIT,
                    title: stringTraitsData.key
                  }}
                  stringTraitsData={stringTraitsData}
                  handleFilterByStringTraits={props.handleFilterByStringTraits}
                />
              </div>
            );
          })}
      </>
    );
  };

  /**
   * Render Dynamic numberic filter.
   * Display on listing and wallet screen.
   * @returns Component filter
   */
  const renderRangeFilterPanel = () => {
    return (
      <>
        {(props.screen === ScreenNavigationMode.LISTING ||
          props.screen === ScreenNavigationMode.WALLET) &&
          props.numbericTraitsDatas.map(numbericTraitsData => {
            return (
              <div
                key={numbericTraitsData.key}
                className={styles.collectionFilterreactDivContainer}
              >
                <RangeFilterPanel
                  dataHeader={{
                    ...DATA_HEADER.RANGER,
                    title: numbericTraitsData.key
                  }}
                  numericTraitsData={numbericTraitsData}
                  handleFilterByNumbericTraits={props.handleFilterByNumbericTraits}
                />
              </div>
            );
          })}
      </>
    );
  };

  return (
    <>
      <div className={styles.navigationSearch}>
        <div className={styles.searchFilterMain}>
          {renderFeaturedFilter()}
          {renderCollectionFilter()}
          {renderPaymentFilter()}
          {renderStringTraitFilter()}
          {renderRangeFilterPanel()}
        </div>
      </div>
    </>
  );
};

export default AssetNavigation;

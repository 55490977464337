import React from 'react';
import { connect } from 'react-redux';

import AccountNav from './AccountNav/AccountNav';

import './AppHeader.scss';

class AppHeader extends React.Component {
  render() {
    return (
      <div className="AppHeader">
        <h2 className="AppHeader__title_page">{this.props.titlePage.title}</h2>
        <div className="AppHeader__account">
          <AccountNav />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  titlePage: state.titlePage
});

export default connect(mapStateToProps)(AppHeader);

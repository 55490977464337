import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
  return {
    panelIsContentPadded: {
      padding: "12px 0px"
    },

    scrollboxContent: {
      borderColor: "rgba(0, 0, 0, 0)",
      overflowY: "auto",
      transition: "border-color 0.2s ease-in 0s",
      height: 146
    },

    collectionFilterItem: {
      alignItems: "center",
      cursor: "pointer",
      display: "flex",
      minHeight: 36,
      padding: "0px 8px",
      fontSize: 12,
      lineHeight: "12px",
      color: theme?.custom?.colors?.colorWhiteRgba085,

      "&:hover": {
        color: theme?.custom?.colors?.colorLightAtlantis
      },

      "&.active": {
        color: theme?.custom?.colors?.colorLightAtlantis
      }
    },

    iconCollectionFilterItem: {
      color: theme?.custom?.colors?.colorWhiteRgba085,
      width: 14,
      height: 14,
      fontSize: 50,
      marginRight: 10,

      "&.active": {
        color: theme?.custom?.colors?.colorLightAtlantis
      }
    },

    scrollboxreactDivContainer: {
      border: `1px solid ${theme?.custom?.colors?.colorDarkShark}`,
      borderRadius: 2,
      padding: 5
    }
  };
});

export default useStyles;

import { createSelector } from 'reselect';

export const getMarketInfoBySymbol = (state, exchangeName, symbol) =>
  state.markets[exchangeName.toUpperCase()] && state.markets[exchangeName.toUpperCase()][symbol]
    ? state.markets[exchangeName.toUpperCase()][symbol]
    : null;

export const getMarketInfo = createSelector([getMarketInfoBySymbol], info => info);
export const getMarketLimits = createSelector([getMarketInfoBySymbol], info =>
  info ? info.limits : null
);

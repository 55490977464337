import { gql } from '@apollo/client';

export const GET_TRADING_PRICE_HISTORY = gql`
  query PriceHistoryQuery(
    $archetype: ArchetypeInputType
    $bucketSize: BucketSize = WEEK
    $cutoff: DateTime
    $collection: CollectionSlug
  ) {
    ...PriceHistory_data_XmWqM
  }
  fragment PriceHistoryGraph_data on TradeHistoryType {
    results {
      bucketStart
      bucketEnd
      quantity
      volume {
        asset {
          assetContract {
            symbol
            id
          }
          decimals
          id
        }
        quantity
        id
      }
    }
  }
  fragment PriceHistoryStats_data on TradeHistoryType {
    results {
      quantity
      volume {
        asset {
          assetContract {
            symbol
            id
          }
          decimals
          id
        }
        quantity
        id
      }
    }
  }
  fragment PriceHistory_data_XmWqM on Query {
    tradeHistory(
      archetype: $archetype
      minTime: $cutoff
      collection: $collection
      bucketSize: $bucketSize
    ) {
      ...PriceHistoryStats_data
      ...PriceHistoryGraph_data
    }
  }
`;

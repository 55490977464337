import {WETH} from '@uniswap/sdk';
import eth_icon from '../assets/eth.png';
import question_icon from '../assets/question.svg';
import {uriToHttp} from './resolveTokenSymbolLink';

export const getTokenSymbol = token => {
  return (token && token.address.toLowerCase() === WETH[token.chainId].address.toLowerCase())
    ? eth_icon
    : token.tokenInfo
      ? uriToHttp(token.tokenInfo.logoURI)[0]
      : 'https://www.gravatar.com/avatar/09cf3818f52d23cd11a16c40d0614edd?s=32&d=identicon&r=PG';
};

export const isEthByDepo = token => {
  return (
    token.address.toLowerCase() === WETH[token.chainId].address.toLowerCase() &&
    token.symbol === 'ETH'
  );
};

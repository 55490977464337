import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
  return {
    rectangle: {
      backgroundColor: "#2a2b31",
      borderRadius: "5px",
      padding: "15px 26px 25px",
      marginTop: "30px"
    },
    topRectangle: {
      position: "relative",
      minHeight: "70px"
    },
    left: {
      float: "left",
      width: "30%",
      marginTop: "13px",
      fontSize: "12px",
      fontFamily: "Metropolis"
    },
    box: {
      float: "right",
      fontSize: "12px",
      verticalAlign: "top",
      borderRadius: "2px",
      padding: "14px 20px",
      backgroundColor: "rgba(255, 255, 255, 0.08)",
      marginLeft: "12px",
      "& a": {
        color: "#FFF"
      }
    },
    offerTable: {
      width: "100%",
      fontSize: "14px"
    },
    tHead: {
      borderTop: "1px solid rgba(255, 255, 255, 0.1)",
      borderBottom: "1px solid rgba(255, 255, 255, 0.1)"
    },
    headerTableTd: {
      color: "#606166",
      padding: "14px 0"
    },
    textCenter: {
      textAlign: "center"
    },
    contentTable: {
      borderBottom: "1px solid rgba(255, 255, 255, 0.03)"
    },
    contentTableTd: {
      padding: "11px 0",
      verticalAlign: "middle",
      fontSize: "12px",
      lineHeight: "12px"
    },
    itemImage: {
      maxWidth: "25px",
      maxHeight: "25px",
      marginRight: "12px",
      "&.no_image": {
        width: 25,
        height: 25,
        border: `1px solid #707070`,
        background: `#292b2f`
      }
    },

    itemTwoImage: {
      width: "12.5px",
      height: "auto",
      verticalAlign: "middle"
    },

    txtName: {
      display: "inline-block",
      width: "calc(100% - 40px)",
      fontSize: "11px",
      verticalAlign: "middle",
      color: "#fff"
    },
    userImage: {
      maxWidth: "16px",
      maxHeight: "16px",
      borderRadius: "50%",
      verticalAlign: "middle",
      marginRight: "14px"
    },
    btnCancel: {
      borderRadius: "2px",
      padding: "7px 10px",
      backgroundColor: "#3b3c42",
      fontFamily: "Metropolis",
      fontWeight: 400,
      fontSize: "10px",
      color: "#7ac231",
      borderWidth: 0,
      cursor: "pointer"
    },
    noData: {
      borderTop: `1px solid rgba(255, 255, 255, 0.1)`,
      fontSize: 14,
      lineHeight: "20px",
      textAlign: "center",
      color: "rgba(255, 255, 255, 0.6)",
      paddingTop: 50,
      paddingBottom: 50,
      minHeight: 175
    },
    contentLoading: {
      textAlign: "center"
    },

    width300: {
      width: "300px"
    }
  };
});

export default useStyles;

import './RemoveLiquidity.scss';
import React, {useEffect, useMemo, useState, useImperativeHandle, forwardRef} from 'react';
import {Button} from '../../../components';

import QuestionMark from '../../../assets/icons/question-mark-circle.svg';
import SettingCircle from '../../../assets/icons/setting-circle.svg';
import {WETH} from '@uniswap/sdk';
import {getTokenSymbol, isEthByDepo} from '../../../util/getTokenSymbol';
import SimpleRemove from './SimpleRemove/SimpleRemove';
import DetailRemove from './DetailRemove/DetailRemove';
import BigNumber from 'bignumber.js';
import cls from 'classnames';
import {ROUTE_V2_ADDR, ROUTE_V2_ADDR_SUSHI} from '../../Swap/uni/const';
import {useSelector} from 'react-redux';
import {
  approveWithSignedTypeData,
  getPairDataForRemove,
  getPairDetails,
  removeLiquidity,
  removeLiquidityETH
} from '../../../util/liquidity-utils';
import routerV2 from '../../Swap/uni/RouterV2.json';
import ConfirmRemoveModal from '../Modal/ConfirmRemoveModal';
import WaitingRemoveLqModal from '../Modal/WaitingRemoveLqModal';
import usePoolPair from '../hooks/usePoolPair';
import {toast} from 'react-toastify';
import {Tooltip} from '@material-ui/core';
import SettingTransection from '../../../components/SettingTransection/SettingTransection';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {trimLeadingZerosWithDecimal} from '../../../util/input';

const RemoveLiquidity = forwardRef((props, ref) => {
  const {selectedPool, selectedLiquidity, setSelectedPair, web3, setChangedLiquidity} = props;

  const {
    deadline,
    setDeadline,
    activePercent,
    setActivePercent,
    setCheckExport,
    checkedExpert,
    multihops,
    setCheckedMultiphop,
    fetchETHBalance
  } = props;

  const [isSimpleRemove, setIsSimpleRemove] = useState(true);
  const [amountPercent, setAmountPercent] = useState(0);
  const [output1token, setOutput1Token] = useState();
  const [output2token, setOutput2Token] = useState();
  const [liquidityToken, setLiquidityToken] = useState();
  const [liquidityTokenBalance, setLiquidityTokenBalance] = useState();
  const [output1Amount, setOutput1Amount] = useState('');
  const [output2Amount, setOutput2Amount] = useState('');
  const [liquidityAmount, setLiquidityAmount] = useState(0);
  const [inputPrice, setInputPrice] = useState(0);
  const [allowance, setAllowance] = useState(0);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [signatureApprove, setSignatureApprove] = useState();
  const [waitingModalStatus, setWaitingModalStatus] = useState('waiting');
  const [txHash, setTxHash] = useState();
  const [openSetting, setOpenSetting] = useState(false);
  const [, setIsChangeLqAmount] = useState(false);

  const [isOpenWaitingModal, setIsOpenWaitingModal] = useState(false);
  const [pairDetails, setPairDetails] = useState();
  const [loading, setLoading] = useState(false);
  const user = useSelector(state => state.user);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const listButtonPercentage = {'25%': '25', '50%': '50', '75%': '75', MAX: '100'};

  const [, v2Pair, token0Deposited, token1Deposited] = usePoolPair(
    pairDetails,
    selectedLiquidity,
    selectedPool
  );
  // const [TokenAmount] = usePoolLibary(selectedPool);

  useImperativeHandle(ref, () => ({
    set100Percent() {
      setAmountPercent(100);
      onChangePercent(100);
    }
  }));

  const [token0Image, token1Image] = selectedLiquidity
    ? [getTokenSymbol(selectedLiquidity?.token0), getTokenSymbol(selectedLiquidity?.token1)]
    : [undefined, undefined];

  const totalPoolShares = pairDetails
    ? new BigNumber(pairDetails.balance)
      .div(new BigNumber(pairDetails.totalSupply))
      .times(100)
      .toFormat(5)
    : '0.00';

  const onApproveToRemove = async () => {
    try {
      const rawLiquidityAmount = new BigNumber(pairDetails.balance)
        .multipliedBy(new BigNumber(amountPercent))
        .dividedBy(100)
        .toFixed(0);
      setLoadingApprove(true);
      approveWithSignedTypeData(selectedLiquidity, user, rawLiquidityAmount, selectedPool)
        .then(signature => {
          setSignatureApprove(signature);
        })
        .finally(() => {
          setLoadingApprove(false);
        });
    } catch (err) {
      console.log(err.message);
    }
  };

  const onConfirmRemoveLiquidity = async () => {
    const router = selectedPool === 'uni' ? ROUTE_V2_ADDR : ROUTE_V2_ADDR_SUSHI;
    const rawLiquidityAmount = new BigNumber(pairDetails.balance)
      .multipliedBy(new BigNumber(amountPercent))
      .dividedBy(100)
      .toFixed(0);
    const routerUniswap = new web3.eth.Contract(routerV2.abi, router);
    setLoading(true);
    const callBackSuccess = async (data, recipt) => {
      if (recipt && !recipt.status) {
        toast.error('Remove liquidity error!');
        return;
      }
      toast.success('Remove liquidity successfully!');
      const newPairDetail = await getPairDetails(selectedLiquidity.liquidityToken.address, user);
      setPairDetails(newPairDetail);
      const balance = new BigNumber(newPairDetail.balance).div(
        new BigNumber(10).pow(selectedLiquidity.liquidityToken.decimals)
      );
      setLiquidityTokenBalance(balance.toString());
      if (balance.isZero()) {
        setOutput1Token(null);
        setOutput2Token(null);
        setAmountPercent(0);
        onChangePercent(0, true);
      }
      setChangedLiquidity({
        balance: newPairDetail.balance,
        liquidityToken: selectedLiquidity.liquidityToken,
        token0: {
          ...v2Pair.token0,
          tokenInfo: v2Pair.token0.address.toLowerCase() === selectedLiquidity.token0.address.toLowerCase() ? selectedLiquidity.token0.tokenInfo : selectedLiquidity.token1.tokenInfo
        },
        token1: {
          ...v2Pair.token1,
          tokenInfo: v2Pair.token1.address.toLowerCase() === selectedLiquidity.token1.address.toLowerCase() ? selectedLiquidity.token1.tokenInfo : selectedLiquidity.token0.tokenInfo
        }
      });

      await fetchETHBalance();
    };
    const callbackHash = async txHash => {
      setTxHash(txHash);
      setWaitingModalStatus('success');
      setIsOpenConfirm(false);
      setLoading(false);
      if (!checkedExpert) {
        setIsOpenWaitingModal(true);
      }
    };
    const callbackError = error => {
      if (!checkedExpert) {
        setIsOpenWaitingModal(true);
      }
      if (error && error.message === 'Returned error: insufficient funds for gas * price + value') {
        toast.error('Insufficient Transaction Fee');
      }
      setLoading(false);
      setWaitingModalStatus('rejected');
    };
    if (!user.privateKey && !checkedExpert) {
      setIsOpenWaitingModal(true);
      setWaitingModalStatus('waiting');
    }
    if (isEthByDepo(output1token) || isEthByDepo(output2token)) {
      const output1TokenIsEth = isEthByDepo(output1token);
      await removeLiquidityETH(
        output1TokenIsEth ? output2token : output1token,
        rawLiquidityAmount,
        output1TokenIsEth ? output2Amount : output1Amount,
        output1TokenIsEth ? output1Amount : output2Amount,
        user,
        signatureApprove,
        callBackSuccess,
        routerUniswap,
        web3,
        callbackError,
        callbackHash
      );
    } else {
      await removeLiquidity(
        output1token,
        output2token,
        selectedLiquidity.liquidityToken,
        rawLiquidityAmount,
        output1Amount,
        output2Amount,
        user,
        signatureApprove,
        callBackSuccess,
        callbackError,
        web3,
        routerUniswap,
        callbackHash
      );
    }
  };

  const isDisabledButtonApprove = useMemo(() => {
    if (allowance || signatureApprove) return true;
    if (new BigNumber(liquidityTokenBalance).isZero()) return true;
    if (!selectedLiquidity) return true;
    if (new BigNumber(liquidityAmount).gt(liquidityTokenBalance)) return true;
    if (new BigNumber(liquidityAmount || 0).isZero()) return true;
    // if (signatureApprove && signatureApprove.deadline < Math.floor(Date.now() / 1000)) return true;
    //check allowance normal approve
    // if (allowance && liquidityTokenBalance <= 100 * 10 ** selectedLiquidity.liquidityToken.decimals)
    //   return true;
    return false;
  }, [allowance, liquidityAmount, signatureApprove, selectedLiquidity, liquidityTokenBalance]);

  const isDisabledRemoveButton = useMemo(() => {
    if (new BigNumber(liquidityTokenBalance).isZero()) return true;
    if (!selectedLiquidity) return true;
    if (!signatureApprove) return true;
    if (signatureApprove && signatureApprove.deadline < Math.floor(Date.now() / 1000)) return true;
    return !isDisabledButtonApprove;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedLiquidity,
    signatureApprove,
    liquidityAmount,
    liquidityTokenBalance,
    output1Amount,
    output2Amount
  ]);

  useEffect(() => {
    if (pairDetails) {
      setOutput1Token(selectedLiquidity.token0);
      setOutput2Token(selectedLiquidity.token1);
      if (selectedLiquidity.token0.address === pairDetails.token0) {
        setInputPrice(
          new BigNumber(pairDetails.reserves._reserve0)
            .div(new BigNumber(pairDetails.reserves._reserve1))
            .toString()
        );
      } else {
        setInputPrice(
          new BigNumber(pairDetails.reserves._reserve1)
            .div(new BigNumber(pairDetails.reserves._reserve0))
            .toString()
        );
      }
      setLiquidityTokenBalance(
        new BigNumber(pairDetails.balance)
          .div(new BigNumber(10).pow(selectedLiquidity.liquidityToken.decimals))
          .toString()
      );
      setLiquidityToken(selectedLiquidity ? selectedLiquidity.liquidityToken : null);
    } else {
      setInputPrice(0);
      setLiquidityToken(null);
      setLiquidityTokenBalance(0);
      setAllowance(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pairDetails]);

  const onChangePercent = (percentage, isChangeFromLqAmount) => {
    if (
      percentage &&
      token0Deposited &&
      token1Deposited &&
      new BigNumber(liquidityTokenBalance).gt(0)
    ) {
      const token0 = token0Deposited.toSignificant();

      const token1 = token1Deposited.toSignificant();

      setOutput1Amount(
        new BigNumber(token0)
          .times(percentage)
          .div(100)
          .toString()
      );
      setOutput2Amount(
        new BigNumber(token1)
          .times(percentage)
          .div(100)
          .toString()
      );
      if (!isChangeFromLqAmount) {
        setLiquidityAmount(
          new BigNumber(liquidityTokenBalance)
            .times(percentage)
            .div(100)
            .toString()
        );
      }
    }
  };

  useEffect(() => {
    setSignatureApprove(undefined);
  }, [liquidityToken, liquidityAmount, amountPercent]);

  useEffect(() => {
    const fetchPairDetail = async () => {
      const newPairDetail = await getPairDetails(selectedLiquidity.liquidityToken.address, user);
      setPairDetails(newPairDetail);
    };
    if (selectedLiquidity) {
      fetchPairDetail();
    } else if (!selectedLiquidity) {
      setPairDetails(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLiquidity]);

  useEffect(() => {
    if (liquidityTokenBalance && amountPercent && token0Deposited && token1Deposited) {
      setLiquidityAmount(
        new BigNumber(liquidityTokenBalance)
          .times(amountPercent)
          .div(100)
          .toString()
      );
      setOutput1Amount(
        new BigNumber(token0Deposited.toSignificant())
          .times(amountPercent)
          .div(100)
          .toString()
      );
      setOutput2Amount(
        new BigNumber(token1Deposited.toSignificant())
          .times(amountPercent)
          .div(100)
          .toString()
      );
    } else {
      setOutput1Amount(0);
      setOutput2Amount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liquidityTokenBalance, token0Deposited, token1Deposited]);

  useEffect(() => {
    const asyncTmp = async () => {
      if (output1token && output2token) {
        try {
          const dataForRemove = await getPairDataForRemove(
            output1token,
            output2token,
            user,
            selectedPool
          );
          setSelectedPair(dataForRemove);
        } catch (e) {
          setSelectedPair(null);
          setLiquidityTokenBalance(0);
        }
      }
    };
    asyncTmp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPool]);

  return (
    <div className="remove-liquidity-container">
      <div className="form-icon-help">
        <Tooltip
          classes={{tooltip: 'tooltip_mnemonic'}}
          title="hihi"
          arrow
          placement="bottom"
          TransitionComponent={() => {
            return (
              <div className="tooltip_liquidity">
                Removing pool tokens converts your position back into underlying tokens at the
                current rate, proportional to your share of the pool. Accrued fees are included in
                the amounts you receive.
              </div>
            );
          }}
        >
          <img src={QuestionMark} className="question-mark" alt="" />
        </Tooltip>
        <ClickAwayListener onClickAway={() => setOpenSetting(false)}>
          <div className="setting-wrapper">
            <img
              src={SettingCircle}
              onClick={() => setOpenSetting(prev => !prev)}
              className="setting-circle"
              alt=""
            />
            {openSetting ? (
              <SettingTransection
                dealine={deadline}
                activePercent={activePercent}
                checkedExpert={checkedExpert}
                checkedMultihop={multihops}
                setDealine={setDeadline}
                setActivePercent={setActivePercent}
                setCheckedExpert={setCheckExport}
                setCheckedMultihop={setCheckedMultiphop}
              />
            ) : null}
          </div>
        </ClickAwayListener>
      </div>
      <div className="form-row">
        <div className="form-row-label">
          <div className="amount-label">Amount</div>
          <div className="detail-label" onClick={() => setIsSimpleRemove(!isSimpleRemove)}>
            {isSimpleRemove ? 'Detailed' : 'Simple'}
          </div>
        </div>
        <div className="form-row-percent">{amountPercent ? `${amountPercent} %` : '---'}</div>
        {isSimpleRemove ? (
          <React.Fragment>
            <div className="slider-row">
              <input
                className="slider-range"
                type="range"
                min="0"
                max="100"
                onChange={event => {
                  const percentage = event.target.value;
                  setAmountPercent(percentage);
                  onChangePercent(percentage);
                }}
                value={amountPercent}
              />
            </div>
            <div className="form-button-group">
              {Object.keys(listButtonPercentage).map(e => {
                const percent = listButtonPercentage[e];
                return (
                  <Button
                    key={e}
                    className={cls({active: amountPercent.toString() === percent})}
                    onClick={() => {
                      setAmountPercent(percent);
                      onChangePercent(percent);
                    }}
                  >
                    <span>{e}</span>
                  </Button>
                );
              })}
            </div>
          </React.Fragment>
        ) : (
          ''
        )}
      </div>
      {isSimpleRemove ? (
        <SimpleRemove
          output1token={output1token}
          output2token={output2token}
          output1Amount={output1Amount}
          output2Amount={output2Amount}
        />
      ) : (
        <DetailRemove
          output1token={output1token}
          output2token={output2token}
          setOutput1Amount={setOutput1Amount}
          setOutput2Amount={setOutput2Amount}
          output1Amount={output1Amount}
          output2Amount={output2Amount}
          liquidityTokenBalance={liquidityTokenBalance}
          setOutput2Token={setOutput2Token}
          setOutput1Token={setOutput1Token}
          liquidityAmount={liquidityAmount}
          setSelectedPair={setSelectedPair}
          inputPrice={inputPrice}
          selectedPool={selectedPool}
          setLiquidityAmount={setLiquidityAmount}
          setAmountPercent={setAmountPercent}
          onChangePercent={onChangePercent}
          setIsChangeLqAmount={setIsChangeLqAmount}
          v2Pair={v2Pair}
          token0Deposited={token0Deposited}
          token1Deposited={token1Deposited}
        />
      )}
      <div className="price-share-pool">
        <div className="price-share-title">Prices</div>
        <div className="price-share-container">
          <div className="price-share-column border-right ">
            <div className="price-share-value">
              {v2Pair ? v2Pair.token0Price.toSignificant() : '---'}
            </div>
            {v2Pair ? (
              <div className="price-share-label">
                {v2Pair?.token1.symbol ?? '---'} per {v2Pair?.token0.symbol ?? '---'}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="price-share-column ">
            <div className="price-share-value">
              {v2Pair ? v2Pair.token1Price.toSignificant() : '---'}
            </div>
            {v2Pair ? (
              <div className="price-share-label">
                {v2Pair?.token0.symbol ?? '---'} per {v2Pair?.token1.symbol ?? '---'}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      <div className="approve-button-group">
        <Button
          disabled={isDisabledButtonApprove}
          onClick={() => onApproveToRemove()}
          loading={loadingApprove}
          className="approve-btn"
        >
          Approve
        </Button>
        <Button
          disabled={isDisabledRemoveButton}
          loading={loading}
          onClick={() => {
            if (checkedExpert) {
              onConfirmRemoveLiquidity();
            } else {
              setIsOpenConfirm(true);
            }
          }}
          className="remove-btn"
        >
          {new BigNumber(output1Amount).gt(0) ? 'Remove' : 'Enter an Amount'}
        </Button>
      </div>

      {new BigNumber(liquidityTokenBalance).isGreaterThan(0) && selectedLiquidity ? (
        <div className="box-position-bottom">
          <div className="position-box-title">Your Position</div>
          <div className="position-box-row">
            <div className="position-label image-container">
              <img alt="" src={token0Image ?? 'https://via.placeholder.com/150'} />
              <img alt="" src={token1Image ?? 'https://via.placeholder.com/150'} />
              {output1token?.symbol}/{output2token?.symbol}
            </div>
            <div className="position-value">
              {trimLeadingZerosWithDecimal(new BigNumber(liquidityTokenBalance).toFixed(5))}
            </div>
          </div>
          <div className="position-box-row">
            <div className="position-label image-container">Your Pool Share</div>
            <div className="position-value">
              {new BigNumber(totalPoolShares).lte(0) ? `< ${0.01}` : totalPoolShares}%
            </div>
          </div>
          <div className="position-box-row">
            <div className="position-label image-container">
              {selectedLiquidity?.token0.symbol ?? '-'}
            </div>
            <div className="position-value">
              {token0Deposited && token0Deposited.toSignificant()}
            </div>
          </div>
          <div className="position-box-row">
            <div className="position-label image-container">
              {selectedLiquidity?.token1.symbol ?? '-'}
            </div>
            <div className="position-value">
              {token1Deposited && token1Deposited.toSignificant()}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      <ConfirmRemoveModal
        isOpen={isOpenConfirm}
        handleClose={() => setIsOpenConfirm(false)}
        output1Amount={output1Amount}
        output2Amount={output2Amount}
        output1Token={output1token}
        output2Token={output2token}
        inputPrice={inputPrice}
        liquidityTokenAmount={liquidityAmount}
        onConfirmSupply={onConfirmRemoveLiquidity}
        v2Pair={v2Pair}
        loading={loading}
      />
      <WaitingRemoveLqModal
        status={waitingModalStatus}
        output1Amount={output1Amount}
        output2Amount={output2Amount}
        handleClose={() => setIsOpenWaitingModal(false)}
        isOpen={isOpenWaitingModal}
        output1Token={output1token}
        output2Token={output2token}
        liquidityAmount={liquidityAmount}
        txHash={txHash}
      />
    </div>
  );
});

export default RemoveLiquidity;

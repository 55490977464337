import useStyles from "./styles";
import StarIcon from "@material-ui/icons/Star";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export type DataHeaderProps = {
  icon: string;
  title: string;
  hiddenDisplay?: boolean; // default is false => show display
};

type BaseHeaderProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
} & DataHeaderProps;

const BaseHeader: React.FC<BaseHeaderProps> = props => {
  const styles = useStyles();

  const { icon, title, isOpen, setIsOpen } = props;
  return (
    <>
      <header
        className={styles.panelHeader}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {icon ? (
          <i className={styles.panelHeaderIcon}>
            <img alt="" src={`/images/icons/${icon}.svg`} />
          </i>
        ) : (
          <StarIcon className={styles.panelHeaderIcon} />
        )}
        {title}
        <i className={styles.panelToggle}>{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</i>
      </header>
    </>
  );
};

export default BaseHeader;

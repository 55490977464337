import BaseService from './BaseService';
import exchangeLookup from '../exchange/exchange-lookup';

class OrderService extends BaseService {
  validate(exchange, symbol) {
    if (!exchange) {
      throw new Error('No exchange specified');
    }

    if (!symbol) {
      throw new Error('No Symbol specified');
    }
  }

  async fetch(exchange, symbol) {
    this.validate(exchange, symbol);
    const data = await exchangeLookup[exchange].fetchOrderBook(symbol);
    return {
      exchange: exchange,
      orders: data
    };
  }
}

export default OrderService.getInstance();

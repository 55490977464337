import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cls from 'classnames';

import Page from 'components/Page/Page';
import { Row, Col, Container } from 'components/GridSystem/GridSystem';

import ExchangeSwitcher from './ExchangeSwitcher/ExchangeSwitcher';
import MarketsList from './MarketsList/MarketsList';
import TopMarkets from './TopMarkets/TopMarkets';
import TopCoinsCharts from './TopCoinsCharts/TopCoinsCharts';

import { formatPercentage, formatVolume } from '_helpers/formatting';

import { changeTitlePage } from '_actions/titlePage.actions';

import './MarketOverview.scss';
import HeaderWarning from 'components/HeaderCommon/HeaderWarning';

export class MarketOverview extends React.Component {
  static propTypes = {
    marketcap: PropTypes.object.isRequired,
    selectedExchange: PropTypes.object,
    sortedMarkets: PropTypes.object,
    quote: PropTypes.string,
    quotes: PropTypes.array,
    history: PropTypes.object.isRequired,
    selectTopCoinsQuote: PropTypes.func.isRequired,
    markets: PropTypes.object,
    exchangegecko: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedQuote: 'BTC',
      searchValueTable: '',
      currentPage: 1,
      selectPage: 1,
      changeTab: 1
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.selectedExchange !== nextProps.selectedExchange) {
      this.setState({
        selectedQuote: 'BTC'
      });
    }
  }
  handleSearchValue(e) {
    const searchValue = e.target.value.toUpperCase();
    this.setState({
      searchValueTable: searchValue,
      currentPage: 0,
      selectPage: 0,
      changeTab: 0
    });
  }

  handleOnBlur() {
    this.setState({
      currentPage: 0,
      selectPage: 0
    });
  }

  handleChangeTab() {
    this.setState({
      changeTab: 1
    });
  }

  onChangeQuote(quote) {
    this.setState({
      selectedQuote: quote,
      searchValueTable: '',
      currentPage: 1,
      selectPage: 1,
      changeTab: 0
    });

    this.props.selectTopCoinsQuote(quote);
    this.props.selectTopMarketsPage(quote, 0);
  }

  renderHeader() {
    const { marketcap, exchangegecko } = this.props;
    return (
      <div className="MarketOverview__Header">
        <Container className="no-padding">
          <Row className="no-margin">
            <Col size="6">
              <div className="MarketOverview__Header-value">{}</div>
            </Col>
            <Col size="3">
              <div className="MarketOverview__Header-secondary-title">24h Volume(BTC)</div>
              <div className="MarketOverview__Header-secondary-value">
                {formatVolume(exchangegecko[0] ? exchangegecko[0].trade_volume_24h_btc : '', {
                  precision: 2
                })}
              </div>
            </Col>
            <Col size="3">
              <div className="MarketOverview__Header-secondary-title">BTC Dominance</div>
              <div className="MarketOverview__Header-secondary-value">
                {marketcap.data &&
                  marketcap.data.market_cap_percentage &&
                  formatPercentage(marketcap.data.market_cap_percentage.btc, {
                    precision: 2
                  })}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  componentDidMount() {
    this.props.dispatch(changeTitlePage('Market'));
  }

  render() {
    const {
      user,
      selectedExchange,
      markets,
      quotes,
      sortedMarkets,
      selections,
      history,
      topCoins,
      selectTopCoinsQuote,
      selectTopMarketsPage,
      selectTopMarketsSorting
    } = this.props;

    const { selectedQuote, searchValueTable, currentPage, selectPage, changeTab } = this.state;
    const title = `${selectedExchange.label} - Market overview`;

    return (
      <Page title={title} className="MarketOverview">
        <Container>
          {user.isWC && <HeaderWarning user={user} />}
          <Row>
            <Col size="9" grow>
              {this.renderHeader()}
            </Col>
            <Col size="3" className="Side-column">
              <ExchangeSwitcher
                selectedExchange={selectedExchange}
                handleExchange={this.onChangeQuote.bind(this)}
              />
            </Col>
          </Row>

          <Row>
            <Col size="9" grow>
              <TopCoinsCharts
                selections={selections}
                selectedExchange={selectedExchange}
                quotes={quotes}
                coins={topCoins}
                selectTopCoinsQuote={selectTopCoinsQuote}
              />
            </Col>
            <Col size="3" overflow className="Side-column">
              <MarketsList selectedExchange={selectedExchange} height={355} />
            </Col>
          </Row>
          <section className="TopMarkets__container">
            <div className="TopMarkets__Header-title">
              <Row>
                <Col size="9">
                  {selectedQuote.toUpperCase()} Markets on {selectedExchange.label}
                </Col>
                <Col size="3">
                  <input
                    className="Markets__inputSearchTable"
                    name="filter"
                    placeholder="Search..."
                    autoComplete="off"
                    onChange={e => this.handleSearchValue(e)}
                    onBlur={this.handleOnBlur.bind(this)}
                  />
                </Col>
              </Row>
            </div>
            <div className="TopMarkets__list">
              <div className="quote-list">
                {quotes.map((quote, i) => (
                  <div
                    onClick={() => this.onChangeQuote(quote)}
                    className={cls({ active: quote === selectedQuote })}
                    key={i}
                  >
                    {quote}
                  </div>
                ))}
              </div>
            </div>
            {quotes.length > 0 ? (
              <TopMarkets
                sortedMarkets={sortedMarkets}
                quote={selectedQuote}
                selections={selections}
                selectedExchange={selectedExchange}
                lastRefresh={markets.meta.timestamp}
                searchValue={searchValueTable}
                history={history}
                selectTopMarketsPage={selectTopMarketsPage}
                selectTopMarketsSorting={selectTopMarketsSorting}
                current_page={currentPage}
                select_page={selectPage}
                changeTab={changeTab}
                handleChangeTab={this.handleChangeTab.bind(this)}
              />
            ) : (
              ''
            )}
            {quotes.length % 2 !== 0 && <div className="TopMarkets__placeholder" />}
          </section>
        </Container>
      </Page>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(MarketOverview);

import {
  CollectionDataType,
  PaymentAssetsDataType,
  DynamicListStringTraitDataType,
  DynamicListRangeDataType,
  FeaturesDataType
} from "Pages/OpenSea/static/type";
import { FC } from "react";
import { useWalletGlobalContext } from "../context/WalletGlobalContext";
import {
  getAssetsFeaturesDataFromR2,
  getCollectionDataFromR2,
  updateAssetsVariablesByFeaturesData,
  updateAssetsVariablesByCollections,
  getPaymentsDataFromR2,
  updateAssetsVariablesByPayments,
  getStringTraitDataFromR2,
  getNumbericTraitDataFromR2,
  updateAssetsVariablesByNumbericTraits,
  updateAssetsVariablesByStringTraits
} from "../../commons/helper";
import AssetNavigation, {
  ScreenNavigationMode
} from "../../commons/AssetsNavigation/AssetNavigation";

const WalletNavigation: FC = (): JSX.Element => {
  const { mappingDataStorage: data, variables, setNewVariables } = useWalletGlobalContext();

  const handleFilterByFeatures = (selectedFeaturesData: FeaturesDataType) => {
    setNewVariables(updateAssetsVariablesByFeaturesData(selectedFeaturesData, variables));
  };

  const handleFilterByCollections = (selectedCollections: CollectionDataType[]) => {
    setNewVariables(updateAssetsVariablesByCollections(selectedCollections, variables));
  };

  const handleFilterByPayments = (selectedPayments: PaymentAssetsDataType) => {
    setNewVariables(updateAssetsVariablesByPayments(selectedPayments, variables));
  };

  const handleFilterByStringTraits = (selectedStringTraits: DynamicListStringTraitDataType) => {
    setNewVariables(updateAssetsVariablesByStringTraits(selectedStringTraits, variables));
  };

  const handleFilterByNumbericTraits = (newNumericTraitsData: DynamicListRangeDataType) => {
    setNewVariables(updateAssetsVariablesByNumbericTraits(newNumericTraitsData, variables));
  };

  return (
    <>
      <AssetNavigation
        screen={ScreenNavigationMode.WALLET}
        featuresData={getAssetsFeaturesDataFromR2(variables)}
        handleFilterByFeatures={handleFilterByFeatures}
        collectionsData={getCollectionDataFromR2(variables, data)}
        handleFilterByCollections={handleFilterByCollections}
        paymentsData={getPaymentsDataFromR2(variables, data)}
        handleFilterByPayments={handleFilterByPayments}
        stringTraitsDatas={getStringTraitDataFromR2(variables, data)}
        handleFilterByStringTraits={handleFilterByStringTraits}
        numbericTraitsDatas={getNumbericTraitDataFromR2(variables, data)}
        handleFilterByNumbericTraits={handleFilterByNumbericTraits}
      />
    </>
  );
};

export default WalletNavigation;

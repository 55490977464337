import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
  return {
    tabHeaderAccount: {
      display: "flex",
      alignItems: "center",
      borderBottom: `1px solid ${theme?.custom?.colors?.colorDarkShark}`
    },

    itemTab: {
      cursor: "pointer",
      padding: "0 25px",
      overflow: "hidden",
      fontSize: 14,
      lineHeight: "18px",
      textAlign: "center",
      transition: "all 0.2s ease 0s",
      marginBottom: "-1px"
    },

    iconTab: {
      height: 18,
      marginRight: 10
    },

    itemBorder: {
      padding: "15px 0",
      borderBottom: "4px solid transparent",
      display: "flex",
      alignItems: "center",
      color: theme?.custom?.colors?.white,
      opacity: 0.6,

      "&.acvite": {
        fontWeight: "bold",
        borderColor: theme?.custom?.colors?.colorLightAtlantis,
        opacity: 1
      }
    }
  };
});

export default useStyles;

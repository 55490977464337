import ModalCommon from '../../../components/ModalCommon/ModalCommon';
import Spinner from '../../../components/Spinner';
import IconSubmitted from '../../../assets/icons/icon-submitted.svg';
import IconRejected from '../../../assets/icons/icon-rejected.svg';
import { useCallback } from 'react';
import './AddLiquidityModal.scss';
import icon_close from '../../../assets/swap/close_icon.svg';
import { Button } from '../../../components';
import { CHAIN_ID } from '../../Swap/uni/const';

export default function WaitingRemoveLqModal(props) {
  const { status, output1Token, output2Token, output1Amount, output2Amount, txHash } = props;

  const renderTitle = useCallback(() => {
    if (status === 'success') {
      return 'Transaction Submitted';
    } else if (props.status === 'rejected') {
      return 'Error';
    } else {
      return 'Waiting For Confirmation';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const renderIcon = () => {
    if (props.status === 'success') {
      return <img alt="" src={IconSubmitted} />;
    } else if (props.status === 'rejected') {
      return <img alt="" src={IconRejected} />;
    } else {
      return <Spinner />;
    }
  };

  const renderText = useCallback(() => {
    if (props.status === 'success') {
      return (
        <div className="content-success">
          <a
            className="view-on-ether"
            target="_blank"
            href={`${
              CHAIN_ID === '4'
                ? `https://rinkeby.etherscan.io/tx/${txHash}`
                : `https://etherscan.io/tx/${txHash}`
            }`}
            rel="noreferrer"
          >
            View on Etherscan
          </a>
          <div className="close-wrapper">
            <Button onClick={() => props.handleClose()}>Close</Button>
          </div>
        </div>
      );
    } else if (props.status === 'rejected') {
      return (
        <div className="content-rejected">
          <div className="message-rejected">Transaction rejected.</div>
          <div className="dismiss-wrapper">
            <Button onClick={() => props.handleClose()}>Dismiss</Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="content-waiting">
          <div className="content-waiting-row1">
            Removing {output1Amount} {output1Token?.symbol} and {output2Amount}{' '}
            {output2Token?.symbol} from your wallet.
          </div>
          <div className="content-waiting-row2">Confirm this transaction in your wallet.</div>
        </div>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, output1Amount, output2Amount, output1Token, output2Token, txHash]);

  return (
    <ModalCommon open={props.isOpen} handleClose={() => props.handleClose}>
      <div className="waiting-liquidity-modal">
        <div className="waiting-lq-title">
          {renderTitle()}
          <img
            className="btn-icon-close"
            alt=""
            src={icon_close}
            onClick={() => {
              props.handleClose();
            }}
          />
        </div>
        <div className="waiting-lq-content">
          <div className="spinner-wrapper">{renderIcon()}</div>
          <div className="content-text">{renderText()}</div>
        </div>
      </div>
    </ModalCommon>
  );
}

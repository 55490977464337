import React, {useState, useEffect, useMemo} from 'react';
import './ListToken.scss';
import close_icon from '../../../assets/swap/close_icon.svg';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import icon_search from '../../../assets/swap/search_icon.svg';
import {getDefaultListToken, INFURA, WETH_TOKEN} from '../../Swap/uni/const';
import Web3 from 'web3';
import ERC20 from '../../Swap/uni/ERC20.json';
import isEmpty from 'lodash/isEmpty';
import ManageToken from '../../Swap/ManageToken/ManageToken';
import {useSelector, useDispatch} from 'react-redux';
import {save_token_in_list} from '../../../_actions/swap.action';
import classNames from 'classnames';
import left_arrow from '../../../assets/swap/left-arrow.png';
import {trimLeadingZerosWithDecimal} from '../../../util/input';

import * as uni_func from '../../Swap/function';

import * as sushi_func from '../../Swap/function_sushi';

import * as UNI_SDK from '@uniswap/sdk';
import * as SUSHI_SDK from '@sushiswap/sdk';

let web3 = new Web3(new Web3.providers.HttpProvider(INFURA));

function ItemToken(props) {
  const {
    item,
    user,
    currentTarget,
    setInputToken,
    setOutputToken,
    handleClose,
    inputToken,
    outputToken
  } = props;
  let isComponentMouted = false;
  const {checkedPool} = useSelector(state => state.swapPage);
  const [balance, setBalance] = useState(Number);
  const setTokenCurrent = async item => {
    const contractToken = new web3.eth.Contract(ERC20.abi, item.address);

    let decimals = Number(await contractToken.methods.decimals().call());

    let updateItem = {
      ...item,
      decimals
    };

    const itemWithBalance = {...updateItem, balance};

    if (currentTarget === 'input-token-name') {
      setInputToken(itemWithBalance);
    } else {
      setOutputToken(itemWithBalance);
    }
    handleClose();
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isComponentMouted = true;
    const fetchData = async () => {
      try {
        let balanceCoin = await new web3.eth.getBalance(user.address);
        let balanceCoinConvert = web3.utils.fromWei(balanceCoin);

        const dataBalance = await new web3.eth.Contract(ERC20.abi, item.address).methods
          .balanceOf(user.address)
          .call();

        await new web3.eth.Contract(ERC20.abi, item.address).methods.decimals().call();

        if (isComponentMouted) {
          setBalance(
            // JIG-447: Hot-Fix Wrap and Unwrap WETH
            // Begin Hot-Fix
            item.address === WETH_TOKEN && item.symbol.toLowerCase() === 'eth'
              ? // End Hot-Fix
              checkedPool === 'uni_swap'
                ? new UNI_SDK.TokenAmount(
                  UNI_SDK.ETHER,
                  balanceCoinConvert * 10 ** 18
                ).toSignificant(6)
                : new SUSHI_SDK.TokenAmount(
                  UNI_SDK.ETHER,
                  balanceCoinConvert * 10 ** 18
                ).toSignificant(6)
              : checkedPool === 'uni_swap'
                ? trimLeadingZerosWithDecimal(new UNI_SDK.TokenAmount(uni_func.getCurrencyToken(item), dataBalance).toFixed(6))
                : trimLeadingZerosWithDecimal(new SUSHI_SDK.TokenAmount(
                  sushi_func.getCurrencyToken(item),
                  dataBalance
                ).toFixed(6)
                ));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    return () => {
      isComponentMouted = false;
    };
  }, []);

  return (
    <div
      className={classNames(
        {
          'item-token-disable':
            //JIG-447: Hot-Fix Wrap and Unwrap WETH
            // Begin Hot-Fix
            (item?.address === inputToken?.address && item?.symbol === inputToken?.symbol) ||
            (item?.address === outputToken?.address && item?.symbol === outputToken?.symbol)
          // End Hot-Fix
        },
        'item-token'
      )}
      onClick={() => setTokenCurrent(item)}
    >
      <div className="container">
        <div className="item-token__logo">
          <img src={item.logoURI} alt="" />
        </div>
        <div className="wraper">
          <div className="item-token__symbol">{item.symbol}</div>
          <span className="item-token__name">{item.name}</span>
        </div>
        <div className="item-token__amount-token">{balance}</div>
      </div>
    </div>
  );
}

function ItemMemo(props) {
  const {
    item,
    user,
    currentTarget,
    setInputToken,
    setOutputToken,
    handleClose,
    inputToken,
    outputToken
  } = props;
  return useMemo(
    () => (
      <ItemToken
        item={item}
        user={user}
        setOutputToken={setOutputToken}
        setInputToken={setInputToken}
        currentTarget={currentTarget}
        handleClose={handleClose}
        inputToken={inputToken}
        outputToken={outputToken}
      />
    ),
    [item, user, currentTarget, setInputToken, setOutputToken, handleClose, inputToken, outputToken]
  );
}

function ItemSearch(props) {
  const dispatch = useDispatch();
  const {tokenSearch, listData, setTokenSearch, setValueSearch} = props;

  const addToken = () => {
    // setListData([...listData, tokenSearch]);
    dispatch(save_token_in_list([tokenSearch]));
    setTokenSearch(null);
    setValueSearch('');
  };

  const checkBtn = tokenSearchAddress => {
    let listDataUpDate = listData.map(item => {
      if (item.tokens) {
        return [...item.tokens];
      }
      return item;
    });

    if (
      listDataUpDate
        .flat()
        .some(
          item =>
            item.address.toLowerCase() === tokenSearchAddress.toLowerCase() &&
            item.symbol.toLowerCase() !== 'eth'
        )
    ) {
      return <div className="item-token__btn-active">ACTIVE</div>;
    } else {
      return (
        <div className="item-token__btn-import" onClick={addToken}>
          IMPORT
        </div>
      );
    }
  };

  return (
    <div className="item-token">
      <div className="container">
        <div className="item-token__logo">
          <img src={tokenSearch.logoURI} alt="" />
        </div>
        <div className="wraper">
          <div className="item-token__symbol">{tokenSearch.symbol}</div>
          <span className="item-token__name">{tokenSearch.name}</span>
        </div>
        {checkBtn(tokenSearch.address)}
      </div>
    </div>
  );
}

export default function ListToken(props) {
  const [valueSearch, setValueSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [tokenSearch, setTokenSearch] = useState([]);
  const [tab, setTab] = useState('select_token');
  // const listTokenHasBeenAdded = useSelector(state => state.swapPage).listTokenAdd;
  const listData = useSelector(state => state.swapPage).listToken;
  const dispatch = useDispatch();

  const dataTokenInfor = localStorage.getItem('dataTokenInfor')
    ? JSON.parse(localStorage.getItem('dataTokenInfor'))
    : null; // null

  useEffect(() => {
    if (!isEmpty(listData)) {
      return;
    } else {
      if (dataTokenInfor) {
        // tinh o day
        // dispatch(save_token_in_list(dataTokenInfor));
        // return;
      } else {
        // tinh o day
        const fetchData = async () => {
          try {
            setLoading(true);
            let chain_id = await new web3.eth.net.getId();
            const list_data = getDefaultListToken(chain_id);
            // setListData(listData);
            dispatch(save_token_in_list(list_data));
            setLoading(false);
          } catch (error) {
            console.log(error);
          }
        };
        fetchData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(listData)) {
      localStorage.setItem('dataTokenInfor', JSON.stringify(listData));
    }
  }, [listData]);

  const handleSearch = React.useCallback(async () => {
    try {
      const contractToken = new web3.eth.Contract(ERC20.abi, valueSearch.trim());
      let symbol = await contractToken.methods.symbol().call();
      let name = await contractToken.methods.name().call();
      let decimals = Number(await contractToken.methods.decimals().call());
      let chainId = await new web3.eth.net.getId();
      let address = valueSearch.trim();
      let logoURI =
        'https://www.gravatar.com/avatar/09cf3818f52d23cd11a16c40d0614edd?s=32&d=identicon&r=PG';
      let isDefault = false;
      let tokenResult = {
        name,
        symbol,
        decimals,
        chainId,
        address,
        logoURI,
        isDefault
      };

      setTokenSearch([tokenResult]);
    } catch (error) {
      let listDataUpDate = listData.map(item => {
        if (item.tokens) {
          return [...item.tokens];
        }
        return item;
      });
      let listResult = listDataUpDate.flat().filter(item => {
        return (
          item.name?.toLowerCase().includes(valueSearch.toLowerCase().trim()) ||
          item.symbol?.toLowerCase().includes(valueSearch.toLowerCase().trim())
        );
      });
      setTokenSearch(listResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueSearch]);

  useEffect(() => {
    if (valueSearch.trim() !== '') {
      handleSearch();
    } else {
      setTokenSearch([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueSearch]);

  return (
    <div className="wrap-content">
      <div className="wrap-content__header">
        {tab === 'select_token' ? null : (
          <img
            src={left_arrow}
            className="img-left"
            onClick={() => setTab('select_token')}
            alt=""
          />
        )}
        {tab === 'select_token' ? 'Select a Token' : 'Manage'}{' '}
        <img src={close_icon} className="img-right" onClick={props.handleClose} alt="" />
      </div>
      <div className="wrap-content__body">
        {tab === 'select_token' ? (
          <>
            <TextField
              autoComplete="off"
              placeholder="Search name or paste address"
              id="form-search-token"
              value={valueSearch}
              onChange={e => setValueSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    {valueSearch !== '' ? (
                      <img
                        src={close_icon}
                        className="close_icon_search"
                        onClick={() => setValueSearch('')}
                        alt=""
                      />
                    ) : (
                      <img src={icon_search} alt="" />
                    )}
                  </InputAdornment>
                )
              }}
            />
            <div className="list-token">
              {tokenSearch && !isEmpty(tokenSearch) ? (
                tokenSearch.map(item => (
                  <ItemSearch
                    key={item.address}
                    tokenSearch={item}
                    listData={listData}
                    // setListData={setListData}
                    setTokenSearch={setTokenSearch}
                    setValueSearch={setValueSearch}
                  />
                ))
              ) : (
                <>
                  {!loading ? (
                    listData.map((item, indexList) => {
                      if (item.tokens) {
                        return item.tokens.map((ele, index) => {
                          return (
                            <ItemMemo
                              item={ele}
                              user={props.user}
                              key={index}
                              setOutputToken={props.setOutputToken}
                              setInputToken={props.setInputToken}
                              currentTarget={props.currentTarget}
                              handleClose={props.handleClose}
                              inputToken={props.inputToken}
                              outputToken={props.outputToken}
                            />
                          );
                        });
                      }
                      return (
                        <ItemMemo
                          item={item}
                          user={props.user}
                          key={indexList}
                          setOutputToken={props.setOutputToken}
                          setInputToken={props.setInputToken}
                          currentTarget={props.currentTarget}
                          handleClose={props.handleClose}
                          inputToken={props.inputToken}
                          outputToken={props.outputToken}
                        />
                      );
                    })
                  ) : (
                    <div
                      style={{
                        color: '#fff'
                      }}
                    >
                      Loading ...
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        ) : (
          <ManageToken />
        )}
      </div>
      {tab === 'select_token' && (
        <div className="wrap-content__footer">
          <div className="footer-content" onClick={() => setTab('manage')}>
            <span>Manage</span>
          </div>
        </div>
      )}
    </div>
  );
}

// isExpertMode

// import React, { useState, useEffect, useMemo } from "react";
// import "./ListToken.scss";
// import close_icon from "../../../assets/swap/close_icon.svg";
// import TextField from "@material-ui/core/TextField";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import icon_search from "../../../assets/swap/search_icon.svg";
// import { getDefaultListToken, INFURA, WETH_TOKEN } from "../uni/const";
// import Web3 from "web3";
// import ERC20 from "../uni/ERC20.json";
// import isEmpty from "lodash/isEmpty";
// import ManageToken from "../ManageToken/ManageToken";
// import { useSelector, useDispatch } from "react-redux";
// import { save_token_in_list } from "../../../_actions/swap.action";
// import classNames from "classnames";
// import { unWrappedNameToken } from "../../../util/getWrappedToken";
// import { debounce } from "debounce";
// import left_arrow from "../../../assets/swap/left-arrow.png";
// import { config } from "../../../../src/config";

// import * as uni_func from "../function";

// import * as sushi_func from "../function_sushi";

// import * as UNI_SDK from "@uniswap/sdk";
// import * as SUSHI_SDK from "@sushiswap/sdk";

// let web3 = new Web3(new Web3.providers.HttpProvider(INFURA));

// function ItemToken(props) {
//   const {
//     item,
//     user,
//     currentTarget,
//     setInputToken,
//     setOutputToken,
//     handleClose,
//     inputToken,
//     outputToken
//   } = props;
//   let isComponentMouted = false;
//   const { checkedPool } = useSelector(state => state.swapPage);
//   const [balance, setBalance] = useState(Number);
//   const setTokenCurrent = async item => {
//     const contractToken = new web3.eth.Contract(ERC20.abi, item.address);

//     let decimals = Number(await contractToken.methods.decimals().call());

//     let updateItem = {
//       ...item,
//       decimals
//     };

//     const itemWithBalance = { ...updateItem, balance };

//     if (currentTarget === "input-token-name") {
//       setInputToken(itemWithBalance);
//     } else {
//       setOutputToken(itemWithBalance);
//     }
//     handleClose();
//   };

//   // useEffect(() => {
//   //   isComponentMouted = true;
//   //   const fetchData = async () => {
//   //     try {
//   //       let balanceCoin = await new web3.eth.getBalance(user.address);
//   //       let balanceCoinConvert = web3.utils.fromWei(balanceCoin);

//   //       const dataBalance = await new web3.eth.Contract(ERC20.abi, item.address).methods
//   //         .balanceOf(user.address)
//   //         .call();

//   //       // const dataDecimal = await new web3.eth.Contract(ERC20.abi, item.address).methods
//   //       //   .decimals()
//   //       //   .call();

//   //       if (isComponentMouted) {
//   //         // setBalance(
//   //         //   item.address === WETH_TOKEN
//   //         //     ? Number(balanceCoinConvert).toFixed(5)
//   //         //     : (dataBalance / 10 ** dataDecimal).toFixed(5)
//   //         // );

//   //         setBalance(
//   //           item.address === WETH_TOKEN
//   //             ? checkedPool === "uni_swap"
//   //               ? new UNI_SDK.TokenAmount(
//   //                   UNI_SDK.ETHER,
//   //                   balanceCoinConvert * 10 ** 18
//   //                 ).toSignificant(6)
//   //               : new SUSHI_SDK.TokenAmount(
//   //                   SUSHI_SDK.ETHER,
//   //                   balanceCoinConvert * 10 ** 18
//   //                 ).toSignificant(6)
//   //             : checkedPool === "uni_swap"
//   //             ? new UNI_SDK.TokenAmount(uni_func.getCurrencyToken(item), dataBalance).toSignificant(
//   //                 6
//   //               )
//   //             : new SUSHI_SDK.TokenAmount(
//   //                 sushi_func.getCurrencyToken(item),
//   //                 dataBalance
//   //               ).toSignificant(6)
//   //         );
//   //       }
//   //     } catch (error) {
//   //       console.log(error);
//   //     }
//   //   };
//   //   fetchData();
//   //   return () => {
//   //     isComponentMouted = false;
//   //   };
//   // }, []);

//   return (
//     <div
//       className={classNames(
//         {
//           "item-token-disable":
//             item?.address === inputToken?.address || item?.address === outputToken?.address
//         },
//         "item-token"
//       )}
//       onClick={() => setTokenCurrent(item)}
//     >
//       <div className="container">
//         <div className="item-token__logo">
//           <img src={item.logoURI} />
//         </div>
//         <div className="wraper">
//           <div className="item-token__symbol">{item.symbol === "WETH" ? "ETH" : item.symbol}</div>
//           <span className="item-token__name">{item.name}</span>
//         </div>
//         <div className="item-token__amount-token">{item.balance}</div>
//       </div>
//     </div>
//   );
// }

// function ItemMemo(props) {
//   const {
//     item,
//     user,
//     currentTarget,
//     setInputToken,
//     setOutputToken,
//     handleClose,
//     inputToken,
//     outputToken
//   } = props;
//   return useMemo(
//     () => (
//       <ItemToken
//         item={item}
//         user={user}
//         setOutputToken={setOutputToken}
//         setInputToken={setInputToken}
//         currentTarget={currentTarget}
//         handleClose={handleClose}
//         inputToken={inputToken}
//         outputToken={outputToken}
//       />
//     ),
//     [item, user, currentTarget, setInputToken, setOutputToken, handleClose, inputToken, outputToken]
//   );
// }

// function ItemSearch(props) {
//   const dispatch = useDispatch();
//   const { tokenSearch, listData, setListData, setTokenSearch, setValueSearch } = props;

//   const addToken = () => {
//     // setListData([...listData, tokenSearch]);
//     dispatch(save_token_in_list([tokenSearch]));
//     setTokenSearch(null);
//     setValueSearch("");
//   };

//   const checkBtn = tokenSearchAddress => {
//     let listDataUpDate = listData.map(item => {
//       if (item.tokens) {
//         return [...item.tokens];
//       }
//       return item;
//     });

//     if (
//       listDataUpDate
//         .flat()
//         .some(item => item.address.toLowerCase() === tokenSearchAddress.toLowerCase())
//     ) {
//       return <div className="item-token__btn-active">ACTIVE</div>;
//     } else {
//       return (
//         <div className="item-token__btn-import" onClick={addToken}>
//           IMPORT
//         </div>
//       );
//     }
//   };

//   return (
//     <div className="item-token">
//       <div className="container">
//         <div className="item-token__logo">
//           <img src={tokenSearch.logoURI} />
//         </div>
//         <div className="wraper">
//           <div className="item-token__symbol">{tokenSearch.symbol}</div>
//           <span className="item-token__name">{tokenSearch.name}</span>
//         </div>
//         {checkBtn(tokenSearch.address)}
//       </div>
//     </div>
//   );
// }

// export default function ListToken(props) {
//   const { checkedPool } = useSelector(state => state.swapPage);
//   const [valueSearch, setValueSearch] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [tokenSearch, setTokenSearch] = useState([]);
//   const [tab, setTab] = useState("select_token");
//   // const listTokenHasBeenAdded = useSelector(state => state.swapPage).listTokenAdd;
//   const listData = useSelector(state => state.swapPage).listToken;
//   const dispatch = useDispatch();

//   const dataTokenInfor = localStorage.getItem("dataTokenInfor")
//     ? JSON.parse(localStorage.getItem("dataTokenInfor"))
//     : null; // null

//   useEffect(() => {
//     if (!isEmpty(listData)) {
//       return;
//     } else {
//       if (dataTokenInfor) {
//         // tinh o day
//         dispatch(save_token_in_list(dataTokenInfor));
//         return;
//       } else {
//         // tinh o day
//         const fetchData = async () => {
//           try {
//             setLoading(true);
//             // let chain_id = await new web3.eth.net.getId();

//             const list_data = getDefaultListToken(config.chain_id);
//             const getBalance = async item => {
//               let balanceCoin = await new web3.eth.getBalance(props.user.address);
//               let balanceCoinConvert = web3.utils.fromWei(balanceCoin);

//               const dataBalance = await new web3.eth.Contract(ERC20.abi, item.address).methods
//                 .balanceOf(props.user.address)
//                 .call();
//               if (checkedPool === "uni_swap") {
//                 return item.address === WETH_TOKEN
//                   ? new UNI_SDK.TokenAmount(
//                       UNI_SDK.ETHER,
//                       balanceCoinConvert * 10 ** 18
//                     ).toSignificant(6)
//                   : new UNI_SDK.TokenAmount(
//                       uni_func.getCurrencyToken(item),
//                       dataBalance
//                     ).toSignificant(6);
//               }
//               return item.address === WETH_TOKEN
//                 ? new SUSHI_SDK.TokenAmount(
//                     SUSHI_SDK.ETHER,
//                     balanceCoinConvert * 10 ** 18
//                   ).toSignificant(6)
//                 : new SUSHI_SDK.TokenAmount(
//                     sushi_func.getCurrencyToken(item),
//                     dataBalance
//                   ).toSignificant(6);
//             };

//             let listTokenInforUpdate = list_data.map(async item => {
//               return {
//                 ...item,
//                 balance: await getBalance(item)
//               };
//             });

//             dispatch(save_token_in_list(await Promise.all(listTokenInforUpdate)));
//             setLoading(false);
//           } catch (error) {
//             console.log(error);
//           }
//         };
//         fetchData();
//       }
//     }
//   }, []);

//   useEffect(() => {
//     if (!isEmpty(listData)) {
//       localStorage.setItem("dataTokenInfor", JSON.stringify(listData));
//     }
//   }, [listData]);

//   const handleSearch = React.useCallback(async () => {
//     try {
//       const contractToken = new web3.eth.Contract(ERC20.abi, valueSearch.trim());
//       let symbol = await contractToken.methods.symbol().call();
//       let name = await contractToken.methods.name().call();
//       let decimals = Number(await contractToken.methods.decimals().call());
//       let chainId = await new web3.eth.net.getId();
//       let address = valueSearch.trim();
//       let logoURI =
//         "https://www.gravatar.com/avatar/09cf3818f52d23cd11a16c40d0614edd?s=32&d=identicon&r=PG";
//       let isDefault = false;
//       let tokenResult = {
//         name,
//         symbol,
//         decimals,
//         chainId,
//         address,
//         logoURI,
//         isDefault
//       };
//       console.log(tokenResult);
//       setTokenSearch([tokenResult]);
//     } catch (error) {
//       let listDataUpDate = listData.map(item => {
//         if (item.tokens) {
//           return [...item.tokens];
//         }
//         return item;
//       });
//       let listResult = listDataUpDate.flat().filter(item => {
//         return (
//           item.name?.toLowerCase().includes(valueSearch.toLowerCase().trim()) ||
//           item.symbol?.toLowerCase().includes(valueSearch.toLowerCase().trim())
//         );
//       });
//       setTokenSearch(listResult);
//     }
//   }, [valueSearch]);

//   useEffect(() => {
//     if (valueSearch.trim() !== "") {
//       handleSearch();
//     } else {
//       setTokenSearch([]);
//     }
//   }, [valueSearch]);

//   return (
//     <div className="wrap-content">
//       <div className="wrap-content__header">
//         {tab === "select_token" ? null : (
//           <img src={left_arrow} className="img-left" onClick={() => setTab("select_token")} />
//         )}
//         {tab === "select_token" ? "Select a Token" : "Manage"}{" "}
//         <img src={close_icon} className="img-right" onClick={props.handleClose} />
//       </div>
//       <div className="wrap-content__body">
//         {tab === "select_token" ? (
//           <>
//             <TextField
//               autoComplete="off"
//               placeholder="Search name or paste address"
//               id="form-search-token"
//               value={valueSearch}
//               onChange={e => setValueSearch(e.target.value)}
//               InputProps={{
//                 startAdornment: (
//                   <InputAdornment>
//                     {valueSearch !== "" ? (
//                       <img
//                         src={close_icon}
//                         className="close_icon_search"
//                         onClick={() => setValueSearch("")}
//                       />
//                     ) : (
//                       <img src={icon_search} />
//                     )}
//                   </InputAdornment>
//                 )
//               }}
//             />
//             <div className="list-token">
//               {tokenSearch && !isEmpty(tokenSearch) ? (
//                 tokenSearch.map(item => (
//                   <ItemSearch
//                     key={item.address}
//                     tokenSearch={item}
//                     listData={listData}
//                     // setListData={setListData}
//                     setTokenSearch={setTokenSearch}
//                     setValueSearch={setValueSearch}
//                   />
//                 ))
//               ) : (
//                 <>
//                   {!loading ? (
//                     listData.map(item => {
//                       if (item.tokens) {
//                         return item.tokens.map(ele => {
//                           return (
//                             <ItemMemo
//                               item={ele}
//                               user={props.user}
//                               key={ele.address}
//                               setOutputToken={props.setOutputToken}
//                               setInputToken={props.setInputToken}
//                               currentTarget={props.currentTarget}
//                               handleClose={props.handleClose}
//                               inputToken={props.inputToken}
//                               outputToken={props.outputToken}
//                             />
//                           );
//                         });
//                       }
//                       return (
//                         <ItemMemo
//                           item={item}
//                           user={props.user}
//                           key={item.address}
//                           setOutputToken={props.setOutputToken}
//                           setInputToken={props.setInputToken}
//                           currentTarget={props.currentTarget}
//                           handleClose={props.handleClose}
//                           inputToken={props.inputToken}
//                           outputToken={props.outputToken}
//                         />
//                       );
//                     })
//                   ) : (
//                     <div
//                       style={{
//                         color: "#fff"
//                       }}
//                     >
//                       Loading ...
//                     </div>
//                   )}
//                 </>
//               )}
//             </div>
//           </>
//         ) : (
//           <ManageToken />
//         )}
//       </div>
//       {tab === "select_token" && (
//         <div className="wrap-content__footer">
//           <div className="footer-content" onClick={() => setTab("manage")}>
//             <span>Manage</span>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

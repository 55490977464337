const lookup_map = {
  binance: {
    translator: require('./binance/translator')
  },

  gdax: {
    translator: require('./gdax/translator')
  },

  kraken: {
    translator: require('./kraken/translator')
  },

  poloniex: {
    translator: require('./poloniex/translator')
  },

  kucoin: {
    translator: require('./kucoin/translator')
  },

  bittrex: {
    translator: require('./bittrex/translator')
  }
};

module.exports = lookup_map;

const initialState = '0';

const btcPrice = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_BTC_PRICE': {
      return action.btcPrice;
    }

    default:
      return state;
  }
};

export default btcPrice;

import BaseService from './BaseService';
import _ from 'lodash';

class BalanceService extends BaseService {
  binanceApi(exchangeName, apiKey = null, secret = null) {
    return this.getExchangeApi(exchangeName, apiKey, secret);
  }

  async getNetworkBalance(exchangeName) {
    const balance = this.getExchangeApi(exchangeName).fetchBalance();
    console.log(balance);
    return balance;
  }

  async getExchangeBalance(exchangeName) {
    const balance = await this.binanceApi(exchangeName).fetchBalance();

    delete balance.free;
    delete balance.info;
    delete balance.total;
    delete balance.used;
    delete balance.datetime;
    delete balance.timestamp;

    let exBalance = _.forEach(balance, function(value) {
      value.available = value.free;
      value.reserved = value.used;
      value.usdValue = null;
    });

    return {
      valuesMap: {
        ...exBalance
      }
    };
  }

  async getBalances(exchangeName, market) {
    await this.binanceApi(exchangeName).fetchBalance(market);
    return {
      valuesMap: {
        BTC: {
          available: 0,
          total: 0,
          reserved: 0,
          usdValue: null
        },
        BCH: {
          available: 0,
          total: 0,
          reserved: 0,
          usdValue: null
        },
        BTXCRD: {
          available: 1000.59241788,
          total: 1000.59241788,
          reserved: 0,
          usdValue: null
        },
        USD: {
          available: 1e-8,
          total: 1e-8,
          reserved: 0,
          usdValue: null
        },
        ETH: {
          available: 0,
          total: 0,
          reserved: 0,
          usdValue: null
        },
        USDT: {
          available: 11.97857389,
          total: 11.97857389,
          reserved: 0,
          usdValue: null
        },
        NXT: {
          available: 0,
          total: 0,
          reserved: 0,
          usdValue: null
        },
        DGB: {
          available: 0,
          total: 300,
          reserved: 300,
          usdValue: null
        }
      }
    };
  }

  convertOpenOrders(openOrders) {
    return _.forEach(openOrders, function(order) {
      order.symbolPair = order.symbol;
      const types = order.type.split('_');
      const type = types[1] ? types[1].toUpperCase() : types[0].toUpperCase();
      order.offerType = order.side + ' ' + type;
      order.status = order.status === 'closed' ? 'traded' : order.status;
      order.amount = order.amount ? order.amount : order.info.fillQuantity;
      order.price = order.price
        ? Number(parseFloat(order.average ? order.average : order.price).toFixed(8))
        : Number(parseFloat(order.cost / order.info.fillQuantity).toFixed(8));
      order.total = order.cost || Number(parseFloat(order.price * order.amount).toFixed(8));
    });
  }

  convertCloseOrders(closeOrders) {
    return _.forEach(closeOrders, function(order) {
      order.symbolPair = order.symbol;
      const types = order.type.split('_');
      const type = types[1] ? types[1].toUpperCase() : types[0].toUpperCase();
      order.offerType = order.side + ' ' + type;
      order.status = order.status === 'closed' ? 'traded' : order.status;
      order.amount = order.amount ? order.amount : order.info.fillQuantity;
      order.price = order.price
        ? Number(parseFloat(order.average ? order.average : order.price).toFixed(8))
        : Number(parseFloat(order.cost / order.info.fillQuantity).toFixed(8));
      order.total = order.cost || Number(parseFloat(order.price * order.amount).toFixed(8));
    });
  }

  async getOrders(exchangeName, symbol) {
    const closeOrders =
      exchangeName.toLowerCase() === 'binance'
        ? []
        : await this.binanceApi(exchangeName).fetchClosedOrders(symbol);
    const openOrders = await this.binanceApi(exchangeName).fetchOpenOrders(symbol);
    const open = this.convertCloseOrders(openOrders);
    const close = this.convertCloseOrders(closeOrders);
    return {
      open: open,
      closed: close
    };
  }

  async getExchangeOrders(exchangeName) {
    const closeOrders =
      exchangeName.toLowerCase() === 'binance'
        ? []
        : await this.binanceApi(exchangeName).fetchClosedOrders();
    const openOrders = await this.binanceApi(exchangeName).fetchOpenOrders();
    const open = this.convertCloseOrders(openOrders);
    const close = this.convertCloseOrders(closeOrders);
    return {
      open: open,
      closed: close
    };
  }
}
export default BalanceService.getInstance();

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import farmsConfig from '../constants/farmsConfig';
import { getAddress } from '../../../util/getAddresses';
import multicall from '../../../util/multicall';
import BigNumber from 'bignumber.js';
import erc20 from '../../../abi/ERC20.json';
import MasterchefJSON from '../../../abi/Masterchef.json';
import PairJSON from '../../../abi/Pair.json';
import { config } from '../../../config';

const MASTERCHEF_ADDRESS = config.masterchef_address || '';

const useFarms = () => {
  const [pools, setPools] = useState(null);
  const { address } = useSelector(state => state.user);

  const fetchDefaultFarms = async () => {
    const data = await Promise.all(
      farmsConfig.map(async farmConfig => {
        const lpAddress = getAddress(farmConfig.lpAddresses);
        if (lpAddress) {
          const calls = [
            // Balance of token in the LP contract
            {
              address: getAddress(farmConfig.tokenAddresses),
              name: 'balanceOf',
              params: [lpAddress]
            },
            // Balance of quote token on LP contract
            {
              address: getAddress(farmConfig.quoteTokenAdresses),
              name: 'balanceOf',
              params: [lpAddress]
            },
            // Balance of LP tokens in the master chef contract
            {
              address: lpAddress,
              name: 'balanceOf',
              params: [MASTERCHEF_ADDRESS]
            },
            // Total supply of LP tokens
            {
              address: lpAddress,
              name: 'totalSupply'
            },
            // Token decimals
            {
              address: getAddress(farmConfig.tokenAddresses),
              name: 'decimals'
            },
            // Quote token decimals
            {
              address: getAddress(farmConfig.quoteTokenAdresses),
              name: 'decimals'
            },
            {
              address: lpAddress,
              name: 'balanceOf',
              params: [address]
            },
            {
              address: lpAddress,
              name: 'decimals'
            }
          ];

          const masterchefCall = [
            {
              address: lpAddress,
              name: 'getReserves'
            }
          ];

          const [
            tokenBalanceLP,
            quoteTokenBlanceLP,
            lpTokenBalanceMC,
            lpTotalSupply,
            tokenDecimals,
            quoteTokenDecimals,
            balance,
            lpTokenDecimals
          ] = await multicall(erc20.abi, calls);

          const [reserves] = await multicall(PairJSON, masterchefCall);
          const { _reserve0, _reserve1 } = reserves;

          // Ratio in % a LP tokens that are in staking, vs the total number in circulation
          const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply));

          // Total value in staking in quote token value
          const lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP)
            .div(new BigNumber(10).pow(18))
            .times(new BigNumber(2))
            .times(lpTokenRatio);

          // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
          const tokenAmount = new BigNumber(tokenBalanceLP)
            .div(new BigNumber(10).pow(tokenDecimals))
            .times(lpTokenRatio);
          const quoteTokenAmount = new BigNumber(quoteTokenBlanceLP)
            .div(new BigNumber(10).pow(quoteTokenDecimals))
            .times(lpTokenRatio);

          const [info, totalAllocPoint, { amount }, pendingSushi] = await multicall(
            MasterchefJSON,
            [
              {
                address: MASTERCHEF_ADDRESS,
                name: 'poolInfo',
                params: [farmConfig.pid]
              },
              {
                address: MASTERCHEF_ADDRESS,
                name: 'totalAllocPoint'
              },
              {
                address: MASTERCHEF_ADDRESS,
                name: 'userInfo',
                params: [farmConfig.pid, address]
              },
              {
                address: MASTERCHEF_ADDRESS,
                name: 'pendingSushi',
                params: [farmConfig.pid, address]
              }
            ]
          );

          const allocPoint = new BigNumber(info.allocPoint._hex);
          const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint));

          return {
            ...farmConfig,
            tokenAmount: tokenAmount.toJSON(),
            quoteTokenAmount: quoteTokenAmount.toJSON(),
            lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
            tokenPriceVsQuote: tokenAmount.gt(0)
              ? quoteTokenAmount.div(tokenAmount).toJSON()
              : new BigNumber(0),
            poolWeight: poolWeight.toJSON(),
            multiplier: `${allocPoint.div(100).toString()}X`,
            allocPoint,
            reserve0: new BigNumber(_reserve1._hex).div(new BigNumber(10).pow(tokenDecimals)),
            reserve1: new BigNumber(_reserve0._hex).div(new BigNumber(10).pow(quoteTokenDecimals)),
            stakedLPToken: new BigNumber(amount._hex).div(
              new BigNumber(10).pow(quoteTokenDecimals)
            ),
            pendingSushi: new BigNumber(pendingSushi).div(new BigNumber(10).pow(18)),
            balance: new BigNumber(balance[0]._hex).div(new BigNumber(10).pow(lpTokenDecimals)),
            lpTokenDecimals
          };
        }

        return null;
      })
    );

    data && setPools(data);
  };

  useEffect(() => {
    fetchDefaultFarms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [pools, fetchDefaultFarms];
};

export default useFarms;

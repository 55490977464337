import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
  return {
    panelHeader: {
      alignItems: "center",
      cursor: "pointer",
      display: "flex",
      fontSize: 18,
      fontWeight: 500,
      padding: "16px 0px",
      color: theme?.custom?.colors?.colorWhiteRgba085,
      userSelect: "none",
      border: 0,
      margin: 0,
      borderBottom: `1px solid ${theme?.custom?.colors?.colorDarkShark}`
    },

    panelHeaderIcon: {
      marginRight: 10,
      color: theme?.custom?.colors?.colorWhiteRgba085,
      "& img": {
        verticalAlign: "inherit",
        width: 16,
        height: 16
      },
      fontSize: "24px",
      lineHeight: 1
    },

    panelToggle: {
      marginLeft: "auto",
      color: theme?.custom?.colors?.colorLightAtlantis
    }
  };
});

export default useStyles;

import SearchInput, { ACTION_CHANGED } from "../../base_component/SearchInput/SearchInput";
import CollectionItem from "./CollectionItem";
import update from "immutability-helper";
import { CHOOSE_ITEM } from "../Featured/FeaturedItem";
import { useEffect, useState } from "react";
import _ from "lodash";
import { CollectionsDataType, CollectionDataType } from "Pages/OpenSea/static/type";
import { withBaseHeader, WithBaseHeaderType } from "../../base_component/BaseHeader";
import useStyles from "./styles";

type CollectionFilterType = {
  defaultSearchValue?: string;
  collectionsData: CollectionsDataType;
  handleFilterByCollections: (selectedCollections: CollectionDataType[]) => void;
} & WithBaseHeaderType;

const CollectionFilter: React.FC<CollectionFilterType> = (props): JSX.Element => {
  const styles = useStyles();

  const { defaultSearchValue, collectionsData, handleFilterByCollections } = props;

  const [collections, setCollections] = useState(collectionsData.collections);
  const [textSearch, setTextSearch] = useState("");
  useEffect(() => {
    if (collectionsData.collections.length > 0) {
      setCollections(collectionsData.collections);
    }
  }, [collectionsData.collections]);

  const onChooseItem = (choosedId: string, action: CHOOSE_ITEM) => {
    const indexUpdate = collections.findIndex(collection => collection.slug === choosedId);
    if (indexUpdate >= 0) {
      const newCollections = update(collections, {
        [indexUpdate]: { isSelected: { $set: action === CHOOSE_ITEM.CHECK } }
      });
      setCollections(newCollections);
      const selectedCollections = _.cloneDeep(newCollections).filter(
        collection => collection.isSelected === true
      );
      handleFilterByCollections(selectedCollections);
    }
  };

  const filterCollectionBySearchInput = (collections: CollectionDataType[], textSearch: string) => {
    if (_.isEmpty(textSearch)) {
      return collections;
    }
    return _.filter(collections, collection =>
      _.includes(_.toLower(collection.name), _.toLower(textSearch))
    );
  };

  return (
    <div className={styles.panelIsContentPadded}>
      <SearchInput
        defaultValue={defaultSearchValue}
        handleChangedValue={(value: string, _actionChanged: ACTION_CHANGED) => {
          setTextSearch(value);
        }}
        followActionChanged={[ACTION_CHANGED.DEBOUNCE]}
      />
      <div className={styles.scrollboxreactDivContainer}>
        <div className={styles.scrollboxContent}>
          {filterCollectionBySearchInput(collections, textSearch).map(collection => {
            return (
              <CollectionItem
                key={collection.id}
                id={collection.slug}
                name={collection.name}
                isChecked={collection.isSelected}
                imgUrl={collection.imageUrl ?? ""}
                onChooseItem={(choosedId: string, action: CHOOSE_ITEM) => {
                  setTextSearch("");
                  onChooseItem(choosedId, action);
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

CollectionFilter.defaultProps = {
  defaultSearchValue: ""
};

export default withBaseHeader(CollectionFilter);

import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Clock from 'assets/icons/icon-clock.svg';
import { shortenAddress } from '../../../../../util/getAddresses';
import useLoadWhenScroll from '../../commons/nfts_hooks/useLoadWhenScroll';

const TradingHistory = ({
  fetchTradingHistoryByToken,
  tradingFilterField,
  setTradingFilterFiled,
  tokenDetails,
  tradingHistory,
  scrollHandler
}) => {
  const [filter, setFilter] = useState(false);
  const [tradingFilterText, setTradingFilterText] = useState('Filter');
  const tradingTableRef = useRef(null);

  const user = useSelector(state => state.user);

  useLoadWhenScroll(tradingTableRef, tradingHistory && tradingHistory.length > 0, scrollHandler);

  useEffect(() => {
    const fetchAsync = async () => {
      tokenDetails && (await fetchTradingHistoryByToken(true, tradingFilterField));
      tradingTableRef.current && tradingTableRef.current.scrollTo(0, 0);
    };

    fetchAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradingFilterField, tokenDetails, tradingTableRef]);

  return (
    <div className="trading">
      <div className="trading__inner">
        <div className="trading__head">
          <img src={Clock} alt="" />
          <span className="trading__text-head">Trade History</span>
        </div>
        <div className="trading__filter">
          <div className="trading__filter-btn" onClick={() => setFilter(!filter)}>
            <div className="trading__filter-text">
              <span>{tradingFilterText}</span>
            </div>
            <div className="trading__filter-icon">
              <svg
                width="10"
                height="7"
                viewBox="0 0 10 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.825 0L5 3.81667L1.175 0L0 1.175L5 6.175L10 1.175L8.825 0Z"
                  fill="#606166"
                />
              </svg>
            </div>
          </div>
          <div className={'trading__filter-item ' + (filter ? 'show-filter' : 'hidden-filter')}>
            <ul>
              <li
                onClick={async () => {
                  setFilter(false);
                  setTradingFilterText('All');
                  setTradingFilterFiled(null);
                }}
              >
                All
              </li>
              <li
                onClick={async () => {
                  setFilter(false);
                  setTradingFilterText('Listings');
                  setTradingFilterFiled('AUCTION_CREATED');
                }}
              >
                Listings
              </li>
              <li
                onClick={async () => {
                  setFilter(false);
                  setTradingFilterText('Sales');
                  setTradingFilterFiled('AUCTION_SUCCESSFUL');
                }}
              >
                Sales
              </li>
              <li
                onClick={async () => {
                  setFilter(false);
                  setTradingFilterText('Bids');
                  setTradingFilterFiled('OFFER_ENTERED');
                }}
              >
                Bids
              </li>
              <li
                onClick={async () => {
                  setFilter(false);
                  setTradingFilterText('Transfers');
                  setTradingFilterFiled('ASSET_TRANSFER');
                }}
              >
                Transfers
              </li>
            </ul>
          </div>
        </div>
      </div>
      <table className="trading-history">
        <thead className="trading__title">
          <th>Event</th>
          <th>Price</th>
          <th>From</th>
          <th>To</th>
          <th>Date</th>
        </thead>
        <tbody ref={tradingTableRef}>
          {tradingHistory && tradingHistory.length > 0 ? (
            tradingHistory.map(trading => (
              <tr key={trading.id} className="trading__rows">
                <td className="trading__list-name">
                  {trading.eventType && trading.eventType.icon && (
                    <img
                      src={trading.eventType.icon}
                      alt="trading-icon"
                      className="trading__list-icon"
                    />
                  )}
                  <span>{trading.eventType && trading.eventType.text}</span>
                  {trading.isExpired && (
                    <span style={{ color: 'red', fontSize: 10, marginLeft: 5 }}>Expired</span>
                  )}
                </td>
                <td className="trading__list-price">
                  {trading.priceSymbol} {trading.price}
                </td>
                <td>
                  {trading.from && (
                    <>
                      <img className="trading__acc-img" src={trading.from.imageUrl} alt="" />
                      <span>
                        {trading.from.userAddress
                          ? trading.from.userAddress.toLowerCase() === user.address.toLowerCase()
                            ? 'You'
                            : shortenAddress(trading.from.address)
                          : shortenAddress(trading.from.address)}
                      </span>
                    </>
                  )}
                </td>
                <td>
                  {trading.to && (
                    <>
                      <img
                        className="trading__acc-img"
                        src={trading.to.imageUrl}
                        style={{ width: 30, height: 30, borderRadius: '50%' }}
                        alt=""
                      />
                      <span>
                        {trading.to.userAddress
                          ? trading.to.userAddress.toLowerCase() === user.address.toLowerCase()
                            ? 'You'
                            : shortenAddress(trading.to.address)
                          : shortenAddress(trading.to.address)}
                      </span>
                    </>
                  )}
                </td>
                <td>
                  {trading.blockExplorerLink ? (
                    <a
                      href={trading.blockExplorerLink}
                      target="_blank"
                      style={{ display: 'flex', alignItems: 'center' }}
                      rel="noreferrer"
                    >
                      {trading.timeStamp}
                    </a>
                  ) : (
                    trading.timeStamp
                  )}
                </td>
              </tr>
            ))
          ) : (
            <div className="detail-item__notify">
              <span>No trading history yet.</span>
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TradingHistory;

import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
  return {
    navigationSearch: {
      alignSelf: "flex-start",
      flexShrink: 0,
      height: "calc(100vh - 72px)",
      position: "sticky",
      top: "72px",
      width: "350px"
    },

    searchFilterMain: {
      top: "initial",
      zIndex: 3,
      width: "100%",
      padding: 20,
      backgroundColor: theme?.custom?.colors?.backgroundColorLeft,
      height: "100%",
      overflow: "auto",
      borderRadius: 5
    },

    collectionFilterreactDivContainer: {
      marginBottom: 10
    }
  };
});

export default useStyles;

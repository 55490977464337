import { getGeckoOverview, getAllByExchange } from '_api/coingecko';

/* ------------------ coinmarketcap data ------------------------- */

export const FETCH_MARKETCAP_REQUEST = '@moon/FETCH_MARKETCAP_REQUEST';
export const FETCH_MARKETCAP_SUCCESS = '@moon/FETCH_MARKETCAP_SUCCESS';
export const FETCH_MARKETCAP_ERROR = '@moon/FETCH_MARKETCAP_ERROR';

export const FETCH_EXCHANGE_REQUEST = '@moon/FETCH_EXCHANGE_REQUEST';
export const FETCH_EXCHANGE_SUCCESS = '@moon/FETCH_EXCHANGE_SUCCESS';
export const FETCH_EXCHANGE_ERROR = '@moon/FETCH_EXCHANGE_ERROR';

export function fetchMarketcapOverviewRequest(exchange) {
  return {
    type: FETCH_MARKETCAP_REQUEST,
    exchange
  };
}

export function fetchMarketcapOverviewSuccess(marketcap) {
  return {
    type: FETCH_MARKETCAP_SUCCESS,
    marketcap
  };
}

export function fetchMarketcapOverviewError(error) {
  return {
    type: FETCH_MARKETCAP_ERROR,
    error
  };
}

export function fetchExchangeRequest(exchangeName) {
  return {
    type: FETCH_EXCHANGE_REQUEST,
    exchangeName
  };
}

export function fetchExchangeSuccess(exchange, exchangeName) {
  return {
    type: FETCH_EXCHANGE_SUCCESS,
    exchange,
    exchangeName
  };
}

export function fetchExchangeError(error, exchangeName) {
  return {
    type: FETCH_EXCHANGE_ERROR,
    error,
    exchangeName
  };
}

export function fetchMarketcapOverview(value) {
  return dispatch => {
    dispatch(fetchMarketcapOverviewRequest(value));
    return getGeckoOverview(value).then(
      data => {
        dispatch(fetchMarketcapOverviewSuccess(data));
      },
      error => dispatch(fetchMarketcapOverviewError(error))
    );
  };
}

export function fetchExchange(exchangeName) {
  exchangeName = exchangeName.toLowerCase();
  return dispatch => {
    dispatch(fetchExchangeRequest(exchangeName));
    return getAllByExchange(exchangeName).then(
      data => {
        dispatch(fetchExchangeSuccess(data, exchangeName));
      },
      error => dispatch(fetchExchangeError(error, exchangeName))
    );
  };
}

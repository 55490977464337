import _ from "lodash";
import { useState } from "react";

export const useStateStorage = <T>(
  initialValue: T
): [T, (value: T) => void, (value: T) => boolean, (newValue: T) => void] => {
  const [value, setValue] = useState<T>(initialValue);
  const [oldValue, setOldValue] = useState<T>(initialValue);

  const isEqualOldValue = (currentValue: T): boolean => {
    return _.isEqual(oldValue, currentValue);
  };

  const updateOldValue = (newValue: T) => {
    if (newValue) {
      setValue(newValue);
      setOldValue(newValue);
    }
  };

  return [value, setValue, isEqualOldValue, updateOldValue];
};

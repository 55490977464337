import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import Map from 'assets/icons/price-history.svg';
import BigNumber from 'bignumber.js';
import { trimLeadingZerosWithDecimal } from '../../../../../util/input';

const PriceHistory = ({ tokenPrices, chartFilterField, setChartFilterField }) => {
  const [showList, setShowList] = useState(false);
  const [showChart, setShowChart] = useState(true);
  const [chartFilterText, setChartFilterText] = useState('All time');
  const priceHistoryChart = useRef(null);

  useEffect(() => {
    if (priceHistoryChart.current && tokenPrices.length > 0) {
      priceHistoryChart.current.height = 300;

      const labels = [];
      const data = [];

      tokenPrices.forEach(token => {
        labels.push(moment(new Date(token.bucketStart)).format('MM/DD'));
        data.push(token.avgPrice);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceHistoryChart.current, tokenPrices]);

  return (
    <div className="detail-item__history">
      <div className="detail-item__offer-tit" onClick={() => setShowChart(!showChart)}>
        <div className="detail-item__style-titOffer">
          <img src={Map} alt="" />
          <span className="detail-item__text-offer">Price History</span>
        </div>
        <div className="detail-item__btn-ip">
          {showChart ? (
            <svg
              width="10"
              height="7"
              viewBox="0 0 10 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.175 6.1748L5 2.35814L8.825 6.1748L10 4.9998L5 -0.000195503L0 4.9998L1.175 6.1748Z"
                fill="#7AC231"
              />
            </svg>
          ) : (
            <svg
              width="10"
              height="7"
              viewBox="0 0 10 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.175 0L5 3.81667L8.825 0L10 1.175L5 6.175L0 1.175L1.175 0Z"
                fill="#7AC231"
              />
            </svg>
          )}
        </div>
      </div>

      <div className={`${showChart ? 'show_chart' : 'hidden_chart'}`}>
        <div className="detail-item__price-sub">
          <div className="detail-item__select">
            <div className="detail-item__group-select">
              <div className="detail-item__select-item" onClick={() => setShowList(!showList)}>
                <span>{chartFilterText}</span>
              </div>
              <div className="detail-item__select-icon" onClick={() => setShowList(!showList)}>
                <svg
                  width="10"
                  height="7"
                  viewBox="0 0 10 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.825 0L5 3.81667L1.175 0L0 1.175L5 6.175L10 1.175L8.825 0Z"
                    fill="white"
                    fill-opacity="0.6"
                  />
                </svg>
              </div>
            </div>
            <div
              className={
                'detail-item__list-item ' + (showList ? 'show_listItem' : 'hidden_listItem')
              }
            >
              <ul>
                <li
                  onClick={() => {
                    setShowList(false);
                    setChartFilterField(null);
                    setChartFilterText('All time');
                  }}
                >
                  All time
                </li>
                <li
                  onClick={() => {
                    setShowList(false);
                    setChartFilterField(7);
                    setChartFilterText('Last 7 Days');
                  }}
                >
                  Last 7 Days
                </li>
                <li
                  onClick={() => {
                    setShowList(false);
                    setChartFilterField(14);
                    setChartFilterText('Last 14 Days');
                  }}
                >
                  Last 14 Days
                </li>
                <li
                  onClick={() => {
                    setShowList(false);
                    setChartFilterField(30);
                    setChartFilterText('Last 30 Days');
                  }}
                >
                  Last 30 Days
                </li>
                <li
                  onClick={() => {
                    setShowList(false);
                    setChartFilterField(60);
                    setChartFilterText('Last 60 Days');
                  }}
                >
                  Last 60 Days
                </li>
                <li
                  onClick={() => {
                    setShowList(false);
                    setChartFilterField(90);
                    setChartFilterText('Last 90 Days');
                  }}
                >
                  Last 90 Days
                </li>
              </ul>
            </div>
          </div>

          {tokenPrices.length > 0 && (
            <div style={{ display: 'flex' }}>
              <div className="detail-item__price--avg">
                <span>{chartFilterField ? `${chartFilterField} Day` : `All Time`} Avg. Price</span>
                <p>
                  {(function(tokenPrices) {
                    return trimLeadingZerosWithDecimal(
                      new BigNumber(
                        tokenPrices.reduce(
                          (acc, tokenPrice) =>
                            new BigNumber(acc)
                              .plus(
                                new BigNumber(tokenPrice.avgPrice).multipliedBy(tokenPrice.quantity)
                              )
                              .toFixed(),
                          0
                        )
                      )
                        .div(
                          tokenPrices.reduce(
                            (acc, tokenPrice) =>
                              new BigNumber(acc).plus(new BigNumber(tokenPrice.quantity)).toFixed(),
                            0
                          )
                        )
                        .toFixed(4)
                    );
                  })(tokenPrices)}
                </p>
              </div>
              {chartFilterField && (
                <div className="detail-item__price--avg">
                  <span>{chartFilterField} Day Volume </span>
                  <p>
                    {trimLeadingZerosWithDecimal(
                      new BigNumber(
                        tokenPrices.reduce(
                          (acc, tokenPrice) =>
                            new BigNumber(acc)
                              .plus(new BigNumber(tokenPrice.volume.quantity))
                              .toFixed(),
                          0
                        )
                      ).toFixed(4)
                    )}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
        <div>
          {tokenPrices.length > 0 ? (
            <canvas id="myChart" width="400" height="400" ref={priceHistoryChart}></canvas>
          ) : (
            <div className="detail-item__notify">
              <span>No Trading data yet.</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PriceHistory;

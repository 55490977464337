import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
  return {
    contentPage: {
      display: "grid",
      gridGap: 20,
      gridTemplateColumns: "repeat(auto-fill, calc(33.33% - 14px))",
      padding: 15,
      [theme.breakpoints.up(1500)]: {
        gridTemplateColumns: "repeat(auto-fill, calc(25% - 15px))"
      },
      [theme.breakpoints.up(1700)]: {
        gridTemplateColumns: "repeat(auto-fill, calc(20% - 16px))"
      },

      "&.emptyData": {
        fontSize: 14,
        lineHeight: "20px",
        color: "rgba(255, 255, 255, 0.6)",
        display: "flex",
        justifyContent: "center",
        padding: 60
      }
    },

    boxItem: {
      padding: "12px 12px",
      background: theme?.custom?.colors?.colorWhiteRgba005,
      borderRadius: 5,
      textDecoration: "none",
      transition: "all 0.2s ease",

      "&:hover": {
        transform: "translateY(-3px)",
        boxShadow: "rgb(14 14 14 / 25%) 0px 0px 8px 0px",
        transition: "all 0.1s ease 0s"
      }
    },

    containerImg: {
      width: "100%",
      borderRadius: 5,
      overflow: "hidden",
      background: "none",
      // backgroundColor: `${theme?.custom?.colors?.colorSilver} !important`,
      backgroundSize: "cover !important",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: 250,

      "& img": {
        maxWidth: "100%",
        maxHeight: "100%"
      }
    },

    infoItem: {
      padding: "18px 0 5px 0"
    },

    assetName: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: "14px",
      height: "14px",
      color: theme?.custom?.colors?.colorLightAtlantis,
      marginBottom: 12,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
    },

    desItem: {
      display: "flex",
      fontSize: 12,
      color: theme?.custom?.colors?.colorWhiteRgba085
    },

    collectionName: {
      width: "60%"
    },

    priceContainer: {
      display: "flex",
      marginLeft: "auto",
      paddingLeft: 15,
      maxWidth: "40%",
      justifyContent: "flex-end"
    },

    priceItem: {
      marginLeft: "auto",
      whiteSpace: "nowrap",
      paddingLeft: 0
    },

    assetMediaVideo: {
      width: "100%",
      height: "100%"
    }
  };
});

export default useStyles;

import {useState, useRef, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Contract, Provider} from 'ethers-multicall';
import {ethers} from 'ethers';
import {toV2LiquidityToken} from '../../../util/liquidity-utils';
import FACTORY_ABI from '../../../abi/Factory.json';
import ERC20_ABI from '../../../abi/ERC20.json';
import {findIndex} from 'lodash';
import {unWrappedNameToken} from '../../../util/getWrappedToken';
import BigNumber from 'bignumber.js';

const INFURA_KEY = process.env.INFURA_KEY;
const FACTORY_CONTRACT_ADDRESS = process.env.REACT_APP_FACTORY_CONTRACT_ADDRESS;
const FACTORY_CONTRACT_SUSHI = process.env.REACT_APP_FACTORY_CONTRACT_ADDRESS_SUSHI;
const NETWORK_NAME = process.env.REACT_APP_NETWORK_NAME;

const useTokenPairsBalance = (pool, pairs, changedLiquidity, setChangedLiquidity) => {
  const [pairsBalance, setPairsBalance] = useState([]);
  const {address} = useSelector(state => state.user);

  useEffect(() => {
    let canceled = false;

    const getPairBalances = async () => {
      const provider = new ethers.providers.InfuraProvider(NETWORK_NAME.toLowerCase(), INFURA_KEY);
      const ethcallProvider = new Provider(provider);
      await ethcallProvider.init();
      const factoryAddress = pool === 'uni' ? FACTORY_CONTRACT_ADDRESS : FACTORY_CONTRACT_SUSHI;
      const factoryContract = new Contract(factoryAddress, FACTORY_ABI);
      const pairsExistCalls = pairs.map(pair => {
        const [token0, token1] = pair;
        return factoryContract.getPair(token0.address, token1.address);
      });

      const pairsExist = await ethcallProvider.all(pairsExistCalls);
      // Shallow copy state and filter pair that doesn't exist => With purpose for output
      const tokensWithPair = [...pairs];

      for (let i = 0; i < pairsExist.length; i++) {
        if (Number(pairsExist[i]) === 0) {
          tokensWithPair.splice(i, 1);
          pairsExist.splice(i, 1);
          i--;
        }
      }

      const pairsBalanceCalls = pairsExist.map(pair => {
        const pairContract = new Contract(pair, ERC20_ABI.abi);
        return pairContract.balanceOf(address);
      });

      const pairsBalanceResult = await ethcallProvider.all(pairsBalanceCalls);

      const tokenPairsWithBalance = pairsBalanceResult.map((balance, index) => ({
        balance,
        liquidityToken: toV2LiquidityToken(
          [tokensWithPair[index][0], tokensWithPair[index][1]],
          pool
        ),
        token0: unWrappedNameToken(tokensWithPair[index][0]),
        token1: unWrappedNameToken(tokensWithPair[index][1])
      }));

      !canceled && setPairsBalance([...tokenPairsWithBalance]);
    };

    pairs && getPairBalances();

    return () => {
      console.log('cancelled');
      canceled = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pairs, pool]);

  useEffect(() => {
    if (changedLiquidity) {
      const match = findIndex(pairsBalance, e => {
        return e.liquidityToken.equals(changedLiquidity.liquidityToken);
      });
      if (match > -1) {
        const newPairsBalance = pairsBalance;
        newPairsBalance.splice(match, 1, changedLiquidity);
        setPairsBalance(newPairsBalance);
      } else {
        setPairsBalance([...pairsBalance, changedLiquidity]);
      }
      setChangedLiquidity(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedLiquidity]);

  return pairsBalance.filter(pair => new BigNumber(pair.balance).gt(0));
};

export default useTokenPairsBalance;

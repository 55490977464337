const initialState = {
  tokens: [],
  tokenBalance: {}
};

const sendtransactionPage = (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_TOKEN': {
      return {
        ...state,
        tokens: [...state.tokens, ...action.tokenList]
      };
    }
    case 'SAVE_TOKEN_BALANCE': {
      return {
        ...state,
        [action.key]: action.value
      };
    }

    default:
      return state;
  }
};

export default sendtransactionPage;

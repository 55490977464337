import { getCandleSticks } from '_api';

export const FETCH_CANDLESTICKS_REQUEST = '@moon/FETCH_CANDLESTICKS_REQUEST';
export const FETCH_CANDLESTICKS_SUCCESS = '@moon/FETCH_CANDLESTICKS_SUCCESS';
export const FETCH_CANDLESTICKS_ERROR = '@moon/FETCH_CANDLESTICKS_ERROR';

export function fetchCandlesticksRequest(exchangeName, symbol, resolution, since) {
  return {
    type: FETCH_CANDLESTICKS_REQUEST,
    exchangeName,
    symbol,
    resolution,
    since
  };
}

export function fetchCandlesticksSuccess(candlesticks, exchangeName, symbol, resolution, since) {
  return {
    type: FETCH_CANDLESTICKS_SUCCESS,
    candlesticks,
    exchangeName,
    symbol,
    resolution,
    since
  };
}

export function fetchCandlesticksError(error, exchangeName, symbol, resolution, since) {
  return {
    type: FETCH_CANDLESTICKS_ERROR,
    error,
    exchangeName,
    symbol,
    resolution,
    since
  };
}

export function fetchCandlesticks(exchangeName, symbol, resolution, since) {
  return async dispatch => {
    try {
      dispatch(fetchCandlesticksRequest(exchangeName, symbol, resolution, since));
      const data = await getCandleSticks(exchangeName, symbol, resolution, since);
      dispatch(fetchCandlesticksSuccess(data, exchangeName, symbol, resolution, since));
      return data;
    } catch (error) {
      console.log(error);
      dispatch(fetchCandlesticksError(error, exchangeName, symbol, resolution, since));
    }
  };
}

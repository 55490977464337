import axios from 'axios';
import { COINGECKO_API_HOST } from '../_constants';
import { COINMARKETCAP_TIMEOUT } from '_constants/timeouts';

const instance = axios.create({
  baseURL: `https://${COINGECKO_API_HOST}`,
  timeout: COINMARKETCAP_TIMEOUT
});

export function getGeckoOverview(data) {
  return instance
    .get('api/v3/global/', {
      params: data ? data : {}
    })
    .then(response => response.data);
}

export function getAllByExchange(exchangeName) {
  return instance
    .get('api/v3/exchanges')
    .then(response => response.data.filter(exchange => exchange.id === exchangeName));
}

import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
  return {
    panelIsContentPadded: {
      padding: "12px 0px"
    },

    scrollboxContent: {
      borderColor: "rgba(0, 0, 0, 0)",
      overflowY: "auto",
      transition: "border-color 0.2s ease-in 0s",
      height: 146
    },

    stringTraitFilterItem: {
      fontSize: 12,
      lineHeight: "12px",
      alignItems: "center",
      borderRadius: 2,
      color: theme?.custom?.colors?.colorWhiteRgba085,
      display: "flex",
      minHeight: 36,
      padding: 10,
      cursor: "pointer",
      transition: "all 0.2s ease 0s",
      overflow: "hidden",
      textTransform: "uppercase",

      "&:hover": {
        color: theme?.custom?.colors?.colorLightAtlantis
      }
    },

    stringTraitFilterCount: {
      marginLeft: "auto"
    },

    stringTraitFilterValue: {
      display: "block",
      maxWidth: "calc(100% - 80px)",
      wordBreak: "break-all"
    },

    styleLable: {
      display: "block",
      position: "relative",
      paddingLeft: 28,
      marginBottom: 12,
      cursor: "pointer",
      fontSize: 22,
      userSelect: "none"
    },

    paymentFilterCheckbox: {
      position: "absolute",
      opacity: 0,
      cursor: "pointer",
      height: 0,
      width: 0
    },

    checkmark: {
      position: "absolute",
      top: -2,
      left: 0,
      height: 16,
      width: 16,
      backgroundColor: theme?.custom?.colors?.backgroundColorLeft,
      border: "1px solid rgba(98, 99, 103, 0.5)",
      overflow: "hidden",
      borderRadius: 2,

      "&:after": {
        content: "''",
        left: 4,
        top: -1,
        color: theme?.custom?.colors?.backgroundColorLeft,
        width: 7,
        height: 13,
        border: `solid ${theme?.custom?.colors?.backgroundColorLeft}`,
        borderWidth: "0 3px 3px 0",
        transform: "rotate(45deg)",
        position: "absolute"
      },

      "&.activeCheckmark": {
        backgroundColor: theme?.custom?.colors?.colorWhiteRgba085,
        borderColor: theme?.custom?.colors?.colorWhiteRgba085,
        color: `${theme?.custom?.colors?.backgroundColorLeft} !important`,

        "&:after": {
          display: "block",
          color: theme?.custom?.colors?.backgroundColorLeft
        }
      }
    },

    scrollboxreactDivContainer: {
      border: `1px solid ${theme?.custom?.colors?.colorDarkShark}`,
      borderRadius: 2,
      padding: 5,
      margin: "10px 0"
    }
  };
});

export default useStyles;

import React from 'react';

import AppHeaderWrapped from 'components/AppHeader/';
import AppFooter from 'components/AppFooter/AppFooter';
import AppLeft from 'components/AppLeft/AppLeft';

import './AppLayout.scss';

export class AppLayout extends React.Component {
  static defaultProps = {
    displaFooter: true
  };

  render() {
    return (
      <div className="App">
        <div className="App__left">
          <AppLeft />
        </div>
        <div className="App__right">
          <div className="App__header">
            <AppHeaderWrapped {...this.props} />
          </div>
          {/* Min height calc by height of vh - (header + footer) */}
          <div className="App__content">{this.props.children}</div>
          {this.props.displaFooter && (
            <div className="App__footer clearfix">
              <AppFooter />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AppLayout;

import { FC, useState, useEffect } from "react";
import useStyles from "./styles";
import useOfferCancelAction from "../../commons/nfts_hooks/useOfferCancelAction";
import { Link } from "react-router-dom";
import { ImageAndTextType } from "Pages/OpenSea/static/type";
import Button from "@material-ui/core/Button";
import CustomDialog from "../../../../../components/CustomDialog";
import _ from "lodash";

const IconSubmitted = require("../../../../../assets/icons/icon-submitted.svg").default as string;

type OfferSearchItemProps = {
  price: string;
  assetQuantity: string;
  from: ImageAndTextType;
  timeStamp: string;
  pathImage: string[];
  assetName: string;
  assetsLink: string;
  status: string;
  showCancel: boolean;
  oldOrderString: any;
};

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID || 1;

const OfferSearchItem: FC<OfferSearchItemProps> = (props): JSX.Element => {
  const [disable, setDisable] = useState(false);
  const [openCancelOfferTxHashPopup, setOpenCancelOfferTxHashPopup] = useState(false);
  const styles = useStyles();
  const {
    cancelOffer,
    txHash: orderCancelTxHash,
    setTxHash: setOrderCancelTxHash
  } = useOfferCancelAction();

  useEffect(() => {
    if (orderCancelTxHash) {
      setOpenCancelOfferTxHashPopup(true);
    }
  }, [orderCancelTxHash]);

  /**
   * Using action cancel by detail. (logic blockchain).
   * @param oldOrder type any because parse from JSON string.
   */
  const handleOfferCancel = async (oldOrder: any) => {
    setDisable(true);
    await cancelOffer(oldOrder);
  };

  const {
    price,
    assetQuantity,
    from,
    timeStamp,
    pathImage,
    assetName,
    status,
    showCancel,
    oldOrderString,
    assetsLink
  } = props;

  const renderImage = () => {
    if (pathImage.length > 1) {
      // bundle
      return (
        <>
          <img
            className={styles.itemTwoImage}
            alt=""
            src={pathImage[0] ?? "/images/placeholder.png"}
          />
          <img
            className={styles.itemTwoImage}
            alt=""
            style={{ marginRight: "12px" }}
            src={pathImage[1] ?? "/images/placeholder.png"}
          />
        </>
      );
    }

    if (pathImage.length === 1) {
      // asset
      const imageUrl = pathImage[0];
      if (imageUrl && imageUrl.slice(-4) === ".mp4") {
        return (
          <video
            autoPlay={false}
            muted={true}
            width="100%"
            className={styles.itemImage}
            controlsList="nodownload"
            loop={true}
            playsInline={true}
            src={imageUrl}
          />
        );
      }

      if (imageUrl && !_.isEmpty(imageUrl)) {
        return <img className={styles.itemImage} src={imageUrl} alt="" />;
      }
    }

    return (
      <img className={`${styles.itemImage} no_image `} src={"/images/placeholder.png"} alt="" />
    );
  };

  return (
    <>
      <tr className={styles.contentTable}>
        <td className={`${styles.contentTableTd} ${styles.width300}`}>
          <Link to={assetsLink}>
            {renderImage()} <span className={styles.txtName}>{assetName}</span>
          </Link>
        </td>
        <td className={styles.contentTableTd}>
          <span>{price}</span>
        </td>
        <td className={styles.contentTableTd}>{assetQuantity}</td>
        <td className={styles.contentTableTd}>
          <div>
            <img alt="" className={styles.userImage} src={from.imageUrl} /> {from.text}
          </div>
        </td>
        <td className={styles.contentTableTd}>{timeStamp}</td>
        <td className={styles.contentTableTd}>{status}</td>
        <td className={styles.contentTableTd}>
          {showCancel && !disable && (
            <button
              className={styles.btnCancel}
              onClick={() => handleOfferCancel(JSON.parse(oldOrderString))}
            >
              Cancel
            </button>
          )}
        </td>
      </tr>
      <CustomDialog
        title="Transaction Submitted"
        isOpened={openCancelOfferTxHashPopup}
        handleDialogClose={() => {
          setOpenCancelOfferTxHashPopup(false);
          setOrderCancelTxHash("");
        }}
      >
        <div className="Farming-box__submitted_dialog">
          <img src={IconSubmitted} alt="" />
          <a
            className="view-on-ether"
            target="_blank"
            rel="noreferrer"
            href={`${
              CHAIN_ID === "4"
                ? `https://rinkeby.etherscan.io/tx/${orderCancelTxHash}`
                : `https://etherscan.io/tx/${orderCancelTxHash}`
            }`}
          >
            View on Etherscan
          </a>
          <div className="close-wrapper">
            <Button
              onClick={() => {
                setOpenCancelOfferTxHashPopup(false);
                setOrderCancelTxHash("");
              }}
              className="dialog__button"
            >
              Close
            </Button>
          </div>
        </div>
      </CustomDialog>
    </>
  );
};

export default OfferSearchItem;

import { useState, useRef, useEffect } from 'react';
import { Contract, Provider } from 'ethers-multicall';
import { ethers } from 'ethers';
import ERC20_ABI from '../../../abi/ERC20.json';

const INFURA_KEY = process.env.INFURA_KEY;

const useLPTokensBalance = (pools, userAddress) => {
  const [LPTokensBalance, setLPTokensBalance] = useState([]);
  const firstTime = useRef(true);

  useEffect(() => {
    const getLPTokensBalance = async (pools, userAddress) => {
      firstTime.current = false;

      const provider = new ethers.providers.InfuraProvider('mainnet', INFURA_KEY);

      const ethcallProvider = new Provider(provider);
      await ethcallProvider.init();

      const calls = pools.map(pool => {
        const erc20Contract = new Contract(pool.pair, ERC20_ABI.abi);
        return erc20Contract.balanceOf(userAddress);
      });

      let lpTokensBalanceResult = await ethcallProvider.all(calls);

      setLPTokensBalance(lpTokensBalanceResult);
    };

    firstTime.current && pools && pools.length > 0 && getLPTokensBalance(pools, userAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pools]);

  return [LPTokensBalance];
};

export default useLPTokensBalance;

import { shortenAddress } from '../../../../../util/getAddresses';
import PlaceHolder from '../../../../../assets/placeholder.png';
import Viewer from 'react-viewer/dist/index.js';

import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

const PageDetailLeft = ({ tokenDetails }) => {
  const [visible, setVisible] = useState(false);

  const renderCustomThumbs = () => {
    const thumbList = tokenDetails.assets.map((asset, index) => {
      if (asset.imageUrl) {
        if (asset.imageUrl.endsWith('.mp4')) return <video muted={true} src={asset.imageUrl} />;
        return (
          <picture key={index}>
            <source data-srcSet={`${asset.imageUrl}`} type="image/jpg" />
            <img key={asset.id} src={`${asset.imageUrl}`} alt="" />
          </picture>
        );
      }
      return <></>;
    });

    return thumbList;
  };

  return (
    <div className="detail">
      <div className="detail__img-item">
        <Carousel showStatus={false} renderThumbs={renderCustomThumbs}>
          {tokenDetails.assets.map(asset => {
            return (
              <div className="page-slider__item">
                {asset.animationUrl ? (
                  <video muted={true} src={asset.animationUrl} loop controls playsinline />
                ) : (
                  <>
                    {asset.imageUrl ? (
                      asset.imageUrl.endsWith('.mp4') ? (
                        <video src={asset.imageUrl} loop controls playsinline />
                      ) : (
                        <img
                          src={asset.imageUrl}
                          onClick={() => setVisible(true)}
                          style={{ cursor: 'pointer' }}
                          alt=""
                        />
                      )
                    ) : (
                      <img
                        src={PlaceHolder}
                        onClick={() => setVisible(true)}
                        style={{ cursor: 'pointer' }}
                        alt=""
                      />
                    )}
                  </>
                )}
              </div>
            );
          })}
        </Carousel>
      </div>
      <div className="detail__desc-title">
        <svg
          width="14"
          height="10"
          viewBox="0 0 14 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 10H14V8H0V10ZM0 0V2H14V0H0ZM0 6H14V4H0V6Z" fill="white" />
        </svg>
        Bundle Description
      </div>
      <div className="detail__inner">
        <div className="detail__desc">
          <div className="detail__text">
            <div className="detail__user-desc">
              {tokenDetails?.maker.imageUrl && (
                <div className="detail__avatar">
                  <img src={tokenDetails.maker.imageUrl} alt="" />
                </div>
              )}
              {tokenDetails.maker && (
                <>
                  <span>Created By&nbsp;</span>
                  <strong>
                    {shortenAddress(tokenDetails.publicUsername || tokenDetails?.maker.address)}
                  </strong>
                </>
              )}
            </div>
            {tokenDetails.description && (
              <p dangerouslySetInnerHTML={{ __html: `${tokenDetails.description.text}` }} />
            )}
          </div>
        </div>
      </div>
      <Viewer
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
      />
    </div>
  );
};

export default PageDetailLeft;

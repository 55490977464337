import axios from 'axios';

const ASSET_API_URL = `${process.env.REACT_APP_PROXY_URL}${process.env.REACT_APP_OPENSEA_ASSET_API}`;

const API_BASE_URL = ASSET_API_URL;

const instance = axios.create({
  baseURL: API_BASE_URL
});

export default instance;

import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce/lib";
import useStyles from "./styles";

export enum ACTION_CHANGED {
  DEBOUNCE,
  PRESS_ENTER,
  CLICK_SEARCH
}

export const ACTION_CHANGED_ALL = [
  ACTION_CHANGED.CLICK_SEARCH,
  ACTION_CHANGED.PRESS_ENTER,
  ACTION_CHANGED.DEBOUNCE
];

type SearchInputType = {
  defaultValue?: string;
  followActionChanged: ACTION_CHANGED[];
  handleChangedValue: (value: string, actionChanged: ACTION_CHANGED) => void;
  placeholder?: string;
};

/*
When make the value change and ENTER, DEBOUNCE will still work.
Not fix yet.
*/
const SearchInput: React.FC<SearchInputType> = (props): JSX.Element => {
  const styles = useStyles();
  const { handleChangedValue, placeholder, defaultValue, followActionChanged } = props;
  const [value, setValue] = useState(defaultValue ?? "");
  const debouncedValue = useDebounce(value, 500)[0];

  /**
   * By default, debounce is avaiable
   * request event to parent component
   * when changed input delay 500 milliseconds.
   */
  useEffect(() => {
    if (followActionChanged.includes(ACTION_CHANGED.DEBOUNCE)) {
      handleChangedValue(debouncedValue, ACTION_CHANGED.DEBOUNCE);
    }
  }, [debouncedValue, followActionChanged, handleChangedValue]);

  const handleKeyPress = (event: { key: string }) => {
    if (event.key === "Enter") {
      if (followActionChanged.includes(ACTION_CHANGED.PRESS_ENTER)) {
        handleChangedValue(value, ACTION_CHANGED.PRESS_ENTER);
      }
    }
  };

  const handleClickSearch = () => {
    if (followActionChanged.includes(ACTION_CHANGED.CLICK_SEARCH)) {
      handleChangedValue(value, ACTION_CHANGED.CLICK_SEARCH);
    }
  };

  return (
    <>
      <div className={styles.groupSearch}>
        <input
          value={value}
          onChange={event => {
            setValue(event.target.value);
          }}
          onKeyPress={handleKeyPress}
          className={styles.searchInput}
          placeholder={placeholder}
          type="text"
        />
        <div className={styles.iconSearch} onClick={handleClickSearch}>
          <img src="/images/icons/icon-search.svg" alt="" />
        </div>
      </div>
    </>
  );
};

SearchInput.defaultProps = {
  placeholder: "Filter",
  defaultValue: ""
};

export default SearchInput;

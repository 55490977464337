const chain_name = process.env.REACT_APP_NETWORK_NAME;

const list_chains = {
  RINKEBY: {
    chain_id: 4,
    chain_name: 'RINKEBY',
    masterchef_address: '0x054Ec7707a9e48Ee7D43A5C5823504eFdbF0b50a'
  },
  MAINNET: {
    chain_id: 1,
    chain_name: 'MAINNET',
    masterchef_address: '0xc2EdaD668740f1aA35E4D8f227fB8E17dcA888Cd'
  }
};

export const config = list_chains[chain_name];

export const list_network = list_chains;

import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
  return {
    groupSearch: {
      alignItems: "center",
      backgroundColor: "transparent",
      border: `1px solid ${theme?.custom?.colors?.colorDarkShark}`,
      boxSizing: "border-box",
      borderRadius: 2,
      color: "rgba(14, 14, 14, 0.75)",
      display: "flex",
      flex: "1 0",
      height: 34,
      maxWidth: 768,
      padding: "0px 10px",
      marginBottom: 11
    },

    searchInput: {
      backgroundColor: "transparent",
      fontSize: 12,
      lineHeight: 12,
      border: "none",
      flex: "1 0",
      height: "100%",
      outline: "none",
      color: theme?.custom?.colors?.colorWhiteRgba085,

      "&::-webkit-input-placeholder": {
        color: theme?.custom?.colors?.colorDarkMidGray
      },

      "&:-ms-input-placeholder": {
        color: theme?.custom?.colors?.colorDarkMidGray
      },

      "&::placeholder": {
        color: theme?.custom?.colors?.colorDarkMidGray
      }
    },

    iconSearch: {}
  };
});

export default useStyles;

import React from 'react';
import {NavLink} from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import {Zoom} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import './AppLeft.scss';

// array of links used for navigation
const listTab = [
  {link: 'dashboard', tooltip: 'Dashboard'},
  {link: 'wallet', tooltip: 'Wallet'},
  {link: 'market', tooltip: 'Market'},
  {link: 'sendtransaction', tooltip: 'Send transaction'},
  {link: 'swap', tooltip: 'Swap'},
  {link: 'liquidity', tooltip: 'Liquidity'},
  {link: 'farming', tooltip: 'Farm'},
  {link: 'stake', tooltip: 'Stake'},
  {link: 'account', tooltip: 'Account'},
  {link: 'opensea', tooltip: 'Opensea'}
];

const AppLeftTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: 16
  }
}))(Tooltip);

export class AppLeft extends React.Component {
  render() {
    return (
      <div className="AppLeft">
        <div className="AppLeft__logo" />

        <div className="crollNavLeft">
          <nav className="AppLeft__nav">
            {listTab.map((item, i) => (
              <AppLeftTooltip
                key={`nav-item-${i}`}
                TransitionComponent={Zoom}
                title={item.tooltip}
                placement="left"
                arrow
              >
                <NavLink
                  to={`/${item.link.toLowerCase()}`}
                  className="AppLeft__nav-link"
                  activeClassName="AppLeft__nav-link--is-active"
                >
                  <i
                    className={`AppLeft__nav-link-icon AppLeft__nav-link-icon--${item.link.toLowerCase()}`}
                  />
                </NavLink>
              </AppLeftTooltip>
            ))}
          </nav>
        </div>
      </div>
    );
  }
}

export default AppLeft;

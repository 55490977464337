import { useState, useCallback, useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Contract } from 'ethers';
import BigNumber from 'bignumber.js';
import MasterchefJSON from '../../../abi/Masterchef.json';
import FarmingContext from '../context/FarmingContext';
import { signTransaction, getTransactionSigner } from '../../../util/transaction';
import { getMasterchefAddress } from '../../../util/getAddresses';
import { getContractInstance } from '../../../util/getContractInstance';

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

const useStakeAction = setTxHash => {
  const { fetchDefaultFarms } = useContext(FarmingContext);
  const [loadingDeposit, setLoadingDeposit] = useState(false);
  const [loadingWithdraw, setLoadingWithdraw] = useState(false);
  const { isMetaMask, privateKey, address, isWC } = useSelector(state => state.user);

  const masterchefAddress = getMasterchefAddress(CHAIN_ID);

  const web3 = useMemo(() => window.web3, []);

  const handleLPTokenDeposit = useCallback(async (testPool = false, pool, input) => {
    const signer = await getTransactionSigner(CHAIN_ID, false, isWC);
    const poolId = testPool ? pool.pid : pool.id;
    const depositValue = new BigNumber(input)
      .times(new BigNumber(10).pow(new BigNumber(pool.lpTokenDecimals)))
      .toFixed();

    try {
      setLoadingDeposit(true);

      if (isMetaMask || isWC) {
        const contract = new Contract(masterchefAddress, MasterchefJSON, signer);
        const transaction = await contract.deposit(poolId, depositValue);
        setTxHash(transaction.hash);
        await transaction.wait(1);
      } else if (!isMetaMask && privateKey) {
        const contract = getContractInstance(web3, MasterchefJSON, masterchefAddress);

        const data = contract.methods.deposit(poolId, depositValue);

        const params = {
          nonce: web3.utils.toHex(await web3.eth.getTransactionCount(address)),
          gasLimit: web3.utils.toHex(
            await data.estimateGas({
              from: address,
              value: '0x00'
            })
          ),
          gasPrice: web3.utils.toHex(await web3.eth.getGasPrice()),
          to: contract._address,
          data: data.encodeABI(),
          value: '0x00'
        };

        await signTransaction(params, privateKey, hash => setTxHash(hash));
      }

      await fetchDefaultFarms();
      setLoadingDeposit(false);
    } catch (err) {
      console.log(err.message);
      setLoadingDeposit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLPTokenWithdraw = useCallback(async (testPool = false, pool, input) => {
    const signer = await getTransactionSigner(CHAIN_ID, false, isWC);
    const poolId = testPool ? pool.pid : pool.id;
    const unstakeValue = new BigNumber(input)
      .times(new BigNumber(10).pow(pool.lpTokenDecimals))
      .toFixed();

    try {
      setLoadingWithdraw(true);
      if (isMetaMask || isWC) {
        const contract = new Contract(masterchefAddress, MasterchefJSON, signer);
        const transaction = await contract.withdraw(poolId, unstakeValue);
        setTxHash(transaction.hash);
        await transaction.wait(1);
      } else if (!isMetaMask && privateKey) {
        const contract = getContractInstance(web3, MasterchefJSON, masterchefAddress);

        const data = contract.methods.withdraw(poolId, unstakeValue);

        const params = {
          nonce: web3.utils.toHex(await web3.eth.getTransactionCount(address)),
          gasLimit: web3.utils.toHex(
            await data.estimateGas({
              from: address,
              value: '0x00'
            })
          ),
          gasPrice: web3.utils.toHex(await web3.eth.getGasPrice()),
          to: contract._address,
          data: data.encodeABI(),
          value: '0x00'
        };

        await signTransaction(params, privateKey, hash => setTxHash(hash));
      }

      await fetchDefaultFarms();
      setLoadingWithdraw(false);
    } catch (err) {
      console.log(err.message);
      setLoadingWithdraw(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loadingWithdraw,
    loadingDeposit,
    handleLPTokenWithdraw,
    handleLPTokenDeposit
  };
};

export default useStakeAction;

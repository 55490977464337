import BaseService from './BaseService';
import exchangeLookup from '../exchange/exchange-lookup';
import lookupService from '../exchange/service-lookup';

class TickerService extends BaseService {
  async ticker(exchangeName, symbolPair) {
    const exchangeLookupService = lookupService[exchangeName];
    if (exchangeLookupService === undefined) {
      throw new Error('Could not find translator for ' + exchangeName);
    }

    const ticker = await exchangeLookup[exchangeName].fetchTicker(symbolPair);

    return exchangeLookupService.translator.ticker(ticker);
  }

  async tickerAllManual(exchangeName) {
    const promiseArray = [];

    console.log('Loading Market Data', exchangeName);
    await exchangeLookup[exchangeName].load_markets();

    for (let symbolPair in exchangeLookup[exchangeName].markets) {
      // Don't search for dark pools (kraken only?)
      if (!symbolPair.includes('.d')) {
        promiseArray.push(this.ticker(exchangeName, symbolPair));
      }
    }

    const result = {};
    return Promise.all(promiseArray).then(tickers => {
      tickers.forEach(tick => {
        result[tick.symbol] = tick;
      });

      return result;
    });
  }

  tickerAll(exchangeName) {
    const client = exchangeLookup[exchangeName];
    if (lookupService[exchangeName] === undefined) {
      throw new Error('Did not find translator for [' + exchangeName + ']');
    }

    const translator = lookupService[exchangeName].translator;

    // GDAX does not have a fetch all tickers in a single call
    if (!client.has.fetchTickers) {
      return this.tickerAllManual(exchangeName);
    } else {
      if (exchangeName === 'bittrex') {
        return client
          .fetch('https://api.bittrex.com/api/v1.1/public/getmarketsummaries')
          .then(result => {
            return translator.tickers(result.result);
          });
      }
      return client.fetchTickers().then(result => {
        return translator.tickers(result);
      });
    }
  }
}

export default TickerService.getInstance();

import { FeaturesDataType } from "Pages/OpenSea/static/type";
import { DataHeaderProps } from "../base_component/BaseHeader";

export const DEFAULT_LINK_SCREEN = {
  LISTING: "/opensea/assets",
  WALLET: "/opensea/account/inwallet?search[resultModel]=ASSETS",
  ACTIVITY: "/opensea/account/activity",
  OFFERS: "/opensea/account/offers"
};

export const ACCOUNT_TAB_HEADER = [
  {
    name: "In Wallet",
    href: DEFAULT_LINK_SCREEN.WALLET,
    icon: "icon-in-wallet"
  },
  { name: "Activity", href: DEFAULT_LINK_SCREEN.ACTIVITY, icon: "icon-activity" },
  { name: "Offers ", href: DEFAULT_LINK_SCREEN.OFFERS, icon: "icon-offters" }
];

export const DATA_HEADER: { [key: string]: DataHeaderProps } = {
  STATUS: { icon: "star", title: "Status", hiddenDisplay: false },
  EVENT_TYPE: { icon: "star", title: "Event Type", hiddenDisplay: false },
  COLLECTION: { icon: "window", title: "Collections", hiddenDisplay: false },
  PAYMENT: { icon: "local_offer", title: "On Sale In", hiddenDisplay: false },
  RANGER: { icon: "bar_chart", title: "{dynamicNumberic}", hiddenDisplay: true },
  STRINGTRAIT: { icon: "list", title: "{dynamicString}", hiddenDisplay: true }
};

export const FIXED_STATUS_ITEMS: FeaturesDataType = [
  { name: "Buy Now", slug: "BUY_NOW", isSelected: false },
  { name: "On Auction", slug: "ON_AUCTION", isSelected: false },
  { name: "New", slug: "IS_NEW", isSelected: false },
  { name: "Has Offers", slug: "HAS_OFFERS", isSelected: false }
];

export const EVENT_TYPE_ITEMS: FeaturesDataType = [
  { name: "Listings", slug: "AUCTION_CREATED", isSelected: false },
  { name: "Sales", slug: "AUCTION_SUCCESSFUL", isSelected: false },
  { name: "Bids", slug: "OFFER_ENTERED", isSelected: false },
  { name: "Transfers", slug: "ASSET_TRANSFER", isSelected: false }
];

export const CATEGORY_FIXED_ITEMS = [
  { name: "Art", slug: "art", isSelected: false },
  { name: "Domain", slug: "domain-names", isSelected: false },
  { name: "Virtual Worlds", slug: "virtual-worlds", isSelected: false },
  { name: "Trading Cards", slug: "trading-cards", isSelected: false },
  { name: "Collecttibles", slug: "collectibles", isSelected: false },
  { name: "Sports", slug: "sports", isSelected: false },
  { name: "Utility", slug: "utility", isSelected: false }
];

export const RESULT_MODEL_ITEMS = [
  { name: "All items", slug: "ALL" },
  { name: "Single Items", slug: "ASSETS" },
  { name: "Bundles", slug: "BUNDLES" }
];

export const RESULT_MODEL_VARIABLES = {
  ALL: { resultModel: null },
  ASSETS: { resultModel: "ASSETS" },
  BUNDLES: { resultModel: "BUNDLES" }
};

export const SORT_BY_NONE_DEFAULT_ITEMS = [
  { name: "Recently Listed", slug: "RECENTLY_LISTED" },
  { name: "Recently Created", slug: "RECENTLY_SOLD" },
  { name: "Recently Sold", slug: "RECENTLY_CREATED" },
  { name: "Ending Soon", slug: "ENDING_SOON" },
  { name: "Price: Low to High", slug: "PRICE_LOW_TO_HIGH" },
  { name: "Price: High to Low", slug: "PRICE_HIGH_TO_LOW" },
  { name: "Highest Last Sale", slug: "HIGHEST_LAST_SALE" },
  { name: "Most Viewed", slug: "MOST_VIEWED" },
  { name: "Most Favorited", slug: "MOST_FAVORITED" },
  { name: "Oldest", slug: "OLDEST" }
];

export const LISTING_SORT_BY_ITEMS = [{ name: "Sort by", slug: "INIT" }].concat(
  SORT_BY_NONE_DEFAULT_ITEMS
);
export const WALLET_SORT_BY_ITEMS = [{ name: "Recently Received", slug: "INIT" }].concat(
  SORT_BY_NONE_DEFAULT_ITEMS
);

const SORT_BY_NONE_DEFAULT_VARIABLES = {
  RECENTLY_LISTED: { sortAscending: false, sortBy: "LISTING_DATE" },
  RECENTLY_SOLD: { sortAscending: false, sortBy: "LAST_SALE_DATE" },
  RECENTLY_CREATED: { sortAscending: false, sortBy: "CREATED_DATE" },
  ENDING_SOON: { sortAscending: true, sortBy: "EXPIRATION_DATE" },
  PRICE_LOW_TO_HIGH: { sortAscending: true, sortBy: "PRICE" },
  PRICE_HIGH_TO_LOW: { sortAscending: false, sortBy: "PRICE" },
  HIGHEST_LAST_SALE: { sortAscending: false, sortBy: "LAST_SALE_PRICE" },
  MOST_VIEWED: { sortAscending: false, sortBy: "VIEWER_COUNT" },
  MOST_FAVORITED: { sortAscending: false, sortBy: "FAVORITE_COUNT" },
  OLDEST: { sortAscending: true, sortBy: "CREATED_DATE" }
};
export const LISTING_SORT_BY_VARIABLES = {
  INIT: { sortAscending: null, sortBy: null },
  ...SORT_BY_NONE_DEFAULT_VARIABLES
};
export const WALLET_SORT_BY_VARIABLES = {
  INIT: { sortAscending: false, sortBy: "LAST_TRANSFER_DATE" },
  ...SORT_BY_NONE_DEFAULT_VARIABLES
};

export const OPENSEA_LOCAL_STORAGE_KEY = {
  ASSETS_LISTING_DATA: "assets_listing_data",
  ACCOUNT_WALLET_DATA: "account_wallet_data",
  ACCOUNT_ACTIVITY_DATA: "account_activity_data",
  ACCOUNT_OFFERS_DATA: "account_offers_data",
  BASE_HEADER_CONTROL: "base_header_control"
};

export const OPENSEA_MESSAGE = {
  LISTING_NO_ITEM: "No items found for this search",
  WALLET_NO_ITEM: "No items to display",
  ACTIVITY_NO_ITEM: "No trading history yet",
  OFFERS_NO_ITEM: "No offers yet"
};

export enum SCREEN {
  LISTING,
  DETAIL,
  INWALLET,
  ACTITIVY,
  OFFERS
}

// Paging in listing and wallet has limit = 32, start page = 1
export const ASSETS_BUNDLE_LIMIT = 32;
export const ASSETS_BUNDLE_START_PAGE = 1;

// Lazy loading in activity and offers, hass limit = 10
export const RECORD_LIMIT = 10;

import {
  UNISWAP_FACTORY_ADDRESS,
  ROUTE_V2_ADDR,
  ROUTER_UNISWAP_SM_ADDRESS,
  FACTORY_CONTRACT_ADDRESS,
  ROUTE_V2_ADDR_SUSHI,
  FACTORY_CONTRACT_SUSHI
} from '../Pages/Swap/uni/const';
import {splitSignature} from '@ethersproject/bytes';

import BigNumber from 'bignumber.js';
import {Token, Pair, TokenAmount} from '@uniswap/sdk';
import factoryJson from '../Pages/Swap/uni/factoryABI.json';
import {ethers} from 'ethers';
import {Contract} from 'ethers';
import ERC20_ABI from '../abi/ERC20.json';
import {getTransactionSigner} from './transaction';
import pairABI from '../Pages/Swap/uni/pairABI.json';
import {Provider, Contract as ContractMulticall} from 'ethers-multicall';
import PAIR_ABI from '../abi/Pair.json';
import {signTypedData_v4} from 'eth-sig-util';
import FACTORY_ABI from '../abi/Factory.json';
import {Pair as PairSushi, Token as TokenSushi, ROUTER_ADDRESS} from '@sushiswap/sdk';

import {Percent, JSBI} from '@uniswap/sdk';

import {unWrappedNameToken} from './getWrappedToken';
import erc20 from '../Pages/Swap/uni/ERC20.json';
import routerV2 from '../Pages/Swap/uni/RouterV2.json';

const DEFAULT_TOKEN_LIST = {
  name: 'Uniswap Default List',
  timestamp: '2021-01-21T23:57:10.982Z',
  version: {
    major: 2,
    minor: 0,
    patch: 0
  },
  tags: {},
  logoURI: 'ipfs://QmNa8mQkrNKp1WEEeGjFezDmDeodkWRevGFN8JCV7b4Xir',
  keywords: ['uniswap', 'default'],
  tokens: [
    {
      name: 'Dai Stablecoin',
      address: '0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735',
      symbol: 'DAI',
      decimals: 18,
      chainId: 4,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png'
    },
    {
      name: 'Maker',
      address: '0xF9bA5210F91D0474bd1e1DcDAeC4C58E359AaD85',
      symbol: 'MKR',
      decimals: 18,
      chainId: 4,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2/logo.png'
    },
    {
      name: 'Uniswap',
      address: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
      symbol: 'UNI',
      decimals: 18,
      chainId: 4,
      logoURI: 'ipfs://QmXttGpZrECX5qCyXbBQiqgQNytVGeZW5Anewvh2jc4psg'
    },
    {
      name: 'Wrapped Ether',
      address: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
      symbol: 'WETH',
      decimals: 18,
      chainId: 4,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xc778417E063141139Fce010982780140Aa0cD5Ab/logo.png'
    }
  ]
};

const Tx = require('ethereumjs-tx').Transaction;

const UNI = 'uni';

const INFURA_KEY = process.env.INFURA_KEY;
const NETWORK_NAME = process.env.REACT_APP_NETWORK_NAME;
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000));

export class WrappedTokenInfo extends Token {
  tokenInfo;
  tags;

  constructor(tokenInfo, tags) {
    super(
      tokenInfo.chainId,
      tokenInfo.address,
      tokenInfo.decimals,
      tokenInfo.symbol,
      tokenInfo.name
    );
    this.tokenInfo = tokenInfo;
    this.tags = tags;
  }

  get logoURI() {
    return this.tokenInfo.logoURI;
  }
}

export class WrappedTokenInfoSushi extends TokenSushi {
  tokenInfo;
  tags;

  constructor(tokenInfo, tags) {
    super(
      tokenInfo.chainId,
      tokenInfo.address,
      tokenInfo.decimals,
      tokenInfo.symbol,
      tokenInfo.name
    );
    this.tokenInfo = tokenInfo;
    this.tags = tags;
  }

  get logoURI() {
    return this.tokenInfo.logoURI;
  }
}

export const getPair = async (web3, tokenA, tokenB, pool) => {
  const factoryAddress = pool === UNI ? UNISWAP_FACTORY_ADDRESS : FACTORY_CONTRACT_SUSHI;
  const factoryContract = new web3.eth.Contract(factoryJson, factoryAddress);
  return await factoryContract.methods.getPair(tokenA.address, tokenB.address).call();
};

export const getPairDataForRemove = async (tokenA, tokenB, user, pool) => {
  const signer = await getTransactionSigner(CHAIN_ID, true);
  const factoryAddress = pool === UNI ? UNISWAP_FACTORY_ADDRESS : FACTORY_CONTRACT_SUSHI;
  const erc20Contract = new Contract(factoryAddress, factoryJson, signer);
  const pairAddress = await erc20Contract.getPair(tokenA.address, tokenB.address);
  const pairContract = new Contract(pairAddress, pairABI, signer);
  const balance = await pairContract.balanceOf(user.address);
  const liquidityToken = toV2LiquidityToken([tokenA, tokenB], pool);
  const token0 = unWrappedNameToken(tokenA);
  const token1 = unWrappedNameToken(tokenB);
  return {
    balance,
    liquidityToken,
    token0,
    token1
  };
};

export const supplyWithEth = async (
  web3,
  token,
  tokenAmount,
  ethAmount,
  contract,
  user,
  callback,
  callbackError,
  callBackHash
) => {
  const settingTransaction = localStorage.getItem('setting_transaction')
    ? JSON.parse(localStorage.getItem('setting_transaction'))
    : null;
  const tokenDesired = new BigNumber(tokenAmount)
    .times(new BigNumber(10).pow(token.decimals))
    .toFixed(0);
  const ethDesired = new BigNumber(ethAmount).multipliedBy(new BigNumber(10).pow(18)).toFixed(0);
  const deadline = (Date.now() + (settingTransaction?.dealine || 10) * 60000).toString();
  const addressTo = user.address;
  const slippageTolerance = settingTransaction?.activePercent || '1';
  const amountTokenMin = new BigNumber(tokenDesired)
    .multipliedBy(new BigNumber(100).minus(slippageTolerance))
    .dividedBy(100)
    .toFixed(0);
  const amountEthMin = new BigNumber(ethDesired)
    .multipliedBy(new BigNumber(100).minus(slippageTolerance))
    .dividedBy(100)
    .toFixed(0);
  const data = contract.methods.addLiquidityETH(
    token.address,
    tokenDesired,
    settingTransaction?.checkedExpert ? 0 : amountTokenMin,
    settingTransaction?.checkedExpert ? 0 : amountEthMin,
    addressTo,
    deadline
  );
  if (user.isWC) {
    const signer = await getTransactionSigner(CHAIN_ID, false, true);
    const contractEther = new Contract(contract._address, routerV2.abi, signer);
    const transaction = await contractEther.addLiquidityETH(
      token.address,
      toFixed(tokenDesired),
      settingTransaction?.checkedExpert ? 0 : toFixed(amountTokenMin),
      settingTransaction?.checkedExpert ? 0 : toFixed(amountEthMin),
      addressTo,
      deadline,
      {value: ethDesired}
    );
    callBackHash(transaction.hash);
    await transaction.wait(1);
    callback();
  } else if (user.privateKey) {
    const gasLimit = 300000;
    const value = web3.utils.toHex(toFixed(ethDesired));
    const params = {
      nonce: web3.utils.toHex(await web3.eth.getTransactionCount(user.address)),
      gasLimit,
      gasPrice: web3.utils.toHex(await web3.eth.getGasPrice()),
      to: contract._address,
      data: data.encodeABI(),
      value
    };
    await signTransaction(params, callback, user, web3, callbackError, callBackHash);
  } else {
    data
      .send(
        {
          from: user.address,
          value: ethDesired
        },
        (error, transactionHash) => {
          if (error) {
            callbackError(error);
          } else {
            callBackHash(transactionHash);
          }
        }
      )
      .once('confirmation', callback);
  }
  return true;
};

export function toFixed(x, floor = true) {
  if (floor) {
    x = Math.floor(x);
  }
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = '0.' + new Array(e).join('0') + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join('0');
    }
  }
  return x.toString();
}

export const supply = async (
  web3,
  token,
  tokenAmount,
  tokenB,
  tokenBAmount,
  contract,
  user,
  callback,
  callBackError,
  callbackHash
) => {
  const settingTransaction = localStorage.getItem('setting_transaction')
    ? JSON.parse(localStorage.getItem('setting_transaction'))
    : null;
  const tokenDesired = new BigNumber(tokenAmount)
    .times(new BigNumber(10).pow(token.decimals))
    .toFixed(0);
  const tokenBDesired = new BigNumber(tokenBAmount)
    .times(new BigNumber(10).pow(tokenB.decimals))
    .toFixed(0);
  const deadline = parseInt(
    (Date.now() + (settingTransaction?.dealine || 10) * 60000) / 1000
  ).toString();
  const slippageTolerance = settingTransaction?.activePercent || '1';
  const tokenAmountMin = new BigNumber(tokenDesired)
    .times(new BigNumber(100).minus(slippageTolerance))
    .div(100)
    .toFixed(0);
  const tokenAmountBMin = new BigNumber(tokenBDesired)
    .times(new BigNumber(100).minus(slippageTolerance))
    .div(100)
    .toFixed(0);

  const data = contract.methods.addLiquidity(
    token.address,
    tokenB.address,
    tokenDesired,
    tokenBDesired,
    settingTransaction?.checkedExpert ? 0 : toFixed(tokenAmountMin),
    settingTransaction?.checkedExpert ? 0 : toFixed(tokenAmountBMin),
    user.address,
    deadline
  );

  if (user.isWC) {
    const signer = await getTransactionSigner(CHAIN_ID, false, true);
    const contractEther = new Contract(contract._address, routerV2.abi, signer);
    const transaction = await contractEther.addLiquidity(
      token.address,
      tokenB.address,
      tokenDesired,
      tokenBDesired,
      settingTransaction?.checkedExpert ? 0 : toFixed(tokenAmountMin),
      settingTransaction?.checkedExpert ? 0 : toFixed(tokenAmountBMin),
      user.address,
      deadline
    );
    callbackHash(transaction.hash);
    await transaction.wait(1);
    callback();
  } else if (user.privateKey) {
    //fix the gas limit sometime too low ???
    const gasLimit = 250000;
    const params = {
      nonce: web3.utils.toHex(await web3.eth.getTransactionCount(user.address)),
      gasLimit,
      gasPrice: web3.utils.toHex(await web3.eth.getGasPrice()),
      to: contract._address,
      data: data.encodeABI(),
      value: '0x00' // 0
    };
    console.log(params);
    await signTransaction(params, callback, user, web3, callBackError, callbackHash);
  } else {
    data
      .send(
        {
          from: user.address
        },
        (error, transactionHash) => {
          if (error) {
            callBackError(error);
          } else {
            callbackHash(transactionHash);
          }
        }
      )
      .once('confirmation', callback);
  }
  return true;
};

export const removeLiquidityETH = async (
  token,
  amountLiquidity,
  amountToken,
  amountETH,
  user,
  signature,
  callback,
  contract,
  web3,
  callbackError,
  callbackHash
) => {
  const settingTransaction = localStorage.getItem('setting_transaction')
    ? JSON.parse(localStorage.getItem('setting_transaction'))
    : null;
  const slippageTolerance = settingTransaction?.activePercent || '1';
  const deadline = Math.floor(
    (Date.now() + (settingTransaction?.dealine || 10) * 60000) / 1000
  ).toString();
  const amountTokenMin = new BigNumber(amountToken)
    .times(new BigNumber(100).minus(slippageTolerance))
    .div(100)
    .times(new BigNumber(10).pow(token.decimals))
    .toString();
  const amountEthMin = new BigNumber(amountETH)
    .times(new BigNumber(100).minus(slippageTolerance))
    .div(100)
    .times(new BigNumber(10).pow(18))
    .toString();

  let data;
  if (signature) {
    data = contract.methods.removeLiquidityETHWithPermit(
      token.address,
      amountLiquidity,
      settingTransaction?.checkedExpert ? 0 : toFixed(amountTokenMin),
      settingTransaction?.checkedExpert ? 0 : toFixed(amountEthMin),
      user.address,
      signature.deadline,
      false,
      signature.v,
      signature.r,
      signature.s
    );
  } else {
    data = contract.methods.removeLiquidityETH(
      token.address,
      amountLiquidity,
      settingTransaction?.checkedExpert ? 0 : toFixed(amountTokenMin),
      settingTransaction?.checkedExpert ? 0 : toFixed(amountEthMin),
      user.address,
      deadline
    );
  }

  if (user.isWC) {
    const signer = await getTransactionSigner(CHAIN_ID, false, true);
    const contractEther = new Contract(contract._address, routerV2.abi, signer);
    let transaction;

    if (signature) {
      transaction = await contractEther.removeLiquidityETHWithPermit(
        token.address,
        amountLiquidity,
        settingTransaction?.checkedExpert ? 0 : toFixed(amountTokenMin),
        settingTransaction?.checkedExpert ? 0 : toFixed(amountEthMin),
        user.address,
        signature.deadline,
        false,
        signature.v,
        signature.r,
        signature.s
      );
    } else {
      transaction = await contractEther.removeLiquidityETH(
        token.address,
        amountLiquidity,
        settingTransaction?.checkedExpert ? 0 : toFixed(amountTokenMin),
        settingTransaction?.checkedExpert ? 0 : toFixed(amountEthMin),
        user.address,
        deadline
      );
    }

    callbackHash(transaction.hash);
    await transaction.wait(1);
    callback();
  } else if (user.privateKey) {
    const params = {
      nonce: web3.utils.toHex(await web3.eth.getTransactionCount(user.address)),
      gasLimit: web3.utils.toHex(await data.estimateGas({from: user.address})),
      gasPrice: web3.utils.toHex(await web3.eth.getGasPrice()),
      to: contract._address,
      data: data.encodeABI(),
      value: '0x00' // 0
    };
    await signTransaction(params, callback, user, web3, callbackError, callbackHash);
  } else {
    data
      .send(
        {
          from: user.address
        },
        (error, transactionHash) => {
          if (error) {
            callbackError();
          } else {
            callbackHash(transactionHash);
          }
          console.log(transactionHash);
        }
      )
      .once('confirmation', callback);
  }
};

export const removeLiquidity = async (
  tokenA,
  tokenB,
  liquidityToken,
  amountLiquidity,
  amountTokenA,
  amountTokenB,
  user,
  signature,
  callback,
  callbackError,
  web3,
  contract,
  callbackHash
) => {
  const settingTransaction = localStorage.getItem('setting_transaction')
    ? JSON.parse(localStorage.getItem('setting_transaction'))
    : null;
  const slippageTolerance = settingTransaction?.activePercent || '1';
  const deadline = Math.floor(
    (Date.now() + (settingTransaction?.dealine || 10) * 60000) / 1000
  ).toString();
  const amountTokenMin = new BigNumber(amountTokenA)
    .times(new BigNumber(100).minus(slippageTolerance))
    .div(100)
    .times(new BigNumber(10).pow(tokenA.decimals))
    .toString();
  const amountTokenBMin = new BigNumber(amountTokenB)
    .times(new BigNumber(100).minus(slippageTolerance))
    .div(100)
    .times(new BigNumber(10).pow(tokenB.decimals))
    .toString();
  let data;
  if (signature) {
    data = contract.methods.removeLiquidityWithPermit(
      tokenA.address,
      tokenB.address,
      amountLiquidity,
      settingTransaction?.checkedExpert ? 0 : toFixed(amountTokenMin),
      settingTransaction?.checkedExpert ? 0 : toFixed(amountTokenBMin),
      user.address,
      signature.deadline,
      false,
      signature.v,
      signature.r,
      signature.s
    );
  } else {
    data = contract.methods.removeLiquidity(
      tokenA.address,
      tokenB.address,
      amountLiquidity,
      settingTransaction?.checkedExpert ? 0 : toFixed(amountTokenMin),
      settingTransaction?.checkedExpert ? 0 : toFixed(amountTokenBMin),
      user.address,
      deadline
    );
  }

  if (user.isWC) {
    data.send(
      {
        from: user.address
      },
      (error, transactionHash) => {
        if (error) {
          callbackError();
        } else {
          console.log(transactionHash);
          callbackHash(transactionHash);
        }
      }
    ).once('confirmation', callback);
  } else if (user.privateKey) {
    const params = {
      nonce: web3.utils.toHex(await web3.eth.getTransactionCount(user.address)),
      gasLimit: web3.utils.toHex(await data.estimateGas({from: user.address})),
      gasPrice: web3.utils.toHex(await web3.eth.getGasPrice()),
      to: contract._address,
      data: data.encodeABI(),
      value: '0x00' // 0
    };
    await signTransaction(params, callback, user, web3, callbackError, callbackHash);
  } else {
    data
      .send(
        {
          from: user.address
        },
        (error, transactionHash) => {
          if (error) {
            callbackError();
          } else {
            callbackHash(transactionHash);
          }
        }
      )
      .once('confirmation', callback);
  }
};

export const approveWithSignedTypeData = async (pair, user, amountRemove, selectedPool) => {
  const settingTransaction = localStorage.getItem('setting_transaction')
    ? JSON.parse(localStorage.getItem('setting_transaction'))
    : null;
  let web3Provider;
  const isUni = selectedPool === UNI;
  if (user.isWC) {
    web3Provider = await new ethers.providers.Web3Provider(window.web3.currentProvider);
  } else if (user.privateKey) {
    web3Provider = await getTransactionSigner(CHAIN_ID, true);
  } else {
    web3Provider = await new ethers.providers.Web3Provider(window.ethereum);
  }
  const erc20Contract = new Contract(pair.liquidityToken.address, ERC20_ABI.abi, web3Provider);
  const nonce = await erc20Contract.nonces(user.address);
  const EIP712Domain = [
    {name: 'name', type: 'string'},
    {name: 'version', type: 'string'},
    {name: 'chainId', type: 'uint256'},
    {name: 'verifyingContract', type: 'address'}
  ];
  const domain = {
    name: isUni ? 'Uniswap V2' : 'SushiSwap LP Token',
    version: '1',
    chainId: CHAIN_ID,
    verifyingContract: pair.liquidityToken.address
  };
  const Permit = [
    {name: 'owner', type: 'address'},
    {name: 'spender', type: 'address'},
    {name: 'value', type: 'uint256'},
    {name: 'nonce', type: 'uint256'},
    {name: 'deadline', type: 'uint256'}
  ];
  const deadline = Math.floor(Date.now() / 1000) + (settingTransaction?.dealine || 10) * 60;
  const message = {
    owner: user.address,
    spender: isUni ? ROUTE_V2_ADDR : ROUTER_ADDRESS[CHAIN_ID],
    value: amountRemove,
    nonce: nonce._hex,
    deadline
  };
  const data = {
    types: {
      EIP712Domain,
      Permit
    },
    domain,
    primaryType: 'Permit',
    message
  };
  if (user.privateKey) {
    const signature = signTypedData_v4(Buffer.from(user.privateKey, 'hex'), {data});
    return new Promise((resolve, reject) => {
      const splitedSignature = splitSignature(signature);
      resolve({...splitedSignature, deadline});
    });
  } else {
    return web3Provider
      .send('eth_signTypedData_v4', [user.address, JSON.stringify(data)])
      .then(splitSignature)
      .then(signature => {
        return {
          v: signature.v,
          r: signature.r,
          s: signature.s,
          deadline
        };
      })
      .catch(error => {
        if (error?.code !== 4001) {
          console.log(error);
        }
        throw error;
      });
  }
};

export const approve = async (
  web3,
  contract,
  user,
  callbackSuccess,
  pool,
  callBackError,
  callBackHash
) => {
  const decimals = await contract.methods.decimals().call();
  const amountIn = new BigNumber(9999999999).times(new BigNumber(10).pow(decimals)).toString();

  const spenderAddress = pool === UNI ? ROUTER_UNISWAP_SM_ADDRESS : ROUTE_V2_ADDR_SUSHI;
  const data = contract.methods.approve(spenderAddress, toFixed(amountIn));
  if (user.isWC) {
    try {
      const signer = await getTransactionSigner(CHAIN_ID, false, true);
      const contractEther = new Contract(contract._address, erc20.abi, signer);
      const transaction = await contractEther.approve(spenderAddress, toFixed(amountIn));
      callBackHash(transaction.hash);
      await transaction.wait(1);
      callbackSuccess();
    } catch (e) {
      console.log(e);
      callBackError(e);
    }
  } else if (user.privateKey) {
    const params = {
      nonce: web3.utils.toHex(await web3.eth.getTransactionCount(user.address)),
      gasLimit: web3.utils.toHex(await data.estimateGas({from: user.address})),
      gasPrice: web3.utils.toHex(await web3.eth.getGasPrice()),
      to: contract._address,
      data: data.encodeABI(),
      value: '0x00' // 0
    };
    await signTransaction(params, callbackSuccess, user, web3, callBackError, callBackHash);
  } else {
    data
      .send({from: user.address}, (error, transactionHash) => {
        if (error) {
          callBackError(error);
        } else {
          callBackHash(transactionHash);
        }
      })
      .once('confirmation', callbackSuccess);
  }
  return amountIn;
};

export const signTransaction = async (
  params,
  callback,
  user,
  web3,
  callbackError,
  callBackTransactionHash
) => {
  const tx = new Tx(params, {chain: NETWORK_NAME.toLowerCase()});
  tx.sign(Buffer.from(user.privateKey, 'hex'));
  const serializeTx = tx.serialize();
  await web3.eth
    .sendSignedTransaction('0x' + serializeTx.toString('hex'))
    .once('error', (e, re) => {
      callbackError(e);
    })
    .once('transactionHash', e => {
      callBackTransactionHash(e);
    })
    .once('confirmation', (number, receipt) => {
      callback(number, receipt);
    });
};

export function serializeToken(token) {
  return {
    chainId: token.chainId,
    address: token.address,
    decimals: token.decimals,
    symbol: token.symbol,
    name: token.name
  };
}

export function deserializeToken(serializedToken) {
  return new Token(
    serializedToken.chainId,
    serializedToken.address,
    serializedToken.decimals,
    serializedToken.symbol,
    serializedToken.name
  );
}

export function toV2LiquidityToken([tokenA, tokenB], pool) {
  const Token = getTokenByPool(pool);
  const Pair = getPairByPool(pool);
  return new Token(tokenA.chainId, Pair.getAddress(deserializeToken(tokenA), deserializeToken(tokenB)), 18, 'UNI', 'Uniswap V2');
}

export function getTokenByPool(pool) {
  return pool === UNI ? Token : TokenSushi;
}

export function getPairByPool(pool) {
  return pool === UNI ? Pair : PairSushi;
}

export const fetchDefaultActiveList = async (chainId, listUrls, pool) => {
  const tokensMappingAddress = {};

  await Promise.all(
    listUrls.map(async url => {
      return fetch(url)
        .then(res => res.json())
        .then(list => {
          list.tokens &&
            list.tokens.length > 0 &&
            list.tokens
              .filter(token => new BigNumber(token.chainId) === new BigNumber(chainId))
              .forEach(token => {
                if (pool === UNI) {
                  tokensMappingAddress[token.address] = new WrappedTokenInfo(token, []);
                } else {
                  tokensMappingAddress[token.address] = new WrappedTokenInfoSushi(token, []);
                }
              });
        });
    })
  );

  const defaultTokens = DEFAULT_TOKEN_LIST.tokens.filter(token => {
    return token.chainId === Number(chainId);
  });

  defaultTokens.forEach(token => {
    tokensMappingAddress[token.address] = new WrappedTokenInfo(token, []);
  });

  return tokensMappingAddress;
};

export const savePairLocal = (token0, token1) => {
  const keyPairLocal = `${token0.address};${token1.address}`;
  const pairsLocal = localStorage.getItem('liquidity_pairs')
    ? JSON.parse(localStorage.getItem('liquidity_pairs'))
    : {};
  if (!pairsLocal[keyPairLocal]) {
    localStorage.setItem(
      'liquidity_pairs',
      JSON.stringify({
        ...pairsLocal,
        [keyPairLocal]: {
          token0,
          token1
        }
      })
    );
  }
};

export const getPairDetails = async (pairAddress, user) => {
  const provider = new ethers.providers.InfuraProvider(NETWORK_NAME.toLowerCase(), INFURA_KEY);
  const ethcallProvider = new Provider(provider);
  await ethcallProvider.init();

  const pairContract = new ContractMulticall(pairAddress, PAIR_ABI);
  const calls = [
    pairContract.balanceOf(user.address),
    pairContract.getReserves(),
    pairContract.totalSupply(),
    pairContract.token0(),
    pairContract.decimals()
  ];
  const pairDetailsResult = await ethcallProvider.all(calls);

  return {
    balance: pairDetailsResult[0],
    totalSupply: pairDetailsResult[2],
    reserves: pairDetailsResult[1],
    token0: pairDetailsResult[3],
    decimals: pairDetailsResult[4]
  };
};

export const getTokenTotalSupply = async token => {
  const signer = await getTransactionSigner(CHAIN_ID, true);
  const erc20Contract = new Contract(token.address, ERC20_ABI.abi, signer);

  const totalSupply = await erc20Contract.totalSupply();
  return new TokenAmount(token, totalSupply.toString());
};

export const getPairBalances = async (pairs, user) => {
  const provider = new ethers.providers.InfuraProvider(NETWORK_NAME.toLowerCase(), INFURA_KEY);
  const ethcallProvider = new Provider(provider);
  await ethcallProvider.init();

  const factoryContract = new ContractMulticall(FACTORY_CONTRACT_ADDRESS, FACTORY_ABI);
  const pairsExistCalls = pairs.map(pair => {
    const [token0, token1] = pair;
    return factoryContract.getPair(token0.address, token1.address);
  });

  const pairsExist = await ethcallProvider.all(pairsExistCalls);
  // Shallow copy state and filter pair that doesn't exist => With purpose for output
  const tokensWithPair = [...pairs];

  pairsExist.forEach((pair, index) => {
    if (Number(pair) === 0) {
      tokensWithPair.splice(index, 1);
    }
  });
  const pairsBalanceCalls = pairsExist
    .filter(pair => Number(pair) !== 0)
    .map(pair => {
      const pairContract = new ContractMulticall(pair, ERC20_ABI.abi);

      return pairContract.balanceOf(user.address);
    });

  const pairsBalanceResult = await ethcallProvider.all(pairsBalanceCalls);
  return pairsBalanceResult.map((balance, index) => ({
    balance,
    liquidityToken: toV2LiquidityToken([tokensWithPair[index][0], tokensWithPair[index][1]]),
    token0: tokensWithPair[index][0],
    token1: tokensWithPair[index][1]
  }));
};

import { FC, useEffect, useRef } from "react";
import "./style.scss";
import useStyles from "./styles";
import { VECTOR_IMG_PATH, ARROW_LEFT_RIGHT_IMG_PATH } from "../../../static/images";
import OfferSearchItem from "./OfferSearchItem";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useOffersGlobalContext } from "../context/OffersGlobalContext";
import { OPENSEA_MESSAGE } from "../../commons/constants";
import OpenSeaLoading from "../../commons/loading_component/OpenSeaLoading";
import TableRecordLoading from "../../commons/loading_component/TableRecordLoading";
import { Link } from "react-router-dom";
import { convertOfferItemsFromOrdersResponse } from "./OfferSearchHelper";

const OfferSearchView: FC = (): JSX.Element => {
  const state = useSelector(state => state);
  const user = _.get(state, "user.address", null);
  const styles = useStyles();
  const {
    ordersData: data,
    ordersLoading: loading,
    ordersError: error,
    isLoadingMore,
    fetchMoreOrders
  } = useOffersGlobalContext();

  /* Lazy loading begin */
  const tbodyRef = useRef<HTMLTableSectionElement>(null);
  const handleScroll = () => {
    const tbodyElement = tbodyRef.current;
    if (tbodyElement) {
      if (tbodyElement.offsetHeight + tbodyElement.scrollTop >= tbodyElement.scrollHeight * 0.9) {
        if (tbodyElement) {
          if (_.get(data, "orders.pageInfo.hasNextPage", false) && !isLoadingMore) {
            fetchMoreOrders();
          }
        }
      }
    }
  };

  useEffect(() => {
    // function handleScroll have dependency [data, isLoadingMore]
    if (data) {
      const tbodyElement = tbodyRef.current;
      if (tbodyElement) {
        tbodyElement.addEventListener("scroll", handleScroll);
      }
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const tbodyElement = tbodyRef.current;
      if (tbodyElement) {
        tbodyElement.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoadingMore]);
  /* Lazy loading end */

  if (loading) return <OpenSeaLoading />;
  if (error || !data) return <div className={styles.noData}>{OPENSEA_MESSAGE.OFFERS_NO_ITEM}</div>;

  const listItem = data.orders.edges;

  return (
    <>
      <div className={styles.rectangle}>
        <div className={styles.topRectangle}>
          <div className={styles.left}>
            <img alt="" style={{ marginTop: "-3px" }} src={VECTOR_IMG_PATH} />{" "}
            <span>Offers Made</span>
          </div>
          <div>
            <div className={styles.box}>
              <Link to="/swap">
                <img alt="" src={ARROW_LEFT_RIGHT_IMG_PATH} /> Convert WETH
              </Link>
            </div>
          </div>
        </div>
        {listItem?.length > 0 && (
          <table className={styles.offerTable}>
            <thead className={styles.tHead}>
              <tr>
                <td width="300px" className={styles.headerTableTd}>
                  Items
                </td>
                <td className={styles.headerTableTd}>Price</td>
                <td className={styles.headerTableTd}>Quantity</td>
                <td className={styles.headerTableTd}>
                  <span>From</span>
                </td>
                <td className={styles.headerTableTd}>Expiration</td>
                <th className={styles.headerTableTd}>Status</th>
                <th className={styles.headerTableTd}></th>
              </tr>
            </thead>
            <tbody ref={tbodyRef} style={{ maxHeight: "400px" }}>
              {convertOfferItemsFromOrdersResponse(data, user).map(item => {
                return (
                  <OfferSearchItem
                    key={item.key}
                    from={item.from}
                    assetName={item.assetName}
                    pathImage={item.pathImage}
                    assetsLink={item.assetsLink}
                    price={item.price}
                    assetQuantity={item.assetQuantity}
                    timeStamp={item.timeStamp}
                    status={item.status}
                    showCancel={item.showCancel}
                    oldOrderString={item.oldOrderString}
                  />
                );
              })}
              {isLoadingMore && (
                <tr className={styles.contentTable}>
                  <td colSpan={7} className={styles.contentLoading}>
                    <TableRecordLoading />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
        {listItem.length === 0 && (
          <div className={styles.noData}>{OPENSEA_MESSAGE.OFFERS_NO_ITEM}</div>
        )}
      </div>
    </>
  );
};

export default OfferSearchView;

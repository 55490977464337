import {
  CollectionType,
  DynamicNumbericItemType,
  DynamicStringItemType,
  PageInfoType,
  PaymentType
} from "./ResponseType";

/*
===Data type===
*/
// event type and toggle type
export type FeaturedDataType = {
  name: string;
  slug: string;
  isSelected: boolean;
};

export type FeaturesDataType = FeaturedDataType[];

export type CollectionDataType = {
  cursor: string;
  isSelected: boolean;
} & CollectionType;

export type CollectionsDataType = {
  collections: CollectionDataType[];
  pageInfo: PageInfoType;
};

export type PaymentAssetDataType = {
  isSelected: boolean;
} & PaymentType;

export type PaymentAssetsDataType = PaymentAssetDataType[];

export type DynamicStringTraitDataType = {
  isSelected: boolean;
} & DynamicStringItemType;

export type DynamicListStringTraitDataType = {
  key: string;
  stringTraits: DynamicStringTraitDataType[];
};

export type DynamicRangeDataType = {
  isModified: boolean; // isModified=false when currentRange=[min,max]
  currentRange: [number, number];
} & DynamicNumbericItemType;

export type DynamicListRangeDataType = {
  key: string;
  numericTraits: DynamicRangeDataType;
};

export type CategoryDataType = {
  name: string;
  slug: string;
  isSelected: boolean;
};

export type CategoriesDataType = CategoryDataType[];

export type DropDownItemDataType = {
  name: string;
  slug: string;
};

export type DropDownDataType = {
  slugSelected: string;
  listItems: DropDownItemDataType[];
};

export enum SEARCH_PILL_TYPE {
  FEATURES,
  COLLECTION,
  PAYMENT,
  NUMBERICTRAIT,
  STRINGTRAIT
}

export type SearchPillDataType = {
  type: SEARCH_PILL_TYPE;
  text: string;
  value: string;
};

/*
===Variables type===
*/

export type NumericTraitsVariableType = {
  name: string;
  ranges: [
    {
      max: number;
      min: number;
    }
  ];
};

export type StringTraitsVariableType = {
  name: string;
  values: string[];
};

export type AssetSearchQueryInSearchPillVariableType = {
  toggles: string[] | null;
  collections: string[];
  paymentAssets: string[] | null;
  stringTraits: StringTraitsVariableType[] | null;
  numericTraits: NumericTraitsVariableType[] | null;
};

// Take variable from query string
export type AssetSearchQueryInQueryStringVariableType = {
  categories: null | string[];
  collection: string | null;
  resultModel: null | string;
  sortAscending: null | boolean;
  sortBy: null | string;
} & AssetSearchQueryInSearchPillVariableType;

export type AssetSearchQueryVariableType = {
  chains: null;
  collectionQuery: null;
  collectionSortBy: string;
  count: number;
  cursor: null | string;
  identity: null | object;
  includeHiddenCollections: boolean;
  includeIsListable: boolean;
  priceRange: null;
  query: string | null;
  shouldShowQuantity: boolean;
} & AssetSearchQueryInQueryStringVariableType;

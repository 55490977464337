import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Web3 from 'web3';

import { loginUserReset, loginUser, login2faUser, loginUserMetaMask } from '_actions/user.actions';
import LoginPage from '../LoginPage/LoginPage';
import encrypter from '../../../../_encrypter/index.js';
import { connectExchangev2 } from '_actions/exchanges.actions';

const INFURA_URL = process.env.REACT_APP_INFURA_URL;

export class LoginPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { privateKey: '', error: '' };
  }

  static propTypes = {
    user: PropTypes.object,
    loginUserReset: PropTypes.func.isRequired,
    loginUser: PropTypes.func.isRequired,
    loginUserMetaMask: PropTypes.func.isRequired,
    login2faUser: PropTypes.func.isRequired
  };

  state = {
    submitError: false
  };

  componentDidMount() {
    this.props.loginUserReset();
    localStorage.removeItem('walletconnect');
  }

  async getLoginAccount() {
    let accounts;
    accounts = await window.web3.eth.getAccounts((error, accounts) => {
      if (error) return;
      window.web3.eth.defaultAccount = accounts[0];
    });

    if (accounts && accounts.length > 0) {
      return accounts[0];
    }
    return null;
  }
  async connectToMetaMask() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
    }
    const account = await this.getLoginAccount();
    let exchanges = ['BINANCE', 'BITTREX'];
    for (let i = 0; i < exchanges.length; i++) {
      await this.decryptApiKey(encrypter, account, exchanges[i]);
    }
    return account;
  }
  async decryptApiKey(encrypter, account, exchange) {
    const { connectExchangev2 } = this.props;
    let encrypterKey = await encrypter.getDataContract(account, exchange);
    if (encrypterKey) {
      let pair = await encrypter.decryptByMetaMask(account, encrypterKey);
      let api = pair.split(' ');
      let values = {
        apiKey: api[0],
        secret: api[1],
        exchangeName: exchange
      };
      connectExchangev2(values, account, () => {});
    }
  }
  async connectByPrivateKey(privateKey) {
    try {
      const provider = new Web3.providers.HttpProvider(INFURA_URL);
      window.web3 = new Web3(provider);

      const account = window.web3.eth.accounts.privateKeyToAccount(privateKey);
      let exchanges = ['BINANCE', 'BITTREX'];
      for (let i = 0; i < exchanges.length; i++) {
        await this.decryptApiKeyByPrivate(encrypter, account.address, exchanges[i], privateKey);
      }
      return account;
    } catch (err) {
      if (privateKey === '' || !privateKey) {
        this.setState({ error: 'Private key is required.' });
      } else {
        this.setState({ error: 'Please enter the correct private key.' });
      }
    }
  }
  async decryptApiKeyByPrivate(encrypter, account, exchange, privateKey) {
    const { connectExchangev2 } = this.props;
    let encrypterKey = await encrypter.getDataContract(account, exchange);
    if (encrypterKey) {
      let pair = await encrypter.decryptByPrivateKey(privateKey, encrypterKey);
      let api = pair.split(' ');
      let values = {
        apiKey: api[0],
        secret: api[1],
        exchangeName: exchange
      };
      connectExchangev2(values, account, () => {});
    }
  }
  initEventMetamask() {
    window.ethereum.autoRefreshOnNetworkChange = true;
    console.log('init listen event!');
    if (!window.isInitListenEventETH) {
      window.isInitListenEventETH = true;
      window.ethereum.on('accountsChanged', accounts => {
        if (window.web3.currentProvider.isMetaMask) {
          console.log('accountsChanged');
          window.location.href = '/auth/login';
        }
      });
      window.ethereum.on('networkChanged', networkId => {
        if (window.web3.currentProvider.isMetaMask) {
          console.log('Phan Hai Nam------------>networkChanged');
          localStorage.removeItem('dataTokenInfor');
          window.location.href = '/auth/login';
        }
      });
    }
  }
  handleSubmit = async values => {
    window.encrypter = encrypter;
    if (values && values.isWc) {
      this.props.loginUserMetaMask(values.account, false, false, null, null, true);
      return;
    }
    if (values && values.isMetaMask) {
      let account = await this.connectToMetaMask();
      this.props.loginUserMetaMask(account);
      this.initEventMetamask();
    } else if (values && values.mnemonicPhrase) {
      let account = await this.connectByPrivateKey(values.mnemonicPhrase);
      let publicKey = encrypter.getPublicKeyFromPrivateKey(values.mnemonicPhrase);
      this.props.loginUserMetaMask(account.address, false, true, publicKey, values.mnemonicPhrase);
    } else {
      let account = await this.connectByPrivateKey(values.privateKey);
      let publicKey = encrypter.getPublicKeyFromPrivateKey(values.privateKey);
      this.props.loginUserMetaMask(account.address, false, false, publicKey, values.privateKey);
    }
  };

  handleSubmitError = error => {
    this.setState({ submitError: true });
  };

  render() {
    const { user } = this.props;
    if (user.address) {
      return <Redirect to={'/'} />;
    }

    const pageProps = {
      user: this.props.user,
      handleSubmit: this.handleSubmit,
      handleSubmitError: this.handleSubmitError,
      submitError: this.state.submitError,
      error: this.state.error
    };

    return <LoginPage {...pageProps} />;
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = {
  loginUser,
  loginUserMetaMask,
  loginUserReset,
  login2faUser,
  connectExchangev2
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageContainer);

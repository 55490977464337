export const reset_token_in_list = () => ({
  type: 'RESET_TOKEN_IN_LIST'
})
export const save_token_in_list = tokens => ({
  type: 'SAVE_TOKEN_IN_LIST',
  tokens
});

export const add_token_in_list = listTokenAdd => ({
  type: 'ADD_TOKEN_IN_LIST',
  listTokenAdd
});

export const remove_token_in_list = listTokenRemove => ({
  type: 'REMOTE_TOKEN_IN_LIST',
  listTokenRemove
});

export const remove_one_token_added_by_address = tokenAddress => ({
  type: 'REMOVE_ONE_TOKEN_ADDED_BY_ADDRESS',
  tokenAddress
});

export const set_check_pool = poolName => ({
  type: 'SET_CHECK_POOL',
  poolName
});

export const save_balance_eth = blanane => ({
  type: 'SAVE_BALANCE_ETH',
  blanane
});

import { combineReducers } from 'redux';

import account from './account.reducer';
import trading from './trading.reducer';
import candlesticks from './candlesticks.reducer';
import exchanges from './exchanges.reducer';
import markets from './markets.reducer';
import exchangegecko from './exchangegecko.reducer';
import marketcap from './marketcap.reducer';
import networth from './networth.reducer';
import orderbook from './orderbook.reducer';
import orders from './orders.reducer';
import selections from './selections.reducer';
import trades from './trades.reducer';
import twoFactorAuth from './twoFactorAuth.reducer';
import user from './user.reducer';
import form from './form.reducer';
import notifications from './notifications.reducer';
import titlePage from './titlepage.reducer';
import swapPage from './swap.reducer';
import sendtransactionPage from './sendtransaction.reducer';
import storeToolkit from './../_reduxToolkit/slice/counterSlice';
import btcPrice from './btcPrice.reducer';
import { actionTypes } from '../_actions/user.actions';

const appReducer = combineReducers({
  account,
  trading,
  candlesticks,
  exchanges,
  markets,
  exchangegecko,
  marketcap,
  networth,
  orderbook,
  orders,
  selections,
  trades,
  twoFactorAuth,
  user,
  form,
  notifications,
  titlePage,
  swapPage,
  btcPrice,
  sendtransactionPage,
  storeToolkit
});

// Thanks Dan! https://stackoverflow.com/a/35641992/873073
const rootReducer = (state, action) => {
  if (action.type === actionTypes.LOGOUT_USER_REQUEST) {
    state = undefined;
    localStorage.removeItem('walletconnect');
  }

  return appReducer(state, action);
};

export default rootReducer;

import {useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {ApolloClient, InMemoryCache} from '@apollo/client';
import BigNumber from 'bignumber.js';

import {GET_CHECKOUT_QUERY} from '../../../../../graphql/opensea/CheckoutModalQuery';

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_PROXY_URL}${process.env.REACT_APP_OPENSEA_GRAPHQL_API}`,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
});

const chain_name = process.env.REACT_APP_NETWORK_NAME;

const useOrderCheckout = tokenDetails => {
  const [orderCheckout, setOrderCheckout] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const {address} = useSelector(state => state.user);

  const fetchOrderCheckout = useCallback(async () => {
    if (address && tokenDetails) {
      try {
        setLoading(true);

        const result = await client.query({
          query: GET_CHECKOUT_QUERY,
          variables: {
            asset: tokenDetails.assetId,
            identity: {
              address,
              chain: chain_name
            },
            orderId: tokenDetails.id
          },
          context: {
            headers: {
              'x-api-key': process.env.OPENSEA_GRAPHQL_API_KEY,
              'x-build-id': 'eFPMdMDFI3fMUMuY2FCeF',
              'x-viewer-address': address,
              'x-viewer-chain': chain_name
            }
          }
        });

        if (result.data) {
          const {blockchain, order} = result.data;

          setLoading(false);
          setOrderCheckout({
            balance: new BigNumber(blockchain.balance).div(1e18).toFixed(),
            quantity: order.makerAssetBundle.assetQuantities.edges[0].node.quantity
          });
        }
      } catch (err) {
        console.log(err.message);
        setLoading(false);
      }
    }
  }, [address, tokenDetails]);

  return {orderCheckout, loading, fetchOrderCheckout};
};

export default useOrderCheckout;

import { ApolloError } from "@apollo/client";
import { AssetSearchQueryResponseType } from "Pages/OpenSea/static/type";
import { FC } from "react";
import { convertContentItemsFromAssetSearchResponse } from "./AssetsContentHelper";
import AssetsContentItem from "./AssetsContentItem";
import useStyles from "./styles";

type AssetsContentViewProps = {
  data: AssetSearchQueryResponseType;
  loading: boolean;
  error: ApolloError | undefined;
  emptyMessage: string;
  isLoadingMore?: boolean;
};

/**
 * Component show list asset items.
 * Using in assets listing and account inwallet screen.
 * This is pure component.
 * only handle the logic of converting data from props passed in and displayed on the screen.
 * don't use useContext or similar effects here.
 * @param props passing data graphql from context here
 * @returns Element JSX
 */
const AssetsContentView: FC<AssetsContentViewProps> = (props): JSX.Element => {
  const styles = useStyles();

  const { emptyMessage, isLoadingMore } = props;

  // this data get in context on wrapped screen
  const { data, loading, error } = props;

  if (loading || error || !data) return <></>;

  const listItem = data?.query?.search?.edges ?? [];

  return (
    <>
      <div className={styles.contentPage + (listItem.length === 0 ? " emptyData " : "")}>
        {convertContentItemsFromAssetSearchResponse(data).map(item => {
          return (
            <AssetsContentItem
              key={item.cursor}
              collectionName={item.collectionName}
              pathImage={item.pathImage}
              assetName={item.itemName}
              paymentQuantity={item.paymentQuantity}
              paymentSymbol={item.paymentSymbol}
              address={item.address}
            />
          );
        })}
        {listItem.length === 0 && !isLoadingMore && <>{emptyMessage}</>}
      </div>
    </>
  );
};

AssetsContentView.defaultProps = {
  isLoadingMore: false
};

export default AssetsContentView;

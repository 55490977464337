import _ from "lodash";
import {
  NumericTraitsVariableType,
  StringTraitsVariableType,
  AssetSearchQueryInQueryStringVariableType,
  ActivityQueryInQueryStringVariableType,
  OfferSearchInQueryStringVariableType
} from "Pages/OpenSea/static/type";
import {
  LISTING_SORT_BY_VARIABLES,
  RESULT_MODEL_VARIABLES,
  SCREEN,
  WALLET_SORT_BY_VARIABLES
} from "../constants";

/*
    STEP1: Get data from query string (in path) and do parsing into variables.
    The basic steps include:
    - Split string into list of [key:value] (special character handling)
    - Filter the list of [key:value] on types of variables
    - Convert to variable types
    - Combine variable types and return the result
*/

const convertSpecialCharacters = (input: string): string => {
  return _(input)
    .chain()
    .replace(new RegExp("%20", "g"), " ")
    .value();
};

const splitRawRecordsFromQueryString = (queryString: string) => {
  const rawRecords = _(convertSpecialCharacters(queryString))
    .chain()
    .replace("?search", "")
    .split("&search")
    .value();
  return rawRecords;
};

type RawRecordDataType = {
  index: number;
  type: TYPE_RECORD;
  rawKey: string;
  value: string;
  rawRecord: string;
};

enum TYPE_RECORD {
  DONT_CARE,
  NUMBERIC_NAME,
  NUMBERIC_MAX,
  NUMBERIC_MIN,
  STRINGTRAIT_NAME,
  STRINGTRAIT_VALUE
}

const getTypeFromRawKey = (rawKey: string) => {
  let type = TYPE_RECORD.DONT_CARE;
  if (testRegex(PATTERN_REGEX.NUMBERIC_NAME, rawKey)) {
    type = TYPE_RECORD.NUMBERIC_NAME;
  } else if (testRegex(PATTERN_REGEX.NUMBERIC_MAX, rawKey)) {
    type = TYPE_RECORD.NUMBERIC_MAX;
  } else if (testRegex(PATTERN_REGEX.NUMBERIC_MIN, rawKey)) {
    type = TYPE_RECORD.NUMBERIC_MIN;
  } else if (testRegex(PATTERN_REGEX.STRINGTRAIT_NAME, rawKey)) {
    type = TYPE_RECORD.STRINGTRAIT_NAME;
  } else if (testRegex(PATTERN_REGEX.STRINGTRAIT_VALUE, rawKey)) {
    type = TYPE_RECORD.STRINGTRAIT_VALUE;
  }
  return {
    type
  };
};

const PATTERN_REGEX = {
  NUMBERIC: "^(\\[numericTraits\\]\\[\\d+\\])",
  NUMBERIC_NAME: "^(\\[numericTraits\\]\\[\\d+\\]\\[name\\])",
  NUMBERIC_MAX: "^(\\[numericTraits\\]\\[\\d+\\]\\[ranges\\]\\[\\d+\\]\\[max\\])",
  NUMBERIC_MIN: "^(\\[numericTraits\\]\\[\\d+\\]\\[ranges\\]\\[\\d+\\]\\[min\\])",
  STRINGTRAIT: "^(\\[stringTraits\\]\\[\\d+\\])",
  STRINGTRAIT_NAME: "^(\\[stringTraits\\]\\[\\d+\\]\\[name\\])",
  STRINGTRAIT_VALUE: "^(\\[stringTraits\\]\\[\\d+\\]\\[values\\]\\[\\d+\\])",
  TOGGLES: "^(\\[toggles\\]\\[\\d+\\])",
  PAYMENT: "^(\\[paymentAssets\\]\\[\\d+\\])",
  COLLECTION: "^(\\[collections\\]\\[\\d+\\])",
  CATEGORY: "^(\\[categories\\]\\[\\d+\\])",
  RESULTMODEL: "^(\\[resultModel\\])",
  SORTASCENDING: "^(\\[sortAscending\\])",
  SORTBY: "^(\\[sortBy\\])",
  EVENT_TYPES: "^(\\[eventTypes\\]\\[\\d+\\])"
};

const testRegex = (pattern: string | RegExp, string: string) => {
  const regex = new RegExp(pattern, "g");
  return regex.test(string);
};

const getIndexFromRawKey = (rawKey: string) => {
  const index = Number(_.split(_.split(rawKey, "[")[2], "]")[0]);
  return {
    index
  };
};

const splitKeyAndValueInRecord = (rawRecord: string) => {
  const tmp = _.split(rawRecord, "=");
  return {
    rawKey: tmp[0],
    value: tmp[1]
  };
};

const filterRawRecordsDataByPattern = (pattern: string | RegExp, rawRecords: string[]) => {
  const filterRawRecords = _.filter(rawRecords, item => testRegex(pattern, item));
  const filterRawRecordsData = filterRawRecords.map(rawRecord => {
    const rawKeyAndValue = splitKeyAndValueInRecord(rawRecord);
    const type = getTypeFromRawKey(rawKeyAndValue.rawKey);
    const index = getIndexFromRawKey(rawKeyAndValue.rawKey);
    return {
      rawRecord,
      ...rawKeyAndValue,
      ...type,
      ...index
    };
  });
  return filterRawRecordsData;
};

const convertTogglesRawRecordsToVariables = (rawRecords: string[]): { toggles: string[] } => {
  let toggles = [] as string[];
  const togglesRawRecordsData = filterRawRecordsDataByPattern(PATTERN_REGEX.TOGGLES, rawRecords);
  if (!_.isEmpty(togglesRawRecordsData)) {
    toggles = _(togglesRawRecordsData)
      .chain()
      .map(togglesRawRecordData => {
        return togglesRawRecordData.value;
      })
      .value();
  }
  return {
    toggles
  };
};

const convertEventTypesRawRecordsToVariables = (rawRecords: string[]): { eventTypes: string[] } => {
  let eventTypes = [] as string[];
  const eventTypesRawRecordsData = filterRawRecordsDataByPattern(
    PATTERN_REGEX.EVENT_TYPES,
    rawRecords
  );
  if (!_.isEmpty(eventTypesRawRecordsData)) {
    eventTypes = _(eventTypesRawRecordsData)
      .chain()
      .map(eventTypesRawRecordData => {
        return eventTypesRawRecordData.value;
      })
      .value();
  }
  return {
    eventTypes
  };
};

const convertCollectionsAssetsRawRecordsToVariables = (
  rawRecords: string[]
): { collection: string | null; collections: string[] } => {
  let collections = [] as string[];
  const collectionRawRecordsData = filterRawRecordsDataByPattern(
    PATTERN_REGEX.COLLECTION,
    rawRecords
  );
  if (!_.isEmpty(collectionRawRecordsData)) {
    collections = _(collectionRawRecordsData)
      .chain()
      .map(collectionRawRecordData => {
        return collectionRawRecordData.value;
      })
      .value();
  }
  return {
    collection: collections.length === 1 ? collections[0] : null,
    collections
  };
};

const convertPaymentAssetsRawRecordsToVariables = (
  rawRecords: string[]
): { paymentAssets: string[] } => {
  let paymentAssets = [] as string[];
  const paymentRawRecordsData = filterRawRecordsDataByPattern(PATTERN_REGEX.PAYMENT, rawRecords);
  if (!_.isEmpty(paymentRawRecordsData)) {
    paymentAssets = _(paymentRawRecordsData)
      .chain()
      .map(paymentRawRecordData => {
        return paymentRawRecordData.value;
      })
      .value();
  }
  return {
    paymentAssets
  };
};

const convertNumbericTraitRawRecordsToVariables = (
  rawRecords: string[]
): { numericTraits: NumericTraitsVariableType[] } => {
  let numericTraits = [] as NumericTraitsVariableType[];
  const numbericRawRecordsData = filterRawRecordsDataByPattern(PATTERN_REGEX.NUMBERIC, rawRecords);
  if (!_.isEmpty(numbericRawRecordsData)) {
    numericTraits = _(numbericRawRecordsData)
      .chain()
      .groupBy("index")
      .map(numbericRawRecordData => {
        const numbericConvert = {} as NumericTraitsVariableType;
        _.forOwn(numbericRawRecordData, (value, key) => {
          const _value = value as RawRecordDataType;
          if (_value.type === TYPE_RECORD.NUMBERIC_NAME) {
            _.update(numbericConvert, "name", () => _value.value);
          } else if (_value.type === TYPE_RECORD.NUMBERIC_MIN) {
            _.update(numbericConvert, "ranges[0].min", () => _value.value);
          } else if (_value.type === TYPE_RECORD.NUMBERIC_MAX) {
            _.update(numbericConvert, "ranges[0].max", () => _value.value);
          }
        });
        return numbericConvert;
      })
      .value();
  }
  return { numericTraits };
};

const convertStringTraitRawRecordsToVariables = (
  rawRecords: string[]
): { stringTraits: StringTraitsVariableType[] } => {
  let stringTraits = [] as StringTraitsVariableType[];
  const stringRawRecordsData = filterRawRecordsDataByPattern(PATTERN_REGEX.STRINGTRAIT, rawRecords);
  if (!_.isEmpty(stringRawRecordsData)) {
    stringTraits = _(stringRawRecordsData)
      .chain()
      .groupBy("index")
      .map(stringRawRecordData => {
        const stringTraitConvert = {} as StringTraitsVariableType;
        _.forOwn(stringRawRecordData, (value, key) => {
          const _value = value as RawRecordDataType;
          if (_value.type === TYPE_RECORD.STRINGTRAIT_NAME) {
            _.update(stringTraitConvert, "name", () => _value.value);
          } else if (_value.type === TYPE_RECORD.STRINGTRAIT_VALUE) {
            _.update(stringTraitConvert, "values", currentValues => {
              if (!currentValues) {
                return [_value.value];
              }
              currentValues.push(_value.value);
              return currentValues;
            });
          }
        });
        return stringTraitConvert;
      })
      .value();
  }
  return {
    stringTraits
  };
};

const convertCategoryRawRecordsToVariables = (
  rawRecords: string[]
): { categories: string[] | null } => {
  let categories = null as string[] | null;

  const categoriesRawRecordsData = filterRawRecordsDataByPattern(
    PATTERN_REGEX.CATEGORY,
    rawRecords
  );
  if (!_.isEmpty(categoriesRawRecordsData)) {
    // only one category
    categories = [categoriesRawRecordsData[0].value];
  }
  return {
    categories
  };
};

const convertResultModelRawRecordsToVariables = (
  rawRecords: string[]
): { resultModel: string | null } => {
  let resultModel = RESULT_MODEL_VARIABLES.ALL.resultModel as string | null;

  const resultModelRawRecordsData = filterRawRecordsDataByPattern(
    PATTERN_REGEX.RESULTMODEL,
    rawRecords
  );
  if (!_.isEmpty(resultModelRawRecordsData)) {
    // only one resultModel
    resultModel = resultModelRawRecordsData[0].value;
  }
  return {
    resultModel
  };
};

/**
 * In assets listing, default is {sortAscending: null; sortBy: null}.
 * In account inwallet, default is {sortAscending: false; sortBy: "LAST_TRANSFER_DATE"}.
 * @param rawRecords
 * @param screenMode
 * @returns
 */
const convertSortItemRawRecordsToVariables = (
  rawRecords: string[],
  defaultSortItem: { sortAscending: null | boolean; sortBy: null | string } = {
    sortAscending: null,
    sortBy: null
  }
): { sortAscending: null | boolean; sortBy: null | string } => {
  let sortAscending = defaultSortItem.sortAscending as null | boolean;
  let sortBy = defaultSortItem.sortBy as string | null;

  const sortAscendingRawRecordsData = filterRawRecordsDataByPattern(
    PATTERN_REGEX.SORTASCENDING,
    rawRecords
  );
  if (!_.isEmpty(sortAscendingRawRecordsData)) {
    // only one sortAscending
    sortAscending = _.isEqual(sortAscendingRawRecordsData[0].value, "true");
  }

  const sortByRawRecordsData = filterRawRecordsDataByPattern(PATTERN_REGEX.SORTBY, rawRecords);
  if (!_.isEmpty(sortByRawRecordsData)) {
    // only one sortBy
    sortBy = sortByRawRecordsData[0].value;
  }
  return {
    sortAscending,
    sortBy
  };
};

/**
 * Convert query string to variables.
 * Using in assets listing and account inwallet screen.
 * @param queryString get in path
 * @returns AssetSearchQueryInQueryStringVariableType
 */
export const convertQueryStringToVariables = (
  queryString: string,
  screenMode: SCREEN.LISTING | SCREEN.INWALLET = SCREEN.LISTING
): AssetSearchQueryInQueryStringVariableType => {
  const rawRecords = splitRawRecordsFromQueryString(queryString);
  const toggles = convertTogglesRawRecordsToVariables(rawRecords);
  const paymentAssets = convertPaymentAssetsRawRecordsToVariables(rawRecords);
  const collections = convertCollectionsAssetsRawRecordsToVariables(rawRecords);
  const numericTraits = convertNumbericTraitRawRecordsToVariables(rawRecords);
  const stringTraits = convertStringTraitRawRecordsToVariables(rawRecords);
  const categories = convertCategoryRawRecordsToVariables(rawRecords);
  const resultModel = convertResultModelRawRecordsToVariables(rawRecords);

  let defaultSortItem = LISTING_SORT_BY_VARIABLES.INIT as {
    sortAscending: null | boolean;
    sortBy: null | string;
  };
  if (screenMode === SCREEN.INWALLET) {
    defaultSortItem = WALLET_SORT_BY_VARIABLES.INIT;
  }
  const sortItem = convertSortItemRawRecordsToVariables(rawRecords, defaultSortItem);
  return {
    ...toggles,
    ...paymentAssets,
    ...collections,
    ...numericTraits,
    ...stringTraits,
    ...categories,
    ...resultModel,
    ...sortItem
  };
};

/**
 * Convert query string to variables.
 * Using in account activity screen.
 * @param queryString get in path
 * @returns ActivityQueryInQueryStringVariableType
 */
export const convertActivityQueryStringToVariables = (
  queryString: string
): ActivityQueryInQueryStringVariableType => {
  const rawRecords = splitRawRecordsFromQueryString(queryString);
  const eventType = convertEventTypesRawRecordsToVariables(rawRecords);
  const collections = convertCollectionsAssetsRawRecordsToVariables(rawRecords);
  return {
    ...eventType,
    ...collections
  };
};

/**
 * Convert query string to variables.
 * Using in account offers screen.
 * @param queryString get in path
 * @returns OfferSearchInQueryStringVariableType
 */
export const convertOffersQueryStringToVariables = (
  queryString: string
): OfferSearchInQueryStringVariableType => {
  const rawRecords = splitRawRecordsFromQueryString(queryString);
  const collectionsDataVariables = convertCollectionsAssetsRawRecordsToVariables(rawRecords);
  return {
    collections: collectionsDataVariables.collections
  };
};

import {
  FETCH_EXCHANGE_REQUEST,
  FETCH_EXCHANGE_SUCCESS,
  FETCH_EXCHANGE_ERROR
} from '_actions/marketcap.actions';

const initialState = {
  meta: {}
};

const exchanges = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EXCHANGE_REQUEST: {
      return {
        ...state,
        meta: {
          loading: true
        }
      };
    }

    case FETCH_EXCHANGE_SUCCESS: {
      return {
        ...action.exchange,
        meta: {}
      };
    }

    case FETCH_EXCHANGE_ERROR: {
      return {
        ...state,
        meta: {
          error: true,
          errorMessage: action.error.message
        }
      };
    }

    default:
      return state;
  }
};

export default exchanges;

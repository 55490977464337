import { useCallback, useState, useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Contract } from 'ethers';
import ERC20JSON from '../../../abi/ERC20.json';
import FarmingContext from '../context/FarmingContext';
import { signTransaction, getTransactionSigner } from '../../../util/transaction';
import { getContractInstance } from '../../../util/getContractInstance';
import { getAddress, getMasterchefAddress } from '../../../util/getAddresses';

const APPROVE_AMOUNT = '9999999999999999999999999';
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

const useTokenApprove = () => {
  const [approveLoading, setApproveLoading] = useState(false);
  const { fetchDefaultFarms } = useContext(FarmingContext);
  const { isMetaMask, privateKey, address, isWC } = useSelector(state => state.user);

  const web3 = useMemo(() => window.web3, []);

  const masterchefAddress = getMasterchefAddress(CHAIN_ID);

  const handleTokenApprove = useCallback(async (testPool = false, pool) => {
    const signer = await getTransactionSigner(CHAIN_ID, false, isWC);
    const tokenAddress = testPool ? getAddress(pool.lpAddresses) : pool.pair;

    try {
      setApproveLoading(true);

      if (isMetaMask || isWC) {
        const contract = new Contract(tokenAddress, ERC20JSON.abi, signer);
        const transaction = await contract.approve(masterchefAddress, APPROVE_AMOUNT);

        await transaction.wait();
      } else if (!isMetaMask && privateKey) {
        const contract = getContractInstance(web3, ERC20JSON.abi, tokenAddress);

        const data = contract.methods.approve(masterchefAddress, APPROVE_AMOUNT);

        const params = {
          nonce: web3.utils.toHex(await web3.eth.getTransactionCount(address)),
          gasLimit: web3.utils.toHex(
            await data.estimateGas({
              from: address,
              value: '0x00'
            })
          ),
          gasPrice: web3.utils.toHex(await web3.eth.getGasPrice()),
          to: contract._address,
          data: data.encodeABI(),
          value: '0x00'
        };

        await signTransaction(params, privateKey);
      }

      await fetchDefaultFarms();
      setApproveLoading(false);
    } catch (err) {
      console.log(err.message);
      setApproveLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [approveLoading, handleTokenApprove];
};

export default useTokenApprove;

import ModalCommon from '../../../components/ModalCommon/ModalCommon';
import './ConfirmRemoveMdal.scss';
import { getTokenSymbol } from '../../../util/getTokenSymbol';
import GreenPlus from '../../../assets/icons/green-plus.svg';
import React from 'react';
import { Button } from '../../../components';
import icon_close from '../../../assets/swap/close_icon.svg';

export default function ConfirmRemoveModal(props) {
  const {
    isOpen,
    handleClose,
    output1Amount,
    output2Amount,
    output1Token,
    output2Token,
    liquidityTokenAmount,
    onConfirmSupply,
    v2Pair,
    loading
  } = props;

  const [token0Image, token1Image] =
    output1Token && output2Token
      ? [getTokenSymbol(output1Token), getTokenSymbol(output2Token)]
      : [undefined, undefined];

  return (
    <ModalCommon open={isOpen} handleClose={handleClose}>
      <div className="confirm-remove-modal">
        <div className="confirm-remove-title">
          You Will Receive
          <img
            className="btn-icon-close"
            alt=""
            src={icon_close}
            onClick={() => {
              handleClose();
            }}
          />
        </div>
        <div className="confirm-modal-content">
          <div className="confirm-modal-row">
            <div className="confirm-row-amount">{output1Amount}</div>
            <div className="confirm-row-label">
              <img alt="" src={token0Image} />
              <span>{output1Token?.symbol}</span>
            </div>
          </div>
          <div className="plus-icon-row">
            <img alt="" src={GreenPlus} />
          </div>
          <div className="confirm-modal-row">
            <div className="confirm-row-amount">{output2Amount}</div>
            <div className="confirm-row-label">
              <img alt="" src={token1Image} />
              <span>{output2Token?.symbol}</span>
            </div>
          </div>

          <div className="warning-text">
            Output is estimated. If the price changes by more than 1% your transaction will revert
          </div>

          <div className="box-information">
            <div className="box-information-row">
              <div className="box-information-label">
                {output1Token?.symbol}/{output2Token?.symbol} Burned
              </div>
              <div className="box-information-value">
                <img alt="" src={token0Image} />
                <img alt="" src={token1Image} />
                <span>{liquidityTokenAmount}</span>
              </div>
            </div>
            <div className="box-information-row">
              <div className="box-information-label">Price</div>
              <div className="box-information-value">
                <div>
                  1 {output1Token?.symbol} = {v2Pair?.token0Price.toSignificant()}{' '}
                  {output2Token?.symbol}
                </div>
                <div>
                  1 {output2Token?.symbol} = {v2Pair?.token1Price.toSignificant()}{' '}
                  {output1Token?.symbol}
                </div>
              </div>
            </div>
          </div>

          <div className="button-confirm">
            <Button loading={loading} onClick={() => onConfirmSupply()}>
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </ModalCommon>
  );
}

import { reduxForm } from 'redux-form';
import { useEffect, useMemo, useState } from 'react';
import './stake.scss';
import { useDispatch, useSelector } from 'react-redux';
import { changeTitlePage } from '_actions/titlePage.actions';
import Button from 'components/Button/Button';
import Web3 from 'web3';
import ERC20 from '../Swap/uni/ERC20.json';
import BigNumber from 'bignumber.js';
import SushiBarJson from './SushiBar.json';
import { toast } from 'react-toastify';
import HeaderCommon from '../../components/HeaderCommon/HeaderCommon';
import { Container } from 'components/GridSystem/GridSystem';
import { config } from '../../config';
import { toFixed } from '../../util/liquidity-utils';
import { isNotValidASCIINumber, isPreventASCIICharacters } from '../../util/input';
import ModalCommon from '../../components/ModalCommon/ModalCommon';
import question from '../../assets/swap/icon_pr.svg';
import icon_close from '../../assets/swap/close_icon.svg';
import { getTransactionSigner } from '../../util/transaction';
import { Contract } from 'ethers';

const Tx = require('ethereumjs-tx').Transaction;
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

const sushiSmartContract = '0x9fD715F62AbcB402682889fb958FA88a9fD1cF16';
const xShushiSmartContract = '0x255DA82e06056A807B89320c5618B804AcCa0CAa';

function Stake(props) {
  const dispatch = useDispatch();
  const [isApprovedSushi, setIsApprovedSushi] = useState(false);
  const [isApprovedXSushi, setIsApprovedXSushi] = useState(false);
  const [isLoadingSushi, setIsLoadingSushi] = useState(false);
  const [isLoadingXSuShi, setIsLoadingXSushi] = useState(false);
  const [sushiBalance, setSushiBalance] = useState(0);
  const [xSushiBalance, setXSushiBalance] = useState(0);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [web3, setWeb3] = useState();
  const [sushiAmount, setSushiAmount] = useState();
  const [xSushiAmount, setXSushiAmount] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [sushiAllowance, setSushiAllowance] = useState(0);
  const [errorMessageSushi, setErrorMessageSushi] = useState();
  const [errorMessageXSushi, setErrorMessageXSushi] = useState();
  const [isDeposit, setIsDeposit] = useState(false);
  const user = useSelector(state => state.user);
  const bigXsushiBalance = new BigNumber(xSushiBalance);
  const bigSushiBalance = new BigNumber(sushiBalance);

  const checkAllowanceSushi = async () => {
    const sushiContract = await new web3.eth.Contract(ERC20.abi, sushiSmartContract);
    const sushiAllowance = await sushiContract.methods
      .allowance(user.address, xShushiSmartContract)
      .call();
    setSushiAllowance(sushiAllowance);
    if (new BigNumber(sushiAllowance).isGreaterThan(0)) {
      setIsApprovedSushi(true);
      setIsApprovedXSushi(true);
    }
  };

  const approve = async () => {
    const sushiContract = await new web3.eth.Contract(ERC20.abi, sushiSmartContract);
    const decimals = await sushiContract.methods.decimals().call();
    const amountIn = new BigNumber(99999999).times(new BigNumber(10).pow(decimals)).toString();
    const data = sushiContract.methods.approve(
      xShushiSmartContract,
      '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
    );
    if (user.isWC) {
      setLoadingApprove(true);
      data.send({ from: user.address }, (error, transactionHash) => {
        if (error) {
          console.log(error);
        } else {
          toast.success('Approve transaction successfully!');
          setIsApprovedSushi(true);
          setIsApprovedXSushi(true);
          setSushiAllowance(toFixed(amountIn));
        }
        setLoadingApprove(false);
      });
    } else if (user.privateKey) {
      try {
        setLoadingApprove(true);
        const params = {
          nonce: web3.utils.toHex(await web3.eth.getTransactionCount(user.address)),
          gasLimit: web3.utils.toHex(await data.estimateGas({ from: user.address })),
          gasPrice: web3.utils.toHex(await web3.eth.getGasPrice()),
          to: sushiSmartContract,
          data: data.encodeABI(),
          value: '0x00' // 0
        };
        await signTransaction(params, () => {
          toast.success('Approve transaction successfully!');
          setIsApprovedSushi(true);
          setIsApprovedXSushi(true);
          setLoadingApprove(false);
        });
      } catch (er) {
        console.log(er);
        setLoadingApprove(false);
      }
    } else {
      setLoadingApprove(true);
      data
        .send({ from: user.address }, error => {
          if (error) {
            setLoadingApprove(false);
            console.log(error);
          }
        })
        .once('confirmation', () => {
          toast.success('Approve transaction successfully!');
          setIsApprovedSushi(true);
          setIsApprovedXSushi(true);
          setLoadingApprove(false);
          setSushiAllowance(toFixed(amountIn));
        });
    }
  };

  const signTransaction = async (params, callback) => {
    const tx = new Tx(params, { chain: config.chain_name.toLowerCase() });
    tx.sign(Buffer.from(user.privateKey, 'hex'));
    const serializeTx = tx.serialize();
    await web3.eth
      .sendSignedTransaction('0x' + serializeTx.toString('hex'))
      .once('confirmation', (e, receipt) => {
        checkAllowanceSushi();
        fetchBalance();
        callback();
      });
  };

  const depositSuShi = async () => {
    const xSushi = new web3.eth.Contract(SushiBarJson, xShushiSmartContract);
    const decimals = await xSushi.methods.decimals().call();
    const amountIn = new BigNumber(sushiAmount).times(new BigNumber(10).pow(decimals)).toFixed();

    if (user.privateKey) {
      const data = xSushi.methods.enter(amountIn);

      try {
        setIsLoadingSushi(true);
        const params = {
          nonce: web3.utils.toHex(await web3.eth.getTransactionCount(user.address)),
          gasLimit: web3.utils.toHex(await data.estimateGas({ from: user.address })),
          gasPrice: web3.utils.toHex(await web3.eth.getGasPrice()),
          to: xShushiSmartContract,
          data: data.encodeABI(),
          value: '0x00' // 0
        };
        await signTransaction(params, () => {
          setIsLoadingSushi(false);
          toast.success('Deposit transaction successfully!');
        });
      } catch (e) {
        setIsLoadingSushi(false);
        console.log(e);
      }
    } else {
      setIsLoadingSushi(true);
      const signer = await getTransactionSigner(CHAIN_ID, false, user.isWC);
      const xSushi = new Contract(xShushiSmartContract, SushiBarJson, signer);
      try {
        const transaction = await xSushi.enter(amountIn);
        await transaction.wait(1);
        toast.success('Deposit transaction successfully!');
        setIsLoadingSushi(false);
        fetchBalance();
      } catch (err) {
        setIsLoadingSushi(false);
        console.log(err);
      }
    }
  };

  const withdrawXSushi = async () => {
    if (isLoadingXSuShi) {
      return;
    }
    const xSushi = new web3.eth.Contract(SushiBarJson, xShushiSmartContract);
    const decimals = await xSushi.methods.decimals().call();
    const amountIn = new BigNumber(xSushiAmount).times(new BigNumber(10).pow(decimals)).toFixed();
    const data = xSushi.methods.leave(amountIn);

    if (user.privateKey) {
      try {
        setIsLoadingXSushi(true);
        const params = {
          nonce: web3.utils.toHex(await web3.eth.getTransactionCount(user.address)),
          gasLimit: web3.utils.toHex(await data.estimateGas({ from: user.address })),
          gasPrice: web3.utils.toHex(await web3.eth.getGasPrice()),
          to: xShushiSmartContract,
          data: data.encodeABI(),
          value: '0x00' // 0
        };

        await signTransaction(params, () => {
          setIsLoadingXSushi(false);
          toast.success('Withdraw transaction successfully!');
        });
      } catch (e) {
        console.log(e);
        setIsLoadingXSushi(false);
      }
    } else {
      setIsLoadingXSushi(true);
      const signer = await getTransactionSigner(CHAIN_ID, false, user.isWC);
      const xSushi = new Contract(xShushiSmartContract, SushiBarJson, signer);

      try {
        const transaction = await xSushi.leave(amountIn);
        await transaction.wait(1);
        toast.success('Withdraw transaction successfully!');
        fetchBalance();
        setIsLoadingXSushi(false);
      } catch (err) {
        setIsLoadingXSushi(false);
        console.log(err);
      }
    }
  };

  const onSetMaxAmountSuShi = () => {
    setSushiAmount(sushiBalance);
    onValidateMessage(sushiBalance, true);
  };

  const onSetMaxAmountXSuShi = () => {
    setXSushiAmount(xSushiBalance);
    onValidateMessage(xSushiBalance, false);
  };

  const onCheckAllowanceSushi = useMemo(() => {
    const result = new BigNumber(sushiAmount ? sushiAmount : 0)
      .times(new BigNumber(10).pow(18))
      .lte(sushiAllowance);
    return result || bigSushiBalance.lt(sushiAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sushiAllowance, sushiAmount]);

  const onCheckAllowanceXSushi = useMemo(() => {
    const result = new BigNumber(xSushiAmount ? xSushiAmount : 0)
      .times(new BigNumber(10).pow(18))
      .lte(sushiAllowance);
    return result || bigXsushiBalance.lt(xSushiAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sushiAllowance, xSushiAmount]);

  const fetchBalance = async () => {
    try {
      const sushiBalance = await new web3.eth.Contract(ERC20.abi, sushiSmartContract).methods
        .balanceOf(user.address)
        .call();
      console.log(web3.utils.fromWei(sushiBalance));
      setSushiBalance(web3.utils.fromWei(sushiBalance));
      const xShushiBalance = await new web3.eth.Contract(ERC20.abi, xShushiSmartContract).methods
        .balanceOf(user.address)
        .call();
      setXSushiBalance(web3.utils.fromWei(xShushiBalance));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (web3) {
      fetchBalance();
      checkAllowanceSushi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3]);

  useEffect(() => {
    dispatch(changeTitlePage('Stake'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user.isWC) {
      setWeb3(new Web3(window.web3));
    } else if (user.privateKey) {
      setWeb3(new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_URL)));
    } else {
      setWeb3(new Web3(window.ethereum));
    }
  }, [user]);

  const onValidateMessage = (value, isSushi) => {
    const amount = new BigNumber(value);
    let message = '';
    if (!value) {
      message = 'The field is required!';
    } else if (new BigNumber(value).isZero()) {
      message = 'The amount must be greater than 0';
    } else if (amount.isGreaterThan(isSushi ? sushiBalance : xSushiBalance)) {
      message = 'The balance is not enough.';
    }
    isSushi ? setErrorMessageSushi(message) : setErrorMessageXSushi(message);
    return !!message;
  };

  return (
    <Container>
      <HeaderCommon user={user} />
      <div className="stake-container">
        <div className="stake-form">
          <div className="stake-title">
            Sushi <span className="green-arrow">&#8594;</span> xSushi
          </div>
          <div className="form__row">
            <div className="form-row-label">
              <div className="label-stake">SUSHI</div>
              <div className="balance-stake">Sushi Balance: {sushiBalance}</div>
            </div>
            <div className="form-row-input">
              <div className="field-group">
                <input
                  className="form__input"
                  onKeyDown={e => isNotValidASCIINumber(e.keyCode, true) && e.preventDefault()}
                  onKeyPress={e => isPreventASCIICharacters(e.key) && e.preventDefault()}
                  value={sushiAmount}
                  onChange={e => {
                    const inputVal = e.target.value;
                    setSushiAmount(inputVal);
                  }}
                  onFocus={() => setErrorMessageSushi('')}
                  maxLength={255}
                  type="number"
                  placeholder={0}
                />
                <Button onClick={() => onSetMaxAmountSuShi()} className="max-button" small={true}>
                  Max
                </Button>
              </div>
              {isApprovedSushi && onCheckAllowanceSushi ? (
                <Button
                  onClick={() => {
                    const message = onValidateMessage(sushiAmount, true);
                    if (message) {
                      return;
                    }
                    setIsDeposit(true);
                    setConfirmModal(true);
                  }}
                  loading={isLoadingSushi}
                  small={true}
                  className="approve-button"
                >
                  Deposit
                </Button>
              ) : (
                <Button
                  onClick={approve}
                  loading={loadingApprove}
                  small={true}
                  className="approve-button"
                >
                  Approve
                </Button>
              )}
            </div>
            <div className="error-message">{errorMessageSushi}</div>
          </div>
          <div className="form__row">
            <div className="form-row-label">
              <div className="label-stake">xSUSHI</div>
              <div className="balance-stake">xSushi Balance: {xSushiBalance}</div>
            </div>
            <div className="form-row-input">
              <div className="form-row-input">
                <div className="field-group">
                  <input
                    className="form__input"
                    onKeyDown={e => isNotValidASCIINumber(e.keyCode, true) && e.preventDefault()}
                    onKeyPress={e => isPreventASCIICharacters(e.key) && e.preventDefault()}
                    value={xSushiAmount}
                    onChange={e => {
                      const inputVal = e.target.value;
                      setXSushiAmount(inputVal);
                    }}
                    onFocus={() => setErrorMessageXSushi('')}
                    maxLength={255}
                    type="number"
                    placeholder={0}
                  />
                  <Button
                    onClick={() => onSetMaxAmountXSuShi()}
                    className="max-button"
                    small={true}
                  >
                    Max
                  </Button>
                </div>

                {isApprovedXSushi && onCheckAllowanceXSushi ? (
                  <Button
                    onClick={() => {
                      const message = onValidateMessage(xSushiAmount, false);
                      if (message) {
                        return;
                      }
                      setIsDeposit(false);
                      setConfirmModal(true);
                    }}
                    small={true}
                    loading={isLoadingXSuShi}
                    className="approve-button"
                  >
                    Withdraw
                  </Button>
                ) : (
                  <Button
                    loading={loadingApprove}
                    small={true}
                    onClick={approve}
                    className="approve-button"
                  >
                    Approve
                  </Button>
                )}
              </div>
            </div>
            <div className="error-message">{errorMessageXSushi}</div>
          </div>
        </div>
        <div className="stake-description">
          <div className="stake-description-title">SushiBar: Make SUSHI work for you</div>
          <div className="stake-description-row">
            Stake your SUSHI into xSUSHI for ~15% APY. No impermanent loss, no loss of governance
            rights. Continuously compounding.
          </div>
          <div className="stake-description-row">
            xSUSHI automatically earn fees (0.05% of all swaps, including multichain swaps)
            proportional to your share of the SushiBar.
          </div>
        </div>
        <ModalCommon
          open={confirmModal}
          handleClose={() => {
            setConfirmModal(false);
          }}
        >
          <div className="confirm-modal-container-stake">
            <img
              className="btn-icon-close"
              alt=""
              src={icon_close}
              onClick={() => {
                setConfirmModal(false);
              }}
            />
            <div className="confirm-stake-content">
              <div className="image-wrapper">
                <img alt="" src={question} />
              </div>
              <div className="text-label">Do you want to {isDeposit ? 'deposit' : 'withdraw'}?</div>
              <div className="button-wrapper">
                <Button onClick={() => setConfirmModal(false)} className="cancel-button">
                  Cancel
                </Button>
                {isDeposit ? (
                  <Button
                    loading={isLoadingSushi}
                    onClick={async () => {
                      await depositSuShi();
                      setConfirmModal(false);
                    }}
                    className="ml-5"
                  >
                    Confirm
                  </Button>
                ) : (
                  <Button
                    loading={isLoadingXSuShi}
                    onClick={async () => {
                      await withdrawXSushi();
                      setConfirmModal(false);
                    }}
                    className="ml-5"
                  >
                    Confirm
                  </Button>
                )}
              </div>
            </div>
          </div>
        </ModalCommon>
      </div>
    </Container>
  );
}

let StakeForm = reduxForm({
  form: 'stakeForm'
})(Stake);

export default StakeForm;

import {useState} from 'react';
import BigNumber from 'bignumber.js';
import {getTokenSymbol} from '../../../../util/getTokenSymbol';
import usePairDetails from '../../hooks/usePairDetails';
import down_icon from '../../../../assets/swap/down_icon.svg';
import IconUp from '../../../../assets/icons/icon-up.svg';
import './LiquidityItem.scss';
import {Button} from '../../../../components';
import usePoolPair from '../../hooks/usePoolPair';

export default function LiquidityItem(props) {
  const [isActive, setIsActive] = useState(false);
  const {pair, onClickRemove, selectedPool} = props;

  const pairDetails = usePairDetails(pair);
  const [, , token0Deposited, token1Deposited] = usePoolPair(pairDetails, pair, selectedPool);

  const token0 = pair.token0;
  const token1 = pair.token1;

  const symbol = `${token0.symbol === 'WETH' ? 'ETH' : token0.symbol}/${token1.symbol === 'WETH' ? 'ETH' : token1.symbol}`;

  const totalPooledTokens = pairDetails
    ? new BigNumber(pairDetails.balance).div(new BigNumber(10).pow(18)).toFormat(5)
    : '0.00';

  const totalPoolShares = pairDetails
    ? new BigNumber(pairDetails.balance)
      .div(new BigNumber(pairDetails.totalSupply))
      .times(100)
      .toFormat(5)
    : '0.00';

  const [token0Image, token1Image] =
    token0 && token1
      ? [getTokenSymbol(pair.token0), getTokenSymbol(pair.token1)]
      : [undefined, undefined];

  return (
    <div className="liquidity-item">
      <div className="liquidity-item-label">
        <div className="liquidity-item-pair">
          <img alt="" src={token0Image} />
          <img alt="" className="ml-5" src={token1Image} />
          <span className="ml-5">{symbol}</span>
        </div>
        <div className="liquidity-item-button" onClick={() => setIsActive(!isActive)}>
          <span>Manage</span>
          <img src={isActive ? IconUp : down_icon} alt="" />
        </div>
      </div>
      {isActive
        ? pairDetails && (
          <div className="liquidity-item-content">
            <div className="lq-item-row">
              <div className="lq-item-label">Your total pool tokens</div>
              <div className="lq-item-value">
                {new BigNumber(totalPooledTokens).lte(new BigNumber("0.01")) ? "<0.01" : totalPooledTokens}
              </div>
            </div>
            <div className="lq-item-row">
              <div className="lq-item-label">Pooled {pair && pair.token0.symbol}</div>
              <div className="lq-item-value">
                {token0Deposited && token0Deposited.toSignificant(6)}
              </div>
            </div>
            <div className="lq-item-row">
              <div className="lq-item-label">Pooled {pair && pair.token1.symbol}</div>
              <div className="lq-item-value">
                {token1Deposited && token1Deposited.toSignificant(6)}
              </div>
            </div>
            <div className="lq-item-row">
              <div className="lq-item-label">Your pool share</div>
              <div className="lq-item-value">
                {new BigNumber(totalPoolShares).lte(0) ? `< ${0.01}` : totalPoolShares}%
              </div>
            </div>
            <div className="lq-button-group">
              <Button
                onClick={() => {
                  props.onClickAdd(pair);
                }}
                outlined
              >
                Add
              </Button>
              <Button onClick={() => onClickRemove(pair)} outlined>
                Remove
              </Button>
            </div>
          </div>
        )
        : ''}
    </div>
  );
}

const ccxt = require('ccxt');

const binance = new ccxt['binance']({ enableRateLimit: true });
const bittrex = new ccxt['bittrex']({
  enableRateLimit: true,
  proxy: process.env.REACT_APP_PROXY_URL,
  proxies: {
    http: process.env.REACT_APP_PROXY_URL,
    https: process.env.REACT_APP_PROXY_URL
  }
});
const kraken = new ccxt['kraken']({ enableRateLimit: true });
const kucoin = new ccxt['kucoin']({ enableRateLimit: true });
const poloniex = new ccxt['poloniex']({ enableRateLimit: true });

const exchange_clients = {
  binance,
  bittrex,
  kraken,
  kucoin,
  poloniex
};

module.exports = exchange_clients;

import { useState, useEffect, useContext } from 'react';
import FarmingContext from '../context/FarmingContext';
import BigNumber from 'bignumber.js';
import { debounce } from 'debounce';
import './farming.scss';
import FarmingTableRecord from '../FarmingTableRecord';

BigNumber.config({ DECIMAL_PLACES: 18 });

const TABLE_HEADERS = ['Farms', 'Yield Per $1k', 'ROI', 'Liquidity', 'Staked', 'Earnings', ''];

const FarmingTable = props => {
  const { pools, testPools } = props;
  const [poolOpen, setPoolOpen] = useState(undefined);
  const [searchPools, setSearchPools] = useState([]);
  const { inputSearch } = useContext(FarmingContext);

  const handleFarmSearching = debounce(() => {
    if (pools && pools.length > 0 && inputSearch) {
      const filterPools = [...pools].filter(pool => {
        const { token0, token1 } = pool;
        const { symbol: token0Symbol } = token0;
        const { symbol: token1Symbol } = token1;

        const lpSymbol = `${token0Symbol}-${token1Symbol}`;
        return lpSymbol.toLowerCase().includes(inputSearch.toLowerCase());
      });

      setSearchPools(filterPools);
    }
  }, 1000);

  useEffect(() => {
    if (pools && pools.length > 0) {
      handleFarmSearching();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch]);

  const renderPools = () => {
    if (pools && pools.length > 0) {
      const displayPools = !inputSearch ? pools : searchPools;

      return displayPools.map(pool => {
        if (pool) {
          return (
            <FarmingTableRecord
              key={pool.id}
              pool={pool}
              poolOpen={poolOpen}
              setPoolOpen={setPoolOpen}
            />
          );
        }
        return <></>;
      });
    }
  };

  return (
    <div className="Farming__table-wrapper">
      <table className="Farming__table">
        <thead className="Farming__thead">
          <tr className="Farming__tr">
            {TABLE_HEADERS.map(header => (
              <th className="Farming__th" key={header}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="Farming__tbody">
          {testPools &&
            testPools.length > 0 &&
            testPools.map(pool => (
              <FarmingTableRecord
                pool={pool}
                testPool
                key={pool.pid}
                setPoolOpen={setPoolOpen}
                poolOpen={poolOpen}
              />
            ))}
          {renderPools()}
        </tbody>
      </table>
    </div>
  );
};

export default FarmingTable;

import BalanceService from '../service_v2/BalanceService';
import TradeService from 'service_v2/TradeService';

export function getBalance({ exchangeName, markets }) {
  return BalanceService.getBalances(exchangeName, markets);
}

export function getExchangeBalance({ exchangeName }) {
  return BalanceService.getExchangeBalance(exchangeName);
}

/**
 * Fetches historical balance for user
 * @param {string} currencySymbol
 */
export function getHistoricalNetworth(currencySymbol = 'USD') {
  return {
    data: {
      usd: [
        {
          value: 0,
          date: 1609143124824,
          currency: 'USD',
          formattedDate: '2020-12-28@08:12:04.824+0000'
        }
      ]
    }
  };
}

/**
 * Fetches base networth for user
 */
export async function getBaseNetworth() {
  return { data: { usd: 1, btc: 1, date: '2020-12-28@08:12:04.256+0000' } };
}

export const getOrders = ({ exchangeName, symbol }) => {
  return BalanceService.getExchangeOrders(exchangeName);
};

export const getExchangeOrders = ({ exchangeName }) => {
  return BalanceService.getExchangeOrders(exchangeName);
};

export async function postOrder({ order }) {
  const result = await TradeService.createOrder(order);
  return result;
}

export async function deleteOrder({ order, exchangeName, symbol }) {
  const result = await TradeService.cancelOrder(order, exchangeName, symbol);
  return result;
}
